<template>
    <profile-field-layout 
        ref="layout"
        v-bind="layout"
        :editable="editable" 
        :iconSrc="iconSrc"
        :value-class="valueClass"
        :value-display-str="valueDisplayStr" 
        :placeholder="placeholder"
        :validation-errors="validationErrors"
        :hint="hint"
        :hint-description="hintDescription">

        <!-- замена valueDisplayStr() -->
        <template #valueDisplay v-if="tags">
            <template v-if="valueDisplay.isAnySelected">
                {{ getPrefix() }}<span v-for="tag in valueDisplay.labels" :key="tag" :class="tagClass">{{ tag }}</span>{{ getPostfix() }}
            </template>
            <template v-else>{{ valueDisplay.placeholder }}</template>
        </template>

        <template #editor>
        <li v-if="tags" class="dropdownEl flex a-i-center">
            <multiselect
                ref="select"
                v-model="value" 
                tag-placeholder="Добавить новый тэг" 
                placeholder="Найти или добавить тэг"
                :preserve-search="true"
                :options="optionsDisplay" 
                :multiple="multiple" 
                :taggable="true"
                :loading="isLoading" 
                :internal-search="false" 
                :options-limit="300" 
                select-label="Выбрать"
                selected-label="Выбрано"
                :dropdownVisible="useNativeDropdown"
                :blockKeys="['Delete', 'Backspace']"
                @search-change="updateChoices"
                @tag="onAddTag"
                @keydown.enter="onSelectPressEnter($refs.select, $event)">
            </multiselect>
        </li>
        <li v-else class="dropdownEl flex a-i-center">
            <multiselect 
                    ref="select"
                    v-model="valueObject" 
                    id="ajax" 
                    label="label" 
                    track-by="value" 
                    :preserve-search="true"
                    :reset-after="false"
                    :placeholder="placeholder" 
                    open-direction="bottom" 
                    :options="currentChoices" 
                    :multiple="multiple" 
                    :searchable="true" 
                    :loading="isLoading" 
                    :internal-search="false" 
                    :clear-on-select="false" 
                    :close-on-select="false" 
                    :options-limit="300" 
                    :limit="5" 
                    :limit-text="limitText" 
                    select-label="Выбрать"
                    selected-label="Выбрано"
                    :max-height="2600" 
                    :show-no-results="false" 
                    :hide-selected="false" 
                    :dropdownVisible="useNativeDropdown"
                    @search-change="updateChoices"
                    @keydown.enter="onSelectPressEnter($refs.select, $event)">
                <template v-slot:option="scope">
                    <span class="option__title" style="white-splace: wrap;">{{ scope.option.label }}</span>
                </template>
                <template v-slot:clear="scope">
                    <div class="multiselect__clear" v-if="value" @mousedown.prevent.stop="clearAll(scope.search)"></div>
                </template>
                <template #noResult>
                    <span>Oops! No elements found. Consider changing the search query.</span>
                </template>
            </multiselect>
        </li>
        <template v-if="!useNativeDropdown">
            <li class="dropdownEl flex a-i-center" v-for="(opt, index) in optionsDisplay" :key="''+opt.value+opt.isChecked">
                <input v-if="multiple" class="form-check-input" type="checkbox"  
                    :id="labelAttrForOptions+opt.value" 
                    name="labelAttrForOptions+opt.value" 
                    @change="onChangeOption(opt.value, $event.target.checked, multiple, $event)"
                    :checked="opt.isChecked">
                <input v-else class="form-check-input" type="radio" 
                    :name="labelAttrForOptions" 
                    :id="labelAttrForOptions+opt.value"  
                    :value="opt.value"
                    :checked="opt.isChecked"
                    @change="onChangeOption(opt.value, $event.target.checked, multiple, $event)">
                <label class="form-check-label findSettings-label" :for="labelAttrForOptions+opt.value">
                    {{ opt.label }}
                </label>
            </li>
        </template>
        </template>
    </profile-field-layout>
</template>

<script>
import ProfilePlainField from './ProfilePlainField.vue'
import SelectFieldMixin from './SelectFieldMixin'
import ProfileVueMultiselect from '@/components/ProfileVueMultiselect.vue'


/**
 * Поле с выбором и поиском
 * @class
 */
 export default {
    mixins: [ProfilePlainField, SelectFieldMixin],
    props: {
        useNativeDropdown: { type: Boolean, default: false },
    },
    components: {
        multiselect: ProfileVueMultiselect
    },
    methods: {
        onAddTag(newTag) {
            newTag = newTag.trim();
            if (!newTag)
                return;
            if (!this.currentChoices)
                this.currentChoices = [];
            if (!this.currentChoices.find(c => c))
                this.currentChoices.unshift(newTag);
            if (!this.value)
                this.value = [];
            if (!this.value.indexOf(newTag) !== -1)
                this.value.unshift(newTag);
        },
        /**
         * Возвращает текст сообщения о превышении лимита количества отображаемых элементов
         * @param {Number} count количество не вместившихся элементов
         * @method
         */
        limitText(count) {
            return `и ${count} других выбранных вариантов`
        },
        clearAll() {
            this.value = undefined
        },
        /**
         * Очистить инпут для поиска вариантов 
         * @method
         */
        clearSearch() {
            this.$refs.select.search = '';
        },
        onSelectPressEnter($multiselect, $event) {
            if (this.currentChoices) {
                if (this.currentChoices.length === 1) {
                    const singleChoice = this.currentChoices[0];
                    if (this.tags) {
                        if (this.value) {
                            this.value.unshift(singleChoice);
                        } else {
                            this.value = [singleChoice];
                        }
                    } else {
                        if (this.multiple) {
                            if (this.value) {
                                this.value.unshift(singleChoice.value);

                            } else {
                                this.value = [singleChoice.value];
                            }
                        } else {
                            this.value = singleChoice.value;
                        }
                    }
                    this.clearSearch();
                }
            }
        },
        onChangeOption(value, isChecked, isMultiple, $event) {
            $event.stopPropagation()
            $event.preventDefault()
            this.checkOption(value, isChecked, isMultiple);
            this.clearSearch()
        }
    },
    computed: {
        labelAttrForOptions() {
            return `option-${this.schema}-${this.field}-`
        },
        /**
         * Адаптер значения this.value для виджета vue-multiselect,
         * т.к. 
         */
        valueObject: {
            cache: false,
            set(val) {
                console.log(`[ProfileAutocompleteSelectField] computed valueObject set(): val=`, val)
            },
            get() {
                if (this.multiple) {
                    if (!Array.isArray(this.value)) return [];
                    return this.value.map(v => ({ value: v, label: this.getLabel(v, this.labelOfUnlabeledChoice) }));
                } else {
                    return { value: this.value, label: this.getLabel(this.value, this.labelOfUnlabeledChoice) };
                }
            }
        }
    }
};
</script>