// export const MESSAGE_TAGS_REGEXP = /\[\[(\w+)\s+(\w+\s*:\s*\".*?\"\s*)*\s*\]\]/gi;
import {
    // EVENTS,
    TextExtraItemTypeEnum,
    // MessengerSelectorStyleEnum,
} from "./constants";

// export const MESSAGE_TAGS_REGEXP = /\[\[(\w+)\s+(\"\w+\"\s*:\s*\\?\".*?\\?\"\s*)*\s*\]\]/gi
export const MESSAGE_TAGS_REGEXP = /\[@(\w*)\s*(\{.*\})\s*\]/gi


export function * matchAllMessageTags(messageText){
    let rawTag, name, attrs;
    for(const match of messageText.matchAll(MESSAGE_TAGS_REGEXP)){
        try {
            [rawTag, name, attrs] = match;
            // attrs = attrs.replace('\\"', '"')
            // attrs = `{${attrs}}`
            attrs = JSON.parse(attrs)
            match.rawTag = rawTag
            match.name = name
            match.attrs = attrs
            yield match
        } catch (e) {
            console.warn('[content/text_extra/utils] matchAllMessageTags(): failed parsing tag, error=', e)
            console.log('[content/text_extra/utils] matchAllMessageTags(): tag match=', match)
            
        }
    } 
}


export const tag2HtmlConverters = {
    emj: {
        toHtml: (m) => {
            return `<img src="${m.attrs.slug}"/>`
        } ,
        // make: (m) => `[[emj "src":"${m.attrs.src}" "slug":"${m.attrs.slug}"]]`,
    },
    stk: {
        toHtml: (m) => `<img src="${m.attrs.src}"/>`,
        toCode: (m) => `[[stk "slug":"${m.slug}"]]`
    },
}

export function slug2Emoji(slug){
    const code = ("" + slug).substring(1);
    return String.fromCodePoint(parseInt(code, 16));
}

export function tag2HtmlConverter(matchTag, collections_list, get_src_fn=undefined, as_txt=false){
    const coll = collections_list.filter(c => c.slug == matchTag.attrs.cs)[0]
    if (!coll) return undefined
    const item = coll.items.filter(i => i.slug == matchTag.attrs.s)[0]
    if (!item) return undefined
    const src = get_src_fn ? get_src_fn(item.src) : item.src
    if (as_txt){
        return `<em class="dialog-textextra" 
                    data-colltype="${coll.type}" 
                    data-collslug="${coll.slug}"
                    data-type="${item.type}" 
                    data-slug="${item.slug}" 
                    > 
                    ${coll.type_label} ${slug2Emoji(item.slug)} 
                </em>`    
    }
    return `<br><img class="dialog-textextra dialog-textextra-image" 
                src="${src}" 
                alt="${slug2Emoji(item.slug)}" 
                data-colltype="${coll.type}" 
                data-collslug="${coll.slug}"
                data-type="${item.type}" 
                data-slug="${item.slug}" 
            /><br>`
}

export function getTextExtraSendText(text_extra, collection) {
    if (text_extra.type == TextExtraItemTypeEnum.EMOJI) {
        return slug2Emoji(text_extra.slug);
    }
    let attrs = JSON.stringify({ s: text_extra.slug, cs: collection.slug, t: text_extra.type })
    return `[@te${attrs}]`;
}
