import { API_URL } from "@/globals";
import {
    // NotificationUserIdTypeEnum,
    WsDataTypeEnum,
    // MessageStatus,
} from "./constants";
import { connector } from "./websocket";
import api from "./index";

/**
 * Сервис описывающий бизнес-логику связанную с изображениями(фото) пользователей.
 * @module
 */

export const startTyping = ({ ws, to_id }) => {
    console.log("[api/Messenger] startTyping(): {ws, to_id}=", { ws, to_id });
    return connector.sendJSON({
        type: WsDataTypeEnum.USER_TYPING,
        to_id,
    });
    // console.debug("[api/Messenger] startTyping(): ws=", ws);
};

export const stopTyping = ({ ws, to_id }) => {
    console.log("[api/Messenger] stopTyping(): {ws, to_id}=", { ws, to_id });
    return connector.sendJSON({
        type: WsDataTypeEnum.USER_STOPPED_TYPING,
        to_id,
    });
    // console.debug("[api/Messenger] stopTyping(): ws=", ws);
};

export const sendMessage = ({ ws, to_id, message }) => {
    console.log("[api/Messenger] sendMessage(): {ws, to_id, message}=", {
        ws,
        to_id,
        message,
    });
    return connector.sendJSON({
        type: WsDataTypeEnum.MESSAGE_SENT,
        to_id,
        data: {
            message,
        },
    });
    // console.debug("[api/Messenger] sendMessage(): ws=", ws);
};

export const queueHistory = ({
    ws,
    to_id = undefined,
    dialog_single = false,
    dialogs_all = false,
    time_from = undefined,
} = {}) => {
    console.log(
        "[api/Messenger] queueHistory(): {ws, to_id, dialog_single, dialogs_all}=",
        { ws, to_id, dialog_single, dialogs_all }
    );
    if (!dialog_single && !dialogs_all) {
        dialog_single = true;
    }
    return connector.sendJSON({
        type: WsDataTypeEnum.HISTORY,
        to_id,
        data: {
            dialog_single,
            dialogs_all,
            time_from,
        },
    });
    // console.debug("[api/Messenger] queueHistory(): ws=", ws);
};

export const markRead = ({
    ws,
    to_id = undefined,
    messages_ids = false,
} = {}) => {
    console.log("[api/Messenger] markRead(): {to_id, messages_ids}=", {
        to_id,
        messages_ids,
    });
    return connector.sendJSON({
        type: WsDataTypeEnum.READ,
        to_id,
        data: {
            messages_ids,
        },
    });
    // console.debug("[api/Messenger] markRead(): ws=", ws);
};

export const clientStatus = ({
    client_id,
}) => {
    console.log("[api/Messenger] clientStatus(): {client_id,}=", {
        client_id,
    });
    return connector.sendJSON({
        type: WsDataTypeEnum.CLIENT_STATUS,
        to_id: client_id,
        client_id,
    });
    // console.debug("[api/Messenger] markRead(): ws=", ws);
};


export function connectWsHandlers({ ws }) {
    ws.onopen = (e) => {
        // on connecting, do nothing but log it to the console
        console.log(
            "[api/Messenger] onopen(): connected. event, websocket=",
            e,
            ws
        );
    };

    ws.onclose = (e) => {
        console.log(
            "[api/Messenger] onclose(): disconnected. event, websocket=",
            e,
            ws
        );
        // automatically try to reconnect on connection loss
    };
    ws.onerror = (e) => {
        console.error("[api/Messenger] onerror(). event, websocket=", e, ws);
        // automatically try to reconnect on connection loss
    };
}

export function* getWebSocket({
    name,
    url,
    retryMaxCount = 1000,
    always = false,
}) {
    let retryCount = 0;
    while (always || retryCount < retryMaxCount) {
        let ws = new WebSocket(url);

        if (ws) {
            connectWsHandlers({ ws });
            retryCount = 0;
            yield { ws, retryCount };
            console.log("[api/Messenger] *getWebSocket(): yelded");
        } else {
            retryCount++;
        }

        if (ws) {
            ws.close();
        }
    }
}

export function* connect({
    host = API_URL.hostname,
    api_url = "/api/v1/user/messenger/ws",
    protocol = undefined,
}) {
    if (!!API_URL.port && API_URL.port != "") {
        host += ":" + API_URL.port;
    }
    if (protocol === undefined && API_URL.protocol.includes("https")) {
        protocol = "wss";
    }
    if (protocol === undefined) {
        protocol = "ws";
    }
    const url = `${protocol}://${host}${api_url}`;
    for (const { ws, retryCount } of getWebSocket({ url })) {
        yield { ws, url };
        console.log("[api/Messenger] *connect(): yelded");
    }
}


export function getClientState(){
    console.log(`[api/Messenger] getClientState(): ...`);
    
    return api.apiCall({
        name: "user_messenger_client_state_api_v1_user_messenger_client_state_get",
    }).then(function (response) {
        console.log(
            `[api/Messenger] getClientState() then: response=`,
            response
        );
        return response.data
    })
    .catch(function (cause) {
        console.log(
            `[api/Messenger] getClientState() catch: cause=`,
            cause
        );
        throw cause;
    });
}
