import {getStaticUrl, getMediaUrl} from "@/utils/files";
import {
    MESSENGER_NO_AVATAR_SRC,
    MESSENGER_NO_NAME_PLACEHOLDER,
    MESSENGER_NO_AGE_PLACEHOLDER,
} from "@/constants";

import { trimSpaces } from "@/utils/index";


export function createMemberDisplay(m){
    m = {...m}
    if (!m.userImageSrc) {
        m.userImageSrc = !!m.avatar_src
            ? getMediaUrl(m.avatar_src)
            : getStaticUrl(MESSENGER_NO_AVATAR_SRC);
    }
    if (m.name && m.name != MESSENGER_NO_NAME_PLACEHOLDER) {
        m.nameItems = trimSpaces(m.name).split(" ");
    } else {
        m.name = MESSENGER_NO_NAME_PLACEHOLDER;
        m.nameItems = [MESSENGER_NO_NAME_PLACEHOLDER];
    }
    if (!m.age) {
        m.age = MESSENGER_NO_AGE_PLACEHOLDER;
    }
    if (!m.locality_label) {
        m.locality_label = '';
    }
    
    return m
}

const OPPONENT_PLACEHOLDER = createMemberDisplay({})


export function msgOpponent (m) {
    return m.opponent ? m.opponent : OPPONENT_PLACEHOLDER
} 
