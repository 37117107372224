import BaseFieldValidationError from './BaseFieldValidationError'


export class TextFieldValidationError extends BaseFieldValidationError {
    constructor(message, field) {
        super(message, field);
        this.name = "TextFieldValidationError";
    }
}

/**
 * Vue-миксин включающий в себя общую/основную логику работы текстовых полей
 * 
 * @mixin
 * */
export default {
    props: {
        minLength: { type: Number, default () { return 3 } },
        maxLength: { type: Number, default () { return 255 } },
        blank: { type: Boolean, default: true },
    },
    methods: {
        _defaultValueValidator(value) {
            if (this.value === undefined || this.value === null) {
                // return true if value can be blank
                return this.blank
            }
            if (value.length < this.minLength)
                throw new TextFieldValidationError(`слишком коротко (< ${this.minLength})`, this);
            else if (value.length > this.maxLength)
                throw new TextFieldValidationError(`слишком длинно (> ${this.maxLength})`, this);
            return true;
        }
    },
    beforeMount() {
        this.valueValidators.push(this._defaultValueValidator.bind(this));
    }
};