<template>
    <profile-field-layout
        ref="layout" v-bind="layout"
        :editable="editable" 
        :iconSrc="iconSrc"
        :value-class="valueClass"
        :value-display-str="valueDisplayStr" 
        :placeholder="placeholder"
        :validation-errors="validationErrors"
        :hint="hint"
        :hint-description="hintDescription"
        >
        
        <template #editor>
        <li class="dropdownEl flex flex-d-column a-i-center">
            {{ label }}
            <input      :type="inputType" 
                        v-model="value"
                        :min="min"
                        :max="max" 
                        class="myProfileMain-dropdownAgeField dropdownInput">
        </li>
        </template>
    </profile-field-layout>
</template>

<script>
import ProfilePlainField from './ProfilePlainField.vue'
import NumberFieldMixin from './NumberFieldMixin'


export default {
    mixins: [ProfilePlainField, NumberFieldMixin],

    props: {
        hint: { type: String, default () { return '' } },
        iconSrc: { type: String, default () { return '/icons/line.png' } },
        min: { type: Number, default () { return 0 } },
        max: { type: Number, default () { return 9999 } },
    },

    methods: {
        getPostfix(number) {
            if (this.noun)
                return this.getNoun(number, ...this.noun);
            return this.postfix;
        },
    }
};
</script>