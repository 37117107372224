import BaseFieldValidationError from './BaseFieldValidationError'
import { INPUT_TYPE } from '@/constants'

export class SelectFieldError extends BaseFieldValidationError {
    constructor(message, field) {
        super(message, field);
        this.name = "SelectFieldError";
    }
}

/**
 * Vue-миксин включающий в себя общую/основную логику работы полей выбора
 * 
 * @example 
 * choices = [
 *      {value: '1', label: 'Value 1 label'},
 *      {value: '2', label: 'Value 2 label'},
 *      ...
 * ],
 * choices = [
 *      'тег1 ', 'тег2', ... 
 * ],
 * @method getFieldChoices {@link getFieldChoices} инъекция метода получения вариантов выбора для поля
 * @vue-data {Array} currentChoices Текущий список вариантов выбора, который формируется при
 * фильтрации базового списка или при создании новых вариантов выбора
 * @vue-data {String|Object} _valueLabel переменная для хранения текущего описания значения (value). 
 * @vue-computed {Array} optionsDisplay список отображаемых опций
 * @vue-prop {Boolean} queryPostFilter флаг включения пост-фильтрации вариантов выбора полученных от сервера 
 * @mixin SelectFieldMixin
 * */
export default {
    props: {
        initial: { type: [Array, Number], default: [] },
        choices: { type: Array, default: [] },
        multiple: { type: Boolean, default: false },
        separator: { type: String, default: '/' },
        tags: { type: Boolean, default: false },
        labelOfUnlabeledChoice: { type: String, default: '***' },
        labelOfBlankChoice: { type: String, default: 'не указано' },
        blankChoiceFirst: { type: Boolean, default: true },
        tagClass: { type: String, default: 'myProfileMain-tag' },
        inputType: { type: String, default: INPUT_TYPE.SELECT },
        capitalizeOptions: { type: Boolean, default: true },
        queryPostFilter: { type: Boolean, default: true },
        checkedFirst: { type: Boolean, default: false },
    },
    inject: ['getFieldChoices'],
    beforeMount(){
        this._updateChoices()
    },
    data() {
        return {
            /**
             * Текущий список вариантов выбора, который формируется при 
             * фильтрации базового списка или при создании новых вариантов выбора
             * @var currentChoices
             */
            currentChoices: this.choices.slice(),
            _valueLabel: undefined,
            _latestQueryedChoices: this.choices.slice(),
            isLoading: false,
        }
    },
    methods: {
        async _updateChoices(){
            try{
                const choices = await this.getFieldChoices(this.schema, this.field)
                if (!choices){ choices = [] }
                this._addBlankChoiceIfRequired(choices)
                // this.currentChoices = choices;
                this.currentChoices.length = 0
                if (choices.length){
                    this.currentChoices.push(...choices);
                }
                this._latestQueryedChoices = choices.slice();
            } catch(e) {
                console.warn(`[SelectFieldMixin] _updateChoices(): failed getting field "${this.field}" (of schema "${this.schema}") choices:`, e);
            };
        },
        _createChoice(value, label) { return { value, label } },
        _createBlankChoice(label) { return this._createChoice(undefined, label || this.labelOfBlankChoice) },
        _addBlankChoiceIfRequired(choicesContainer) {
            if (!this.blank) return;
            if (this.multiple) return;

            let hasBlankChoice = false;
            for (const ch of choicesContainer) {
                if (this._isBlank(ch.value)) {
                    hasBlankChoice = true;
                    break;
                }
            }
            if (!hasBlankChoice) {
                if (this.blankChoiceFirst) {
                    choicesContainer.unshift(this._createBlankChoice())
                } else {
                    choicesContainer.push(this._createBlankChoice())
                }
            }
        },
        /**
         * Находит подходящий вариант выбора
         * @param {*} value значение искомого варианта выбора
         * @returns вариант выбора соответствующий значению
         * @method _getMatchingChoice
         */
        _getMatchingChoice(value) {
            if (this._useValueCompareAsStr) {
                const valJson = JSON.stringify(value);
                return this.currentChoices.find(ch => JSON.stringify(ch.value) == valJson);
            }
            return this.currentChoices.find(ch => ch.value == value);
        },
        getLabel(value, def = null, includeCurrentValueLabel = true) {
            if (includeCurrentValueLabel && !!this._valueLabel) {
                /**
                 * пробуем получить предварительно сохранённый label (this._valueLabel) 
                 * и вернуть его, если он есть и он подходит под значение
                 * */
                if (this.multiple) {
                    if (this._valueLabel[value]) {
                        return this._valueLabel[value];
                    }
                } else {
                    if (value == this.value) {
                        return this._valueLabel;
                    }
                }
            }
            // предварительно сохранённый label не был использован, ищем в вариантах (this.currentChoices) 
            def = def === null ? this.labelOfUnlabeledChoice : def;
            const match = this._getMatchingChoice(value);
            return match === undefined ? def : match.label;
        },
        getSelectedValuesLabels(value) {
            if (value === undefined || value === null) {
                return []
            }
            if (this.multiple) {
                if (this.tags){
                    return value
                }
                return value.map(val => this.getLabel(val));
            }
            return [this.getLabel(value)];
        },
        isAnySelected(value) {
            if (this._isBlank(value)) {
                return false;
            }
            if (this.multiple) {
                return !!value.length;
            }
            return true;
        },
        /**
         * Отметить опцию
         * @param {*} optionValue значение, которое надо отметить
         * @param {Boolean} areChecked состояние опции (выбрана ли опция) 
         * @param {Boolean} isMultiple является ли выбор множественным
         */
        checkOption(optionValue, areChecked, isMultiple) {
            if (!isMultiple) {
                this.value = optionValue;
                return;
            }
            let value = this.value ? [...this.value] : [];
            if (areChecked) {
                if (value.indexOf(optionValue) === -1)
                    value.push(optionValue);
            } else {
                // this.value = value.filter(v => v != optionValue);
                value = value.filter(v => v != optionValue);
            }
            if (this.value){
                this.value.length = 0
                this.value.push(...value);
            } else {
                this.value = value
            }
        },
        /**
         * Определяет, выбран ли вариант
         * @param {*} selectOptionValue значение варианта выбора
         * @param {*} selectValue выбранное текущее значение
         * @param {Boolean} isMultiple множественный ли выбор
         * @returns true/false
         */
        _isOptionChecked(selectOptionValue, selectValue, isMultiple) {
            if (selectValue === undefined || selectValue === null)
                return false
            if (this._useValueCompareAsStr) {
                if (isMultiple) {
                    const valJson = JSON.stringify(selectOptionValue);
                    return !!selectValue.find(v => JSON.stringify(v) == valJson);
                }
                return JSON.stringify(selectValue) == JSON.stringify(selectOptionValue);
            }
            if (isMultiple)
                return selectValue.indexOf(selectOptionValue) !== -1;
            return selectValue == selectOptionValue;
        },
        _getCurrentChoices(query, choices) {
            if (this.queryPostFilter) {
                // пост-фильтрация для более "отзывчивого" очищения результатов запроса
                const regexp = new RegExp(query, "i");
                if (this.tags)
                    return choices.filter(c => (c || '').match(regexp));
                return choices.filter(c => (c.label || '').match(regexp));
            }
            return choices;
        },
        _getAvailableChoices() {
            let choices = this._latestQueryedChoices;
            if (this.tags) {
                return choices.concat((this.value || []).filter(v => !choices.includes(v)));
            }
            return choices;
        },
        updateChoices(query) {
            this.isLoading = true;
            query = ('' + query).trim();
            this.getFieldChoices(this.schema, this.field, query, this.value, true).then(choices => {
                this._addBlankChoiceIfRequired(choices);
                this._latestQueryedChoices = choices;
                this.currentChoices = this._getCurrentChoices(query, this._getAvailableChoices());
                // console.debug(`[SupdateChoiceselectFieldMixin] updateChoices(): обновлены варианты выбора поля "${this.field}" (схемы "${this.schema}") запрос "${query}", варианты:`, choices);
                this.isLoading = false;
            }).catch(reason => {
                console.warn(`[SelectFieldMixin] updateChoices(): ошибка обновления вариантов выбора поля "${this.field}" (схемы "${this.schema}") запрос "${query}", причина:`, reason);
                this.currentChoices = this._getCurrentChoices(query, this._getAvailableChoices());
                this.isLoading = false;
            });
        },
        _getValueLabelUpdate(value) {
            if (this.multiple) {
                return (value || []).reduce((acc, curr) => { acc[curr] = this.getLabel(curr, undefined, false); return acc; }, {});
            }
            return this.getLabel(value, undefined, false);
        },
        _isValueInChoices(value) {
            return this._getMatchingChoice(value);
        }
    },
    watch: {
        async value(val) { 
            if (!this._isValueInChoices(val)){
                await this._updateChoices()
            }
            this._valueLabel = this._getValueLabelUpdate(val) 
        },
        currentChoices() { 
            if (!this._valueLabel){ this._valueLabel = this._getValueLabelUpdate(this.value)}
        },
    },
    computed: {
        /**
         * Аттрибут-помошник определяющий, в зависимости от типа значений вариантов выбора,
         * каким образом сравнивать значения вариантов между собой:
         * @returns true - сравнивать как строки (используя JSON.stringify()), 
         *          false - обычным способом (используя "=" и т.п.)
         */
        _useValueCompareAsStr() {
            return !!this.currentChoices.length && (
                Array.isArray(this.currentChoices[0].value) ||
                this.currentChoices[0].value instanceof Object
            )
        },
        labelForOptions() {
            return `option-${this.schema}-${this.field}-`
        },
        /**
         * 
         * @returns {Object} отображаемое значение, значение которое должно быть выведено модификаций (кроме предобазования типов)
         */
        valueDisplay() {
            const value = this.valueForDisplay;
            return {
                value,
                placeholder: this.placeholder,
                isAnySelected: this.isAnySelected(value),
                labels: this.getSelectedValuesLabels(value)
            };
        },
        /**
         * 
         * @returns {Object} отображаемое строчное значение, готовое для вывода в шаблоне в виде строки
         */
        valueDisplayStr() {
            const display = this.valueDisplay;
            if (!display.isAnySelected) return undefined;
            return `${this.getPrefix(display.value)}${display.labels.join(this.separator)}${this.getPostfix(display.value)}`;
        },
        optionsDisplay() {
            let opts
            if (this.tags) {
                opts = this.currentChoices.map((value) => ({
                    value,
                    label: (this.capitalizeOptions ? this._capitalizeFirst(value) : value),
                    isChecked: this._isOptionChecked(value, this.value, this.multiple)
                }));
            } else {
                opts = this.currentChoices.map(({ value, label }) => ({
                    value,
                    label: (this.capitalizeOptions ? this._capitalizeFirst(label) : label),
                    isChecked: this._isOptionChecked(value, this.value, this.multiple)
                }));
            }

            if (this.checkedFirst) {
                // сортировка опций: первые идут выбранные
                opts.sort((a, b) => +b.isChecked - +a.isChecked);
            }
            return opts;
        }
    }
};