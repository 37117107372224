// import { modals } from '@/globals'
// import { id } from '@/utils/index'
import api from "@/api/index";
import { events } from "./state";

export function lookup({ timestamp_from }={}) {
    console.log(`[content/event/services] lookup(): timestamp_from=${timestamp_from}`);
    return api
        .apiCall({
            name: "user_profile_events_list_api_v1_user_events_list_get",
            data: { 
                timestamp_from,
            },
        })
        .then(function (response) {
            console.log(
                `[content/event/services] lookup() then: timestamp_from=${timestamp_from}, response=`,
                response
            );
            return response
            // delete imageData.toPost;
        })
        .catch(function (cause) {
            console.log(
                `[content/event/services] lookup() catch: timestamp_from=${timestamp_from}, cause=`,
                cause
            );
            // Object.assign(imageData, previousImageData)
        });
}


export function loadInitial() {
    console.log(`[content/event/services] loadInitial()...`);
    return api
        .apiCall({
            name: "user_profile_me_events_api_v1_user_profile_events_my_get",
        })
        .then(function (response) {
            console.log(
                `[content/event/services] loadInitial() then: response=`,
                response
            );
            if (response.data && response.data.length){
                events.value = response.data    
            }
            
            return response
            // delete imageData.toPost;
        })
        .catch(function (cause) {
            console.log(
                `[content/event/services] loadInitial() catch: cause=`,
                cause
            );
            // Object.assign(imageData, previousImageData)
        });
}


export function mount(){
    loadInitial()
}