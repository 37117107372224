<template>
    <button class="strangersProfileMain-strangersButton flex flex-d-column text-white-w-o-important a-i-center line-height-1" 
            @click="showModal()">
        <span class="hide-medium hide-small">Напишите мне!</span>
        <img class="strangersProfileMain-messageIcon" :src="$static('/img/MessageForStrangerIcon.png')">
    </button>
</template>

<script>
import { EVENTS } from "../../constants";
import { bootstrap, modals, $ } from "@/globals";
import bus from "@/utils/eventBus";

export default {
    /**
     * Компонент кнопки для сохранения значений на сервере значений хранилища определённых одной схемой
     */
    props: {
        bsTarget: { type: String, default: "#messenger-dialog-modal" },
        labelSave: { type: String, default: "cохранить изменения" },
        labelSavedSuccessfully: { type: String, default: "успешно сохранено" },
        labelSaveRetry: { type: String, default: "попробовать сохранить" },
        // задержка отображения, чтоб показать сообщение, перед тем как исчезнуть,
        // например - об удачном сохранении, т.к. при удачном сохранении данные сразу
        // становятся идентичными сохранённым и кнопка должна исчезнуть
        showTimeout: { type: Number, default: 2000 },
    },
    data() {
        return {
            // triggerName: EVENTS.TRIGGER_SCHEMA_STORAGE_SAVE,
            triggerName: "trigger.null",
            messengerDialog: undefined,
            label: this.labelSave,
            disabled: false,
            isShowTimeout: true, // вышло ли время для задержки отображения кнопки?
            hasToSave: false, // есть ли изменённые значения для сохранения?
        };
    },
    methods: {
        // import { bootstrap, modals, $ } from "@/globals";
        showModal() {
            console.log('[ProfileSendMessageButton] showModal(): this.$parent=', this.$parent);
            console.log('[ProfileSendMessageButton] showModal(): storage=', this.$parent.getValuesStorage('profile'));
            const user_id = this.$parent.getValuesStorage('profile').user_id;
            console.log('[ProfileSendMessageButton] showModal(): user_id=', user_id)
            // window._kupOpenMessengerDialog({ user_id })
            bus.emit(EVENTS.TRIGGER_PROFILE_OPEN_DIALOG, { user_id });
        },
        onActive() {
            this.disabled = true;
        },
        _onSchemaStorageSaved({ success, schema }) {
            if (schema != this.schema) {
                return;
            }
            if (success) {
                this.label = this.labelSavedSuccessfully;
            } else {
                this.label = this.labelSaveRetry;
                this.disabled = false;
            }
        },
    },
    computed: {
        triggerData() {
            if (this.schema) {
                return { schema: this.schema };
            }
            return undefined;
        },
        isButtonVisible() {
            // кнопка видна только если есть что сохранять или не вышло время отображения после нажатия
            return this.hasToSave || !this.isShowTimeout;
        },
    },
    watch: {
        // hasUnsavedValuesChanges: {
        //     handler(newChanges) {
        //         const thisSchemaHasChanges = !!newChanges[this.schema]
        //         if (this.hasToSave == thisSchemaHasChanges) {
        //             return
        //         }
        //         this.hasToSave = thisSchemaHasChanges;
        //         if (this.hasToSave) {
        //             this.disabled = false;
        //         } else {
        //             this.isShowTimeout = false;
        //             setTimeout((function() {
        //                 this.isShowTimeout = true;
        //                 this.label = this.labelSave;
        //             }).bind(this), this.showTimeout);
        //         }
        //     },
        //     deep: true
        // },
    },
};
</script>
