<template>
    <base-button
            @active="onActive"
            :fixed="false"
            :disabled="disabled" 
            :trigger-name="triggerName" 
            :trigger-data="triggerData">    
        {{ label }}
    </base-button>
</template>

<script>
import BaseButton from './BaseButton.vue'
import { EVENTS } from '../../constants'
import bus from '../../utils/eventBus'

export default {
    /**
     * Компонент кнопки для сохранения значений на сервере значений хранилища определённых одной схемой  
     */
    props: {
        schema: { type: String, default: undefined },
        labelPull: { type: String, default: 'обновить' },
        labelPulledSuccessfully: { type: String, default: 'обновлено' },
        labelPullRetry: { type: String, default: 'попробовать обновить' },
        // задержка отображения, чтоб показать сообщение, перед тем как исчезнуть,
        // например - об удачном сохранении, т.к. при удачном сохранении данные сразу 
        // становятся идентичными сохранённым и кнопка должна исчезнуть
        showTimeout: { type: Number, default: 2000 }
    },
    emits: ["active"],
    components: { BaseButton },
    mounted() {
        bus.on(EVENTS.SCHEMA_STORAGE_PULLED, this._onSchemaStoragePulled, this)
    },
    unmounted() {
        bus.off(EVENTS.SCHEMA_STORAGE_PULLED, this._onSchemaStoragePulled)
    },
    data() {
        return {
            triggerName: EVENTS.TRIGGER_SCHEMA_STORAGE_PULL,
            label: this.labelPull,
            disabled: false,
            isShowTimeout: true, // вышло ли время для задержки отображения кнопки?
            // hasToSave: false // есть ли изменённые значения для сохранения? 
        }
    },
    methods: {
        onActive() {
            this.disabled = true;
            this.$emit("active");
        },
        _onSchemaStoragePulled({ success, schema }) {
            console.log('[SchemaStoragePullButton] _onSchemaStoragePulled(): ', { success, schema } )
            if (schema != this.schema) {
                return
            }
            // if (success) {
            //     this.label = this.labelPulledSuccessfully;
            // } else {
            //     this.label = this.labelPullRetry
            // }
            this.disabled = false;
        }
    },
    computed: {
        triggerData() {
            if (this.schema) {
                return { schema: this.schema }
            }
            return undefined
        },
    },
    watch: {
        disabled(valNew, valOld){
            console.log('[SchemaStoragePullButton] watch disabled: this.disabled=', this.disabled )
        }
        // hasUnsavedValuesChanges: {
        //     handler(newChanges) {
        //         const thisSchemaHasChanges = !!newChanges[this.schema]
        //         if (this.hasToSave == thisSchemaHasChanges) {
        //             return
        //         }
        //         this.hasToSave = thisSchemaHasChanges;
        //         if (this.hasToSave) {
        //             this.disabled = false;
        //         } else {
        //             if (this.showTimeout) {
        //                 this.isShowTimeout = false;
        //                 setTimeout((function() {
        //                     this.isShowTimeout = true;
        //                     this.label = this.labelPull;
        //                 }).bind(this), this.showTimeout);
        //             }
        //         }
        //     },
        //     deep: true
        // },
    }
};
</script>
