import BaseCompositeComponent from "@/composites/BaseCompositeComponent";
import SchemaStorageCompositeMixin from "@/composites/SchemaStorageCompositeMixin";

import { profile } from '@/content/state'


/**
 * Компонент (композит из компонетов) представляющий профиль пользователя
 */
export default class AboutMeComponent extends BaseCompositeComponent{
    constructor(config) {
        super({ ...config, schema: "profile" });
        this.vueComponent = {
            mixins: [SchemaStorageCompositeMixin],
            data() {
                return {
                    _handleStorageSavePull: false,
                };
            },
            methods: {
                _addEventListeners(){},
                _removeEventListeners(){},
            },
            computed: {
                profile(){
                    return profile
                },
            },
        };
    }
}
