import bus from "@/utils/eventBus";
import {getStaticUrl, getMediaUrl} from "@/utils/files";
import {
    EVENTS,
    MESSENGER_NO_AVATAR_SRC,
    MESSENGER_NO_NAME_PLACEHOLDER,
    MESSENGER_NO_AGE_PLACEHOLDER,
} from "@/constants";
import { initial_state } from "@/globals";
import { computed } from "vue/dist/vue.esm-bundler";
import {
    WsDataTypeEnum,
    NotificationUserIdTypeEnum,
    MessageStatus,
} from "@/api/constants";
// import {
//     MessageStatus,
//     // NotificationUserIdTypeEnum,
// } from "@/services/MessengerService";
import {
    includesId,
    indexId,
    equalId,
    makeUserClientId,
    makeDialogClientId,
} from "@/utils/messenger";

import { trimSpaces } from "@/utils/index";
import {
    matchAllMessageTags,
    tag2HtmlConverter,
} from "@/content/text_extra/utils";

import * as service from "@/services/MessengerService";


export function createMemberDisplay(m){
    m = {...m, _raw:m}
    if (!m.userImageSrc) {
        m.userImageSrc = !!m.avatar_src
            ? getMediaUrl(m.avatar_src)
            : getStaticUrl(MESSENGER_NO_AVATAR_SRC);
    }
    if (m.name && m.name != MESSENGER_NO_NAME_PLACEHOLDER) {
        m.nameItems = trimSpaces(m.name).split(" ");
    } else {
        m.name = MESSENGER_NO_NAME_PLACEHOLDER;
        m.nameItems = [MESSENGER_NO_NAME_PLACEHOLDER];
    }
    if (!m.age) {
        m.age = MESSENGER_NO_AGE_PLACEHOLDER;
    }
    if (!m.locality_label) {
        m.locality_label = '';
    }
    return m
}

export const membersDisplay = computed(() => service.members.map(createMemberDisplay));

function getMemberDisplay(from_id) {
    for (const m of membersDisplay.value) {
        if (equalId({ id1: m.client_id, id2: from_id })) return m;
    }
    return undefined;
}

service.beforeAppendMessagePipeline.push((message) => {
    if (!message.member && !!message.from_id) {
        message.member = getMemberDisplay(message.from_id);
    }
    if (!message.opponent && !!message.to_id) {
        let dialogId = message.to_id[1];
        let opponentId = undefined;
        if (message.to_id[0] == NotificationUserIdTypeEnum.DIALOG_ID){
            dialogId = message.to_id[1]
            opponentId = service.getOpponentId(dialogId)
        } else if (message.to_id[0] == NotificationUserIdTypeEnum.USER_ID){
            opponentId = dialogId
        }
        
        message.opponent = getMemberDisplay(opponentId);
    }
    return message
});

const OPPONENT_PLACEHOLDER = createMemberDisplay({})
// import { getNoun, getAge } from '@/utils/index'
// import { modalsFn } from '@/globals'
// import { EVENTS as PROFILE_COMPONENT_EVENTS } from '@/composites/ProfileComponent'

export default {
    // props: ["profiles"],
    data() {

        return {
            noAvatarSrc: "/icons/camera.png", // /icons/camera.png
            // noAvatarSrc: "/img/avatarIcon.png",
            userNoName: "[аноним]",
        };
    },
    methods: {
        msgOpponent: m => m.opponent ? m.opponent : OPPONENT_PLACEHOLDER,  
        getMember: service.getMember,
        getMemberIds: service.getMemberIds,
        setMember: service.setMember,
        getMemberInfo: service.getMemberInfo,
        getUserMessageInfo: service.getUserMessageInfo,
        getDialogMessageInfo: service.getDialogMessageInfo,
        // getDialogMessageInfo: (...args)=>service.getDialogMessageInfo(...args),
        getMessageUserNameList: service.getMessageUserNameList,

        getMessageUserImageSrcFull: service.getMessageUserImageSrcFull,
        getMessageUserName: service.getMessageUserName,
        // getMessageUserNameList: service.getMessageUserNameList,
        // getMessageUserNameList: service.getMessageUserNameList,
        // getMessageUserNameList: service.getMessageUserNameList,
        // getMessageUserNameList: service.getMessageUserNameList,
        // getMessageUserNameList: service.getMessageUserNameList,
        // getMessageUserNameList: service.getMessageUserNameList,
        // createMemberDisplay,
        getMemberDisplay,
        // appendOpponentDisplay(dialogDisplayItem) {
        //     if (!dialogDisplayItem.opponent) {
        //         const opp = service.getOpponent(dialogDisplayItem.dialog_id);
        //         if (!opp.userImageSrc) {
        //             opp.userImageSrc = !!opp.avatar_src
        //                 ? this.$media(opp.avatar_src)
        //                 : this.$static(MESSENGER_NO_AVATAR_SRC);
        //         }
        //         if (opp.name && opp.name != MESSENGER_NO_NAME_PLACEHOLDER) {
        //             opp.nameItems = trimSpaces(opp.name).split(" ");
        //         } else {
        //             opp.name = MESSENGER_NO_NAME_PLACEHOLDER;
        //             opp.nameItems = [MESSENGER_NO_NAME_PLACEHOLDER];
        //         }
        //         if (!opp.age) {
        //             opp.age = MESSENGER_NO_AGE_PLACEHOLDER;
        //         }
        //         dialogDisplayItem.opponent = opp;
        //     }
        //     // if (dialogDisplayItem.isMe === undefined) {
        //     //     dialogDisplayItem.isMe = equalId({
        //     //         id1: dialogDisplayItem.from_id,
        //     //         id2: service.currentClientId,
        //     //     });
        //     // }
        //     return dialogDisplayItem;
        // },
        // getMemberDisplay(message) {
        //     if (!message.member) {
        //         message.member = service.getMember(message.from_id);
        //     }
        //     if (!message.member.userImageSrc) {
        //         message.member.userImageSrc = !!message.member.avatar_src
        //             ? this.$media(message.member.avatar_src)
        //             : this.$static(MESSENGER_NO_AVATAR_SRC);
        //     }
        //     if (
        //         message.member.name &&
        //         message.member.name != MESSENGER_NO_NAME_PLACEHOLDER
        //     ) {
        //         message.member.nameItems = trimSpaces(
        //             message.member.name
        //         ).split(" ");
        //     } else {
        //         message.member.name = MESSENGER_NO_NAME_PLACEHOLDER;
        //         message.member.nameItems = [MESSENGER_NO_NAME_PLACEHOLDER];
        //     }
        //     if (!message.member.age) {
        //         message.member.age = MESSENGER_NO_AGE_PLACEHOLDER;
        //     }
        //     // message.member = member;

        //     // if (message.isMe === undefined) {
        //     //     message.isMe = equalId({
        //     //         id1: message.from_id,
        //     //         id2: service.currentClientId,
        //     //     });
        //     // }
        //     // message.data.messageHtml =
        //     //     message.data.messageHtml || message.data.message;
        //     return message.member;
        // },

        getMessageText(txt) {
            txt = txt
                .replaceAll("&quot;", '"')
                // .replaceAll("&amp;", '\\')
                .replaceAll("&amp;quot;", '"');
            let txtNew = "";
            let lastMatchIndex = 0;
            let hasTags = false;
            const tags = matchAllMessageTags(txt);
            for (const matchTag of tags) {
                // console.log(
                //     `[ProfileMessengerDialogComponent] getMessageText(): foun tag=`,
                //     matchTag
                // );
                matchTag.attrs.src = this.$static(matchTag.attrs.src);
                const htmlTag = tag2HtmlConverter(
                    matchTag,
                    window.kup.initial_content.text_extra_collections,
                    undefined,
                    true
                );
                // const htmlTag = converter.toHtml(matchTag);
                // console.log(
                //     `[ProfileMessengerDialogComponent] getMessageText(): htmlTag=`,
                //     htmlTag
                // );
                // lastMatchIndex + matchTag.rawTag.length
                txtNew += txt.substring(lastMatchIndex, matchTag.index);
                txtNew += htmlTag;
                lastMatchIndex = matchTag.index + matchTag.rawTag.length;
                hasTags = true;
                // console.log(
                //     `[ProfileMessengerDialogComponent] getMessageText(): txtNew=`,
                //     txtNew
                // );
            }

            if (hasTags) {
                txtNew += txt.substring(lastMatchIndex, txt.length);
                return txtNew;
            } else {
                // console.log(
                //     `[ProfileMessengerDialogComponent] getMessageText(): no tags found! tags=`,
                //     [...tags]
                // );
                // console.log(
                //     `[ProfileMessengerDialogComponent] getMessageText(): txt=`,
                //     txt
                // );
                // [[emj "src":"/img/text-extra/10/default_emoji/U0001F600.png"]] [[emj "src":"/img/text-extra/10/default_emoji/U0001F600.png", "slug":"U0001F600"]]
            }
            return txt;
        },

        // getMember(clientId) {
        //     for (const m of this.members) {
        //         // console.log('[ProfileInbox] getMember() m=', m)
        //         // console.log('[ProfileInbox] getMember() m.client_id=', m.client_id)
        //         if (equalId({ id1: m.client_id, id2: clientId })) {
        //             return m;
        //         }
        //     }
        //     // console.log(
        //     //     "[MessengerMixin] getMember(): this.members=",
        //     //     this.members
        //     // );
        //     return undefined;
        // },
        // getMemberIds() {
        //     const ids = [];
        //     for (const m of this.members) {
        //         ids.push(m.client_id);
        //     }
        //     return ids;
        // },
        // // getUserMessageInfo(message, name, def = undefined) {
        // //     if (!message) return def;
        // //     if (
        // //         !message.from_id ||
        // //         message.from_id[0] != NotificationUserIdTypeEnum.USER_ID
        // //     ) {
        // //         return def;
        // //     }
        // //     let info = undefined;
        // //     for (const m of this.dialogs[message.to_id[1]]
        // //         .members_ids) {
        // //         // console.log('[ProfileInbox] getMember() m=', m)
        // //         // console.log('[ProfileInbox] getMember() m.client_id=', m.client_id)
        // //         if (!equalId({ id1: m, id2: this.currentClientId })) {
        // //             info = this.getMember(m);
        // //             break;
        // //         }
        // //     }
        // //     const userId = message.from_id[1];
        // //     // const info = this.getMember(message.from_id);
        // //     if (!info) {
        // //         console.log(
        // //             `[ProfileInbox] not fount info ${name} for user ${userId}, available: ${this.getMemberIds()}`
        // //         );
        // //         console.debug(`[ProfileInbox] info name=${name}`);
        // //         return def;
        // //     }
        // //     return info[name] || def;
        // // },
        // setMember(clientId, member) {
        //     if (!clientId){
        //         console.error()
        //     }
        //     this.members = this.members.filter(
        //         (m) => !equalId({ id1: m.client_id, id2: clientId })
        //     );
        //     this.members.push(member);
        // },
        // getMemberInfo(clientId, name, def = undefined) {
        //     const info = this.getMember(clientId);
        //     if (!info) {
        //         console.log(
        //             `[MessengerMixin] not fount info ${name} for client ${clientId}, available: ${this.getMemberIds()}`
        //         );
        //         console.debug(`[MessengerMixin] info name=${name}`);
        //         return def;
        //     }
        //     // console.log('[MessengerMixin] getMemberInfo(): name=', name, 'info=', info, 'clientId=', clientId)
        //     return info[name] || def;
        // },
        // getUserMessageInfo(message, name, def = undefined) {
        //     if (!message) return def;
        //     if (
        //         !message.from_id ||
        //         message.from_id[0] != NotificationUserIdTypeEnum.USER_ID
        //     ) {
        //         return def;
        //     }
        //     const clientId = message.from_id;

        //     const info = this.getMemberInfo(clientId, name, def);
        //     // console.log('[MessengerMixin] getUserMessageInfo(): clientId=', clientId, 'info=', info)
        //     return info;
        // },
        // getDialogMessageInfo(message, name, def = undefined) {
        //     if (!message) return def;
        //     if (
        //         !message.from_id ||
        //         message.from_id[0] != NotificationUserIdTypeEnum.USER_ID
        //     ) {
        //         return def;
        //     }
        //     let clientId = undefined;
        //     // console.log(
        //     //     "[MessengerMixin] getDialogMessageInfo(): message.to_id=",
        //     //     message.to_id
        //     // );
        //     // console.log(
        //     //     "[MessengerMixin] getDialogMessageInfo(): this.dialogs=",
        //     //     this.dialogs
        //     // );
        //     // console.log(
        //     //     "[MessengerMixin] getDialogMessageInfo(): this.dialogs[message.to_id[1]]=",
        //     //     this.dialogs[message.to_id[1]]
        //     // );
        //     for (const mId of this.dialogs[message.to_id[1]].members_ids) {
        //         // console.log('[ProfileInbox] getMember() m=', m)
        //         // console.log('[ProfileInbox] getMember() m.client_id=', m.client_id)
        //         if (!equalId({ id1: mId, id2: this.currentClientId })) {
        //             clientId = mId;
        //             // console.log(
        //             //     "[MessengerMixin] getDialogMessageInfo(): !equalId - { id1: mId, id2: this.currentClientId }=",
        //             //     { id1: mId, id2: this.currentClientId }
        //             // );
        //             break;
        //         }
        //     }

        //     const info = this.getMemberInfo(clientId, name, def);
        //     // console.log('[MessengerMixin] getDialogMessageInfo(): clientId=', clientId, 'info=', info)
        //     return info;
        // },
        // getUserMessageInfo(message, name, def = undefined) {
        //     if (!message) return def;
        //     if (
        //         !message.from_id ||
        //         message.from_id[0] != NotificationUserIdTypeEnum.USER_ID
        //     ) {
        //         return def;
        //     }
        //     let info = undefined;
        //     for (const m of this.dialogs[message.to_id[1]]
        //         .members_ids) {
        //         // console.log('[ProfileInbox] getMember() m=', m)
        //         // console.log('[ProfileInbox] getMember() m.client_id=', m.client_id)
        //         if (!equalId({ id1: m, id2: this.currentClientId })) {
        //             info = this.getMember(m);
        //             break;
        //         }
        //     }
        //     const userId = message.from_id[1];
        //     // const info = this.getMember(message.from_id);
        //     if (!info) {
        //         console.log(
        //             `[ProfileInbox] not fount info ${name} for user ${userId}, available: ${this.getMemberIds()}`
        //         );
        //         console.debug(`[ProfileInbox] info name=${name}`);
        //         return def;
        //     }
        //     return info[name] || def;
        // },
        // getUserDialogMessageInfo(message, name, def = undefined) {
        //     if (!message) return def;
        //     if (
        //         !message.from_id ||
        //         message.from_id[0] != NotificationUserIdTypeEnum.USER_ID
        //     ) {
        //         return def;
        //     }
        //     let info = undefined;
        //     for (const m of this.dialogs[message.to_id[1]]
        //         .members_ids) {
        //         // console.log('[ProfileInbox] getMember() m=', m)
        //         // console.log('[ProfileInbox] getMember() m.client_id=', m.client_id)
        //         if (!equalId({ id1: m, id2: this.currentClientId })) {
        //             info = this.getMember(m);
        //             break;
        //         }
        //     }
        //     const userId = message.from_id[1];
        //     // const info = this.getMember(message.from_id);
        //     if (!info) {
        //         console.log(
        //             `[ProfileInbox] not fount info ${name} for user ${userId}, available: ${this.getMemberIds()}`
        //         );
        //         console.debug(`[ProfileInbox] info name=${name}`);
        //         return def;
        //     }
        //     return info[name] || def;
        // },
        // getMessageUserImageSrcFull(message) {
        //     const avatarSrc = this.getUserMessageInfo(message, "avatar_src");
        //     if (avatarSrc) {
        //         return this.$media(avatarSrc);
        //     }
        //     return this.$static(this.noAvatarSrc);
        // },
        // getUserMessageInfo(message, name, def = undefined) {
        //     if (!message) return def;
        //     if (!message.from_id || message.from_id[0] != 20) {
        //         return def;
        //     }
        //     const userId = message.from_id[1];
        //     const info = this.getMember(message.from_id);
        //     if (!info) {
        //         console.log(
        //             `[ProfileInbox] not fount info ${name} for user ${userId}, available: ${this.getMemberIds()}`
        //         );
        //         console.debug(`[ProfileInbox] info name=${name}`);
        //         return def;
        //     }
        //     return info[name] || def;
        // },
        // getMessageUserImageSrcFull(message) {
        //     const avatarSrc = this.getUserMessageInfo(message, "avatar_src");
        //     if (avatarSrc) {
        //         return this.$media(avatarSrc);
        //     }
        //     return this.$static(this.noAvatarSrc);
        // },
        // getMessageUserName(message) {
        //     return this.getUserMessageInfo(message, "name", this.userNoName);
        // },
        getUnreadMessagesCount(messages) {
            if (!messages || !messages.length) {
                return 0;
            }

            // TODO: FIX THIS!!!!!
            const myClientId = !!this.getCurrentClientId
                ? this.getCurrentClientId()
                : initial_state.messenger.current_client_id;

            // console.log("[MessengerMixin] getUnreadMessagesCount(): myClientId=",myClientId)
            const unread = messages.filter(
                (m) =>
                    m.data.status != MessageStatus.READ &&
                    !equalId({ id1: m.from_id, id2: myClientId })
            );
            return unread.length;
        },
        getAllUnreadMessagesCount() {
            const dialog_ids = Object.keys(this.dialogs);
            return dialog_ids.reduce(
                (unread_count, d_id) =>
                    (unread_count += this.getUnreadDialogMessagesCount(d_id)),
                0
            );
        },
        _getMessengerStorage() {
            return this.$parent.getValuesStorage("messenger");
        },
        _getDialogs() {
            if (!this._getMessengerStorage().dialogs) {
                return {};
            }
            return this._getMessengerStorage().dialogs;
        },
        _getMembers() {
            if (!this._getMessengerStorage().members) {
                return [];
            }
            return this._getMessengerStorage().members;
        },
        getCurrentClientId() {
            return initial_state.messenger.current_client_id;
            // return this.getValuesStorage().current_client_id;
        },
    },
    computed: {
        dialogs() {
            return this._getDialogs();
        },
        currentClientId() {
            return initial_state.messenger.current_client_id;
            // return this._getMessengerStorage().current_client_id;
        },
        messages() {
            return this.currentDialog.messages;
        },
        members() {
            console.log(
                "[MessengerMixin] computed members: this._getMembers()=",
                this._getMembers()
            );
            return this._getMembers();
        },
    },
};
