import BaseCompositeComponent from "@/composites/BaseCompositeComponent";
import SchemaStorageCompositeMixin from "@/composites/SchemaStorageCompositeMixin";
import { update as topsUpdate } from "@/content/tops/services";
// import { removeEmptys } from '@/utils/index'
// import SchemaDescriptorCompositeMixin from './SchemaDescriptorCompositeMixin'
// import { ProfilePermissionsMixin, PROFILE_OWNER_RELATION } from './ProfilePermissionsMixin'

// import SchemaStorageSaveButton from '@/components/buttons/SchemaStorageSaveButton.vue'
// import SchemaStoragePullButton from '@/components/buttons/SchemaStoragePullButton.vue'
// import ProfileRawTextField from '@/components/fields/ProfileRawTextField.vue'
// import ProfilePlainField from '@/components/fields/ProfilePlainField.vue'
// import ProfileSelectField from '@/components/fields/ProfileSelectField.vue'
// import ProfileAutocompleteSelectField from '@/components/fields/ProfileAutocompleteSelectField.vue'
// import ProfileImagesCollectionField from '@/components/fields/ProfileImagesCollectionField.vue'
// import ProfileAgeField from '@/components/fields/ProfileAgeField.vue'
// import ProfileNumberField from '@/components/fields/ProfileNumberField.vue'
// import ProfileTextAreaField from '@/components/fields/ProfileTextAreaField.vue'
// import ProfileRangeField from '@/components/fields/ProfileRangeField.vue'

// import ProfilesList from '@/components/lists/ProfilesList.vue'

// import DialogModal from '@/components/modals/DialogModal.vue'
// import
import { UP_TOP_COUNTRY_CONTENT_SLUG } from "../constants";
import { order } from "@/content/services";
import { Exception } from "sass";

// export { PROFILE_OWNER_RELATION }

/**
 * Компонент (композит из компонетов) представляющий профиль пользователя
 */
export default class extends BaseCompositeComponent {
    constructor(config) {
        super({ ...config, schema: "user_content" });
        this.vueComponent = {
            mixins: [SchemaStorageCompositeMixin],
            components: {
                // Buttons
                // SchemaStorageSaveButton,
                // SchemaStoragePullButton,
                // // Fields
                // ProfileRawTextField,
                // ProfilePlainField,
                // ProfileSelectField,
                // ProfileAutocompleteSelectField,
                // ProfileImagesCollectionField,
                // ProfileAgeField,
                // ProfileNumberField,
                // ProfileTextAreaField,
                // ProfileRangeField,
                // Lists
                // ProfilesList,
                // // Modals
                // DialogModal,
            },
            data() {
                return {
                    // topupAmount: DEFAULT_TOPUP_AMOUNT,
                };
            },
            beforeMount() {
                // this.pullSchemaValues(this.compositeSchema)
            },
            methods: {
                onClickOrder() {
                    order({ 
                        content_slug: UP_TOP_COUNTRY_CONTENT_SLUG 
                    }).then((result)=>{
                        console.log('[UpInCountryModal] onClickOrder() then: result=', result)
                        // this.getValuesStorage().search_position=1;
                        topsUpdate()
                        window.closeModal(this.compositeConfig.mountPoint);
                    }).catch((cause)=>{
                        window.closeModal(this.compositeConfig.mountPoint);
                    })
                },
                // getValuesStorageForPull(schema) {
                //     const findSettingsStorageName = "findSettings";
                //     let storage = this.getValuesStorage(
                //         findSettingsStorageName
                //     );
                //     if (!storage) {
                //         console.log(
                //             "[ProfileFindComponent] getValuesStorageForPull(): no storage"
                //         );
                //         return {};
                //     }
                //     console.log(
                //         "[ProfileFindComponent] getValuesStorageForPull(): storage =",
                //         storage
                //     );
                //     storage = removeEmptys(storage);
                //     return {
                //         [findSettingsStorageName]: JSON.stringify(storage),
                //     };
                // },
                // getCoinsCount() {
                //     try{
                //         console.log(
                //             "[TopupModal] getCoinsCount(): this.getValuesStorage() =",
                //             this.getValuesStorage()
                //         );
                //         return this.getValuesStorage().coins;
                //     } catch (e) {
                //         console.error(
                //             "[TopupModal] getCoinsCount(): error =", e
                //         );
                //         return 0;
                //     }
                    
                // },
                getShowcaseContent(slug){
                    const showcase = this.getValuesStorage().content_showcase;
                    console.log(`[UpInSearchModal] getShowcaseContent(): showcase=`, showcase)
                    return showcase.filter(value => value.slug==slug)[0]
                },
                // getOfferCost(){
                //     const content = this.getShowcaseContent(UP_TOP_COUNTRY_CONTENT_SLUG);
                //     return content.price
                // },
                // getOfferCost(){
                //     const content = this.getShowcaseContent(UP_TOP_COUNTRY_CONTENT_SLUG);
                //     return content.price
                // }
                getContentParams(){
                    const content = this.getShowcaseContent(UP_TOP_COUNTRY_CONTENT_SLUG);
                    console.log(`[UpInSearchModal] getContentParams(): content=`, content)
                    return content;
                }
            },
            computed: {
                contentParams() {
                    return this.getContentParams()
                },
                // amount(){
                //     return contentParams.price
                // },
                amountTypeStr() {
                    return 'дней'
                    // TODO: добавить получение из openapi информации о типе
                    // return this.contentParams.amount_type  
                },
            },
        };
    }
}
