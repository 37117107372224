import BaseCompositeComponent from "@/composites/BaseCompositeComponent";
import SchemaStorageCompositeMixin from "@/composites/SchemaStorageCompositeMixin";
import ModalsMixin from "@/components/mixins/ModalsMixin";
import bus from '@/utils/eventBus'
// import SchemaDescriptorCompositeMixin from './SchemaDescriptorCompositeMixin'
// import { ProfilePermissionsMixin, PROFILE_OWNER_RELATION } from './ProfilePermissionsMixin'

// import SchemaStorageSaveButton from '@/components/buttons/SchemaStorageSaveButton.vue'
// import SchemaStoragePullButton from '@/components/buttons/SchemaStoragePullButton.vue'
// import ProfileRawTextField from '@/components/fields/ProfileRawTextField.vue'
// import ProfilePlainField from '@/components/fields/ProfilePlainField.vue'
// import ProfileSelectField from '@/components/fields/ProfileSelectField.vue'
// import ProfileAutocompleteSelectField from '@/components/fields/ProfileAutocompleteSelectField.vue'
// import ProfileImagesCollectionField from '@/components/fields/ProfileImagesCollectionField.vue'
// import ProfileAgeField from '@/components/fields/ProfileAgeField.vue'
// import ProfileNumberField from '@/components/fields/ProfileNumberField.vue'
// import ProfileTextAreaField from '@/components/fields/ProfileTextAreaField.vue'
// import ProfileRangeField from '@/components/fields/ProfileRangeField.vue'

// import ProfilesList from '@/components/lists/ProfilesList.vue'

// import DialogModal from '@/components/modals/DialogModal.vue'
// import
import EVENTS from "../../events";
// import { topup, topupAndConfirm, confirmTopup } from "../services";
import { topup, topupAndConfirm, confirmTopup } from "../services";


/**
 * Компонент (композит из компонетов) представляющий профиль пользователя
 */
export default class extends BaseCompositeComponent {
    constructor(config) {
        super({ ...config, schema: "user_content" });
        this.vueComponent = {
            mixins: [
                SchemaStorageCompositeMixin,
                ModalsMixin,
            ],
            mounted() {
                bus.on(EVENTS.ORDERING_FAILED, this.onOrderingFailed, this)
            },
            unmounted() {
                bus.off(EVENTS.ORDERING_FAILED, this.onOrderingFailed)
            },
            data() {
                // return {
                //     bsTarget: config.mountPoint
                //     // topupAmount: DEFAULT_TOPUP_AMOUNT,
                // };
            },
            methods: {
                onOrderingFailed({cause}){
                    console.log('[WarningBalanceModal] onOrderingFailed(): cause=', cause)
                    if (cause.status == 402 || cause.status == 409){  // TODO: FIX THIS ON BACKEND!!!!
                        this.showModal(this.compositeConfig.mountPoint)
                    }
                    // this.showModal();
                },
                onClickTopup() {
                    console.log('[WarningBalanceModal] onClickTopup(): this.compositeConfig.topupModalComponentSelector=', this.compositeConfig.topupModalComponentSelector)
                    this.closeModal(this.compositeConfig.mountPoint)
                    this._modal = undefined
                    this.showModal(this.compositeConfig.topupModalComponentSelector);
                    this._modal = undefined
                    // .then((result)=>{
                    //     console.log('[ActivateVipModal] onClickOrder() then: result=', result)
                    //     this.getValuesStorage().is_vip=true;
                    //     window.closeModal(this.compositeConfig.mountPoint);
                    // })
                },
                getCoinsCount() {
                    try{
                        console.log(
                            "[TopupModal] getCoinsCount(): this.getValuesStorage() =",
                            this.getValuesStorage()
                        );
                        return this.getValuesStorage().coins;
                    } catch (e) {
                        console.error(
                            "[TopupModal] getCoinsCount(): error =", e
                        );
                        return 0;
                    }
                    
                },
            },
            computed: {
                bsTarget(){
                    return this.mountPoint
                },
                coins_count() {
                    return this.getValuesStorage().coins;
                },
            },
        };
    }
}
