import { modals, modalsFn } from '@/globals'
// import { id } from '@/utils/index'
import api from "@/api/index";


export function confirmTopupRobokassa({
    amount,
    url = "/",
    success = undefined,
    dataType = undefined,
}) {
    /**
     * TODO: реализовать переход к оплате через робокассу
     */
    const data = {
        "OutSum": amount,
        // "MerchantLogin": amount,
    };
    // Access-Control-Allow-Origin
    window.location.href = url
    return $.ajax({
        type: "POST",
        url: url,
        headers: {'Access-Control-Allow-Origin': '*'},
        data: data,
        success: success,
        dataType: dataType,
        async: false,
    });
}


export function regirectToPayment({
    amount,
    response
}) {
    /**
     * TODO: реализовать переход к оплате через робокассу
     */
    let url;
    if (response.data.payment){
        url = response.data.payment.purchase_url
        window.location.href = url 
        // window.open(url, '_blank').focus();
    } else {
        console.warn("[content/coin/services]regirectToPayment(): no url for follow found")
    }
    
    // Access-Control-Allow-Origin
    // window.location.href = url
    // return $.ajax({
    //     type: "GET",
    //     url: '/',
    //     headers: {'Access-Control-Allow-Origin': '*'},
    //     data: data,
    //     success: success,
    //     dataType: dataType,
    //     async: false,
    // });
}

export function confirmTopup({ amount, response }) {
    console.log("[content/coin/services]confirmTopup(): ", { amount, response })
    // return confirmTopupRobokassa({ amount });
    return regirectToPayment({ amount, response })
    // return { amount, response }
}

export function topup({ amount }) {
    console.log(`[content/coin/services] topup(): amount=${amount}`);
    return api
        .apiCall({
            name: "billing_wallet_topup_api_v1_billing_ordering_topup__post",
            data: { amount: amount },
        })
        .then(function (response) {
            console.log(
                `[content/coin/services] topup() then: amount=${amount}, response=`,
                response
            );
            return response
        })
        .catch(function (cause) {
            console.log(
                `[content/coin/services] topup() catch: amount=${amount}, cause=`,
                cause
            );
            throw cause
        });
}

export function topupAndConfirm({ amount }) {
    return topup({amount})
        .then((response)=>confirmTopup({amount, response}))
        .catch(function (cause) {
            console.log(
                `[content/coin/services] topupAndConfirm() fail: amount=${amount}, cause=`,
                cause
            );

            if (cause.error.code == "PAYMENT_BACKEND_FAIL"){
                let text = "" + cause.error.detail
                try{
                    text = "" + cause.error.detail.message
                } catch(e) {}
                modals.dialog.show({
                    title: "Ошибка платёжной системы", 
                    text: text,
                    buttons: undefined,
                })
                modalsFn.close("#topUpBalance-modal")
            } else {
                modals.dialog.show({
                    title: "Ошибка",
                    text: "" + cause.error.detail,
                    buttons: undefined,
                })
                modalsFn.close("#topUpBalance-modal")
            } 
            // Object.assign(imageData, previousImageData)
        });
}