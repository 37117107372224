import { pathGet, pathSet } from '@/utils/object'
/**
 * Миксин для полей являющийся адаптером для хранилища значений (valuesStorage), 
 * предоставляет аттрибут в компонент аттрибут value в соответствии со schema и field.
 * @mixin
 */
export default {
    props: {
        isArrayItem: { type: Boolean, default: false },
    },
    inject: ['valuesStorage', 'getValuesStorage'],
    data() {
        return {
            __arrayIndex: undefined
        }
    },
    methods: {
        _valueGet() {
            try {
                return pathGet(this.__valuesStorageSchema, this.__path, undefined)
            } catch (exception) {
                console.warn('[ValuesStorageFieldMixin] _valueGet(): failed to get value by path:', this.__path)
                console.warn('[ValuesStorageFieldMixin] _valueGet(): got exception:', exception)
                return undefined;
            }
        },
        _valueSet(val, isArrayItem = false) {
            try {
                this.__arrayIndex = pathSet(this.__valuesStorageSchema, this.__path, val, isArrayItem, this.__arrayIndex);
            } catch (exception) {
                console.warn('[ValuesStorageFieldMixin] _valueSet(): failed to set value by path:', this.__path)
                console.warn('[ValuesStorageFieldMixin] _valueSet(): got exception:', exception)
                // return undefined;
            }
        }
    },
    computed: {
        __valuesStorageSchema() {
            if (this.getValuesStorage) {
                return this.getValuesStorage(this.schema)
            }
            return {
                [this.schema]: {}
            }
        },
        __path() {
            const path = this.field.trim().split('.');
            if (this.__arrayIndex !== undefined && path[path.length - 1] == '*') {
                path[path.length - 1] = this.__arrayIndex;
            }
            return path;
        },
    }
};