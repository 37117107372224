<template>
  <button
    v-if="disabled"
    disabled
    type="button"
    @click="toggle($event)"
    @keydown.enter="toggle($event)"
  >
    <slot />
  </button>
  <button
    v-else
    type="button"
    @click="toggle($event)"
    @keydown.enter="toggle($event)"
  >
    <slot />
  </button>
</template>

<script>
import bus from "@/utils/eventBus";

export default {
  /**
   * Компонент кнопки
   */
  props: {
    triggerName: { type: String, default: undefined },
    triggerData: { type: Object, default: undefined },
    fixed: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  emits: ["active"],
  data() {
    return {
      stateActive: false,
    };
  },
  computed: {
    isActive: {
      /**
       * Свойство определяющее состояние кнопки
       */
      get() {
        if (this.fixed) {
          return this.stateActive;
        }
        return false;
      },
      set(val) {
        this.stateActive = val;
      },
    },
  },
  methods: {
    toggle($event) {
      if (this.fixed) {
        this.isActive = !this.isActive;
        if (this.isActive) {
          this.$emit("active", this._getActiveEventData());
          if (this.triggerName) this._doTrigger(this.triggerName, $event);
        }
      } else {
        if (this.triggerName) {
          this._doTrigger(this.triggerName, $event);
        }
        this.$emit("active", this._getActiveEventData());
      }
    },
    _doTrigger(triggerName, $event) {
      bus.emit(triggerName, this._getTriggerData($event));
    },
    _getTriggerData($event) {
      return this.triggerData || this.$el.dataset;
    },
    _getActiveEventData() {
      return this.$el.dataset;
    },
  },
};
</script>