<template>
    <profile-field-layout
        ref="layout" v-bind="layout"
        :editable="editable" 
        :iconSrc="iconSrc"
        :value-class="valueClass"
        :valueDisplayStr="valueDisplayStr" 
        :placeholder="placeholder"
        :validation-errors="validationErrors"
        :hint="hint"
        :hint-description="hintDescription">
        
        <template #editor>
        {{ label }}
        <li class="dropdownEl">
            {{labelFrom}}
            <input v-model="valueFrom" class="findSettings-age dropdownInput" type="number" :min="min" :max="max"></li>
        <li class="dropdownEl">
            {{labelTo}}
            <input v-model="valueTo" class="findSettings-age dropdownInput" type="number" :min="min" :max="max"></li>
        </template>
    </profile-field-layout>
</template>

<script>
import BaseFieldValidationError from './BaseFieldValidationError'
import ProfilePlainField from '@/components/fields/ProfilePlainField.vue'
import NumberFieldMixin from '@/components/fields/NumberFieldMixin'
import { INPUT_TYPE } from '@/constants';


export class RangeFieldValidationError extends BaseFieldValidationError {
    constructor(message, field) {
        super(message, field);
        this.name = "RangeFieldValidationError";
    }
}

export default {
    mixins: [ProfilePlainField, NumberFieldMixin],

    props: {
        iconSrc: { type: String, default: '/icons/line.png' },
        labelFrom: { type: String, default: 'от ' },
        labelTo: { type: String, default: 'до ' },
        separator: { type: String, default: '-' },
        inputType: { type: String, default: INPUT_TYPE.RANGE },
    },

    methods: {
        _getPreparedToSetValue(valueToSet, valueInputType = INPUT_TYPE.RANGE) {
            if (!Array.isArray(valueToSet) || valueToSet.length != 2) {
                valueToSet = []
            }
            // приводим значение к общему виду
            valueToSet = [
                this._isBlank(valueToSet[0]) ? undefined : +valueToSet[0],
                this._isBlank(valueToSet[1]) ? undefined : +valueToSet[1]
            ];
            return valueToSet;
        },
        _defaultValueValidator([valueFrom, valueTo]) {
            // переопределяем базовый валидатор для чисел, т.к. у range значение имеет вид массива с двумя числами
            NumberFieldMixin.methods._defaultValueValidator.call(this, valueFrom);
            NumberFieldMixin.methods._defaultValueValidator.call(this, valueTo);
            if (+valueFrom > +valueTo)
                throw new RangeFieldValidationError('значение "от" не может быть больше "до"', this);
        },
    },
    computed: {
        valueFrom: {
            cache: false,
            get() { return Array.isArray(this.value) ? this.value[0] : undefined },
            set(val) {
                // присвоение производится через массив т.к. тогда корректно срабатывает сеттер this.value
                this.value = [val, this._isBlank(this.value) ? undefined : this.value[1]];
            }
        },
        valueTo: {
            cache: false,
            get() { return Array.isArray(this.value) ? this.value[1] : undefined },
            set(val) {
                // присвоение производится через массив т.к. тогда корректно срабатывает сеттер this.value
                this.value = [this._isBlank(this.value) ? undefined : this.value[0], val];
            }
        },
        valueForDisplay() {
            if (this._isBlank(this.valueFrom) && this._isBlank(this.valueTo)) {
                return undefined;
            } else if (!this._isBlank(this.valueFrom) && this._isBlank(this.valueTo))
                return `${this.labelFrom}${this.valueFrom}`;
            else if (this._isBlank(this.valueFrom) && !this._isBlank(this.valueTo))
                return `${this.labelTo}${this.valueTo}`;
            return `${this.valueFrom}${this.separator}${this.valueTo}`
        },
    }
};
</script>