<template>
    <div class="myProfileMain-findButton">
        <button
            class="myProfileMain-pinkButton pinkButton"
            @click="showModal()"
        >
            найти...
            <img
                class="myProfileMain-loupe"
                :src="$static('/icons/loupe.png')"
            />
        </button>
    </div>
</template>

<script>
import { EVENTS } from "../../constants";
import { bootstrap, modals } from "@/globals";

export default {
    /**
     * Компонент кнопки для сохранения значений на сервере значений хранилища определённых одной схемой
     */
    props: {
        bsTarget: { type: String, default: "#find-modal" },
        labelSave: { type: String, default: "cохранить изменения" },
        labelSavedSuccessfully: { type: String, default: "успешно сохранено" },
        labelSaveRetry: { type: String, default: "попробовать сохранить" },
        // задержка отображения, чтоб показать сообщение, перед тем как исчезнуть,
        // например - об удачном сохранении, т.к. при удачном сохранении данные сразу
        // становятся идентичными сохранённым и кнопка должна исчезнуть
        showTimeout: { type: Number, default: 2000 },
    },
    // mounted() {
    //     bus.on(EVENTS.SCHEMA_STORAGE_SAVED, this._onSchemaStorageSaved, this)
    // },
    // unmounted() {
    //     bus.off(EVENTS.SCHEMA_STORAGE_SAVED, this._onSchemaStorageSaved)
    // },
    data() {
        return {
            // triggerName: EVENTS.TRIGGER_SCHEMA_STORAGE_SAVE,
            findModal: undefined,
            label: this.labelSave,
            disabled: false,
            isShowTimeout: true, // вышло ли время для задержки отображения кнопки?
            hasToSave: false, // есть ли изменённые значения для сохранения?
        };
    },
    methods: {
        showModal() {
            modals.findModal = new bootstrap.Modal(
                document.querySelector(this.bsTarget),
                {
                    // keyboard: false
                }
            );

            modals.findModal.show();
        },
        onActive() {
            this.disabled = true;
        },
        _onSchemaStorageSaved({ success, schema }) {
            if (schema != this.schema) {
                return;
            }
            if (success) {
                this.label = this.labelSavedSuccessfully;
            } else {
                this.label = this.labelSaveRetry;
                this.disabled = false;
            }
        },
    },
    computed: {
        triggerData() {
            if (this.schema) {
                return { schema: this.schema };
            }
            return undefined;
        },
        isButtonVisible() {
            // кнопка видна только если есть что сохранять или не вышло время отображения после нажатия
            return this.hasToSave || !this.isShowTimeout;
        },
    },
    watch: {
        // hasUnsavedValuesChanges: {
        //     handler(newChanges) {
        //         const thisSchemaHasChanges = !!newChanges[this.schema]
        //         if (this.hasToSave == thisSchemaHasChanges) {
        //             return
        //         }
        //         this.hasToSave = thisSchemaHasChanges;
        //         if (this.hasToSave) {
        //             this.disabled = false;
        //         } else {
        //             this.isShowTimeout = false;
        //             setTimeout((function() {
        //                 this.isShowTimeout = true;
        //                 this.label = this.labelSave;
        //             }).bind(this), this.showTimeout);
        //         }
        //     },
        //     deep: true
        // },
    },
};
</script>
