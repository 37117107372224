<template>
    <profile-field-layout
        ref="layout" v-bind="layout"
        :editable="editable"
        :value-class="valueClass"
        :value-display-str="valueDisplayStr" 
        :placeholder="placeholder"
        :validation-errors="validationErrors"
        :hint="hint"
        :hint-description="hintDescription">

        <template #value>
        {{ valueDisplayStr || placeholder }}
        </template>
        
        <template #editor>
        <li class="dropdownEl flex flex-d-column a-i-center">
            <input  :type="inputType" 
                    v-model="value"
                    :minlength="minLength"
                    :maxlength="maxLength" 
                    class="myProfileMain-dropdownNameField dropdownInput">
        </li>
        </template>
    </profile-field-layout>
</template>

<script>
import ProfilePlainField from './ProfilePlainField.vue'
import TextFieldMixin from './TextFieldMixin'


export default {
    mixins: [ProfilePlainField, TextFieldMixin]
}
</script>
