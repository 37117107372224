import bus from '@/utils/eventBus'

import BaseCompositeComponent from './BaseCompositeComponent'
import SchemaStorageCompositeMixin from './SchemaStorageCompositeMixin'
import SchemaDescriptorCompositeMixin from './SchemaDescriptorCompositeMixin'
import { ProfilePermissionsMixin, PROFILE_OWNER_RELATION } from './ProfilePermissionsMixin'

import SchemaStorageSaveButton from '@/components/buttons/SchemaStorageSaveButton.vue'
import ProfileFindButton from '@/components/buttons/ProfileFindButton.vue'
import ProfileGoHomeButton from '@/components/buttons/ProfileGoHomeButton.vue'
import ProfileSendMessageButton from '@/components/buttons/ProfileSendMessageButton.vue'
import ProfileOrderVipButton from '@/content/vip/components/ProfileOrderVipButton.vue'
import ProfileTopupCoinButton from '@/content/coin/components/ProfileTopupCoinButton.vue'
import OrderUpSearchButton from '@/content/up/components/ProfileOrderUpInSearchButton.vue'

import ProfileRawTextField from '@/components/fields/ProfileRawTextField.vue'
import ProfilePlainField from '@/components/fields/ProfilePlainField.vue'
import ProfileSelectField from '@/components/fields/ProfileSelectField.vue'
import ProfileAutocompleteSelectField from '@/components/fields/ProfileAutocompleteSelectField.vue'
import ProfileImagesCollectionField from '@/components/fields/ProfileImagesCollectionField.vue'
import { ImageSticker } from '@/components/fields/ProfileImageField.vue'
import ProfileAgeField from '@/components/fields/ProfileAgeField.vue'
import ProfileNumberField from '@/components/fields/ProfileNumberField.vue'
import ProfileTextAreaField from '@/components/fields/ProfileTextAreaField.vue'
import ProfileRangeField from '@/components/fields/ProfileRangeField.vue'
import ProfileInbox from '@/components/lists/ProfileInbox.vue'
import Slider from '@/components/sliders/Slider'
import SliderItems from '@/components/sliders/SliderItems.vue'
import SmallSlider from '@/components/sliders/SmallSlider'

import { history } from '@/services/index'
import { historyState } from '@/services/HistoryService'
import DialogModal from '@/components/modals/DialogModal.vue'

import {
    allUnreadMessagesCount
} from "@/services/MessengerService";
// import MessengerModal from '@/components/modals/MessengerModal.vue'
// import MessengerDialogsModal from '@/components/modals/MessengerDialogsModal.vue'
import {
    reactive,
    watchEffect,
    ref,
    computed,
    watch,
    nextTick,
} from "vue/dist/vue.esm-bundler";

import { userContent, profile, loggedInUserId } from '@/content/state'
import { OnlineStatusEnum } from '@/constants'

const EVENTS = {
    TRIGGER_SWITCH_UUID: 'trigger.profile-component.switch-uuid',
}

export { PROFILE_OWNER_RELATION, EVENTS }


export const profileOnline = computed(() => profile.online);
export const profileIsOnline = computed(() => !!profileOnline.value && profileOnline.value[0] == OnlineStatusEnum.ONLINE);
export const profileOnlineStr = computed(() => profileOnline.value[1] || "оффлайн");
// export const profileLocalityWatcher = watch(profile.locality, (newLocality) => {
//     newProfile.
// }, { deep: true });

export const OPER_ID_PULL_ME = "user_profile_me_api_v1_user_profile_get"
export const OPER_ID_PULL_ANOTHER = "user_profile_another_api_v1_user_profile__uuid__get"
export const OPER_ID_PUSH_ME = "user_profile_me_api_v1_user_profile_post"
export const OPER_ID_PUSH_ANOTHER = "user_profile_another_api_v1_user_profile__uuid__post"

/**
 * Компонент (композит из компонетов) представляющий профиль пользователя
 */
export default class ProfileComponent extends BaseCompositeComponent {
    constructor(config) {
        super({...config, schema: 'profile' });
        const composite = this;
        this.vueComponent = {
            mixins: [SchemaStorageCompositeMixin, SchemaDescriptorCompositeMixin, ProfilePermissionsMixin],
            components: {
                // Buttons
                SchemaStorageSaveButton,
                ProfileFindButton,
                ProfileGoHomeButton,
                ProfileSendMessageButton,
                ProfileOrderVipButton,
                ProfileTopupCoinButton,
                OrderUpSearchButton,

                // Fields
                ProfileRawTextField,
                ProfilePlainField,
                ProfileSelectField,
                ProfileAutocompleteSelectField,
                ProfileImagesCollectionField,
                ProfileAgeField,
                ProfileNumberField,
                ProfileTextAreaField,
                ProfileRangeField,

                // Lists
                ProfileInbox,
                
                // Sliders
                Slider,
                SliderItems,
                SmallSlider,

                // Modals
                DialogModal,
                // MessengerModal,
                // MessengerDialogsModal,
            },
            data() {
                return {
                    profileOnlineStr,
                    profileIsOnline,
                    profileOnline,
                    PROFILE_OWNER_RELATION,
                    uuid: loggedInUserId.value,
                    composite,
                }
            },
            computed: {
                _isMyProfile(){
                    return this.ownerRelation == PROFILE_OWNER_RELATION.ME
                },
                storageApi() {
                    const apiName = this.getValuesApiName(this.compositeSchema)
                    let api = this.valuesStorageApi[apiName];
                    const api4uid = Object.assign({}, api);
                    if (this.uuid || this.ownerRelation == PROFILE_OWNER_RELATION.STRANGER) {
                        if (!this.uuid) {
                            api4uid.url = api4uid.urlMe;
                            console.error('[ProfileComponent] storageApi: no uuid found, stranger must be with uuid!')
                            return api4uid;            
                        }
                        api4uid.url = api.urlAnother + this.uuid + '/'
                    } else {
                        api4uid.url = api.urlMe
                    }
                    return api4uid;
                },
                isVipEnabled(){
                    if (this._isMyProfile){
                        return userContent.is_vip
                    } else {
                        return this.getValuesStorage().is_vip_enabled
                    }
                },
                avatarStickers(){
                    if(this.isVipEnabled){
                        return [new ImageSticker()]
                    }
                    return []
                },
                online(){
                    return profile.online
                },
                isOnline(){
                    return !!profile.online && profile.online[0] == OnlineStatusEnum.ONLINE
                },
                onlineStr(){
                    if (!!profile.online)
                        return profile.online[1]
                    return "оффлайн"
                },
                unreadMessagesCount: () => allUnreadMessagesCount.value,
                bindAll() {
                    return {
                        blank: true,
                        editable: this.canEditFields,
                        labelOfBlankChoice: "не заполнено",
                        blankChoiceFirst: false,
                        schema: this.composite.config.schema,
                    }
                }
            },
            methods: {
                _getPullOId(){
                    return this._isMyProfile ? OPER_ID_PULL_ME : OPER_ID_PULL_ANOTHER
                },
                _getPullArgs(){
                    return this._isMyProfile ? undefined : {uuid: this.uuid}
                },
                _getPushOId(){
                    return this._isMyProfile ? OPER_ID_PUSH_ME : OPER_ID_PUSH_ANOTHER
                },
                // _getPushArgs(){
                //     return this.getValuesStorage()
                // },
                isSchemaStorageEqualsSaved() {
                    /**
                     * Метод проверки на равенство сохранённых значений (_pushedValuesStorage) с текущими (valuesStorage).
                     * @param schema {String} схема для сравнения
                     * 
                     * @returns {Boolean} 
                     */
                    if (this.ownerRelation == PROFILE_OWNER_RELATION.ME){
                        return this._getPushedValuesStorage() == JSON.stringify(this.getValuesStorage())
                    }
                    return true
                },
                _updateSchemaDescription(){
                    const locDescr = this.getFieldDescription(this.composite.config.schema, "locality")
                    // console.log(`[ProfileComponent] _updateSchemaDescription(): locDescr before=`, locDescr)
                    if (!locDescr.enum){ locDescr.enum = []}
                    if (!locDescr._labels){ locDescr._labels = []}
                    if (!locDescr.enum.map((e) => ""+e).includes(""+profile.locality)){
                        locDescr.enum.push(profile.locality)
                        locDescr._labels.push({value: profile.locality, label: profile.locality_label})
                    } else {
                        // console.debug(`[ProfileComponent] _updateSchemaDescription(): nothing to add...`)
                        // console.debug(`[ProfileComponent] _updateSchemaDescription(): locDescr.enum=`, locDescr.enum)
                        // console.debug(`[ProfileComponent] _updateSchemaDescription(): locDescr._labels=`, locDescr._labels)
                    }
                    // console.log(`[ProfileComponent] _updateSchemaDescription(): locDescr after=`, locDescr)
                },
                onSchemaStoragePulled(newStarage){
                    profile.online = newStarage.online
                    this._updateSchemaDescription()
                    // updating locality description from new profile data
                    
                    // Object.assign(profile, newStarage)
                },
                // async getFieldChoices(schema, field, query, forceQuery=false){
                //     if (schema == this.composite.schema && field == 'locality'){
                //         return await super.getFieldChoices(schema, field, qu, true)
                //     }
                //     return await super.getFieldChoices(schema, field, query, forceQuery)
                // },
                _pushComponentState(replaceIfNull=true){
                    let user_id = this.uuid;
                    if (!user_id){
                        user_id = loggedInUserId.value
                    }
                    const state =  this._getComponentState()
                    if (!state || state.user_id != user_id)
                        history.pushState(this.composite.schema, {user_id}, replaceIfNull)
                },
                _getComponentState(){
                    return history.getState(this.composite.schema)
                },
                isMyProfile(){
                    return !this.uuid || this.uuid == loggedInUserId.value
                },
                switchToUUID(uuid, pushState=true) {
                    if (this.uuid == uuid) return false;
                    if (uuid == loggedInUserId.value){
                        this.uuid = undefined;    
                    } else {
                        this.uuid = uuid;
                    }
                    if (pushState){
                        this._pushComponentState()
                    }
                    this.$forceUpdate();  // @TODO: try to remove this
                    return true
                },
                switchDataToUUID(uuid, pushState=true){
                    const isSwitched = this.switchToUUID(uuid, pushState)
                    if(isSwitched){
                        this.pullSchemaValues(this.compositeSchema)
                    }
                },
                _onPopedState(state){
                    console.log(
                        `[ProfileComponent] _onPopedState(): state=`,
                        state
                    );
                    if (state && state.user_id){
                        this.switchDataToUUID(state.user_id, false)
                        // this.switchToUUID(state.user_id)
                    }
                },
                getValuesStorageApi(schema) {
                    const api = this.storageApi;
                    return api;
                },
                goToMyProfile({noUpdate}={}){
                    this.switchToUUID(undefined);
                    if (!noUpdate) {
                        this.pullSchemaValues(this.compositeSchema)
                    }
                    console.log('goToMyProfile() switched!');
                },
                onTriggerSwitchToUUID(triggerData) {
                    if ('uuid' in triggerData && triggerData.uuid) {
                        // switch to personal another profile
                        this.switchToUUID(triggerData.uuid);
                        console.log('[ProfileComponent] onTriggerSwitchToUUID(): switched to another profile, uuid=', triggerData.uuid);
                    } else {
                        // switch to personal my profile
                        this.switchToUUID(undefined);
                        console.log('[ProfileComponent] onTriggerSwitchToUUID(): switched to my profile');
                    }

                    if (!triggerData.noUpdate) {
                        this.pullSchemaValues(this.compositeSchema)
                    }
                },
                _preparePulledData(pulledData, storageObj){
                    // prevent clearing photos field on update
                    const photosKey = 'photos'
                    // console.debug('[ProfileComponent] _preparePulledData() pulledData =', pulledData);
                    // console.debug('[ProfileComponent] _preparePulledData() storageObj =', storageObj);
                    if (photosKey in pulledData 
                        && (pulledData[photosKey] === undefined
                            || pulledData[photosKey] === null)
                    ){
                        delete pulledData[photosKey]
                        // console.debug('[ProfileComponent] _preparePulledData() null photos found, deleted. pulledData (now) =', pulledData);
                    }
                    this._updateSchemaDescription()
                    return pulledData
                },
            },
            valuesStorage: {
                handler(newValues, oldValues) {
                    // // reqire filtering???
                    // if (
                    //     newValues[this.composite.config.schema].locality 
                    //     != oldValues[this.composite.config.schema].locality
                    // ){
                    //     this._updateSchemaDescription()
                    // }
                    this._updateSchemaDescription()
                },
                deep: true
            },
            provide() {
                return {
                    switchToUUID: this.switchToUUID,
                }
            },
            beforeMount(){
                const state = this._getComponentState()
                console.log('[ProfileComponent] beforeMount(): #history initial state=', state)
                // if (!state && window.history.length > 3) {
                //     window.history.back()
                // }
                const watchState = watch(historyState, (args) => {
                    const state = this._getComponentState()
                    this._onPopedState(state)
                });
                
                this._pushComponentState()
                console.log('[ProfileComponent] beforeMount(): #history pushed state=', this._getComponentState())
                if (state && state.user_id){
                    console.log('[ProfileComponent] beforeMount(): #history switching to:', state.user_id)
                    this.switchDataToUUID(state.user_id, false)
                }
                if (this._setupOpenApiDataFromInitial()){
                    this._updateSchemaDescription()
                } else {
                    console.warn("[ProfileComponent] beforeMount(): failed update description")
                }
            },
            mounted() {
                bus.on(EVENTS.TRIGGER_SWITCH_UUID, this.onTriggerSwitchToUUID, this);
            },
            unmounted() {
                bus.off(EVENTS.TRIGGER_SWITCH_UUID, this.onTriggerSwitchToUUID);
            },
        };
    }
    // _getCompositeGlobalProperties(...args){
    //     const props = super._getCompositeGlobalProperties(...args);
    //     props.$closeModal()
    //     return props;
    // }
}