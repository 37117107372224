import {
    reactive,
    watchEffect,
    ref,
    computed,
    watch,
    nextTick,
} from "vue";

import { initial_state } from "@/globals";
import { SyncStateEnum } from "./constants";

/**
 * Сервис описывающий состояние контента.
 * @module
 */


export const userContent = initial_state.user_content = reactive(initial_state.user_content);
export const profile = initial_state.profile = reactive(initial_state.profile);
export const loggedInUserId = initial_state.logged_in_user_id = ref(initial_state.logged_in_user_id);

export const userContentSync = reactive({});


export class Sync{
    state = undefined
    constructor(state, cause=undefined){
        this.state = state
        if (cause){this.cause = cause}
    }
    static loading(){return new Sync(SyncStateEnum.LOADING)}
    static ready(){return new Sync(SyncStateEnum.READY)}
    static failed(cause=undefined){return new Sync(SyncStateEnum.FAILED, cause)}
}

export function setLoading(name){
    if (userContentSync[name]){
        userContentSync[name].state = SyncStateEnum.LOADING
        return
    }
    userContentSync[name] = Sync.loading()
}

export function setReady(name){
    if (userContentSync[name]){
        userContentSync[name].state = SyncStateEnum.READY
        return
    }
    userContentSync[name] = Sync.ready()
}

export function setFailed(name, cause=undefined){
    console.error("[content/state] setFailed():", {name, cause})
    if (userContentSync[name]){
        userContentSync[name].state = SyncStateEnum.FAILED
        return
    }
    userContentSync[name] = Sync.failed(cause)
}

export function getSyncState(name, defaultUndefined=true){
    if (!userContentSync[name]){
        if (defaultUndefined)
            return undefined
        else
            throw new Error(`Unknown content "${name}" sync state`)
    }
    return userContentSync[name].state;
}

export function isLoading(name, defaultIsLoading=true){
    const state = getSyncState(name, defaultIsLoading)
    return state == SyncStateEnum.LOADING || state == undefined;
}

export function isReady(name, defaultIsLoading=true){
    return getSyncState(name, defaultIsLoading) == SyncStateEnum.READY;
}

export function isFailed(name, defaultIsLoading=true){
    return getSyncState(name, defaultIsLoading) == SyncStateEnum.FAILED;
}

function patchSyncState(state){
    state.setLoading = setLoading
    state.setReady = setReady
    state.setFailed = setFailed
    state.getSyncState = getSyncState
    state.isLoading = isLoading
    state.isReady = isReady
    state.isFailed = isFailed
}

patchSyncState(userContentSync)
