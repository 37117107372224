<template>
    <teleport :to="teleportTo">
        <span :class="[textClass, 'dialog-text']">{{ textDisplay }}</span>
        <div v-if="buttonsDisplay && !!buttonsDisplay.length" class="container overflow-hidden">
            <div class="row gy-5">
                <div class="col-12 p-3">
                    <div class="d-flex justify-content-evenly">
                        <button 
                                v-for="(btn, index) in buttonsDisplay" :key="index"
                                @click="onClickButton(btn.value, $event)"
                                :class="[btn.class || buttonClass, 'dialog-'+btn.value]">
                            {{ btn.label }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="container overflow-hidden">
            <div class="row gy-5">
                <div class="col-12 p-3">
                    <div class="d-flex justify-content-evenly">
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>
import { bootstrap, modals, $ } from "@/globals";
/**
 * Компонент всплывающего окна для подтверждения чего-либо
 * 
 * @class 
 */
export default {
    props: {
        buttons: { type: Array, default: () => [{ value: 'no', label: 'Нет' }, { value: 'yes', label: 'Да' }] },
        title: { type: String, default: 'подтверждение' },
        text: { type: String, default: 'Вы уверены?' },

        buttonClass: { type: String, default: 'goldButton box-shadow-g-button' },
        textClass: { type: String, default: 'line-height-1' },
        teleportTo: { type: String, default: '#dialog-modal .modal-content' },
        titleSelector: { type: String, default: '#dialog-modal .dialog-title' },
        modalSelector: { type: String, default: '#dialog-modal' },
    },
    emits: ['answer'],
    data() {
        return {
            buttonsDisplay: this.buttons,
            titleDisplay: this.title,
            textDisplay: this.text,
            instance: undefined,
            answerCallback: undefined,
            closeCallback: undefined,
        }
    },
    methods: {
        onClickButton(buttonValue, $event) {
            this.$emit('answer', buttonValue);
            if (this.answerCallback)
                this.answerCallback(buttonValue);
        },
        setTitle(title) {
            document.querySelector(this.titleSelector).innerText = title;
        },
        show(kwargs) {
            // if (!!this.instance){
            //     this.close()
            // } 
            const { buttons, title, text, bsOptions } = kwargs
            if ("buttons" in kwargs) this.buttonsDisplay = buttons;
            if ("title" in kwargs) this.titleDisplay = title;
            if ("text" in kwargs) this.textDisplay = text;

            this.setTitle(this.titleDisplay);

            const modalEl = document.querySelector(this.modalSelector);
            if (!this.instance)
                this.instance = new bootstrap.Modal(modalEl, bsOptions);

            if (this.onHidden._listener)
                this.onHidden._listener = this.onHidden.bind(this);

            modalEl.addEventListener('hidden.bs.modal', this.onHidden._listener);

            this.instance.show();
        },
        getAnswer(options = {}) {
            const answerPromise = new Promise((resolve, reject) => {
                this.answerCallback = resolve;
                this.closeCallback = reject;
            });
            this.show(options);
            return answerPromise;
        },
        showHint(hintText, closeTimeout=0, trackLimit=0) {
            const answerPromise = new Promise((resolve, reject) => {
                this.answerCallback = resolve;
                this.closeCallback = reject;
            });
            var options = {
                buttons :[],
                title : "",
                text: hintText,
            }
            this.show(options);
            if (!!closeTimeout){
                setTimeout(()=>this.close(), closeTimeout);
            }
            if (!!trackLimit){
                let track = 0;
                const trackEvent = "mousemove"
                const onTrackHandler = () => {
                    console.log('Track:', track)
                    if (track > trackLimit){
                        this.close()
                        $(window).unbind(trackEvent, onTrackHandler)        
                    }
                    track++;
                }
                $(window).bind(trackEvent, onTrackHandler)
            }
            return answerPromise;
        },
        getConfirmation(options = {}) {
            if (!options.buttons)
                options.buttons = [{ value: 'no', label: 'Нет' }, { value: 'yes', label: 'Да' }] || this.buttons;
            return new Promise((resolve, reject) => {
                this.getAnswer(options).then(answer => {
                    if (answer == 'yes') {
                        resolve(true);
                    } else {
                        reject(false);
                    }
                    this.close();
                }).catch((e) => {
                    reject(false);
                    this.close();
                });
            });
        },
        close() {
            if (!this.instance) return;
            this.instance.hide();

            if (this.onHidden._listener)
                this.instance.removeEventListener('hidden.bs.modal', this.onHidden._listener);
        },
        onHidden(event) {
            if (this.closeCallback)
                this.closeCallback(event);
        }
    },
    watch: {
        titleDisplay(newTitle) { this.setTitle(newTitle); }
    },
    created() {
        /**
         *  Регистрируем модальный диалог, чтоб он был доступен дл я приложения глобально
         */
        modals.dialog = this;
    }
};
</script>