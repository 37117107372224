<template>
    <div class="find-itemsWrapper flex j-c-center">
        <button 
                class="find-item flex" 
                v-for="profile in profiles_display" 
                :key="profile.id"
                @click="trigggerGoToUserProfileWithUUID(profile.user_id)"
        >
            <div class="find-left flex flex-d-column">
            <img class="find-noPhoto" :src="profile.photoSrc ? $media(profile.photoSrc) : $static(PROFILE_NO_AVATAR_SRC)">
            <div class="find-about flex flex-d-column">
                <span class="find-name text-purple line-height-1 a-s-flex-start text-truncate">{{profile.name || PROFILE_NO_NAME_PLACEHOLDER}}</span>
                <div class="flex">
                    <span v-if="profile.age" class="find-info text-purple line-height-1">
                        {{profile.age || PROFILE_NO_AGE_PLACEHOLDER}}
                        <span class="find-infoSmall">
                            {{profile.ageNoun}}
                        </span>
                    </span>
                    <span v-if="profile.locality_label" class="find-infoSmall text-purple line-height-1">
                        <span class="find-info text-truncate">
                            {{profile.locality_label}}
                        </span>
                    </span>
                </div>
            </div>
            </div>
            <div v-if="profile.isOnline" class="find-online flex a-s-flex-end"></div>
        </button>
        <div class="flex" v-if="!profiles_display.length">
            <span class="find-name text-purple line-height-1 a-s-flex-start">{{ emptyListPlaceholder }}</span>
        </div>
    </div>
</template>

<script>
import bus from '@/utils/eventBus'
import { getNoun, getAge } from '@/utils/index'
import { modalsFn } from '@/globals'
import {
    PROFILE_NO_AGE_PLACEHOLDER,
    PROFILE_NO_AVATAR_SRC,
    PROFILE_NO_LOCALITY_PLACEHOLDER,
    PROFILE_NO_NAME_PLACEHOLDER,
    OnlineStatusEnum,
} from "@/constants";
import { EVENTS as PROFILE_COMPONENT_EVENTS } from '@/composites/ProfileComponent'

export default {
    // props: ['profiles'],
    props: {
        profiles: { type: Array, default: [] },
        emptyListPlaceholder: { type: String, default: 'Список пуск' },
    },
    data(){
        const profiles_autogenerated = [];
        for (let i=0; i<15; i++){
            profiles_autogenerated.push({photoSrc: '/img/noPhotoFind.png', age: undefined, name:"", id:i})
        }
        return {
            OnlineStatusEnum,
            PROFILE_NO_AGE_PLACEHOLDER,
            PROFILE_NO_AVATAR_SRC,
            PROFILE_NO_LOCALITY_PLACEHOLDER,
            PROFILE_NO_NAME_PLACEHOLDER,
            noPhotoSrc: '/img/noPhotoFind.png',  // /icons/camera.png
            profiles_autogenerated
        }
    },
    methods:{
        trigggerGoToUserProfileWithUUID(uuid){
            bus.emit(PROFILE_COMPONENT_EVENTS.TRIGGER_SWITCH_UUID, {uuid});
            modalsFn.close('#find-modal');
        },
    },
    computed:{
        profiles_display(){
            if (!this.profiles)
                return this.profiles_autogenerated;
            
            for (const p of this.profiles){
                if(p.birth_date){
                    p.age = getAge(p.birth_date)
                    p.ageNoun = getNoun(p.age, ...['год', 'года', 'лет'])
                }
                if(p.photos && p.photos.length){
                    p.photoSrc = p.photos[0].src
                }
                if(p.online && p.online[0] == OnlineStatusEnum.ONLINE){
                    p.isOnline = true
                } else {
                    p.isOnline = false
                }
            }
            return this.profiles;
        }
    }
}
</script>
