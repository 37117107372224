import ImagesService from '@/services/ImagesService'
import { id } from '@/utils/index'


/**
 * Vue-миксин для работы с информацией о изображении
 * @mixin 
 */
export default {
    methods: {
        createBlankImageNewId() {
            return id.tempGenerator.next().value;
        },
        /**
         * Создаёт объект описывающий "пустое" изображение (информация изображение) 
         * @param {object} fill заполнение объекта описывающего изображение "по умолчанию"
         * @returns объект описывающий пустое изображение
         * @method createBlankImage
         */
        createBlankImage(fill = {}) {
            return ImagesService.createBlankImage(fill);
        },
        /**
         * Присвоено ли изображению какое-либо значение (не пустое)
         * @param {Object} imageData изображение
         * @method isImageSet
         */
        isImageSet(imageData) {
            return ImagesService.isImageSet(imageData);
        },
        /**
         * Метод создания меню для изображения
         * @param {Object} imageData изображение, для которого получается меню
         * @param {Object} labels текст пунктов меню
         * @param {Function} handlers обработчики активации пунктов меню
         * @param {Object} extra дополнительные параметры
         * @returns массив пунктов меню с текстом и обработчиками
         * @method getImageMenu
         */
        getImageMenu(imageData, labels = {}, handlers = {}, extra = {}) {
            if (!imageData) {
                console.warn('[ImageFieldMixin] getImageMenu(): imageData is not set.');
            }
            const actions = ImagesService.getImageAvailableActions({ imageData });
            return actions.map(a => ({ label: labels[a], handler: handlers[a], ...(extra[a] || extra['*'] || {}) }));
        },
        getImageMenuActionsLabels(parameters) {
            return {
                [ImagesService.IMAGE_ACTIONS.UPLOAD]: 'Загрузить',
                [ImagesService.IMAGE_ACTIONS.REPLACE]: 'Заменить',
                [ImagesService.IMAGE_ACTIONS.DELETE]: 'Удалить',
                [ImagesService.IMAGE_ACTIONS.SET_AVATAR]: 'Сделать главным',
            };
        },
        getImageMenuActionsHandlers(parameters) {
            return {
                [ImagesService.IMAGE_ACTIONS.UPLOAD]: function() { return ImagesService.upload(parameters) },
                [ImagesService.IMAGE_ACTIONS.REPLACE]: function() { return ImagesService.replace(parameters) },
                [ImagesService.IMAGE_ACTIONS.DELETE]: function() { return ImagesService.delete(parameters) },
                [ImagesService.IMAGE_ACTIONS.SET_AVATAR]: function() { return ImagesService.setAvatar(parameters) },
            };
        },
    },
};