import ProfileComponent from '@/composites/ProfileComponent'
import AboutMeModal from '@/components/modals/AboutMeModal'
import { PROFILE_OWNER_RELATION } from '@/composites/ProfileComponent'
import ProfileFindComponent from '@/composites/ProfileFindComponent'
import ProfileFindSettingsComponent from '@/composites/ProfileFindSettingsComponent'
import ProfileMessengerComponent from '@/composites/ProfileMessengerComponent'
import ProfileMessengerDialogsComponent from '@/composites/ProfileMessengerDialogsComponent'
import ProfileMessengerInboxPopupComponent from '@/composites/ProfileMessengerInboxPopupComponent'
import IndicationBar from '@/composites/IndicationBarComposite'

import TopupModalComponent from '@/content/coin/composites/TopupModal'
import WarningBalanceModal from '@/content/coin/composites/WarningBalanceModal'
import UpInSearchModal from '@/content/up/composites/UpInSearchModal'
import UpInCountryModal from '@/content/up/composites/UpInCountryModal'
import UpInRegionCityModal from '@/content/up/composites/UpInRegionCityModal'
import ActivateVipModal from '@/content/vip/composites/ActivateVipModal'
import GiftSelectModal from '@/content/gifts/composites/GiftSelectModal'
import EventsModal from '@/content/event/composites/EventsModal'
import { pullData } from '@/utils/synchronizer'
import bus from '@/utils/eventBus'
import { modals } from '@/globals'
import constants from '@/constants'
import * as services from '@/services'


/**
 * Экспортируем компоненты(коспозиты из компонентов), которые будут потом использоваться на странице
 */
export default { 
    ProfileComponent,
    AboutMeModal,
    ProfileFindComponent,
    ProfileFindSettingsComponent,
    ProfileMessengerComponent,
    ProfileMessengerDialogsComponent,
    ProfileMessengerInboxPopupComponent,
    IndicationBar,

    // Content...
    TopupModalComponent,
    UpInSearchModal,
    UpInCountryModal,
    UpInRegionCityModal,
    ActivateVipModal,
    GiftSelectModal,
    WarningBalanceModal,
    EventsModal,
    
    // Extra...
    PROFILE_OWNER_RELATION, 
    bus,
    constants,
    services,
    
    pullData,
    modals,
}