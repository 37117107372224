<template>
<div class="slider flex">
  <div class="slider-top">
    <button class="slider-changeRegionButton" @click="onClickSwitchTops">
      <img class="slider-photo" :src="$static('/img/slider-top.png')" />
      <div
        v-if="visibleTop==TopLevelEnum.REGION || !visibleTop"
        class="slider-topText slider-region">
        Ваш регион
      </div>
      <div
        v-if="visibleTop==TopLevelEnum.CITY"
        class="slider-topText slider-country">
        Ваш город
      </div>
    </button>
  </div>

  <!-- слайдер региона -->
  <div v-show="visibleTop==TopLevelEnum.REGION" class="owl-carousel slider-owl slider-region">
    <slider-items
        :top-list="topsDisplay.region || []"
        :show-loading="isLoadingDisplay"
    ></slider-items>
  </div>

  <!-- слайдер города -->
  <div v-show="visibleTop==TopLevelEnum.CITY" class="owl-carousel slider-owl slider-country" >
    <slider-items
        :top-list="topsDisplay.city  || []"
        :show-loading="isLoadingDisplay"
    >
    </slider-items>
  </div>
</div>
</template>

<script>
// import { $ } from "@/globals"
import { userContent, userContentSync } from "@/content/state"
import {
    USER_CONTENT_KEY as TOPS_UC_KEY,
    USER_CONTENT_LOADING_KEY as TOPS_UC_LOADING_KEY,
    TopLevelEnum,
} from "@/content/tops/constants"
import { OC_SLIDER_PARAMS, OC_SLIDER_AUTOPLAY } from "./constants"
import SliderItems from './SliderItems.vue'
import TopSliderMixin from "./TopSliderMixin"

const OC_SELECTOR = '.slider-owl'
const TOPS_CHOICES = [TopLevelEnum.REGION, TopLevelEnum.CITY]


export default {
    props: {
        sliderParams: { type: Object, default(){ return OC_SLIDER_PARAMS } },
    },
    components: { SliderItems },
    data() { return {
        visibleTop: TopLevelEnum.REGION,
    }},
    created() {
        this.OC_SELECTOR = OC_SELECTOR;
    },
    // emits: ['activate'],
    mixins: [TopSliderMixin],
    methods: {
        onClickSwitchTops(){
          this.toggleVisible()
        },
        toggleVisible(){
            const opts = [...TOPS_CHOICES, TOPS_CHOICES[0]]
            const currentOpt = opts.indexOf(this.visibleTop)
            this.visibleTop = opts[currentOpt+1]
        },
        _initOCExtra($owlCarousel){
            $owlCarousel.trigger('play.owl.autoplay', [OC_SLIDER_AUTOPLAY]);;
        },
        _hasTopsForDisplay(){
            return (
              this.tops
              && (
                (this.tops.city && this.tops.city.length)
                || (this.tops.region && this.tops.region.length)
              )
            )
        },
    },
    watch:{
        topsDisplay(topsDisplay, topsDisplayOld){
            console.log("[Slider] topsDisplay watch:", {
                OS_SELECTOR: this.OC_SELECTOR,
                topsDisplay,
                topsDisplayOld,
                isLoadingDisplay: this.isLoadingDisplay,
            });
        }
    },
    mounted(){
      console.log("[Slider] mounted():", {
          isLoadingDisplay: this.isLoadingDisplay,
      });
    }
};
</script>
