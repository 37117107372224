<template>
<order-button
    :bs-target="bsTarget"
    class="myProfileMain-arrowUp"
>
    <img class="myProfileMain-menuIcons" :src="$static('/icons/arrowUp.png')" />
    <span
    class="myProfileMain-menuText myProfileMain-youProfileInTopText line-height-1 text-a-center text-purple"
    >
    место вашей анкеты в поиске
    <span class="myProfileMain-boldText">{{ value || 0 }}</span>
    </span>
    <div
    class="myProfileMain-menuButton hide-small hide-medium goldButton"
    >
    поднять
    </div>
    <div
    class="myProfileMain-menuButton hide-large line-height-1 goldButton"
    >
    +
    </div>
</order-button>
</template>

<script>
import OrderButton from "@/components/buttons/ProfileOrderBaseButton.vue";
import BaseField from "@/components/fields/BaseField";
// import { EVENTS } from '@/constants'
// import { bootstrap } from '@/globals'
// import bus from '../../utils/eventBus'

export default {
    /**
     * Компонент кнопки для сохранения значений на сервере значений хранилища определённых одной схемой
     */
    props: {
        bsTarget: { type: String, default: "#upInSearch-modal" },
        bsToggle: { type: String, default: "modal" },
        // labelSave: { type: String, default: 'cохранить изменения' },
        // labelSavedSuccessfully: { type: String, default: 'успешно сохранено' },
        // labelSaveRetry: { type: String, default: 'попробовать сохранить' },
        // // задержка отображения, чтоб показать сообщение, перед тем как исчезнуть,
        // // например - об удачном сохранении, т.к. при удачном сохранении данные сразу
        // // становятся идентичными сохранённым и кнопка должна исчезнуть
        // showTimeout: { type: Number, default: 2000 }
    },
    mixins: [BaseField],
    components: {
        OrderButton,
    },
    emits: ["activate"],
    // mounted() {
    //     this.$attrs.style.display = 'inherit'; // убираем скрытие кнопки (изначально в вёрстке она скрыта)
    //     bus.on(EVENTS.SCHEMA_STORAGE_SAVED, this._onSchemaStorageSaved, this)
    // },
    // unmounted() {
    //     bus.off(EVENTS.SCHEMA_STORAGE_SAVED, this._onSchemaStorageSaved)
    // },
    data() {
        return {
            // _modal: undefined,
            // triggerName: EVENTS.TRIGGER_SCHEMA_STORAGE_SAVE,
            // label: this.labelSave,
            // disabled: false,
            // isShowTimeout: true, // вышло ли время для задержки отображения кнопки?
            // hasToSave: false // есть ли изменённые значения для сохранения?
        };
    },
    methods: {
        // onClick(){
        //     this.$emit('activate');
        //     this.showModal();
        // },
        // getModaleEl(){return document.querySelector(this.bsTarget)},
        // getModal(){
        //     if (!this._modal){
        //         this._modal = new bootstrap.Modal(this.getModaleEl(), {keyboard: false})
        //     }
        //     return this._modal;
        // },
        // showModal(){
        //     if (!this.modal)
        //         return;
        //     this.modal.show();
        // },
    },
    computed: {
        // triggerData() {
        //     if (this.schema) {
        //         return { schema: this.schema }
        //     }
        //     return undefined
        // },
        // isButtonVisible() {
        //     // кнопка видна только если есть что сохранять или не вышло время отображения после нажатия
        //     return this.hasToSave || !this.isShowTimeout
        // },
    },
    // watch: {
    //     hasUnsavedValuesChanges: {
    //         handler(newChanges) {
    //             const thisSchemaHasChanges = !!newChanges[this.schema]
    //             if (this.hasToSave == thisSchemaHasChanges) {
    //                 return
    //             }
    //             this.hasToSave = thisSchemaHasChanges;
    //             if (this.hasToSave) {
    //                 this.disabled = false;
    //             } else {
    //                 this.isShowTimeout = false;
    //                 setTimeout((function() {
    //                     this.isShowTimeout = true;
    //                     this.label = this.labelSave;
    //                 }).bind(this), this.showTimeout);
    //             }
    //         },
    //         deep: true
    //     },
    // }
};
</script>
