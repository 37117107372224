import { state } from "@/globals";

import { reactive, ref, watch } from "vue/dist/vue.esm-bundler";

export const historyState = ref(undefined);

/**
 * получаем последнее состояние из localStorage
 */
const LOCAL_STORAGE_LAST_STATE_KEY = "historyLastState";
let LOCAL_STORAGE_LAST_STATE = undefined;
LOCAL_STORAGE_LAST_STATE = window.localStorage.getItem(
    LOCAL_STORAGE_LAST_STATE_KEY
);
if (LOCAL_STORAGE_LAST_STATE) {
    LOCAL_STORAGE_LAST_STATE = JSON.parse(LOCAL_STORAGE_LAST_STATE);
} else {
    LOCAL_STORAGE_LAST_STATE = {};
}
// делаем это состоянмие реактивным
export const lastState = reactive(LOCAL_STORAGE_LAST_STATE);

function _saveToLocalStorage() {
    const json = JSON.stringify(lastState);
    window.localStorage.setItem(LOCAL_STORAGE_LAST_STATE_KEY, json);
}

const watchLastState = watch(lastState, (args) => {
    _saveToLocalStorage();
});


export function mount() {
    state.history = [];
    window.addEventListener("popstate", (event) => {
        console.log("poped state:", event);
        historyState.value = event;
    });
    console.log(`[HistoryService] mount(): ready!`);
}

// export const historySeStr = computed(() => "" + historyState);

/**
 * ЗАменить состояние компонента в истории
 * TODO: сделать заменупо компонентам
 * * сейчас замена происходит для "общего компонента"
 * @param {*} component компонент, состояние которого сохраняется (не используется)
 * @param {Object} state состояние компонента
 */
export function replaceState(component, state) {
    lastState[component] = state;
    return window.history.replaceState({ component, state }, undefined);
}

/**
 * Получить состояние компонента из истории
 * TODO: сделать сохранение по компонентам
 * * сейчас полученмие происходит для "общего компонента"
 * @param {*} component компонент, состояние которого сохраняется (не используется)
 */
export function getState(component) {
    if (!!window.history.state) {
        return window.history.state.state;
    }
    if (window.history.length > 3 && lastState[component]) {
        return lastState[component];
    }
    return window.history.state;
    // window.history.pushState({component, state}, undefined)
}

/**
 * Записать состояние компонента в историю
 * TODO: сделать сохранение по компонентам
 * * сейчас сохранение происходит для "общего компонента"
 * @param {*} component компонент, состояние которого сохраняется (не используется)
 * @param {Object} state состояние компонента
 */
export function pushState(component, state, replaceIfNull = true) {
    if (!getState(component) && replaceIfNull) {
        return replaceState(component, state);
    }
    lastState[component] = state;
    return window.history.pushState({ component, state }, undefined);
}
