// import { modals } from '@/globals'
// import { id } from '@/utils/index'
import api from "@/api/index";
import { userContent, setReady, setFailed, setLoading } from "../state";
import { USER_CONTENT_KEY } from "./constants";


export function update(){
    setLoading(USER_CONTENT_KEY)
    return api.apiCall({
        name: "content_tops_my_api_v1_content_tops_my_get",
    }).then(function (response) {
        console.log(
            `[content/tops/services] loadMissingContent() then: response=`,
            response
        );
        userContent[USER_CONTENT_KEY] = response.data
        setReady(USER_CONTENT_KEY)
        return response
    }).catch((cause) => {
        setFailed(USER_CONTENT_KEY, cause)
    })
}

// TODO: remove this!!! only for debug! 
// setTimeout(()=>update(), 3000)

export function loadMissingContent(){
    return update()
} 

// export function confirmTopupRobokassa({
//     amount,
//     url = "/",
//     success = undefined,
//     dataType = undefined,
// }) {
//     /**
//      * TODO: реализовать переход к оплате через робокассу
//      */
//     const data = {
//         "OutSum": amount,
//         // "MerchantLogin": amount,
//     };
//     // Access-Control-Allow-Origin
//     window.location.href = url
//     return $.ajax({
//         type: "POST",
//         url: url,
//         headers: {'Access-Control-Allow-Origin': '*'},
//         data: data,
//         success: success,
//         dataType: dataType,
//         async: false,
//     });
// }

// export function confirmTopup({ amount }) {
//     return confirmTopupRobokassa({ amount });
// }

// export function topup({ amount }) {
//     console.log(`[content/coint/services] topup(): amount=${amount}`);
//     return api
//         .apiCall({
//             name: "billing_wallet_topup_api_v1_billing_ordering_topup__post",
//             data: { amount: amount },
//         })
//         .then(function (response) {
//             console.log(
//                 `[content/coint/services] topup() then: amount=${amount}, response=`,
//                 response
//             );
//             // delete imageData.toPost;
//         })
//         .catch(function (cause) {
//             console.log(
//                 `[content/coint/services] topup() catch: amount=${amount}, cause=`,
//                 cause
//             );
//             // Object.assign(imageData, previousImageData)
//         });
// }

// export function topupAndConfirm({ amount }) {
//     return topup({amount})
//         .then(()=>confirmTopup({amount}))
//         .catch(function (cause) {
//             console.log(
//                 `[content/coint/services] topupAndConfirm() catch: amount=${amount}, cause=`,
//                 cause
//             );
//             // Object.assign(imageData, previousImageData)
//         });
// }