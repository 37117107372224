import { getNoun } from ".";


export const YEARS_NOUN = [
    'год', 'года', 'лет'
]

/**
 * Возвращает правильное окончание для чисел (последеней цифры числа)
 * @param {Number} years
 * @method getNoun 
 */
export function getAgeNoun(years) {
    return getNoun(years, ...YEARS_NOUN);
}