import { pullData } from '@/utils/synchronizer'
import { pathGet } from '@/utils/object'
import api from "@/api/index";


/**
 * Миксин композита для работы с описанием схемы (compositeSchema)
 * @vue-data descriptionStorage {object} объект для хранения описания схемы
 * @vue-data descriptorApi {object} объект для хранения апи доступа к описанию
 * @mixin SchemaDescriptorCompositeMixin
 */
export default {
    data() {
        return {
            _descriptionUpdateDebounce: 100, // ms

            // // хранилище описания
            descriptionStorage: {
                [this.getDescriptorApiName()]: this.compositeConfig.description
            },

            // // настройки api для хранилища
            descriptorApi: {
                _openApi: { url: '^/openapi.json', json: this.compositeConfig.openApiJson },
                [this.getDescriptorApiName()]: {
                    url: this.compositeConfig.descriptorApiUrl,
                    ...(this.compositeConfig.descriptorApi || {})
                }
            },
            _openApiJsonLoadingPromise: undefined,
        }
    },
    provide() {
        // dependency injection in composites components
        return {
            getFieldChoices: this.getFieldChoices,
        }
    },
    computed: {
        openApiCompositeSchemaName() { return this._openApiGetSchemaName(this.compositeSchema) }
    },
    methods: {
        getDescriptionStorageName(schema) { return schema || this.compositeConfig.schema },
        getDescriptorApiName(schema) { return schema || this.compositeConfig.schema },
        getDescriptionStorage(schema) { return this.descriptionStorage[this.getDescriptionStorageName(schema)] },
        getDescriptorApi(schema) { return this.descriptorApi[this.getDescriptorApiName(schema)] },
        _openApiGetSchemaName(name) {
            name = name.replace(/^\w/, c => c.toUpperCase())
            return `${name}Schema`;
        },
        _openApiGetRefObject(openApiJson, ref, def) {
            const path = ref.split('/').slice(1); // removing first '#' in path
            return pathGet(openApiJson, path, def);
        },
        /**
         * Возвращает описание значений enum-а поля
         * @param {Object} fieldDescription описание поля в openapi.json
         * @returns описание значений enum-а поля
         * @method _getLabelsForOpenApiEnums
         */
        _getLabelsForOpenApiEnums(fieldDescription) {
            if (fieldDescription._labels && !!fieldDescription._labels.length){
                // console.debug(
                //     '[SchemaDescriptorCompositeMixin] _getLabelsForOpenApiEnums(): fieldDescription._labels=', 
                //     fieldDescription._labels
                // );
                return fieldDescription._labels
            }
            if (!fieldDescription.additionalProperties){
                // console.debug(
                //     '[SchemaDescriptorCompositeMixin] _getLabelsForOpenApiEnums(): additional props no found fieldDescription=', 
                //     fieldDescription
                // );
                return undefined
            }
            const loc = fieldDescription.additionalProperties.localization
            if (!loc){
                console.debug(
                    '[SchemaDescriptorCompositeMixin] _getLabelsForOpenApiEnums(): descr no found fieldDescription=', 
                    fieldDescription
                );
                return undefined
            }
            const labels = []
            for (const val of fieldDescription.enum){
                labels.push({value: val, label: loc[val]})
            }
            if (!labels.length){
                return undefined
            }
            fieldDescription._labels = labels
            return labels
            // return fieldDescription.additionalProperties;
        },
        /**
         * Определяет, используются ли значения вариантов выбора как их текстовые описания.
         * 
         * @param {Object} fieldDescription описание поля в openapi.json
         * @returns true - значения являются описаниями, false - значения не равны описаниям
         * @method _isValuesAsLabels
         */
        _isValuesAsLabels(fieldDescription) {
            /**
             * Просто смотрим в описании поля наличие boolean аттрибута со значением true.
             */
            return (
                !!fieldDescription.additionalProperties 
                && fieldDescription.additionalProperties.values_as_labels
            );
        },
        _isOApiLoaded(){
            return !!this.descriptorApi._openApi.json
        },
        _setupOpenApiDataFromInitial(){
            if (this._isOApiLoaded()) {
                return true
            } else if (!!window.kup.openapi_schema) {
                this.descriptorApi._openApi.json = window.kup.openapi_schema;
                return true
            }
            return false
        },
        /**
         * Подготавливает контент openapi.json
         * @method
         */
        async _prepareOpenApiData() {
            if (this._setupOpenApiDataFromInitial()) {
                return true
            }
            if (this._openApiJsonLoadingPromise) {
                await this._openApiJsonLoadingPromise;
            }
            console.debug('[SchemaDescriptorCompositeMixin] _prepareOpenApiData(): загружаю openapi.json...');
            try {
                this._openApiJsonLoadingPromise = pullData(this.descriptorApi._openApi.url);
                this.descriptorApi._openApi.json = await this._openApiJsonLoadingPromise;
                console.debug('[SchemaDescriptorCompositeMixin] _prepareOpenApiData(): получен openapi.json, содержимое:', this.descriptorApi._openApi.json);
            } catch (e) {
                console.warn('[SchemaDescriptorCompositeMixin] _prepareOpenApiData(): не удалось загрузить описание в openapi.json. Ошибка:', e);
                throw e;
            } finally {
                this._openApiJsonLoadingPromise = undefined;
            }
            if (!this.descriptorApi._openApi.json) {
                console.warn('[SchemaDescriptorCompositeMixin] _prepareOpenApiData(): не удалось прочитать описание в openapi.json.');
                return false;
            }
        },
        _oapiGetField(schema, fieldPath, def, refRedirect = true) {
            const root = this.descriptorApi._openApi.json.components.schemas[schema].properties[fieldPath[0]];
            const walk = (currentNode, path, index = 0) => {
                if (index > 0) {
                    if (currentNode.properties) {
                        currentNode = currentNode.properties[path[index]]
                    } else {
                        return currentNode;
                    }
                }
                if (!currentNode)
                    return undefined;

                let $ref;
                if (currentNode.$ref) {
                    $ref = currentNode.$ref
                } else if (currentNode.items && currentNode.items.$ref){
                    $ref = currentNode.items.$ref
                }
                if ($ref) {
                    currentNode = this._openApiGetRefObject(this.descriptorApi._openApi.json, $ref);
                    // index++;
                }

                if (index < path.length - 1) {
                    return walk(currentNode, path, index + 1);
                }
                return currentNode;
            }
            return walk(root, fieldPath, 0);
        },
        /**
         * Возвращает описание поля из openapi.json
         * @method
         */
        async _getOpenApiSchemaFieldDescription(schema, fieldPath, def, refRedirect = true) {
            const _isDebug = ["partner.sex", "interests", "locality", "languages"].includes(fieldPath.join("."))
            if (_isDebug){
                console.log(`[SchemaDescriptorCompositeMixin] _getOpenApiSchemaFieldDescription(): описание ${fieldPath} схемы ${schema}...`);
                // console.debug(`[SchemaDescriptorCompositeMixin] getFieldChoices(): описание ${field} схемы ${schema}...`);
            }
            if (!this._setupOpenApiDataFromInitial()){ 
                if (! await this._prepareOpenApiData()){
                    return undefined;
                }
            }
            // console.log('[SchemaDescriptorCompositeMixin] _getOpenApiSchemaFieldDescription(): getting description from openapi downloaded content...');
            // recursive walker function
            const field = this._oapiGetField(schema, fieldPath, def, refRedirect)
            return field;
        },
        /**
         * Возвращает путь к полю field в схеме schema
         * @param {String} schema 
         * @param {String} field 
         * @returns {Array[String]}
         */
        _getFieldPath(schema, field){
            return field.split('.');
        },
        /**
         * Возвращает варианты выбора.
         * @todo: исправить первый вызов queryFieldChoicesDebounced(), который не возвращает никаких значений
         * @param {String} schema схема, где находится описание поля
         * @param {String} field поле, чьи варианты нужны
         * @param {String} query запрос фильтрующий варианты
         * @returns список вариантов дял поля
         * @method getFieldChoices
         */
        async getFieldChoices(schema, field, query, value, valueSet=false) {
            // если есть строка запроса - обращаемся сразу на сервер:
            if (query && query != '' || valueSet) {
                // let url;
                // if (valueSet) {
                //     url = this.getFieldValueDescriptionQueryUrl(schema, field, query, value)
                // } else {
                //     url = this.getFieldDescriptionQueryUrl(schema, field, query)
                // }
                // const choicesResponce = await pullData(url);
                const choicesResponce = await api.apiCall({
                    name: `user_profile_descr_locality_api_v1_user_descr_${schema}_${field}_get`,
                    data: { v:value, q:query, },
                });
                try{
                    return choicesResponce.data.choices || [];
                }catch(e){
                    console.error(`[SchemaDescriptorCompositeMixin] getFieldChoices(): ошибка при получении вариантов выбора для поля ${field} схемы ${schema} по запросу ${q}`);
                    console.debug(`[SchemaDescriptorCompositeMixin] getFieldChoices(): ошибка =`, e);
                    return []
                }
            }
            const path = this._getFieldPath(schema, field);
            
            if (!this._setupOpenApiDataFromInitial()){ 
                if (! await this._prepareOpenApiData()){
                    console.error("failed to prepare oapi data!")
                }
            }
            let descr = this._oapiGetField(this._openApiGetSchemaName(schema), path);
            // console.debug(`[SchemaDescriptorCompositeMixin] getFieldChoices(): описание ${field} схемы ${schema} = `, descr);
            let oapiEnum;
            if (descr && Array.isArray(descr.enum)){
                oapiEnum = descr.enum
            }
            let additionalProperties;
            if (descr && descr.additionalProperties){
                additionalProperties = descr.additionalProperties
            }
            if (!oapiEnum && additionalProperties) {
                if (additionalProperties.widget_type == "select"){
                    oapiEnum = additionalProperties.initial_choices || []
                    descr.enum = oapiEnum
                }
            }
            if (!oapiEnum) {
                // если в openapi.json нет описания поля, то возвращаем описание из descriptionStorage
                console.debug(`[SchemaDescriptorCompositeMixin] getFieldChoices(): нет описания или enum в описании поля ${field} схемы ${schema} в openapi, используем значение в descriptionStorage`);
                descr = pathGet(this.getDescriptionStorage(schema), path);
                if (!descr){
                    console.error(`[SchemaDescriptorCompositeMixin] getFieldChoices(): отсутствует описание поля ${field} схемы ${schema} в descriptionStorage!`);
                }
                return descr;
            }
            if (this._isValuesAsLabels(descr)) {
                // console.debug(`[SchemaDescriptorCompositeMixin] getFieldChoices(): value as label for ${field} схемы ${schema}`);
                
                const choosedValues = pathGet(this.getValuesStorage(schema), path) || []
                const choices = choosedValues.slice();
                
                for(const ch of oapiEnum){
                    if (choices.includes(ch)){ continue };
                    choices.push(ch);
                }
                // console.debug(`[SchemaDescriptorCompositeMixin] getFieldChoices(): choices=`, choices);
                return choices;
            }
            let enumLabels = this._getLabelsForOpenApiEnums(descr);
            if (!enumLabels) {
                // если в openapi.json нет описания значений enum-а, то используем описания из хранилища
                console.info(`[SchemaDescriptorCompositeMixin] getFieldChoices(): enum в openapi.json есть, но без текстовых описаний (поле ${field} схема ${schema}), берём из descriptionStorage`);
                enumLabels = pathGet(this.getDescriptionStorage(schema), path);
                if (!enumLabels) console.error(`[SchemaDescriptorCompositeMixin] getFieldChoices(): отсутствует текстовые описание enum-ов поля ${field} схемы ${schema} в descriptionStorage!`);
            }
            // else console.debug(`[SchemaDescriptorCompositeMixin] getFieldChoices(): найдено текстовое описание значений enum-а поля ${field} схемы ${schema} в openapi.json, берём его`);
            return oapiEnum.map(value => ({ value, label: this._getLabelForValue(value, enumLabels) }));

        },
        getFieldDescription(schema, field){
            const path = this._getFieldPath(schema, field);
            return this._oapiGetField(
                this._openApiGetSchemaName(schema),
                path,
            );
        },
        // async getFieldDescription(schema, field){
        //     const path = this._getFieldPath(schema, field);
        //     return await this._getOpenApiSchemaFieldDescription(
        //         this._openApiGetSchemaName(schema),
        //         path,
        //     );
        // },
        _getLabelForValue(value, labelsCollection) {
            const match = labelsCollection.find(o => o.value == value);
            if (match) return match.label;
            return '' + value;
        },
        // getFieldDescriptionQueryUrl(schema, field, query) {
        //     const baseUrl = this.getDescriptorApi(schema).url;
        //     query = query ? `?q=${query}` : '';
        //     return `${baseUrl}/${field}${query}`;
        // },
        // getFieldValueDescriptionQueryUrl(schema, field, query, value) {
        //     const baseUrl = this.getDescriptorApi(schema).url;            
        //     if (!value){
        //         query = query ? `?q=${query}` : '';    
        //     } else {
        //         query = query ? `&q=${query}` : '';   
        //     }
        //     value = value ? `?v=${encodeURI(value)}` : '';
        //     return `${baseUrl}/${field}${value+query}`;
        // },
    },
};