<template>
<a 
  :data-user_id="li.user_id" 
  onclick="profileSwitchTo(this.dataset.user_id)"
  v-for="li in topListDisplay"
  :key="li.user_id" 
  class="myProfileMain-item"
>
  <div class="myProfileMain-inTopPhotoWrapper flex">
    <img v-if="li.is_vip" class="myProfileMain-coronaInTop hide-small hide-medium" :src="$static('/icons/corona-on-avatar.png')">

    <img v-if="li.avatar" class="myProfileMain-inTopPhoto" 
        :src="$media(li.avatar.srcFull)" :alt="'Фото '+(li.name || '[аноним]')"/>
    <img v-else class="myProfileMain-inTopPhoto myProfileMain-inTopPhoto--no-photo" 
        :src="$static('/icons/camera.png')" :alt="'Фото '+(li.name || '[аноним]')"/>
      
    <div class="myProfileMain-shortInfoBar flex flex-d-column ">
      <span class="myProfileMain-nameBar line-height-1">
        {{ li.name || 'Аноним' }}
      </span>
      <div>
        <span class="myProfileMain-yearsBar line-height-1">
          {{li.age}}
          <span class="myProfileMain-smallLetter">
            {{ getAgeNounDisplay(li.age) }}
          </span>
        </span>
        <span class="myProfileMain-cityBar line-height-1">
          <span class="myProfileMain-smallLetter"><!--г.--></span>
          {{ li.locality_name || '' }}
        </span>
      </div>
    </div>
  </div>
</a>
<a v-if="showEmpty" href="#" class="myProfileMain-item">
  <div class="myProfileMain-inTopPhotoWrapper flex">
    <img  
      :src="$static('/icons/camera.png')"
      class="myProfileMain-inTopPhoto myProfileMain-inTopPhoto--no-photo" 
      alt="Нет фото"
    />
  </div>
</a>
<a v-if="showLoadingDisplay" class="myProfileMain-item" href="#">
  <div class="myProfileMain-inTopPhotoWrapper flex">
    <div class="slider-small-placeholder-wrapper">
      <slider-item-loading-placeholder>
      </slider-item-loading-placeholder>
    </div>
  </div>
</a>
</template>
<script>
import BaseSliderItems from "./BaseSliderItems"


export default {
  extends: BaseSliderItems,
  watch:{
    showLoadingDisplay(val, valOld){
      console.log(`[SmallSliderItems] showLoadingDisplay watch:`, {val, valOld})
    },
    showEmpty(val, valOld){
      console.log(`[SmallSliderItems] showEmpty watch:`, {val, valOld})
    },
    topListDisplay(val, valOld){
      console.log(`[SmallSliderItems] topListDisplay watch:`, {val, valOld})
    },
  }
};
</script>

<style>
.slider-small-placeholder-wrapper{
  position: relative;
  height: 13vw;
  display: inline-block;
  left: 43%;
  scale: .4;
  opacity: 0.4;
  top: 2vw;
}
.slider-small-placeholder-wrapper .cssload-shadow{
  display: none;
}
</style>