<template>
    <div class="myProfileMain-myProfilePhotos flex flex-d-column">
        <slot name="avatar" :image-field-class="avatarClass">
            <!-- avatar image field -->
        </slot>
        <button class="myProfileMain-pinkButtonAboutMe pinkButton line-height-1 hide-large"
                data-bs-toggle="modal"
                data-bs-target="#aboutMe-modal"> 
            Обо мне...
        </button>
        <div class="myProfileMain-anotherPhotos flex j-c-s-between">
            <slot name="previews" :image-field-class="previewsClass">
                <!-- images previews fields -->
            </slot>
        </div>
        <div class="myProfileMain-buttonsBlockMobile line-height-1 flex flex-d-column a-i-center j-c-center">
            <!--<button class="myProfileMain-simpleButton"
                    data-bs-toggle="modal"
                    data-bs-target="#announcement-modal">
                объявления
            </button> --> 
            <button @click="onClickAboutMe"
                class="myProfileMain-simpleButton"
                data-bs-toggle="modal"
                data-bs-target="#events-modal"
            >
                события
            </button>
        </div>
    </div>
    <slot name="extra"><!-- extra elements of layout --></slot>
</template>

<script>
/**
 * Поле описывающее коллекцию изображений в профиле
 * @vue-prop previewsCount {Number} количество превью под аватаром 
 * @class 
 */
export default {
    props: {
        avatarClass: { type: String, default: 'myProfileMain-mainAvatar' },
        previewsClass: { type: String, default: 'myProfileMain-photoWrapper' },
    },
    data() {
        return {
            // _modal: undefined,
            // triggerName: EVENTS.TRIGGER_SCHEMA_STORAGE_SAVE,
            // label: this.labelSave,
            // disabled: false,
            // isShowTimeout: true, // вышло ли время для задержки отображения кнопки?
            // hasToSave: false // есть ли изменённые значения для сохранения?
        };
    },
    methods: {
        onClickAboutMe(){
            
        },
        // getModaleEl(){return document.querySelector(this.bsTarget)},
        // getModal(){
        //     if (!this._modal){
        //         this._modal = new bootstrap.Modal(this.getModaleEl(), {keyboard: false})
        //     }
        //     return this._modal;
        // },
        // showModal(){
        //     if (!this.modal)
        //         return;
        //     this.modal.show();
        // },
    },
    computed: {
        // triggerData() {
        //     if (this.schema) {
        //         return { schema: this.schema }
        //     }
        //     return undefined
        // },
        // isButtonVisible() {
        //     // кнопка видна только если есть что сохранять или не вышло время отображения после нажатия
        //     return this.hasToSave || !this.isShowTimeout
        // },
    },
}
</script>