// import { modals } from '@/globals'
// import bus from '@/utils/eventBus'
// import api from "@/api/index";
// import { EVENTS } from '@/content/coin/composites/WarningBalanceModal'


export default {
    ORDERED_SUCCESSFULLY: "ORDERED_SUCCESSFULLY",
    ORDERING_FAILED: "ORDERING_FAILED",
}
