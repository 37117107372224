/**
 * Модуль с исключениями приложения
 */


export class MessengerError extends Error {
    module = "мессенджер"
    messageDefault = "Произошла ошибка в модуле"
    constructor(message=undefined){
        super(message)
        console.log("[MessengerError]", {
            "this.message": this.message, 
            message, 
            "this.messageDefault": this.messageDefault,
        })
        this.message = this.message || this.messageDefault
        console.log("[MessengerError] init this.message=", this.message)
    }
    // getMessage(){
    //     if (!this.message){
    //         return this.messageDefault
    //     }
    // }
}

export class MessengerFoundContactsError extends MessengerError {
    messageDefault = "В тексте сообщения найдены запрещённые ссылки или контакты"
    constructor(message=undefined){
        super(message)
        console.log("[MessengerError]", {
            "this.message": this.message, 
            message, 
            "this.messageDefault": this.messageDefault,
        })
        this.message = message ? message : this.messageDefault
        console.log("[MessengerError] init this.message=", this.message)
    }
}
