import ValuesStorageFieldMixin from '@/components/fields/ValuesStorageFieldMixin'
import { INPUT_TYPE } from '@/constants'
import BaseFieldValidationError from '@/components/fields/BaseFieldValidationError'


/**
 * Базовый компонент, содержащий в себе основную логику полей.
 * 
 * @prop {Boolean} initialAsDefault разрешение использовать initial как значение по умолчанию
 * @prop {Boolean} initialAsDefaultSet разрешение использовать initial как значение по умолчанию при присвоении новых значений
 * @prop {Boolean} blank разрешение полю быть пустым (с без присвоенного значения)
 * @prop {Boolean} editable разрешение полю быть редактируемым
 * @vue-computed {*} value значения поля
 * 
 * 
 * @example 
 * // Cоздание нового компонента на базе BaseField
 * <html>
 * <date-field></date-field>
 * <sctipt>
 * const BaseField = {...};  // продключаем BaseField
 * const DateField = {
 *   // Новый компонент, например поле даты
 *   mixins: [BaseField],  // используем его как mixin Vue для нового компонента
 *   
 *   // выполняем нужные переопределения mixin-а BaseField
 *   props: {initial: {type: Date, default: new Date()}, initialAsDefault: {default: true}},
 *   template: '<span>Текущая дата: {{ valueDisplayStr }}</span>'
 * }
 * Vue.createApp({ components: { DateField } }).mount('#app')
 * </sctipt>
 * </html>
 * @class
 */
export default {
    mixins: [ValuesStorageFieldMixin],
    props: {
        schema: String,
        field: String,
        inputType: { type: String, default: INPUT_TYPE.TEXT },
        initial: { default: undefined },
        initialAsDefault: { type: Boolean, default: false },
        initialAsSetDefault: { type: Boolean, default: false },
        blank: { type: Boolean, default: false },
        prefix: { type: String, default: '' },
        postfix: { type: String, default: '' },
        editable: { type: Boolean, default: false },
    },
    data() {
        return {
            valueValidators: [],
            validationErrors: [],
        }
    },
    methods: {
        getValidationErrorsForValue(value) {
            const errors = []
            for (let validatorFunc of this.valueValidators) {
                try {
                    validatorFunc(value)
                } catch (e) {
                    if (e instanceof BaseFieldValidationError) {
                        errors.push(e);
                    } else {
                        throw e;
                    }
                }
            }
            return errors;
        },
        clearValidationErrors() {
            this.validationErrors = [];
        },
        getPrefix() {
            return this.prefix;
        },
        getPostfix() {
            return this.postfix;
        },
        _getPreparedToSetValue(valueToSet, valueInputType = INPUT_TYPE.TEXT, initial, initialAsSetDefault) {
            switch (valueInputType) {
                case INPUT_TYPE.TEXT:
                    return ('' + valueToSet);
                case INPUT_TYPE.NUMBER:
                    return +valueToSet;
                case INPUT_TYPE.OBJECT:
                    if (initialAsSetDefault) {
                        return {...initial, ...valueToSet }
                    }
                    break;
            }
            return valueToSet;
        },
        /**
         * Проверяет значение на "пустоту"
         * @method _isBlank
         */
        _isBlank(value) {
            return value === undefined || value === null || value === ''
        },
    },
    created() {
        if (this.initialAsDefault && this.initial !== undefined && this._isBlank(this.value)) {
            this.value = this.initial;
        }
    },
    computed: {
        hasValidationErrors() {
            return !!this.validationErrors.length;
        },
        valueForDisplay() {
            /**
             * Возвращает значение для отображения
             */
            return this.value;
        },
        valueDisplayStr() {
            /**
             * Возвращает строку представляющую значение для отображения.
             * В случае отсутствия значения - undefined.
             */
            const val = this.valueForDisplay;
            if (this._isBlank(val)) {
                return undefined
            }
            return `${this.getPrefix(val)}${val}${this.getPostfix(val)}`;
        },
        value: {
            /**
             * Модель, значение поля
             */
            cache: false,
            get() {
                return this._valueGet(); // предоставляется mixin-ом
            },
            set(val) {
                val = this._getPreparedToSetValue(val, this.inputType, this.initial, this.initialAsSetDefault);
                this.validationErrors = this.getValidationErrorsForValue(val);
                return this._valueSet(val, this.isArrayItem); // предоставляется mixin-ом
            },
        },
    }
};