import BaseCompositeComponent from "@/composites/BaseCompositeComponent";
import SchemaStorageCompositeMixin from "@/composites/SchemaStorageCompositeMixin";
import {
    WsDataTypeEnum,
    NotificationUserIdTypeEnum,
    MessageStatus,
} from "@/api/constants";
import {
    sendMessage,
    stopTyping,
    startTyping,
    queueHistory,
    markRead,
    // MessageStatus,
    // NotificationUserIdTypeEnum,
} from "@/services/MessengerService";
import {
    connector,
    getConnector,
    // MessageStatus,
    // NotificationUserIdTypeEnum,
} from "@/api/websocket";
// import { removeEmptys } from '@/utils/index'
// import SchemaDescriptorCompositeMixin from './SchemaDescriptorCompositeMixin'
// import { ProfilePermissionsMixin, PROFILE_OWNER_RELATION } from './ProfilePermissionsMixin'

// import SchemaStorageSaveButton from '@/components/buttons/SchemaStorageSaveButton.vue'
// import SchemaStoragePullButton from '@/components/buttons/SchemaStoragePullButton.vue'
// import ProfileRawTextField from '@/components/fields/ProfileRawTextField.vue'
// import ProfilePlainField from '@/components/fields/ProfilePlainField.vue'
// import ProfileSelectField from '@/components/fields/ProfileSelectField.vue'
// import ProfileAutocompleteSelectField from '@/components/fields/ProfileAutocompleteSelectField.vue'
// import ProfileImagesCollectionField from '@/components/fields/ProfileImagesCollectionField.vue'
// import ProfileAgeField from '@/components/fields/ProfileAgeField.vue'
// import ProfileNumberField from '@/components/fields/ProfileNumberField.vue'
// import ProfileTextAreaField from '@/components/fields/ProfileTextAreaField.vue'
// import ProfileRangeField from '@/components/fields/ProfileRangeField.vue'

// import ProfilesList from '@/components/lists/ProfilesList.vue'

// import DialogModal from '@/components/modals/DialogModal.vue'
// import
// import { DEFAULT_TOPUP_AMOUNT } from "../constants";
import { lookup } from "../services";

import bus from '@/utils/eventBus'

import { events } from "../state";

// export { PROFILE_OWNER_RELATION }

/**
 * Компонент (композит из компонетов) представляющий профиль пользователя
 */
export default class extends BaseCompositeComponent {
    constructor(config) {
        super({ ...config, schema: "events" });
        this.vueComponent = {
            mixins: [SchemaStorageCompositeMixin],
            components: {
                // Buttons
                // SchemaStorageSaveButton,
                // SchemaStoragePullButton,
                // // Fields
                // ProfileRawTextField,
                // ProfilePlainField,
                // ProfileSelectField,
                // ProfileAutocompleteSelectField,
                // ProfileImagesCollectionField,
                // ProfileAgeField,
                // ProfileNumberField,
                // ProfileTextAreaField,
                // ProfileRangeField,
                // Lists
                // ProfilesList,
                // // Modals
                // DialogModal,
            },
            data() {
                // if (!this.getValuesStorage().events_list){
                //     this.getValuesStorage().events_list = []
                // }
                return {
                    latestTimestamp: undefined,
                    // eventsList: events.value,
                    // eventsList: this.getValuesStorage().events_list,
                };
            },
            created(){
                // this.eventsList = events.value || []
                this._onIncomingMessageBind = this.onIncomingMessage.bind(this)
                this.connectWs()
            },
            methods: {
                connectWs() {
                    // const connector = getConnector();
                    connector.addEventListener("message", this._onIncomingMessageBind)
                    // console.log("[ProfileMessengerComponent] openWs(): connector=", connector)
                    const connectionPromise = connector.getConnection();
                    
                    // console.log("[ProfileMessengerComponent] openWs(): connectionPromise=", connectionPromise)
                    return connectionPromise.then((ws) => {
                        this.ws = ws;
                        return ws;
                    });
                },
                onIncomingMessage(wsMessageEvent){
                    // console.log("[EventsModal] onIncomingMessage(): wsMessageEvent=", wsMessageEvent)
                    const wsData = JSON.parse(wsMessageEvent.data)
                    console.log("[EventsModal] onIncomingMessage(): wsData=", wsData)
                    if (wsData.type != WsDataTypeEnum.EVENT){
                        return
                    }
                    console.log("[EventsModal] onIncomingMessage(): assigning items...")
                    // events.value = [...wsData.data.items, ...events.value]
                    this.updateEventsList(wsData.data.items, true)
                    console.log("[EventsModal] onIncomingMessage(): this.eventsList=", events.value)
                },
                updateEventsList(new_list, append = false){
                    if (append){
                        events.value = [...new_list, ...events.value];
                    } else {
                        events.value=new_list;
                    }
                    
                    console.log("[EventsModal] update(): new_list.lenght=", new_list.lenght)
                    console.log("[EventsModal] update(): new_list=", new_list)
                    if (!!new_list.lenght){
                        this.latestTimestamp = new_list[0].timestamp
                        console.log("[EventsModal] update(): new_list[0].timestamp=", new_list[0].timestamp)
                        console.log("[EventsModal] update(): _this.latestTimestamp=", this.latestTimestamp)
                    }
                },
                update(){
                    const _this = this;
                    console.log("[EventsModal] update():_this.eventsList=", _this.eventsList)
                    lookup().then((response) => {
                        const new_list = response.data || []
                        this.updateEventsList(new_list)
                        console.log("[EventsModal] update() then:_this.eventsList=", _this.eventsList, "new_list=", new_list, "_this.latesttimestamp=", _this.latestTimestamp)
                    })
                },
                getAvatarUrl(event){
                    if (event.actor_avatar_src)
                        return this.$media(event.actor_avatar_src);
                    
                    return this.$static('/icons/camera.png')
                },
                // onClickTopup() {
                //     topupAndConfirm({ amount: this.topupAmount })
                // },
                // getCoinsCount() {
                //     try{
                //         console.log(
                //             "[TopupModal] getCoinsCount(): this.getValuesStorage() =",
                //             this.getValuesStorage()
                //         );
                //         return this.getValuesStorage().coins;
                //     } catch (e) {
                //         console.error(
                //             "[TopupModal] getCoinsCount(): error =", e
                //         );
                //         return 0;
                //     }
                    
                // },
            },
            computed: {
                eventsList() { return events.value }
                // coins_count() {
                //     return this.getValuesStorage().coins;
                // },
            },
        };
    }
}
