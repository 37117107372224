<template>
    <order-button
        bs-target="#activateVip-modal"
    >
        <div
            :class="[
                { 'myProfileMain-greenDot': isVip },
                { 'myProfileMain-redDot': !isVip },
            ]"
        ></div>
        <img
            class="myProfileMain-menuIcons"
            :src="
                $static(
                    isVip ? '/icons/corona-on-avatar.png' : '/icons/corona.png'
                )
            "
        />
        <span
            v-if="isVip"
            class="myProfileMain-menuText myProfileMain-vipActive line-height-1 text-a-center text-purple"
        >
            Vip-статус
            <span class="myProfileMain-boldText text-uppercase">
                активирован
            </span>
        </span>
        <span
            v-else
            class="myProfileMain-menuText line-height-1 text-a-center text-purple"
        >
            Vip-возможности
            <span class="myProfileMain-boldText">не доступны!</span>
        </span>
        
        <div
            v-if="!isVip"
            class="myProfileMain-menuButton hide-small hide-medium goldButton"
        >
            активировать
        </div>
        <div
            v-if="!isVip"
            class="myProfileMain-menuButton hide-large line-height-1 goldButton"
        >
            +
        </div>
    </order-button>
</template>

<script>
import OrderButton from "@/components/buttons/ProfileOrderBaseButton.vue";
import BaseField from "@/components/fields/BaseField";
import { userContent } from '@/content/state'

export default {
    props: {
        bsTarget: { type: String, default: "#topUpBalance-modal" },
        bsToggle: { type: String, default: "modal" },
    },
    mixins: [BaseField],
    components: {
        OrderButton,
    },
    emits: ["activate"],
    data() {return {};},
    methods: {},
    computed: {
        isVip() {return userContent.is_vip; },
    },
};
</script>
