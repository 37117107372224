
export const OC_SLIDER_PARAMS = {
    nav: false,
    items: 5,
    loop: true,
    margin: 10,
    slideTransition: 'linear',
    autoplayTimeout: 3000,
    autoplaySpeed: 3000,
    autoplay: true,
    autoplayHoverPause: true,
    autoWidth: true,
    dragEndSpeed: 3000,
};

export const OC_SLIDER_AUTOPLAY = 1000

export const OC_SMALL_SLIDER_PARAMS = {
    items: 1,
    loop: true,
    margin: 20,
    touchDrag  : true,
    mouseDrag  : true,
    pullDrag    :true,
    freeDrad    :true,
    // autoplay: true,
}