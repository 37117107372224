<template>
    <profile-field-layout 
        ref="layout"
        :editable="editable" 
        :iconSrc="iconSrc"
        :value-class="valueClass"
        :value-display-str="valueDisplayStr" 
        :placeholder="placeholder"
        :validation-errors="validationErrors"
        :hint="hint"
        :hint-description="hintDescription"
        v-bind="layout"
        >
        
        <template #editor>
        <li class="dropdownEl flex flex-d-column a-i-center">
            {{ label }}
            <input      :type="inputType" 
                        v-model="value"
                        :min="min"
                        :max="max" 
                        class="myProfileMain-dropdownAgeField dropdownInput">
        </li>
        </template>
    </profile-field-layout>
    
</template>

<script>
import ProfileNumberField from './ProfileNumberField.vue'
import { getZodiac } from '@/utils/zodiac' 
export default {
    mixins: [ProfileNumberField],

    props: {
        iconSrc: { type: String, default () { return 'icons/birthday.png' } },
        hint: { type: String, default () { return 'Ваш возраст' } },
        noun: { type: Array, default () { return [' год', ' года', ' лет'] } },
        min: { type: Number, default () { return 12 } },
        max: { type: Number, default () { return 12000 } },
    },

    computed: {
        birthday(){
            if (this.value && !(this.value instanceof Date)) {
                return new Date(this.value)
            }
            return this.value
        },
        valueForDisplay() {
            if (this.value)
                return `${this.calculateAge(this.birthday)}`;
            return undefined;
        },
    },

    methods: {
        calculateAge(birthday) {
            if (!(birthday instanceof Date)) {
                birthday = new Date(birthday)
            }
            const ageDifMs = Date.now() - birthday;
            const ageDate = new Date(ageDifMs);
            return Math.abs(ageDate.getUTCFullYear() - 1970);
        },
        calculateZodiac(birthday) {
            if (!(birthday instanceof Date)) {
                birthday = new Date(birthday)
            }
            return getZodiac(birthday)
        },
        getPostfix(number) {
            let postfix = this.postfix
            if (this.noun)
                postfix = this.getNoun(number, ...this.noun);
            if (this.birthday){
                postfix += `, ${this.calculateZodiac(this.birthday)}`
            }
            return postfix;
        },
    },
}
</script>