// import { ref, reactive } from 'vue/dist/vue.esm-bundler';
import { ref, reactive } from 'vue';
const jQuery = window.jQuery || window.$
const PhotoSwipe = window.PhotoSwipe
const PhotoSwipeUI_Default = window.PhotoSwipeUI_Default
const bootstrap = window.bootstrap

let API_URL = undefined;
try{
    const url_str = window.apiUrl || (""+window.location)
    API_URL = new URL(window.apiUrl)
} catch (e){
    const url = window && window.apiUrl
    console.error(`[globals.js] failed API_URL_OBJ getting from window.apiUrl=${url}. Exception=`, e)
};

const APP_ATTR_NAME = window.kup.APP_ATTR_NAME
const request = window.request
const baseRequest = window.baseRequest
const getApiCsrfToken = window.kup.getApiCsrfToken
const getStaticUrlTemplate = window.kup.getStaticUrlTemplate
const getMediaUrlTemplate = window.kup.getMediaUrlTemplate

if (!('settings' in window.kup)) {
    window.kup.settings = {}
}
const settings = window.kup.settings
const initial_content = window.kup.initial_conten
// window.kup.initial_state = ref(window.kup.initial_state)
const initial_state = window.kup.initial_state
const state = reactive({
    history: undefined,
})

// const state = window[APP_ATTR_NAME]

const APP_STORAGE_NAME = `${APP_ATTR_NAME}Storage`
const APP_STORAGE_API_NAME = `${APP_ATTR_NAME}StorageApi`

// console.log('initial_state=', initial_state)
// console.log('window[APP_STORAGE_NAME]=', window[APP_STORAGE_NAME])
// console.log('window=', window)
window[APP_STORAGE_NAME] = initial_state;


const modalsFn = {
    close: window.closeModal,
    show: window.showModal
}

const modals = {}



export {
    // global external libs
    jQuery,
    jQuery as $,
    bootstrap,
    PhotoSwipe,
    PhotoSwipeUI_Default,
    
    // global external variables and functions 
    API_URL,
    APP_STORAGE_NAME,
    APP_STORAGE_API_NAME,
    request,
    baseRequest,
    getApiCsrfToken,
    getStaticUrlTemplate,
    getMediaUrlTemplate,
    settings,
    initial_content,
    initial_state,
    state,

    // global container for modals
    modals,
    modalsFn
}