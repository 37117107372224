/**
 * Synchronizer of front and back
 */


import { API_URL, request, getApiCsrfToken, baseRequest } from "../globals";

// получаем урл хоста 
const apiUrlA = document.createElement('a');
apiUrlA.setAttribute('href', API_URL);
const HOST_URL = `${apiUrlA.protocol}//${apiUrlA.hostname}:${apiUrlA.port}`;

/**
 * Утилита получения url-ов для выполнения запросов.
 * 
 * Если есть символ ^ в url - это говорит о необходимости построении 
 * url запроса от HOST_URL, а не от точки входа в api.
 * @function _parseUrl
 */
function _parseUrl(url) {
    let rootUrl
    if (url.charAt(0) == '^') {
        rootUrl = HOST_URL
        url = url.slice(1);
    } else {
        rootUrl = API_URL
    }
    return { rootUrl, url }
}

/**
 * Загрузчик данных с сервера
 * @function pullData
 */
export function pullData(targetUrl, args) {
    let { rootUrl, url } = _parseUrl(targetUrl);
    return new Promise((resolve, reject) => {
        console.log('[synchronizer] pullData(): url=', url)
        request(url, 'GET', args, undefined, resolve, reject, getApiCsrfToken(), { rootUrl });
    });
}

/**
 * Выгрузчик данных на сервер
 * @function pushData
 */
export function pushData(targetUrl, data = {}, hasFiles) {
    const { rootUrl, url } = _parseUrl(targetUrl);
    return new Promise((resolve, reject) => {
        request(url, 'POST', data, undefined, resolve, reject, getApiCsrfToken(), { contentType: hasFiles ? "application/x-www-form-urlencoded" : undefined, rootUrl });
    });
}

/**
 * Выгрузчик данных на сервер
 */
export function requestApi({ url, data, hasFiles, method, absUrl, hostUrl, beforeSend}) {
    const params = {
        type: method || 'get',
        CSRFToken: getApiCsrfToken(),
    };
    if (data){ params.data = data }
    if (hasFiles){ params.hasFiles = hasFiles }
    if (beforeSend){ params.beforeSend = beforeSend }

    if (absUrl){
        params.url = absUrl
    } else if (hostUrl){
        params.url = HOST_URL + hostUrl
    } else if (url) {
        params.url = API_URL + url
    } else { 
        throw TypeError('No one of required arguments set.')
    }
    
    return new Promise((resolve, reject) => {
        params.successCallback = resolve;
        params.errorCallback = reject;
        baseRequest(params);
    });
}