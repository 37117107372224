
export function getZodiac(birth_date) {
    birth_date = birth_date || new Date()
    const dayInMonth = birth_date.getDate()
    let zodiacSign;
    switch (birth_date.getMonth()+1) {
        case 1:
            if (dayInMonth <= 19)
                zodiacSign = 'козерог';
            else
                zodiacSign = 'водолей';
            break;
        case 2:
            if (dayInMonth <= 18)
                zodiacSign = 'водолей';
            else
                zodiacSign = 'рыбы';
            break;
        case 3:
            if (dayInMonth <= 20)
                zodiacSign = 'рыбы';
            else
                zodiacSign = 'овен';
            break;
        case 4:
            if (dayInMonth <= 19)
                zodiacSign = 'овен';
            else
                zodiacSign = 'телец';
            break;
        case 5:
            if (dayInMonth <= 20)
                zodiacSign = 'телец';
            else
                zodiacSign = 'близнецы';
            break;
        case 6:
            if (dayInMonth <= 21)
                zodiacSign = 'близнецы';
            else
                zodiacSign = 'рак';
            break;
        case 7:
            if (dayInMonth <= 22)
                zodiacSign = 'рак';
            else
                zodiacSign = 'лев';
            break;
        case 8:
            if (dayInMonth <= 22)
                zodiacSign = 'лев';
            else
                zodiacSign = 'дева';
            break;
        case 9:
            if (dayInMonth <= 22)
                zodiacSign = 'дева';
            else
                zodiacSign = 'весы';
            break;
        case 10:
            if (dayInMonth <= 22)
                zodiacSign = 'весы';
            else
                zodiacSign = 'скорпион';
            break;
        case 11:
            if (dayInMonth <= 22)
                zodiacSign = 'скорпион';
            else
                zodiacSign = 'стрелец';
            break;
        case 12:
            if (dayInMonth <= 21)
                zodiacSign = 'стрелец';
            else
                zodiacSign = 'козерог';
            break;
    }
    return zodiacSign
}