// import BaseButton from './BaseButton.vue'
import { EVENTS } from "@/constants";
import { bootstrap } from "@/globals";
// import bus from '../../utils/eventBus'

export default {
    /**
     * Компонент кнопки для сохранения значений на сервере значений хранилища определённых одной схемой
     */
    props: {
        bsTarget: { type: String, default: undefined },
        bsToggle: { type: String, default: "modal" },
    },
    emits: ['click'],
    data() {
        return {
            _modal: undefined,
        };
    },
    methods: {
        onClick() {
            console.log("[ModalsMixin] onClick(): click!");
            this.$emit("click");
            this.showModal();
        },
        getModaleEl(modalSelector) {
            return document.querySelector(modalSelector || this.bsTarget);
        },
        getModal(modalSelector) {
            if (!this._modal) {
                this._modal = new bootstrap.Modal(this.getModaleEl(modalSelector), {
                    keyboard: false,
                });
            }
            return this._modal;
        },
        showModal(modalSelector) {
            const modal = this.getModal(modalSelector);
            console.log("[ModalsMixin] showModal(): modal=", modal);
            if (!modal) return;
            modal.show();
        },
        closeModal(modalSelector) {
            const modal = bootstrap.Modal.getInstance(this.getModaleEl(modalSelector));
            console.log("[ModalsMixin] closeModal(): modal=", modal);
            if (!modal) return;
            modal.hide();
        },
    },
};