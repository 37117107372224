<template>
<a  
  class="slider-item" 
  :data-user_id="li.user_id" 
  onclick="profileSwitchTo(this.dataset.user_id)"
  v-for="li in topListDisplay"
  :key="li.user_id"
>
  <img v-if="li.avatar" class="slider-photo" 
      :src="$media(li.avatar.srcFull)" :alt="'Фото '+(li.name || '[аноним]')"/>
  <img v-else class="slider-photo slider-photo--no-photo" 
      :src="$static('/icons/camera.png')" :alt="'Фото '+(li.name || '[аноним]')"/>
  <div class="slider-profileInfo">
    <div>{{ li.name || 'Аноним' }}</div>
    <div v-if="li.age">{{getAgeDisplay(li.age)}}</div>
    <div v-if="li.locality_name">{{ li.locality_name || '' }}</div>
  </div>
</a>
<a v-if="showLoadingDisplay" class="slider-item" href="#">
  <div class="slider-photo placeholder-wrapper">
    <slider-item-loading-placeholder>
    </slider-item-loading-placeholder>
  </div>
</a>
<a v-if="showEmpty" class="slider-item" href="#">
  <img class="slider-photo slider-photo--no-photo" :src="$static('/icons/camera.png')" alt="Нет фото"/>
  <div class="slider-profileInfo">
    <div>Пока пусто</div>
    <div></div>
    <div></div>
  </div>
</a>
</template>
<script>
import BaseSliderItems from './BaseSliderItems'
import { getAgeNoun } from '@/utils/userInfo'
import { SliderItemLoadingPlaceholder } from './binding'


export default {
  extends: BaseSliderItems,
  methods: {},
  computed:{},
  watch:{
    topListDisplay(topListDisplay, topListDisplayOld){
      console.log("[SliderItems] topListDisplay watch:", {topListDisplay, topListDisplayOld})
    },
    topList(topList, topListOld){
      console.log("[SliderItems] topList watch:", {topList, topListOld})
    },
    showEmpty(showEmpty, showEmptyOld){
      console.log("[SliderItems] showEmpty watch:", {showEmpty, showEmptyOld})
    },
    showLoadingDisplay(showLoadingDisplay, showLoadingDisplayOld){
      console.log("[SliderItems] showLoadingDisplay watch:", {showLoadingDisplay, showLoadingDisplayOld})
    },
  },
  // mounted(){
  //   console.log("[SliderItems] mounted(): ", {items: this.items, $refs: this.$refs})
  // }
};
</script>
<style>
.placeholder-wrapper{
  position: relative;
  height: 10vw;
  display: inline-block;
  bottom: -25%;
  scale: .4;
  opacity: 0.4;
}
</style>