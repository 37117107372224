<template>
    <profile-field-layout
        ref="layout" v-bind="layout"
        :editable="editable" 
        :iconSrc="iconSrc"
        :value-class="valueClass"
        :value-display-str="valueDisplayStr" 
        :placeholder="placeholder"
        :validation-errors="validationErrors"
        :hint="hint"
        :hint-description="hintDescription">
        
        <template #value>
            <span :class="labelClass">
                {{ label }}
            </span>
            <div class="myProfileMain-purpleWrap flex">
                <span :class="valueClass">
                    <template v-if="valueDisplayStr!==undefined">{{ valueDisplayStr }}</template>   
                    <template v-else>{{ placeholder }}</template>
                </span>
            </div>
        </template>

        <template #editor>
        <li class="dropdownEl flex flex-d-column a-i-center">
            <textarea 
                :cols="cols" 
                :rows="rows" 
                :resize="resize"
                v-model="value" 
                :style="{'resize':resize, 'overflow': overflow}"
                class="dropdownInput"></textarea>
        </li>
        </template>
    </profile-field-layout>
</template>

<script>
import ProfilePlainField from './ProfilePlainField.vue'
import TextFieldMixin from './TextFieldMixin'


export default {
    mixins: [ProfilePlainField, TextFieldMixin],
    props: {
        labelClass: { type: String, default: 'myProfileMain-bubble text-uppercase text-bold-600 text-white-w-o-important' },
        valueClass: { type: String, default: 'myProfileMain-textInPurpleBubble text-white-w-o-important' },
        rows: { type: Number, default: 2 },
        cols: { type: Number, default: 33 },
        resize: { type: String, default: 'both' },
        overflow: { type: String, default: 'auto' },
    }
};
</script>