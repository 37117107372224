import { userContent, userContentSync } from "@/content/state"
import { 
    USER_CONTENT_KEY as TOPS_UC_KEY,
    TopLevelEnum,
} from "@/content/tops/constants"


export default {
    props: {
        sliderParams: { type: Object, default(){ return {}} },
    },
    data() { return { 
        isInit: false,
        topsDisplay: userContent[TOPS_UC_KEY] || {},
        isLoadingDisplay: this.isLoading || !this.isInit,
    }},
    created() {
        this.TopLevelEnum = TopLevelEnum;
    },
    methods: {
        _getOCSelector(){ return this.OC_SELECTOR },
        _getOC(){ return $(this._getOCSelector()) },
        _getOCParams(){ return this.sliderParams || {} },
        getOCParams(){ return this._getOCParams() },
        _cleanOC(){
            if (!this.isInit) 
                return false;
            
            let $oc = this._getOC()
            $oc.trigger('destroy.owl.carousel'); 
            $oc.find('.owl-stage-outer').children().unwrap();
            $oc.removeClass('owl-center owl-loaded owl-text-select-on');
            this.isInit = false;
            return true;
        },
        _initOC(){
            if (this.isInit) 
                return false;
            let $oc = this._getOC()
            $oc.owlCarousel(this._getOCParams());
            if (this._initOCExtra){
                this._initOCExtra($oc)
            }
            this.isInit = true;
            return true;
        },
        _initOCExtra($owlCarousel){},
        _hasTopsForDisplay(){
            return this.tops && (
                (this.tops.city && this.tops.city.length ) 
                || (this.tops.region && this.tops.region.length)
            )
        },
    },
    computed:{
        tops() { return userContent[TOPS_UC_KEY] },
        isLoading() { 
            return userContentSync.isLoading(TOPS_UC_KEY)
        },
    },
    watch: {
        tops(tops, topsOld) {
            this.topsDisplay = tops;
            // if (!this._hasTopsForDisplay())
            //     return;
            // this._cleanOC()
            // this.$nextTick(() => { 
            //     this.topsDisplay = tops;
            //     this.$nextTick(() => { 
            //         this._initOC() 
            //     }); 
            // });         
        },
        isLoading(isLoading, isLoadingOld) {
            this._cleanOC()
            this.$nextTick(() => { 
                this.isLoadingDisplay = isLoading;
                this.$nextTick(() => { 
                    this._initOC() 
                }); 
            });
        },
    },
    mounted(){
        this._initOC()
    }
};