import { EVENTS } from "@/constants";
import {
    allUnreadMessagesCount
} from "@/services/MessengerService";
import bus from "@/utils/eventBus";
import { debounce, trimSpaces } from "@/utils/index";
import {
    equalId, includesId,
    indexId, makeDialogClientId, makeUserClientId
} from "@/utils/messenger";
import BaseCompositeComponent from "./BaseCompositeComponent";
import SchemaStorageCompositeMixin from "./SchemaStorageCompositeMixin";
import IndicatorButton from "@/components/buttons/IndicatorButton.vue";
import Slider from "@/components/sliders/Slider.vue";
/**
 * Компонент (композит из компонетов) представляющий профиль пользователя
 */
/* 

*/
export default class extends BaseCompositeComponent {
    constructor(config) {
        super({ ...config, schema: "indication" });
        this.vueComponent = {
            // mixins: [
            //     SchemaStorageCompositeMixin,
            // ],
            components: {
                IndicatorButton,
                Slider,  // TODO: refactor this!
            },
            // data() {
            //     return {
            //         unreadMessagesCount: 0,
            //     };
            // },
            computed: {
                unreadMessagesCount: () => allUnreadMessagesCount.value
            },
        };
    }
}
