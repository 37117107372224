import { EVENTS } from '@/constants'
import { APP_STORAGE_NAME, APP_STORAGE_API_NAME } from '@/globals'
import bus from '@/utils/eventBus'
import { debounce } from '@/utils/index';
import { pushData, pullData } from '@/utils/synchronizer'
import { computed } from '@vue/reactivity';
import api from '@/api/index'

/**
 * Миксин композита для работы со значениями в хранилище значений (valuesStorage), определённых 
 * схемой (compositeSchema)
 * @mixin
 */
export default {
    data() {
        // console.log('[SchemaStorageCompositeMixin] data(): valuesStorage (before init) =', this.valuesStorage);
        // console.log('[SchemaStorageCompositeMixin] data(): valuesStorageApi (before init) =', this.valuesStorageApi);
        
        const storage = this._getRootValuesStorage();
        const storageName = this.getValuesStorageName();
        // console.log('[SchemaStorageCompositeMixin] data(): storage (before init) =', storage);
        
        
        storage[storageName] = storage[storageName] || this.compositeConfig.values || {};

        const storageApi = this._getRootValuesStorageApi();
        // console.log('[SchemaStorageCompositeMixin] data(): storageApi (before init) =', storageApi);
        storageApi[this.getValuesApiName()] = { 
            url: this.compositeConfig.apiUrl,
            ...(this.compositeConfig.storageApi || {})
        }
        
        // console.log('[SchemaStorageCompositeMixin] data(): storage (after init) =', storage);
        // console.log('[SchemaStorageCompositeMixin] data(): storageApi (after init) =', storageApi);

        return {
            // глобальный объект для хранения всех значений
            valuesStorage: storage,

            // "снимки" valuesStorage для отслежтвания изменений
            _pushedValuesStorage: {},

            // таймаут для выявлния изменений в данных
            _changeDebounce: 500,

            // флаг наличия несохранённых изменений
            hasUnsavedValuesChanges: {},

            // настройки api для хранилищ
            valuesStorageApi: storageApi,

            _handleStorageSavePull: true,
        }
    },
    provide() {
        // dependency injection in composite's components
        return {
            hasUnsavedValuesChanges: computed(() => this.hasUnsavedValuesChanges),
            valuesStorage: computed(() => this.valuesStorage),
            getValuesStorage: this.getValuesStorage,
        }
    },
    computed: {
        _pushedValuesStorageName() { return this.getValuesStorageName() },
        valuesStorageName() { return this.getValuesStorageName() },
        valuesApiName() { return this.getValuesStorageApiName() },
    },
    methods: {
        _getPullOId(){ return },
        _getPullArgs(){ return },
        _getPushOId(){ return },
        _getPushArgs(){ return this.getValuesStorage() },
        // async _getInitialValuesOfStorage(schema, def = {}) {
        //     if (this.compositeConfig.values) {
        //         return this.compositeConfig.values;
        //     }

        //     try {
        //         const response = await this.pullSchemaValues();
        //         if (response && response.data)
        //             return response.data
        //     } catch (exception) {
        //         console.warn('[SchemaStorageCompositeMixin] _getInitialValuesOfStorage(): не удалось получить значения для инициализации композита!');
        //         console.debug('[SchemaStorageCompositeMixin] _getInitialValuesOfStorage(): exception', exception);

        //         console.warn('[SchemaStorageCompositeMixin] _getInitialValuesOfStorage(): используем значение по умолчанию:', def);
        //         return def
        //     }
        // },
        _getRootObj(name='__kupRootObj') {
            if (!(name in window)){
                window[name] = {}
            }
            // console.log('_getRootObj(): window[name] keys=', Object.keys(window[name]))
            return window[name]
        },
        _getRootValuesStorage() { return this._getRootObj(APP_STORAGE_NAME) },
        _getRootValuesStorageApi() { return this._getRootObj(APP_STORAGE_API_NAME)},
        getValuesStorageName(schema) { return schema || this.compositeConfig.schema },
        getValuesApiName(schema) { return schema || this.compositeConfig.schema },
        getValuesStorage(schema) { return this.valuesStorage[this.getValuesStorageName(schema)] },
        getValuesStorageApi(schema) { return this.valuesStorageApi[this.getValuesApiName(schema)] },

        getValuesStorageForPull(schema) { return this.getValuesStorage(schema)},
        getValuesStorageForPullSet(schema) { return this.getValuesStorage(schema)},
        getValuesStorageForPush(schema) { return this.getValuesStorage(schema)},

        getValuesStorageApiForPull(schema) { return this.getValuesStorageApi(schema)},
        getValuesStorageApiForPush(schema) { return this.getValuesStorageApi(schema)},

        getValuesStorageNameForPull(schema) { return this.getValuesStorageName(schema)},
        getValuesStorageNameForPush(schema) { return this.getValuesStorageName(schema)},

        _getPushedValuesStorage() { return this._pushedValuesStorage[this._pushedValuesStorageName] },
        _setPushedValuesStorage(values) { this._pushedValuesStorage[this._pushedValuesStorageName] = values },

        _preparePulledData(pulledData, storageObj){ 
            // console.log('[SchemaStorageCompositeMixin] _preparePulledData() pulledData =', pulledData);
            // console.log('[SchemaStorageCompositeMixin] _preparePulledData() storageObj =', storageObj);
            return pulledData 
        },
        onSchemaStoragePulled(storageNew){},
        pullData(){
            const name = this._getPullOId();
            if (!name) return undefined;
            const data = this._getPullArgs() || {};
            return api.apiCall({name, data});
        },
        pushData(){
            const name = this._getPushOId();
            if (!name) return undefined;
            const data = this._getPushArgs() || {};
            return api.apiCall({name, data});
        },
        pullSchemaValues(schema) {
            schema = schema || this.compositeConfig.schema;
            const api = this.getValuesStorageApiForPull(schema);
            const storage = this.getValuesStorageForPull(schema);

            let url = api && api.url;
            if (api.valuesAsGetParams){
                url+='?' + new URLSearchParams(storage).toString();
            }
            // return pullData(
                // url,
            return this.pullData(
            ).then((response) => {
                console.log('[SchemaStorageCompositeMixin] Success result.');
                console.debug('[SchemaStorageCompositeMixin] Response =', response);
                // console.debug('[SchemaStorageCompositeMixin] storage (before assign) =', storage);
                // console.debug('[SchemaStorageCompositeMixin] valuesStorage (before assign) =', this.valuesStorage);
                const storageObj = this.getValuesStorageForPullSet(schema);
                const returnedTarget = Object.assign(
                    this.getValuesStorageForPullSet(schema), 
                    this._preparePulledData(response.data, storageObj)
                );
                // console.debug('[SchemaStorageCompositeMixin] storage (after assign) =', storage);
                // console.debug('[SchemaStorageCompositeMixin] valuesStorage (after assign) =', this.valuesStorage);
                bus.emit(EVENTS.SCHEMA_STORAGE_PULLED, {
                    success: true,
                    schema: this.compositeSchema
                });
                // this._savePushedValues(schema);
                this.onSchemaStoragePulled(this.getValuesStorage(schema));
                return this.valuesStorage
            }).catch((reason) => {
                console.warn('[SchemaStorageCompositeMixin] Failed pushing data on backend.');
                console.debug('[SchemaStorageCompositeMixin] Fail reason:', reason);
                console.debug('[SchemaStorageCompositeMixin] Data to push:', storage);
                bus.emit(EVENTS.SCHEMA_STORAGE_PULLED, {
                    success: false,
                    schema: this.compositeSchema,
                    reason
                });
            });
        },
        pushSchemaValues(extraData={}, hasFiles = false) {
            const schema = extraData.schema || this.compositeConfig.schema;
            const api = this.getValuesStorageApiForPush(schema);
            const storage = this.getValuesStorageForPush(schema);

            const data = {...storage, ...(extraData || {}) }
            console.debug('[SchemaStorageCompositeMixin] Pushing data on backend:', data);
            // pushData(
            //     api && api.url, data, hasFiles
            return this.pushData(
            ).then((response) => {
                console.log('[SchemaStorageCompositeMixin] Success result.');
                console.debug('[SchemaStorageCompositeMixin] Response data =', response.data);
                // console.debug('[SchemaStorageCompositeMixin] extraData =', extraData);
                // console.debug('[SchemaStorageCompositeMixin] storage before assign =', storage);
                // console.debug('[SchemaStorageCompositeMixin] storage jsoned =', JSON.parse(JSON.stringify(storage)));
                
                const toAssign = this._preparePulledData(response.data, storage)
                // console.debug('[SchemaStorageCompositeMixin] toAssign =', toAssign);
                const returnedTarget = Object.assign(
                    storage, toAssign
                );
                // console.debug('[SchemaStorageCompositeMixin] storage after assign =', storage);
                bus.emit(EVENTS.SCHEMA_STORAGE_SAVED, {
                    success: true,
                    schema
                });
                this._savePushedValues(schema);
            }).catch((reason) => {
                console.warn('[SchemaStorageCompositeMixin] Failed pushing data on backend.');
                console.debug('[SchemaStorageCompositeMixin] Fail reason:', reason);
                console.debug('[SchemaStorageCompositeMixin] Data to push:', storage);
                bus.emit(EVENTS.SCHEMA_STORAGE_SAVED, {
                    success: false,
                    schema,
                    reason
                });
            });
        },
        _savePushedValues(schema) {
            this._setPushedValuesStorage(JSON.stringify(this.getValuesStorage(schema)))
                // this._pushedValuesStorage[this._pushedValuesStorageName] = JSON.stringify(this.getValuesStorage());
        },
        isSchemaStorageEqualsSaved() {
            /**
             * Метод проверки на равенство сохранённых значений (_pushedValuesStorage) с текущими (valuesStorage).
             * @param schema {String} схема для сравнения
             * 
             * @returns {Boolean} 
             */
            return this._getPushedValuesStorage() == JSON.stringify(this.getValuesStorage())
        },
        onChangeValuesStorageDebounced() {
            if (!this._getPushedValuesStorage()){
                this._savePushedValues(this.compositeSchema)
                this.hasUnsavedValuesChanges[this.compositeSchema] = false
                return
            }
            this.hasUnsavedValuesChanges[this.compositeSchema] = !this.isSchemaStorageEqualsSaved();
            // console.debug(
            //     '[SchemaStorageCompositeMixin] onChangeValuesStorageDebounced():', 
            //     {"this.hasUnsavedValuesChanges": this.hasUnsavedValuesChanges},
            // );
        },
        onTriggerSchemaStorageSave(triggerData) {
            if (!this._handleStorageSavePull) {
                return
            }
            if (this.compositeSchema != triggerData.schema)
                return;
            this.pushSchemaValues(triggerData.extra, triggerData.hasFiles);
        },
        onTriggerSchemaStoragePull(triggerData) {
            if (!this._handleStorageSavePull) {
                return
            }
            if (this.compositeSchema != triggerData.schema)
                return;
            this.pullSchemaValues(this.compositeSchema);
        },
        _addEventListeners(){
            this.onChangeValuesStorageDebounced = debounce(
                this.onChangeValuesStorageDebounced.bind(this),
                this._changeDebounce
            );
            this._savePushedValues(this.compositeSchema)
            bus.on(EVENTS.TRIGGER_SCHEMA_STORAGE_SAVE, this.onTriggerSchemaStorageSave, this);
            bus.on(EVENTS.TRIGGER_SCHEMA_STORAGE_PULL, this.onTriggerSchemaStoragePull, this);
        },
        _removeEventListeners(){
            bus.off(EVENTS.TRIGGER_SCHEMA_STORAGE_SAVE, this.onTriggerSchemaStorageSave);
            bus.off(EVENTS.TRIGGER_SCHEMA_STORAGE_PULL, this.onTriggerSchemaStoragePull);
        },
    },
    watch: {
        valuesStorage: {
            handler(newValues, oldValues) {
                this.onChangeValuesStorageDebounced(newValues, oldValues);
            },
            deep: true
        }
    },
    mounted() {
        this._addEventListeners()
    },
    unmounted() {
        this._removeEventListeners()
    }
};