<template>
    <button
        @click="onClick"
        :class="[buttonClass, 'flex', 'a-i-center']"
    >
        <img
            :class="[iconClass, 'hide-medium', 'hide-small']"
            :src="$static(iconSrc)"
        />
        <div v-if="isCountVisible" :class="[positionClass, 'hide-medium', 'hide-small']">
            <span :class="counterClass">
                <sup>{{ count }}</sup>
            </span>
        </div>
    </button>
</template>
<script>
import ModalsMixin from '../mixins/ModalsMixin.js'
import { EVENTS } from '../../constants'

// import bus from '../../utils/eventBus'

/**
 * Компонент меню (кнопка с выпадающим меню), обёртывает элемент dropdown от bootstrap
 * 
 * @param {Array} menu список из объектов (object) описывающих свойства элементов меню
 * @param {String} label текст для кнопки меню
 * @example // элемент массива описывающего меню должен иметь следующий вид:
 * const singleMenuItem = {
 *      handler(item, index, $event){
 *          // функция обработки выбора элемента меню, где:
 *          // @arg item {object} выбранный элемент
 *          // @arg index {Number} выбранный элемент
 *          // @arg $event объект события выбора элемента
 *      },
 *      label: 'Menu item label',
 *      href: 'http://example.ex/example', // optional menu property
 * }
 * <menu-button :menu="[singleMenuItem, ...anotherMenuItems]"></menu-button>
 * @class
 */
export default {
    props: {
        iconSrc: { type: String, default: '/img/message-icon.png' },
        iconClass: { type: String, default: 'myProfileHeader-messageIcon' },
        buttonClass: { type: String, default: 'myProfileHeader-messageButton' },
        positionClass: { type: String, default: 'myProfileHeader-messagePosition' },
        counterClass: {type: String, default: 'myProfileHeader-msgCount'},
        count: {type: Number, default: 0},
        bsTarget: {type: String, default: undefined},
    },
    emits: ['activate'],
    mixins: [ModalsMixin],
    methods: {
        onClick(){
            this.showIndicatorModal();
            this.$emit('activate')
        },
        showIndicatorModal(){
            if (this.bsTarget){
                this.showModal()
            }
        },
    },
    computed:{
        isCountVisible(){
            return this.count > 0
        }
    }
};
</script>