import { getStaticUrlTemplate, getMediaUrlTemplate } from '../globals'


const staticUrlTemplate = getStaticUrlTemplate();
const mediaUrlTemplate = getMediaUrlTemplate();

/**
 * Функция для генерации ссылок на статику
*/
export const getStaticUrl = url => staticUrlTemplate.replace('{url}', url)

/**
 * Функция для генерации ссылок на загружаемые/генерируемые файлы
 */
export const getMediaUrl = url => mediaUrlTemplate.replace('{url}', url)