import BaseCompositeComponent from "@/composites/BaseCompositeComponent";
import SchemaStorageCompositeMixin from "@/composites/SchemaStorageCompositeMixin";
// import { removeEmptys } from '@/utils/index'
// import SchemaDescriptorCompositeMixin from './SchemaDescriptorCompositeMixin'
// import { ProfilePermissionsMixin, PROFILE_OWNER_RELATION } from './ProfilePermissionsMixin'

// import SchemaStorageSaveButton from '@/components/buttons/SchemaStorageSaveButton.vue'
// import SchemaStoragePullButton from '@/components/buttons/SchemaStoragePullButton.vue'
// import ProfileRawTextField from '@/components/fields/ProfileRawTextField.vue'
// import ProfilePlainField from '@/components/fields/ProfilePlainField.vue'
// import ProfileSelectField from '@/components/fields/ProfileSelectField.vue'
// import ProfileAutocompleteSelectField from '@/components/fields/ProfileAutocompleteSelectField.vue'
// import ProfileImagesCollectionField from '@/components/fields/ProfileImagesCollectionField.vue'
// import ProfileAgeField from '@/components/fields/ProfileAgeField.vue'
// import ProfileNumberField from '@/components/fields/ProfileNumberField.vue'
// import ProfileTextAreaField from '@/components/fields/ProfileTextAreaField.vue'
// import ProfileRangeField from '@/components/fields/ProfileRangeField.vue'

// import ProfilesList from '@/components/lists/ProfilesList.vue'

// import DialogModal from '@/components/modals/DialogModal.vue'
// import
import { DEFAULT_TOPUP_AMOUNT } from "../constants";
import { topup, topupAndConfirm, confirmTopup } from "../services";

// export { PROFILE_OWNER_RELATION }

/**
 * Компонент (композит из компонетов) представляющий профиль пользователя
 */
export default class extends BaseCompositeComponent {
    constructor(config) {
        super({ ...config, schema: "user_content" });
        this.vueComponent = {
            mixins: [SchemaStorageCompositeMixin],
            components: {
                // Buttons
                // SchemaStorageSaveButton,
                // SchemaStoragePullButton,
                // // Fields
                // ProfileRawTextField,
                // ProfilePlainField,
                // ProfileSelectField,
                // ProfileAutocompleteSelectField,
                // ProfileImagesCollectionField,
                // ProfileAgeField,
                // ProfileNumberField,
                // ProfileTextAreaField,
                // ProfileRangeField,
                // Lists
                // ProfilesList,
                // // Modals
                // DialogModal,
            },
            data() {
                return {
                    topupAmount: DEFAULT_TOPUP_AMOUNT,
                };
            },
            beforeMount() {
                // this.pullSchemaValues(this.compositeSchema)
            },
            methods: {
                onClickTopup() {
                    topupAndConfirm({ amount: this.topupAmount })
                },
                getCoinsCount() {
                    try{
                        console.log(
                            "[TopupModal] getCoinsCount(): this.getValuesStorage() =",
                            this.getValuesStorage()
                        );
                        return this.getValuesStorage().coins;
                    } catch (e) {
                        console.error(
                            "[TopupModal] getCoinsCount(): error =", e
                        );
                        return 0;
                    }
                    
                },
            },
            computed: {
                coins_count() {
                    return this.getValuesStorage().coins;
                },
            },
        };
    }
}
