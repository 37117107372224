import { getStaticUrl, getMediaUrl } from '../utils/files'
import { createApp } from 'vue/dist/vue.esm-bundler';

/**
 * Базовый клас композита (из компонентов).
 * Сюда выненена логика инициализации данными компонентов композита, создания для него 
 * экземпляра vue-компонента, пробрасывания методов и свойств в vue-компонент, 
 * монтирование vue-компонента в DOM-дерево.
 * Композиту соответствует определённая схема и api для взаимодействия для обмена 
 * данными с сервером.
 */
export default class {
    vueComponent = {}
    vueComponentDataBuilders = []

    constructor(config) {
        this.vueComponentDataBuilders.push(this.buildBaseVueComponentData)
        this.config = config;
        this._baseCompositeVueMixin = this.buildBaseCompositeVueMixin(config)
    }
    getVueComponentData(config) {
        return { compositeSchema: config.schema };
    }
    buildBaseCompositeVueMixin(config) {
        return {
            props: { compositeConfig: { type: Object, default: () => config } },
            data: () => this.getVueComponentData(config)
        }
    }
    _getCompositeGlobalProperties() {
        return {
            '$static': getStaticUrl,
            '$media': getMediaUrl,
        }
    }
    createVueComponentAsApp() {
        // пробрасываем базовый для всех композитов миксин
        this.vueComponent.mixins = [...(this.vueComponent.mixins || []), this._baseCompositeVueMixin];
        this._vueComponentApp = createApp(this.vueComponent);
        this._vueComponentApp.config.unwrapInjectedRef = true;
        // пробрасываем методы и свойства в экземпляр vue-компонента композита 
        const globalPropertiesInjectorPlugin = {
            install: () => {
                for (const [name, val] of Object.entries(this._getCompositeGlobalProperties())) {
                    this._vueComponentApp.config.globalProperties[name] = val
                }
            }
        };
        this._vueComponentApp.use(globalPropertiesInjectorPlugin);
        return this._vueComponentApp
    }
    mount(mountPoint) {
        /**
         * Монтируем композит в элемент
         * @param mountPoint {String} - селектор точки для монтирования
         */
        if (this._vueApp) {
            console.warn(`[${this.costructor.name}] ${this.callee.name}(): can't mount! Vue app configured.`);
            return
        }
        const isMountPointNotExists = !document.querySelectorAll(mountPoint).length
        if (isMountPointNotExists) {
            console.error(`[${this.costructor.name}] ${this.callee.name}(): can't mount! Mount point "${mountPoint}" does no exist.`);
            return;
        }
        // создаём vue-компонент, который будет выполнять функцию контейнера для компонентов композита
        this._vueApp = this.createVueComponentAsApp();
        // монтируем vue-компонент
        this.config.mountPoint=mountPoint;
        this._vueApp.mount(mountPoint);
        return this;
    }
}