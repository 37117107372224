import BaseCompositeComponent from './BaseCompositeComponent'
import SchemaStorageCompositeMixin from './SchemaStorageCompositeMixin'
import SchemaDescriptorCompositeMixin from './SchemaDescriptorCompositeMixin'

import ProfileSelectField from '@/components/fields/ProfileSelectField.vue'
import ProfileAutocompleteSelectField from '@/components/fields/ProfileAutocompleteSelectField.vue'
import ProfileNumberField from '@/components/fields/ProfileNumberField.vue'
import ProfileRangeField from '@/components/fields/ProfileRangeField.vue'

import SchemaStoragePullButton from '@/components/buttons/SchemaStoragePullButton.vue'


/**
 * Компонент (композит из компонетов) представляющий окно настроек
 */
export default class extends BaseCompositeComponent {
    constructor(config) {
        const baseSchema = 'profile'
        config = {...config, schema: baseSchema}
        super(config);
        const valuesStorageSchemaName = 'findSettings'
        console.log(`[${this.constructor.name}] constructor(): config=`, config);
        this.vueComponent = {
            mixins: [SchemaStorageCompositeMixin, SchemaDescriptorCompositeMixin],
            components: {
                // Buttons
                SchemaStoragePullButton,

                // Fields
                ProfileSelectField,
                ProfileAutocompleteSelectField,
                ProfileNumberField,
                ProfileRangeField,
            },
            methods: {
                getValuesStorageName: () => valuesStorageSchemaName,
                _addEventListeners(){},
                _removeEventListeners(){},
            },
            data: () => ({
                _handleStorageSavePull: false,
                bindAll: {
                    blank: true,
                    editable: true,
                    schema: baseSchema,
                    labelOfBlankChoice: 'не важно',
                    class: "findSettings-buttonWrapper",
                    dropClass: 'dropright',
                    valueClass: "findSettings-purpleItem text-purple",
                    layout: {
                        buttonWrap: true,
                        iconClass: 'findSettings-icon',
                        buttonWrapClass: 'findSettings-infoItem flex a-i-center',
                        buttonWrapAttrs: {
                            'data-bs-toggle': "dropdown",
                            'aria-expanded': "false",
                        }
                    }
                }
            })
        };
    }
}