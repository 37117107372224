<template>
    <button
        :class="[classBtn, {[classNotAllowed]: !allowed}]"
        type="button"
        :id="'btn-text-extra-'+slug"
        data-bs-toggle="dropdown"
        aria-expanded="false"
    >
        <img :class="classBtnIcon" :src="$static(icon_src)" />
    </button>
    
    <div
        :class="'dropdown-menu ' + 'dialog-dropdown-' + slug"
        :id="'dropdown-' + slug"
        :aria-labelledby="'btn-text-extra-'+slug"
    >
        <div
            :class="
                classExtrasWrapper +
                ' gradientBorder flex flex-d-column a-i-center'
            "
        >
            <div :class="classExtrasClose + ' flex j-c-flex-end'">
                <template v-if="title">
                    <div class="dialog-title flex j-c-center a-i-center">
                    <span class="dialog-titleText text-a-center text-white-w-o-important">
                        {{ title }}
                    </span>
                    <button
                        class="dialog-close"
                        @onclick="window.closeDropdown('#dropdown-' + slug)"
                    >
                        <img
                        class="dialog-closeIcon"
                        :src="$static('/img/closeIcon.png')"
                        />
                        <img
                        class="dialog-closeIconHov"
                        :src="$static('/img/closeIconHover.png')"
                        />
                    </button>
                    </div>
                </template>
                <template v-else>
                    <button
                        class="dialog-close"
                        @onclick="window.closeDropdown('#dropdown-' + slug)"
                    >
                        <img
                            class="dialog-closeIcon"
                            :src="$static('/img/closeIcon.png')"
                        />
                        <img
                            class="dialog-closeIconHov"
                            :src="$static('/img/closeIconHover.png')"
                        />
                    </button>
                </template> 
            </div>
            <div :class="classExtrasItemsWrapper + ' flex j-c-center'">
                <template v-if="isSelectorStyleEmoji">
                    <div
                        :class="classExtrasItem"
                        v-for="text_extra of items"
                        @click="onClickItem({text_extra, $event})"
                    >
                        <span class="dialog-picture">{{ getEmojiSymbol(text_extra.slug) }}</span>
                    </div>
                </template>
                <template v-else>
                    <div
                        :class="classExtrasItem"
                        v-for="text_extra of items"
                        @click="onClickItem({text_extra, $event})"
                    >
                        <img
                            class="dialog-picture"
                            :src="$static('/' + text_extra.src)"
                            :alt="getEmojiSymbol(text_extra.slug)"
                        />
                    </div>
                </template>
            </div>
            <!-- <div class="dialog-goldLineSmiles hide-medium hide-small"></div> -->
        </div>
    </div>
</template>

<script>
// import { EVENTS } from "@/constants";
import { bootstrap, modals, $, initial_content } from "@/globals";
import bus from "@/utils/eventBus";
import {
    EVENTS,
    TextExtraItemTypeEnum,
    MessengerSelectorStyleEnum,
} from "../constants";

import {
    getTextExtraSendText,
} from './../utils';

import {
    EVENTS as ROOT_EVENTS,
} from "@/constants";


export default {
    /**
     * Компонент кнопки для сохранения значений на сервере значений хранилища определённых одной схемой
     */
    props: {
        // bsTarget: { type: String, default: "#messenger-dialog-modal" },
        collection: { type: Object, default: undefined },
        title: { type: String, default: undefined },  
        coll_slug: { type: String, default: "default_emoji" },
        classBtn: { type: String, default: "dialog-addSmile" },
        classNotAllowed: { type: String, default: "not-allowed" },
        classBtnIcon: { type: String, default: "dialog-smile" },
        classExtrasWrapper: { type: String, default: "dialog-smilesWrapper" },
        classExtrasClose: { type: String, default: "dialog-smileClose" },
        classExtrasItemsWrapper: {
            type: String,
            default: "dialog-smilesItemsWrapper",
        },
        classExtrasItem: { type: String, default: "dialog-smilesItem" },

        // label: { type: String, default: "Эмоджи" },
        // allowed: { type: Boolean, default: true },
        // label: { type: String, default: "Эмоджи" },
    },
    data() {
        const collection = this.collection || (initial_content.text_extra_collections.filter(
            (c) => (c.slug == this.coll_slug) && ['EMOJI', 'STICKER'].includes(c.type_code)
        )[0]);
        return {
            // triggerName: EVENTS.TRIGGER_SCHEMA_STORAGE_SAVE,
            // collection,
            items: collection.items,
            slug: this.coll_slug || collection.slug,
            icon_src: "/" + collection.icon_src,
            allowed: collection.allowed,
            label: collection.label,
            // triggerName: "trigger.null",
        };
    },
    methods: {
        onClickItem({text_extra, $event}){
            if (!this.allowed){
                console.error('Sending of this content is not allowed, ssorry.')
                console.error('...but in test mode working...')
                // return
            }
            const text = getTextExtraSendText(text_extra, this.collection)
            if (text_extra.type == TextExtraItemTypeEnum.EMOJI) {
                bus.emit(EVENTS.TRIGGER_INSERT_AT_CURSOR, { text });    
            } else {
                bus.emit(ROOT_EVENTS.TRIGGER_MESSAGE_SEND, { text })
            }
        },
        getEmojiSymbol(slug) {
            const code = ("" + slug).substring(1);
            return String.fromCodePoint(parseInt(code, 16));
        },
        insertSymbolAtCursor(slug) {
            const symbol = this.getEmojiSymbol(slug);
            bus.emit(EVENTS.TRIGGER_INSERT_AT_CURSOR, { text: symbol });
        },
    },
    computed: {
        isSelectorStyleEmoji(){
            // return false
            return this.collection.force_selector_style == TextExtraItemTypeEnum.EMOJI
        }
    },
    watch: {},
};
</script>
