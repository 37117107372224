<template>
    <div ref="dropdown" :class="[editable ? editableClass : '', dropClass]"
         :id="$attrs.id" 
         @click="editorOnClick && toggleEditor($event, true)">
        <button-wrap :disabled="!buttonWrap" :wrap-attrs="buttonWrapAttrs" :class="buttonWrapClass">
            <slot name="value">
                <img v-if="iconSrc" :class="iconClass" :src="iconSrcDisplay">
                <span :class="valueClass">
                    <slot name="valueDisplay" v-if="valueDisplayStr!==undefined">{{ valueDisplayStr }}</slot>   
                    <slot name="placeholder" v-else>{{ placeholder }}</slot>
                </span>
            </slot>
        </button-wrap>
        <slot name="editorWrap" v-if="editable">
            <ul :class="dropdownListClasses">
                <div v-if="hint || hintDescription" class="flex flex-d-column">
                    <span v-if="hint" class="myProfileMain-dropdownTitle line-height-1">{{ hint }}</span>
                    <span v-if="hintDescription" class="myProfileMain-small line-height-1 block">{{ hintDescription }}</span>
                </div>
                <slot name="editor">
                    <p>редактор...</p>
                </slot>
                <li v-if="hasValidationErrors" class="dropdownEl flex flex-d-column a-i-center">
                    <span v-for="e in validationErrors" :key="e.message" class="myProfileMain-small line-height-1 block">{{ e.message }}</span>
                </li>
            </ul>
        </slot>
    </div>
</template>

<script>
export default {
    /**
     * Компонент разметки для полей. Реализует базовое отображение полей
     * в профиле и редактирования их. 
     */
    props: {
        editable: { type: Boolean, default: false },
        editorOnClick: { type: Boolean, default: true },
        iconSrc: { type: String, default: undefined },
        iconClass: { type: String, default: 'myProfileMain-icon' },
        valueClass: { type: String, default: 'myProfileMain-purpleItem' },
        dropdownListClass: { type: String, default: 'myProfileMain-list' },
        dropClass: { type: String, default: 'dropend' },
        focusElementSelector: { type: String, default: 'input' },

        valueDisplayStr: { type: String, default: undefined },
        hint: { type: String, default: undefined },
        hintDescription: { type: String, default: undefined },
        placeholder: { type: String, default: 'не заполнено' },

        validationErrors: { type: Array, default: ()=>[] },
        buttonWrap: { type: Boolean, default: false },
        buttonWrapAttrs: { type: Object, default: ()=>{} },
        buttonWrapClass: { type: String, default: '' },
    },
    components: {
        'button-wrap': {
            inheritAttrs: false,
            props: {
                wrapAttrs: { type: Object, default: {} },
                disabled: { type: Boolean, default: true }
            },
            template: `
            <template v-if="disabled" v-bind="$attrs"><slot /></template> 
            <button v-else v-bind="{...wrapAttrs, ...$attrs}">
                <slot />
            </button>
            `
        }
    },
    emits: [
        'editor-show', // вознакает при показе редактора,
        'editor-hide', // .. при скрытии редактора
    ],
    data() {
        return {
            bsDropdown: undefined,
            editorClass: 'editor',
            editModeOn: false,
            editableClass: 'editable',
            showClass: 'show',
            hidePromiseResolve: undefined,

            /**
             * TODO: уйти от использования флага таймаута
             * Флаг таймаута - используется, чтоб редактор не закрывался сразу,
             * т.к. _onDocumentClick() вызывыется сразу же после включения прослушки
             * события document.addEventListener('click', ...), что согласно логики _onDocumentClick()
             * приприводит к вызову hideEditor() и редактор вовсе не открывается.
             * */
            editorHideTimeout: false, // флаг таймаута
        }
    },
    methods: {
        getDropdownListClasses(dropdownListClass) {
            return [
                'dropdown-menu',
                'dropdownPurple',
                dropdownListClass,
                'text-a-center',
                this.editorClass,
                (this.editModeOn ? this.showClass : '')
            ]
        },
        toggleEditor(e, hideIfOnlyInEditor = true) {
            if (this.editModeOn) {
                if (hideIfOnlyInEditor) {
                    if (!this._isEventTargetInEditor(e)) {
                        this.hideEditor();
                    }
                } else {
                    this.hideEditor();
                }
            } else {
                this.showEditor();
            }
        },
        showEditor() {
            if (!this.editable) return;
            this._startEditorListening();
            this.editModeOn = true;

            // Установка флага таймаута (о флаге таймаута см. выше)
            setTimeout((function() {
                this.onShownEditor();
                this.editorHideTimeout = true;
            }).bind(this), 100);
            this.editorHideTimeout = false;
            return new Promise((resolve => this.hidePromiseResolve = resolve));
        },
        onShownEditor() {
            const focusTarget = this.getFocusElement();
            if (focusTarget) focusTarget.focus();
            this.$emit('editor-show', { editor: this });
        },
        getFocusElement() {
            return this.$el.querySelector(this.focusElementSelector);
        },
        hideEditor() {
            if (!this.editable) return;
            this._stopEditorListening();
            this.editModeOn = false;
            this.$emit('editor-hide', { editor: this });
            if (this.hidePromiseResolve) {
                this.hidePromiseResolve(this);
                this.hidePromiseResolve = undefined;
            }
        },
        _startEditorListening() {
            document.addEventListener('click', this._onDocumentClick);
            document.addEventListener('keydown', this._onDocumentKeyDown);
        },
        _stopEditorListening() {
            document.removeEventListener('click', this._onDocumentClick);
            document.removeEventListener('keydown', this._onDocumentKeyDown);
        },
        _isEventTargetInEditor(e) {
            return this._isChild(e.target, this.$el.querySelector(`.${this.editorClass}`));
        },
        _onDocumentClick(e) {
            if (this.editorHideTimeout && !this._isEventTargetInEditor(e)) {
                this.hideEditor();
            }
        },
        _onDocumentKeyDown(e) {
            // TODO: исправить закрытие редактора при нажатии клавиши Esc
            if (this.editorHideTimeout && (e.key == 'Escape' || e.keyCode === 27)) {
                this.hideEditor();
            }
        },
        _isChild(node, parent) {
            /**
             * Определяет, является ли node наследником parent в дереве DOM
             */
            let currentNode = node;
            const goalParent = parent || this.$el;
            while (currentNode) {
                if (goalParent == currentNode)
                    return true;
                currentNode = currentNode.parentNode;
            }
            return false;
        },
    },
    computed: {
        dropdownListClasses() {
            return this.getDropdownListClasses(this.dropdownListClass)
        },
        iconSrcDisplay() {
            if (this.iconSrc)
                return this.$static(this.iconSrc);
            return undefined
        },
        hasValidationErrors() {
            return !!this.validationErrors.length;
        },
    },
    // TODO: make gold borders for values on mobile device
};
</script>