import BaseCompositeComponent from "./BaseCompositeComponent";
import SchemaStorageCompositeMixin from "./SchemaStorageCompositeMixin";
import { EVENTS } from "@/constants";

import bus from "@/utils/eventBus";

import * as service from "@/services/MessengerService";
// import MessengerMixin from "../components/mixins/MessengerMixin";

import ProfileInboxPopup from "@/components/lists/ProfileInboxPopup.vue";

/**
 * Компонент (композит из компонетов) представляющий профиль пользователя
 */
export default class extends BaseCompositeComponent {
    constructor(config) {
        super({ ...config, schema: "messenger" });
        this.vueComponent = {
            mixins: [SchemaStorageCompositeMixin],
            data() {
                return {
                    dialogsDisplay:service.dialogsDisplay,
                    // listDisplay: [],
                    noAvatarSrc: "/img/avatarIcon.png",
                    // testParam: 'testParam',
                    // renderComponent: true,
                };
            },
            components: {ProfileInboxPopup},
            mounted() {},
            created() {},
            unmounted() {},
            methods: {
                onDialogsDisplayUpdated({ dialogsDisplay } = {}) {
                    // const storage = this.$parent.getValuesStorage('messenger');
                    // this.listDisplay = dialogsDisplay;
                    this.listDisplay.length = 0;
                    dialogsDisplay = dialogsDisplay || this.getValuesStorage().dialogsDisplay
                    this.listDisplay.push(...dialogsDisplay)
                    this.$forceUpdate();
                },
                showDialog({ user_id, id } = {}) {
                    console.log("[ProfileInbox] showDialog(): {user_id, id}=", {
                        user_id,
                        id,
                    });

                    bus.emit(EVENTS.TRIGGER_PROFILE_OPEN_DIALOG, {
                        user_id,
                        id,
                    });
                    window.closeModal("#messenger-dialogs-list-modal");
                },
            },
            computed: {},
        };
    }
}
