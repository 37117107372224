import { jQuery as $ } from "../globals";

class Emitter {
    emit(event, args) {
        $(this).trigger(event, args);
    }
    on(event, fn, thisBind, bindArgs = []) {
        fn.__busAdapter = fn.__busAdapter || function(...args) {
            return fn(...args.slice(1));
        };
        if (thisBind) {
            fn.__busAdapter = fn.__busAdapter.bind(thisBind, ...bindArgs);
        }
        $(this).bind(event, fn.__busAdapter);
    }
    off(event, fn) {
        if (fn.__busAdapter)
            $(this).unbind(event, fn.__busAdapter);
    }
}

const bus = new Emitter();
window.kup.bus = bus;

export default bus;