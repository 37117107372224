import { API_URL } from "@/globals";
import { getKey } from '@/utils/object'
// import { EVENTS } from '@/constants'
// import bus from '@/utils/eventBus'
// // import { debounce } from '@/utils/index';
// import { pushData, pullData } from '@/utils/synchronizer'
// // import { computed } from '@vue/reactivity';

/**
 * Сервис описывающий бизнес-логику связанную с изображениями(фото) пользователей.
 * @module
 */

import {
    WsDataTypeEnum,
    NotificationUserIdTypeEnum,
    WebSocketConnectorStateEnum,
    WS_URL,
    RETRY_COUNT,
    TRIAL_CONNECTION_TIMEOUT_MS,
    CONNECTING_TIMEOUT_MS,
} from "./constants";


/**
 * inits a websocket by a given url, returned promise resolves with initialized websocket, rejects after failure/timeout.
 *
 * @param url the websocket url to init
 * @param existingWebsocket if passed and this passed websocket is already open, this existingWebsocket is resolved, no additional websocket is opened
 * @param timeoutMs the timeout in milliseconds for opening the websocket
 * @param retryCount the number of times initializing the socket should be retried, if not specified or 0, no retries are made
 *        and a failure/timeout causes rejection of the returned promise
 * @return {Promise}
 */
function initWebsocket(
    url,
    existingWebsocket,
    timeoutMs = CONNECTING_TIMEOUT_MS,
    retryCount = RETRY_COUNT,
    trialConnectionTimeout = TRIAL_CONNECTION_TIMEOUT_MS,
) {
    console.info(
        "[api/websocket] initWebsocket(): starting initialization... " +url
    );
    let hasReturned = false;
    let rejectArgs;
    let promise = new Promise((resolve, reject) => {
        let websocket = undefined;
        setTimeout(function () {
            // console.info("[api/websocket] initWebsocket() initialization timeout");
            if (!hasReturned) {
                console.info(
                    "[api/websocket] initWebsocket() opening websocket timed out: " +
                        url
                );
                if (!websocket) {
                    console.debug("[api/websocket] initWebsocket() : websocket is none");    
                } else {
                    const stateStr = {
                        [WebSocket.CLOSED]: "CLOSED",
                        [WebSocket.CLOSING]: "CLOSING",
                        [WebSocket.CONNECTING]: "CONNECTING",
                        [WebSocket.OPEN]: "OPEN",
                    }[websocket.readyState]
                    console.debug(`[api/websocket] initWebsocket() : websocket.readyState= ${websocket.readyState} [${stateStr}]`);
                    console.debug("[api/websocket] initWebsocket() : ", {
                        existingWebsocket,
                        timeoutMs,
                        retryCount,
                        trialConnectionTimeout,
                    });
                    console.debug("[api/websocket] initWebsocket() : websocket=", websocket);
                }
                rejectInternal();
            }
        }, timeoutMs);
        if (
            !existingWebsocket ||
            existingWebsocket.readyState != existingWebsocket.OPEN
        ) {
            if (existingWebsocket) {
                existingWebsocket.close();
            }
            websocket = new WebSocket(url);
            console.debug("[api/websocket] initWebsocket(): websocket created!");
            websocket.onopen = function (args) {
                if (hasReturned) {
                    websocket.close();
                } else {
                    console.info(
                        "[api/websocket] initWebsocket() websocket to opened! url: " +
                            url,
                        "args=",
                        args
                    );
                    resolve(websocket);
                }
            };
            websocket.onclose = function (args) {
                console.info(
                    "[api/websocket] initWebsocket() websocket closed! url: " +
                        url,
                    "args=",
                    args
                );
                rejectArgs = args;
                rejectInternal();
            };
            websocket.onerror = function (args) {
                console.info(
                    "[api/websocket] initWebsocket() websocket error! url: " +
                        url,
                    "args=",
                    args
                );
                rejectArgs = args;
                rejectInternal();
            };
            // websocket.onmessage = function (args) {
            //     console.info(
            //         "[api/websocket] initWebsocket() message! url: " + url,
            //         "args=",
            //         args
            //     );
            //     // rejectArgs = args
            //     // rejectInternal();
            // };
            existingWebsocket = websocket
        } else {
            resolve(existingWebsocket);
        }

        function rejectInternal() {
            if (retryCount <= 0 && !trialConnectionTimeout) {
                reject(rejectArgs);
            } else if (!hasReturned) {
                hasReturned = true;
                if (retryCount < 0 && !!trialConnectionTimeout){
                    timeoutMs = trialConnectionTimeout
                    console.info(
                        "[api/websocket] initWebsocket() retrying connect to websocket trying in trial mode! url: " +
                            url +
                            ", trial: " +
                            (-retryCount)
                    );
                }else {
                    console.info(
                        "[api/websocket] initWebsocket() retrying connection to websocket! url: " +
                            url +
                            ", remaining retries: " +
                            (retryCount - 1)
                    );
                }
                initWebsocket(url, null, timeoutMs, retryCount - 1).then(
                    resolve,
                    reject
                );
            }
        }
    });
    promise.then(
        function (ws) {
            hasReturned = true;
            return ws
        },
        function (args) {
            hasReturned = true;
            throw args
        }
    );
    return promise;
}

export class WebSocketConnector {
    ws = undefined;
    _wsPromise = undefined;
    _state = WebSocketConnectorStateEnum.DISCONNECTED;
    eventListeners = []

    constructor() {
        this._onWsMessageBind = this.onWsMessage.bind(this);
    }
    isWsOpen() {
        return !!this.ws && this.ws.readyState == WebSocket.OPEN;
    }
    getWsUrl({
        host = API_URL.hostname,
        api_url = WS_URL,
        protocol = undefined,
    } = {}) {
        if (!!API_URL.port && API_URL.port != "") {
            host += ":" + API_URL.port;
        }
        if (protocol === undefined && API_URL.protocol.includes("https")) {
            protocol = "wss";
        }
        if (protocol === undefined) {
            protocol = "ws";
        }
        return `${protocol}://${host}${api_url}`;
    }
    setup() {
        console.info("[api/websocket/WebSocketConnector] setup(): requested websocket setup.");
        console.debug(
            "[api/websocket/WebSocketConnector] setup(): initial _state=", getKey(WebSocketConnectorStateEnum, this._state)
        );
        if (!!this._wsPromise && this._state != WebSocketConnectorStateEnum.RECONNECTING){
            console.debug(
                "[api/websocket/WebSocketConnector] setup(): setup already started. Waiting setup..."
            );
            return this._wsPromise
        }
        if (!this._onWsMessageBind) {
            throw TypeError("this._onIncomingMessageBind must be set");
        }
        
        if ([WebSocketConnectorStateEnum.DISCONNECTED, WebSocketConnectorStateEnum.ERROR].includes(this._state)){
            this._state = WebSocketConnectorStateEnum.CONNECTING;
            console.debug(
                "[api/websocket/WebSocketConnector] setup(): _state=", getKey(WebSocketConnectorStateEnum, this._state)
            );
        }
        this._wsPromise = initWebsocket(this.getWsUrl(), this.ws)
            .then((ws) => {
                this.ws = ws;
                this._reconnectEventListeners();
                this.ws.addEventListener("message", this._onWsMessageBind);
                this.ws.addEventListener("close", (event) => {
                    console.warn(
                        "[api/websocket/WebSocketConnector] setup() then: connection closed.",
                        event
                    );

                    if (this._state == WebSocketConnectorStateEnum.CONNECTED){
                        this._state = WebSocketConnectorStateEnum.RECONNECTING;
                        console.debug(
                            "[api/websocket/WebSocketConnector] setup(): _state=", getKey(WebSocketConnectorStateEnum, this._state)
                        );
                        this.setup()
                    }

                    // if ([WebSocketConnectorStateEnum.CONNECTED].includes(this._state)){
                    //     this._state = WebSocketConnectorStateEnum.RECONNECTING;
                    //     setTimeout(() => this.setup(), RETRY_PERIOD_MS*10);
                    // }
                });
                // console.debug(
                //     "[api/websocket/WebSocketConnector] setup() then: ws= ",
                //     this.ws
                // );
                this._state = WebSocketConnectorStateEnum.CONNECTED;
                console.debug(
                    "[api/websocket/WebSocketConnector] setup(): _state=", getKey(WebSocketConnectorStateEnum, this._state)
                );
                return ws;
            })
            .catch((cause) => {
                console.debug(
                    "[api/websocket/WebSocketConnector] setup() catch: cause= ",
                    cause
                );
                // setTimeout(() => this.setup(), RETRY_PERIOD_MS*10);
                // this._state = WebSocketConnectorStateEnum.RECONNECTING;
                // setTimeout(() => this.setup(), RETRY_PERIOD_MS);
                this._state = WebSocketConnectorStateEnum.ERROR;
                console.debug(
                    "[api/websocket/WebSocketConnector] setup(): _state=", getKey(WebSocketConnectorStateEnum, this._state)
                );
                throw cause;
            });
        return this._wsPromise;
    }
    getConnection(setupIfRequired=false) {
        if (setupIfRequired || !this._wsPromise) {
            return this.setup();
        }
        // if (this._state != WebSocketConnectorStateEnum.DISCONNECTED){
        //     return this._wsPromise 
        // }
        return this._wsPromise;
    }
    isWsOpen() {
        return (
            this.ws &&
            (this.ws.readyState == WebSocket.OPEN ||
                this.ws.readyState == WebSocket.CONNECTING)
        );
    }
    onWsMessage(data) {
        console.log(
            "[api/websockets/WebSocketConnector] onWsMessage(): data=",
            data
        );
    }
    send(data) {
        return this.getConnection().then((ws) => {
            ws.send(data);
            return ws;
        });
    }
    sendJSON(data) {
        data = JSON.stringify(data);
        return this.send(data);
    }
    addEventListener(name, func){
        this.eventListeners.push({name, func})
        this.getConnection().then((ws)=> ws.addEventListener(name, func))
    }
    _reconnectEventListeners(){
        this.getConnection().then((ws)=> {
            for(const el of this.eventListeners){
                ws.addEventListener(el.name, el.func)
            }
        })
    }
}

export const connector = new WebSocketConnector();

export function getConnector(){
    return connector;
}

