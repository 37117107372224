export const WS_URL = "/api/v1/user/messenger/ws";
export const RETRY_COUNT = 3;
export const TRIAL_CONNECTION_TIMEOUT_MS = 15 * 60 * 1000;
export const CONNECTING_TIMEOUT_MS = 1000 * 5;


export const WsDataTypeEnum = {
    USER_CONNECTED: "USER_CONNECTED",
    USER_DISCONNECTED: "USER_DISCONNECTED",
    MESSAGE_SENT: "MESSAGE_SENT",
    USER_TYPING: "USER_TYPING",
    USER_STOPPED_TYPING: "USER_STOPPED_TYPING",
    READ: "READ",
    HISTORY: "HISTORY",
    ERROR: "ERROR",
    DIALOG_CREATED: "DIALOG_CREATED",
    CLIENT_STATUS: "CLIENT_STATUS",
    EVENT: "EVENT",
};

export const NotificationUserIdTypeEnum = {
    SESSION_ID: 10,
    USER_ID: 20,
    DIALOG_ID: 30,
};

export const MessageStatus = {
    ON_SERVER: 0,
    ON_RECIPIENT: 10,
    READ: 20,
};

export const WebSocketConnectorStateEnum = {
    DISCONNECTED: 10,
    CONNECTING: 20,
    CONNECTED: 30,
    RECONNECTING: 40,
    DISCONNECTING: 50,
    ERROR: 100,
};

