import BaseCompositeComponent from "@/composites/BaseCompositeComponent";
import SchemaStorageCompositeMixin from "@/composites/SchemaStorageCompositeMixin";

import { CONTENT_SLUG } from "../constants";
import { order } from "@/content/services";
import { userContent } from "@/content/state";

/**
 * Компонент (композит из компонетов) представляющий профиль пользователя
 */
export default class extends BaseCompositeComponent {
    constructor(config) {
        super({ ...config, schema: "user_content" });
        this.vueComponent = {
            mixins: [SchemaStorageCompositeMixin],
            // data() {
            //     return {
            //         topupAmount: DEFAULT_TOPUP_AMOUNT,
            //     };
            // },
            beforeMount() {
                // this.pullSchemaValues(this.compositeSchema)
            },
            mounted() {
                const nextArrow = "<div class='activateVip-nextArrowIcon'></div>"
                const prevArrow = "<div class='activateVip-prevArrowIcon'></div>"

                let $avoc = $(".activateVip-carousel")
                // $avoc.owlCarousel('destroy');
                // $avoc = $(".activateVip-carousel")
                $avoc.owlCarousel({
                    nav: true,
                    navigation : true,
                    navContainerClass: "activateVip-nav",
                    navClass: ["activateVip-prev","activateVip-next"],
                    navText: [prevArrow, nextArrow],
                    items: 1,
                    responsive: {
                        0: {
                            items: 1,
                            navigation : true,
                        },
                        769: {
                            items: 3,
                            navigation : false,
                        }
                    },
                    loop: true,
                    margin: 20,
                    autoplay: true,
                });
                $avoc.removeClass('d-none');
                console.log('[ActivateVipModal] mounted(): ', "slider init...")
            },
            methods: {
                onClickOrder() {
                    order({ 
                        content_slug: CONTENT_SLUG ,
                    }).then((result)=>{
                        console.log('[ActivateVipModal] onClickOrder() then: result=', result)
                        this.getValuesStorage().is_vip=true;
                        window.closeModal(this.compositeConfig.mountPoint);
                    }).catch((cause)=>{
                        window.closeModal(this.compositeConfig.mountPoint);
                    })
                },
            },
            computed: {
                isVip(){ return this.getValuesStorage().is_vip; }
            },
        };
    }
}
