import MenuButton from '@/components/buttons/MenuButton.vue'
import LikeButton from '@/components/buttons/LikeButton.vue'
import { PhotoSwipe, PhotoSwipeUI_Default } from '../../globals'

/**
 * Vue-миксин для добавления функционала галлереи к компоненту
 * 
 * @vue-prop galleryPatchScalingThreshold {} порог срабатывания компенсации масштабирования слайда галереи
 * @vue-prop gallerySelector {} селектор шаблона галереи
 * @vue-prop galletyMenuTeleportSelector {} селектор контейнера меню галереи
 * @vue-prop {object} pswpOptions Опции для PhotoSwipe.js
 * @mixin 
 */
export default {
    props: {
        pswpOptions: {
            type: Object,
            default: {
                bgOpacity: 0.85,
                showHideOpacity: true,
            }
        },
        galleryPatchScalingThreshold: { type: Number, default: 0.98 },
        gallerySelector: { type: String, default: '.pswp' },
        galleryMenuTeleportSelector: { type: String, default: '.pswp .pswp__menu' },
    },
    data() {
        return {
            galleryInstance: undefined, // текущий экземпляр гелереи
            galleryInstanceIndex: undefined, // updating from gallery's "change" callback
            imageFieldRefPrefix: 'imageField',
            _templateMixin: undefined, // экземпляр компонента 'gallery-mixin'
        }
    },
    methods: {
        /**
         * Функция (обработчик события), которая устанавливает размер слайда по размеру изображения на слайде 
         * @param {PhotoSwipe} pswpGallery экземпляр галереи
         * @param {Number} index index
         * @param {Object} item gallery item object
         */
        __pswpSetSlidesSizesFromImages(pswpGallery, index, item) {
            if (item.w < 1 || item.h < 1) { // unknown size
                var img = new Image();
                img.onload = function() { // will get size after load
                    item.w = this.width; // set image width
                    item.h = this.height; // set image height
                    pswpGallery.invalidateCurrItems(); // reinit Items
                    pswpGallery.updateSize(true); // reinit Items
                }
                img.src = item.src; // let's download image
            }
        },
        __pswpInitGallery({ $pswpElement, items, options, pswpUiConfig, index = 0 }) {
            const gallery = new PhotoSwipe($pswpElement, pswpUiConfig, items, options);
            gallery.listen('gettingData', this.__pswpSetSlidesSizesFromImages.bind(this, gallery));
            gallery.init();
            return gallery;
        },
        __pswpGetGalleryDefaultParams({ $pswpElement, items, options, pswpUiConfig } = {}) {
            return {
                $pswpElement: $pswpElement || this._$pswpElement,
                items: items || this._pswpItems,
                options: options || this.pswpOptions,
                pswpUiConfig: pswpUiConfig || PhotoSwipeUI_Default,
            }
        },
        getImageGalleryIndex(imageData, galleryImages) {
            const isImgSet = this.isImageSet(imageData)
            if (isImgSet) {
                const index = galleryImages.indexOf(imageData)
                return index === -1 ? undefined : index
            }
            return undefined;
        },
        /**
         * Вызов галереи
         * @param {Number} index порядковый номер изображения с которого надо начать показ галереи
         */
        galleryShow(index = 0) {
            const params = this.__pswpGetGalleryDefaultParams();
            params.options.index = index;
            this.galleryInstance = this.__pswpInitGallery(params);
            this.galleryInstanceIndex = index;
            this.$nextTick(() => this._galleryPatchCurrentSlide());
            this.galleryInstance.listen('destroy', () => {
                this.galleryInstance = undefined;
                this.galleryInstanceIndex = undefined;
            });
            this.galleryInstance.listen('afterChange', () => {
                this.galleryInstanceIndex = this.galleryInstance.getCurrentIndex();
                const elId = this.galleryGetImageContainerId(this.galleryInstanceIndex);
                if (this.galleryInstance.currItem.container.getAttribute('id') != elId) {
                    this.galleryInstance.currItem.container.setAttribute('id', elId);
                }
                this._galleryPatchCurrentSlide();
            });
        },
        galleryClose() {
            if (this.galleryInstance) this.galleryInstance.close();
        },
        galleryGetImageContainerId(imageIndex) {
            return `gallery-img-container-${imageIndex}`;
        },
        _galleryPopContainerPatch($container, $patchSrc) {
            const patches = Array.from($container.children).filter(c => c.dataset.patchName == $patchSrc.dataset.patchName);
            for (const cp of patches) cp.remove();
            const patch = $patchSrc.cloneNode(true);
            patch.dataset.patchName = $patchSrc.dataset.patchName;
            return patch;
        },
        /**
         * Патчер слайда галереи добавляющий лайк к слайду.
         * 
         * @todo уйти от клонирования компонента, сделав рендер
         * @param {*} $container целевой контейнер, куда будет помещён патч
         * @param {*} $patchSrc источник патча
         * @param {*} onClickHandler обработчик клика 
         *
         * @see {@link 'gallery-mixin'} миксин, источник контента для патчей галереи
         */
        _galleryUpdateContainerLikePatch($container, $patchSrc, onClickHandler) {
            const patch = this._galleryPopContainerPatch($container, $patchSrc);
            const pswpImg = $container.querySelector('img.pswp__img');
            /** 
             * порог масштабирования изображения, с которого начинается компенсирование 
             * @var patchScalingThreshold
             */
            const patchBtn = patch.querySelector('button');
            if (!patchBtn) {
                /**
                 * Если кнопка не найдена в патче, значит по каким-либо причинам она не отрисовалась 
                 * (в т.ч. при отсутствии необходимости в её отрисовке) и патчить нечем, поэтому 
                 * завершаем работу метода.
                 * @see {@link 'gallery-mixin'}
                 * @see 'gallery-mixin'
                 */
                return;
            }
            if (pswpImg) {
                /**
                 * Выполняем компенсацию трансформаций выполняемых галереей для слайдов, т.к. патч
                 * тоже попадает под них.
                 */
                patch.style.width = pswpImg.style.width;
                patch.style.height = pswpImg.style.height;
                let scaleIn = ('' + $container.style.transform).match(/scale\(([\d.,]+)\)/ig);
                let transform = '',
                    scaleInFloat;
                if (scaleIn) {
                    scaleInFloat = parseFloat(scaleIn[0].slice(6, -1));
                    if (scaleInFloat < this.galleryPatchScalingThreshold)
                        transform += `scale(${ 1 / scaleInFloat })`
                }
                const styleTarget = patch.querySelector('button');
                if (transform != '' && styleTarget) {
                    styleTarget.style.webkitTransform = transform;
                    styleTarget.style.MozTransform = transform;
                    styleTarget.style.msTransform = transform;
                    styleTarget.style.OTransform = transform;
                    styleTarget.style.transform = transform;
                }
            }
            patchBtn.onclick = onClickHandler;
            $container.prepend(patch);
        },
        /**
         * Обработчик клика лайка в галерее
         * @method _galleryOnClickImageLike
         */
        _galleryOnClickImageLike() {
            this._galleryGetDomProvider().onClickLike();
            this.$nextTick(() => this._galleryPatchCurrentSlide());
        },
        _galleryGetDomProvider() {
            return this._templateMixin;
        },
        /**
         * Обработчик события добавления компонента {@link 'gallery-mixin'} в шаблон
         * @method galleryOnMixinAdded
         */
        galleryOnMixinAdded(mixin) {
            this._templateMixin = mixin;
            mixin._galleryMixinBase = this;
        },
        /**
         * Метод добавления новых элементов в галерею PhotoSwipe
         * @method _galleryPatchCurrentSlide
         */
        _galleryPatchCurrentSlide() {
            const patchSrc = this._galleryGetDomProvider().getGalleryImageLikeDom();
            if (patchSrc) {
                if (!this._galleryOnClickImageLike._bindthis)
                    this._galleryOnClickImageLike._bindthis = this._galleryOnClickImageLike.bind(this);
                this.$nextTick(
                    () => this._galleryUpdateContainerLikePatch(
                        this.galleryInstance.currItem.container,
                        patchSrc,
                        this._galleryOnClickImageLike._bindthis
                    )
                );
            } else {
                console.error('[ProfileImagesCollectionComponentGalleryMixin] _galleryPatchCurrentSlide(): patchDom is none!');
            }
        },
        galleryGetImageMenu(imageData, imageCollection) {
            // переопределив эту функцию можно управлять генерацией меню в галерее для изображения
            return undefined;
        },
        _galleryIsInstanceSet() {
            return this.galleryInstanceIndex !== undefined && this.galleryInstance !== undefined
        }
    },
    computed: {
        galleryCurrentImageMenu() {
            if (!this._galleryIsInstanceSet()) return undefined;
            return this.galleryGetImageMenu({imageData: this.value[this.galleryInstanceIndex], imagesCollection: this.value});
        },
        galleryCurrentImageValue() {
            if (!this._galleryIsInstanceSet()) return undefined;
            return this.galleryInstance.currItem.$value;
        },
        galleryCurrentItemContainerSelector() {
            if (!this._galleryIsInstanceSet()) return undefined;
            const elId = this.galleryGetImageContainerId(this.galleryInstanceIndex);
            const selector = '#' + elId;
            return selector;
        },
        /**
         * DOM-элемент галереи
         * @prop {} _$pswpElement
         */
        _$pswpElement() { return document.querySelector(this.gallerySelector) },

        /**
         * Массив слайдов галлереи
         * @prop {Array} _pswpItems
         */
        _pswpItems() {
            // TODO: сделать сортировку изображений в галерее согласно разметки на странице (аватар, превью, остальные)
            const prepareSrc = (src) => {
                if (src instanceof File) {
                    return URL.createObjectURL(src);
                } else if (src.match(/https?:\/\/(www\.)?/gi)) {
                    return src
                } else {
                    return this.$media(src)
                }
            }
            return this.value.map(p => ({ $value: p, src: prepareSrc(p.srcFull), w: 0, h: 0 }));
        },
    },
    components: {
        /**
         * Компонент-vue (провайдер) предоставляющий миксину DOM контент для патчей галереи.
         * 
         * Для работы его необходимо размещать в шаблоне модифицируемого компонента.
         * 
         * Примечание: использование разных методик патча обусловлено отличием между целевыми 
         * контейнерами, а именно - динамичностью. Vue 3 teleport не работает с динамически 
         * созданнымми контейнерами DOM, поэтому в случае с добавлением лайка пришлось применить 
         * клонирование элемента DOM. 
         * 
         * @vue-data {*} _galleryMixinBase экземпляр базового миксина
         * @mixin 'gallery-mixin'
         */
        'gallery-mixin': {
            emits: ['added', 'config'],
            created() { this.$emit('added', this); },
            data() {
                return {
                    likePatchId: 'profile-image-collection-gallery-mixin',
                    _galleryMixinBase: undefined
                }
            },
            computed: {
                gallery() { return this._galleryMixinBase && this._galleryMixinBase.galleryInstance; },
                imageData() { return this._galleryMixinBase && this._galleryMixinBase.galleryCurrentImageValue; },
                imageMenu() { return this._galleryMixinBase && this._galleryMixinBase.galleryCurrentImageMenu; },
                menuToSelector() { return this._galleryMixinBase && this._galleryMixinBase.galleryMenuTeleportSelector; },
                canLike() { return this._galleryMixinBase && this._galleryMixinBase.canLike; },
                canEditPhotos() { return this._galleryMixinBase && this._galleryMixinBase.editable; },
            },
            methods: {
                getGalleryImageLikeDom() { return this.$refs.likePatch },
                onClickLike() { return this.$refs.like.onClickLike() },
            },
            components: { MenuButton, LikeButton },
            template: `
            <div style="display: none">
                <!-- патч добавляющий меню фото -->
                <teleport :to="menuToSelector"> 
                    <menu-button 
                        :menu="imageMenu || []"
                        v-if="canEditPhotos"
                        class="no-background pswp__button"
                        toggle-class="no-background pswp__button"
                        icon-class="no-background pswp__button">
                    </menu-button>
                </teleport>
            
                <!-- патч слайда добавляющий лайк -->
                <div    :id="likePatchId"
                        ref="likePatch"
                        data-patch-name="galleryLike"                      
                        class="pswp__img myProfileMain-galleryImageLikeWrapper">
                    <like-button 
                        ref="like"
                        :can-like="canLike" 
                        :value="imageData || {}"
                        class="strangersProfileMain-likeWrapper--gallery">
                    </like-button> 
                </div>
            </div>
            `
        },
    },
};