<template>
<button 
    v-for="message in inboxDialogsDisplay"
    :key="message?.data?.message_id"
    @click="showDialog({ id: message.data.dialog_id })"
    class="inboxMessages-inboxMsgItem flex flex-d-column"
>
    <div class="flex">
    <img class="inboxMessages-senderAvatar" :src="msgOpponent(message).userImageSrc">
    <div class="inboxMessages-wrapperInfo flex flex-d-column">
        <span v-if="msgOpponent(message).age != '*'" 
            class="inboxMessages-senderAge text-white-w-o-important text-bold-600 line-height-1 text-a-start">
            {{ msgOpponent(message).age }}
            <span class="inboxMessages-senderAgeText line-height-1">лет</span>
        </span>
        <span v-if="msgOpponent(message).locality_label != '' " 
            class="inboxMessages-senderCity text-white-w-o-important line-height-1 text-a-start">
            {{ msgOpponent(message).locality_label }}
        </span>
    </div>
    </div>
    <div class="inboxMessages-senderInfo flex flex-d-column">
    <span
        v-for="nItem in msgOpponent(message).nameItems"
        class="inboxMessages-senderName text-white-w-o-important line-height-1 text-a-start"
    >
        {{ nItem }}
    </span>
    <!-- <span class="inboxMessages-senderName text-white-w-o-important line-height-1 text-a-start">Гурбангулы</span>
    <span class="inboxMessages-senderSecondName text-white-w-o-important line-height-1 text-a-start">Мяликкулиевич</span>
    <span class="inboxMessages-senderSurname text-white-w-o-important line-height-1 text-a-start">Бердымухаммедов</span>
    -->
    </div> 
</button>
</template>

<script>
import { watch } from "vue";
import bus from "@/utils/eventBus";
import {
    EVENTS,
    MESSENGER_NO_AVATAR_SRC,
    MESSENGER_NO_NAME_PLACEHOLDER,
} from "@/constants";
import {
    WsDataTypeEnum,
    MessageStatus,
    NotificationUserIdTypeEnum,
    dialogsDisplay,
    getOpponentId,
} from "@/services/MessengerService";
import {
    includesId,
    indexId,
    equalId,
    makeUserClientId,
    makeDialogClientId,
} from "@/utils/messenger";
import { trimSpaces } from "@/utils/index";
import { msgOpponent } from "@/services/messenger/utils"
import MessengerMixin from "@/components/mixins/MessengerMixin";


watch(dialogsDisplay, (dd, ddOld)=>{
    console.log("[ProfileInboxPopup] dialogsDisplay watch:", {dd, ddOld});
});
// import { getNoun, getAge } from '@/utils/index'
// import { modalsFn } from '@/globals'
// import { EVENTS as PROFILE_COMPONENT_EVENTS } from '@/composites/ProfileComponent'

export default {
    // props: ["profiles"],
    mixins: [MessengerMixin],
    created(){
        this.msgOpponent = msgOpponent
    },
    computed: {
        inboxDialogsDisplay() {
            let inbox = dialogsDisplay.value;
            // let inbox = dialogsDisplay.value.filter(
            //     (dd) => (dd.unreadMessagesCount || 0) > 0
            // );
            inbox = inbox.map((dd) => ({
                ...dd,
                // liKey: dd.data.message_id,
                opponent: this.getMemberDisplay(getOpponentId(dd.dialog_id)),
            })).filter((dd)=>!dd.data);
            return inbox;
        },
    },
    methods: {
        showDialog({ user_id, id } = {}) {
            console.log("[ProfileInbox] showDialog(): {user_id, id}=", {
                user_id,
                id,
            });
            bus.emit(EVENTS.TRIGGER_PROFILE_OPEN_DIALOG, { user_id, id });
            window.closeModal("#inboxMessages-modal")
        },
    },
};
</script>
