import BaseCompositeComponent from "@/composites/BaseCompositeComponent";
import SchemaStorageCompositeMixin from "@/composites/SchemaStorageCompositeMixin";
import { content_params } from '../bridge'

import { CONTENT_SLUG } from "../constants";
import { $ } from "@/globals"
import { order } from "@/content/services";
import { trimSpaces } from "@/utils/index";
import { userContent } from "@/content/state"; 

// export { PROFILE_OWNER_RELATION }

/**
 * Компонент (композит из компонетов) представляющий профиль пользователя
 */
export default class extends BaseCompositeComponent {
    constructor(config) {
        super({ ...config, schema: "user_content" });
        this.vueComponent = {
            mixins: [SchemaStorageCompositeMixin],
            components: {
                // Buttons
                // SchemaStorageSaveButton,
                // SchemaStoragePullButton,
                // // Fields
                // ProfileRawTextField,
                // ProfilePlainField,
                // ProfileSelectField,
                // ProfileAutocompleteSelectField,
                // ProfileImagesCollectionField,
                // ProfileAgeField,
                // ProfileNumberField,
                // ProfileTextAreaField,
                // ProfileRangeField,
                // Lists
                // ProfilesList,
                // // Modals
                // DialogModal,
            },
            data() {
                return {
                    selection: undefined,
                    message: undefined,
                    // topupAmount: DEFAULT_TOPUP_AMOUNT,
                };
            },
            beforeMount() {
                // this.pullSchemaValues(this.compositeSchema)
            },
            mounted(){
                const nextArrowGifts = "<div class='gifts-nextArrowIcon'></div>"
                const prevArrowGifts = "<div class='gifts-prevArrowIcon'></div>"

                $(".gifts-carousel").owlCarousel({
                    nav: true,
                    navContainerClass: "gifts-nav",
                    navClass: ["gifts-prev","gifts-next"],
                    navText: [prevArrowGifts, nextArrowGifts],
                    items: 1,
                    //если бесконечная прокрутка - не работает border на label
                    loop: false,
                    margin: 20,
                });
            },
            methods: {
                getToUserId(){
                    console.log("[GiftSelectModal] getToUserId() content_params.to_user_id=", content_params.to_user_id)
                    if (content_params.to_user_id){
                        const val = content_params.to_user_id;
                        content_params.to_user_id = undefined;
                        return val;
                    }
                    return this.getValuesStorage('profile').user_id
                },
                onClickOrder() {
                    if (!this.selection){
                        return
                    }
                    // id of strangers profile
                    const to_user_id = this.getToUserId();
                    if (!to_user_id){
                        throw new Error('to_user_id is required parameter for gift!')
                    }
                    console.log('[GiftSelectModal] onClickOrder() to_user_id=', to_user_id)
                    order({ 
                        content_slug: CONTENT_SLUG,
                        content_params: {
                            ...this.selection, 
                            to_user_id,
                            message: trimSpaces(this.message || ''),
                        },
                    }).then((result)=>{
                        console.log('[GiftSelectModal] onClickOrder() then: result=', result)
                        // this.getValuesStorage().search_position=1;
                        window.closeModal(this.compositeConfig.mountPoint);
                    }).catch((cause)=>{
                        window.closeModal(this.compositeConfig.mountPoint);
                    })
                },
                getGiftsCollections(){
                    let collections = userContent.text_extra_collections;
                    if (collections){
                        collections = collections.filter(coll=>coll.type_code=="GIFT")
                    }
                    return collections
                },
                getShowcaseContent(slug){
                    const showcase = this.getValuesStorage().content_showcase;
                    console.log(`[GiftSelectModal] getShowcaseContent(): showcase=`, showcase)
                    return showcase.filter(value => value.slug==slug)[0]
                },
                getGiftFormValue(collection, item){
                    return {'collection_slug': collection.slug, 'collection_item_slug': item.slug}
                    // return `gift_${collection.slug}_${item.slug}`
                },
                getGiftFormId(collection, item){
                    return `gift-${collection.slug}-${item.slug}`
                },
                getItemsGroups(collection, groupSize=3){
                    const items = [...collection.items]
                    const groups = []
                    let group = items.splice(0, groupSize) 
                    while (group.length > 0){
                        groups.push(group)
                        group = items.splice(0, groupSize)
                    }
                    return groups
                },
                getContentParams(){
                    const content = this.getShowcaseContent(CONTENT_SLUG);
                    console.log(`[GiftSelectModal] getContentParams(): content=`, content)
                    return content;
                }
            },
            computed: {
                giftsCollections(){
                    console.log(`[GiftSelectModal] giftsCollections computed: userContent=`, userContent)
                    let collections = userContent.text_extra_collections;
                    if (collections){
                        collections = collections.filter(coll=>coll.type_code=="GIFT")
                    }
                    console.log(`[GiftSelectModal] giftsCollections computed: collections=`, collections)
                    return collections
                },
                contentParams() {
                    return this.getContentParams()
                },
                // amount(){
                //     return contentParams.price
                // },
                currencyTypeStr() {
                    return 'монет'
                    // TODO: добавить получение из openapi информации о типе
                    // return this.contentParams.amount_type  
                },
                amountForTypeStr() {
                    return 'дней'
                    // TODO: добавить получение из openapi информации о типе
                    // return this.contentParams.amount_type  
                }
            },
        };
    }
}
