<template>
    <profile-field-layout
        ref="layout" v-bind="layout"
        :class="['c-f-'+field.replace('.', '--'),'c-s-'+schema]"
        :editable="editable" 
        :iconSrc="iconSrc"
        :value-class="valueClass"
        :value-display-str="valueDisplayStr" 
        :placeholder="placeholder"
        :validation-errors="validationErrors"
        :hint="hint"
        :hint-description="hintDescription">
        
        <template #editor>
        <li :class="['dropdownEl flex a-i-center', 'c-v-'+opt.value]" v-for="opt in optionsDisplay" :key="opt.value">
            <input v-if="multiple" class="form-check-input" type="checkbox"  
                :id="labelForOptions+opt.value" 
                :name="labelForOptions+opt.value" 
                @change="checkOption(opt.value, $event.target.checked, multiple)"
                :checked="opt.isChecked">
            <input v-else class="form-check-input" type="radio" 
                :name="labelForOptions" 
                :id="labelForOptions+opt.value"  
                :value="opt.value"
                :checked="opt.isChecked"
                @change="checkOption(opt.value, $event.target.checked, multiple)">
            <label class="form-check-label findSettings-label" :for="labelForOptions+opt.value">
                {{ opt.label }}
            </label>
        </li>
        </template>
    </profile-field-layout>
</template>


<script>
import ProfilePlainField from './ProfilePlainField.vue'
import SelectFieldMixin from './SelectFieldMixin'


export default {
    mixins: [ProfilePlainField, SelectFieldMixin]
};
</script>