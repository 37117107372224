<template>
  <button
    v-if="value.likesCount > 0 || canLike"
    :class="wrapperClass"
    @click="onClickLike"
  >
    <img
      :class="[likeClass, { disabled: !value.isILikeIt }]"
      :src="$static('/icons/heartIcon.png')"
    />
    <span v-if="value.likesCount > 0" :class="likesCountClass">
      {{ value.likesCount }}
    </span>
  </button>
</template>

<script>
import ImagesService from "@/services/ImagesService";
/**
 * Компонент лайк (кнопка лайка + количество лайков)
 *
 * @property {Object} value объект лайка
 * @property {Boolean} canLike флаг-разрешение ставить лайк
 * @example // значение value должно содержать следующие аттрибуты:
 * const value = {
 *      likesCount: 0,  // количество лайков изображения
 *      isILikeIt: false,  // установлен ли лайк мой
 *      // ...anotherImageAttributes
 * }
 * <menu-button :menu="[singleMenuItem, ...anotherMenuItems]"></menu-button>
 * @class
 */
export default {
  props: {
    /* eslint-disable vue/require-valid-default-prop */
    value: { type: Object, default: { likesCount: 0, isILikeIt: false } },
    canLike: { type: Boolean, default: true },
    wrapperClass: { type: String, default: "strangersProfileMain-likeWrapper" },
    likeClass: { type: String, default: "strangersProfileMain-like" },
    likesCountClass: {
      type: String,
      default: "strangersProfileMain-likesCount",
    },
  },
  emits: ["like"],
  methods: {
    onClickLike() {
      if (!this.canLike) return;
      ImagesService.like({ imageData: this.value });
      this.$emit("like", this.value);
    },
  },
};
</script>