<template>
<a  
  class="slider-item" 
  :data-user_id="li.user_id" 
  onclick="profileSwitchTo(this.dataset.user_id)"
  v-for="li in topListDisplay"
  :key="li.user_id"
>
  <img v-if="li.avatar" class="slider-photo" 
      :src="$media(li.avatar.srcFull)" :alt="'Фото '+(li.name || '[аноним]')"/>
  <img v-else class="slider-photo slider-photo--no-photo" 
      :src="$static('/icons/camera.png')" :alt="'Фото '+(li.name || '[аноним]')"/>
  <div class="slider-profileInfo">
    <div>{{ li.name || 'Аноним' }}</div>
    <div v-if="li.age">{{getAgeDisplay(li.age)}}</div>
    <div v-if="li.locality_name">{{ li.locality_name || '' }}</div>
  </div>
</a>
<a v-if="showLoadingDisplay" class="slider-item" href="#">
  <div class="slider-photo placeholder-wrapper">
    <slider-item-loading-placeholder>
    </slider-item-loading-placeholder>
  </div>
</a>
<a v-if="showEmpty" class="slider-item" href="#">
  <img class="slider-photo slider-photo--no-photo" :src="$static('/icons/camera.png')" alt="Нет фото"/>
  <div class="slider-profileInfo">
    <div>Пока пусто</div>
    <div></div>
    <div></div>
  </div>
</a>
</template>
<script>
import { getAgeNoun } from '@/utils/userInfo'
import { SliderItemLoadingPlaceholder } from './binding.js'


export default {
    props: {
        topList: { type: Array, required: true },
        // topListLoading: { type: Array, default(){return []} },
        showLoading: { type: Boolean, default: true },
    },
    components: { SliderItemLoadingPlaceholder },
    data(){ return {items: []}},
    methods: {
        onClickItem(user_id){
            window.profileSwitchTo(user_id)
        },
        getAgeDisplay(age){
          return `${age} ${getAgeNoun(age)}`
        },
        getAgeNounDisplay(age){
          return getAgeNoun(age)
        }
    },
    computed:{
        topListDisplay(){
          if (this.showLoading){ return [] } 
          return this.topList || [] 
        },
        showLoadingDisplay(){ return this.showLoading },
        isTopListEmpty(){ return !this.topList || !this.topList.length},
        showEmpty(){ return this.isTopListEmpty && !this.showLoading },
    },
    watch:{
      showEmpty(showEmpty, showEmptyOld){
        console.log("[BaseSliderItems] [SliderItems] showEmpty watch:", {showEmpty, showEmptyOld})
      },
    },
};
</script>
<style>
.placeholder-wrapper{
  position: relative;
  height: 10vw;
  display: inline-block;
  bottom: -25%;
  scale: .4;
  opacity: 0.4;
}
</style>