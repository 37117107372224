const PROFILE_OWNER_RELATION = {
    ME: 'me',
    STRANGER: 'stranger',
};
/**
 * Миксин прав доступа к композиту профиля
 * @class
 */
const ProfilePermissionsMixin = {
    computed: {
        ownerRelation() {
            if (this.compositeConfig.ownerRelation)
                return this.compositeConfig.ownerRelation; 
            return this.isMyProfile() ? PROFILE_OWNER_RELATION.ME : PROFILE_OWNER_RELATION.STRANGER
        },
        canLikeImages() { return this.ownerRelation == PROFILE_OWNER_RELATION.STRANGER },
        canEditFields() { return this.ownerRelation == PROFILE_OWNER_RELATION.ME },
    },
};


export { ProfilePermissionsMixin, PROFILE_OWNER_RELATION };