import { INPUT_TYPE } from '@/constants'
import BaseFieldValidationError from './BaseFieldValidationError'


class NumberFieldValidationError extends BaseFieldValidationError {
    constructor(message, field) {
        super(message, field);
        this.name = "NumberFieldValidationError";
    }
}

/**
 * Vue-миксин включающий в себя общую/основную логику работы числовых полей
 * @mixin
 */

export default {
    props: {
        min: { type: Number, default () { return 1 } },
        max: { type: Number, default () { return 255 } },
        noun: { type: Array, default () { return undefined } },
        inputType: { type: String, default: INPUT_TYPE.NUMBER },
    },
    methods: {
        /**
         * Возвращает правильное окончание для чисел (последеней цифры числа)
         * @param {Number} number
         * @param {String} one
         * @param {String} two 
         * @param {String} five 
         * @method getNoun 
         */
        getNoun(number, one, two, five) {
            let n = Math.abs(number);
            n %= 100;
            if (n >= 5 && n <= 20) {
                return five;
            }
            n %= 10;
            if (n === 1) {
                return one;
            }
            if (n >= 2 && n <= 4) {
                return two;
            }
            return five;
        },
        _defaultValueValidator(value) {
            if (this._isBlank(value)) {
                if (this.blank) { // if allowed to be blank
                    return true
                } else {
                    throw new NumberFieldValidationError(`не может быть пустым`, this);
                }
            }
            if (value < this.min)
                throw new NumberFieldValidationError(`слишком мало (< ${this.min})`, this);
            else if (value > this.max)
                throw new NumberFieldValidationError(`слишком много (> ${this.max})`, this);
            return true;
        },
    },
    beforeMount() {
        this.valueValidators.push(this._defaultValueValidator.bind(this));
    }
};