<template>
  <profile-images-collection-layout ref="layout">
        <template #avatar="{imageFieldClass}">
            <profile-image-field 
                :class="imageFieldClass"
                :img-class="avatarImgClass"
                show-like
                :can-like="canLike"
                :editable="editable"
                :stickers="stickers"
                
                @upload="onUploadAvatarImage"
                @img-click="isImageSet(valueAvatarImage) && galleryShow(avatarGalleryIndex)"
                :editor-on-click="!isImageSet(valueAvatarImage)"
                :data-index='avatarGalleryIndex'
                
                :ref="imageFieldRefPrefix+0"
                :initial="valueAvatarImage"
                initial-as-set-default
                is-array-item
                :schema="schema" :field="getImageFieldName(valueAvatarImage, value)">

                <menu-button
                    v-if="editable"
                    :menu="avatarMenu"
                    class="myProfileMain-photoMenuWrapper"
                    icon-class="myProfileMain-photoMenu">
                </menu-button>
            </profile-image-field>
        </template>
        <template #previews>     
            <profile-image-field 
                class="myProfileMain-photoWrapper flex a-i-center j-c-center"
                disable-wrap
                img-class="myProfileMain-photoIcon"
                no-img-class="myProfileMain-noPhotoIcon"
                hover-class="myProfileMain-photoHover"
                
                :editable="editable"
                
                v-for="(p, index) in valueImagesPreviews" 
                :key="index+1" 
                :ref="imageFieldRefPrefix+(index+1)"
                
                @add="onImgFieldAdd"
                @upload="onUploadImage"

                :initial="p"
                initial-as-set-default
                is-array-item
                :schema="schema" :field="getImageFieldName(p, value)"
                :stickers="[]">
            </profile-image-field>
        </template>
        <template #extra>
            <gallery-mixin 
                @added="galleryOnMixinAdded"
                :can-like="canLike"
                :can-edit-photos="editable"
                :image-menu-items="galleryCurrentImageMenu">
            </gallery-mixin>
        </template>
    </profile-images-collection-layout>
    
</template>

<script>
import ProfilePlainField from '@/components/fields/ProfilePlainField.vue'
import { ImageSticker } from '@/components/fields/ProfileImageField.vue'
import ProfileImageField from '@/components/fields/ProfileImageField.vue'
import ImageFieldMixin from '@/components/fields/ImageFieldMixin'
import MenuButton from '@/components/buttons/MenuButton.vue'
import ImagesService from '@/services/ImagesService'
import ProfileImagesCollectionGalleryMixin from '@/components/fields/ProfileImagesCollectionGalleryMixin'
import ProfileImagesCollectionLayout from '@/components/layouts/profile/ProfileImagesCollectionLayout.vue'

/**
 * Поле описывающее коллекцию изображений в профиле
 * @property {Number} previewsCount количество превью под аватаром
 * @property {Boolean} canLike флаг-разрешение ставить лайк
 * @property {Array} stickers стикеры изображений пользователя 
 * @class ProfileImagesCollectionField
 */
export default {
    mixins: [ProfilePlainField, ImageFieldMixin, ProfileImagesCollectionGalleryMixin],
    props: {
        alt: { type: String, default: 'Изображение' },
        accept: { type: String, default: 'image/png, image/jpeg' },
        notSetSrc: { type: String, default: '/icons/camera.png' },
        noFileSelectedLabel: { type: String, default: 'фото не выбрано' },
        uploadFileLabel: { type: String, default: 'загрузить фото' },
        selectFileLabel: { type: String, default: 'выбрать фото' },
        selectAnotherFileLabel: { type: String, default: 'выбрать другое' },
        previewsCount: { type: Number, default: 3 },
        canLike: { type: Boolean, default: true },

        stickers: { type: Array, default: () => [new ImageSticker()] },

        initial: { type: Array, default: () => [] },
    },

    components: {
        ProfileImagesCollectionLayout,
        ProfileImageField,
        MenuButton,
    },

    data() {
        return {
            avatarImgClass: 'myProfileMain-avatar',
            imageFieldRefPrefix: 'imageField',
            previews: [], // временное хранилище для загружаемых изображений
            avatar: undefined, // временное хранилище для загружаемых изображений
        }
    },
    methods: {
        getImageFieldName(imageData, images) {
            let subname = undefined;
            const index = (images || []).indexOf(imageData);
            if (index !== -1) {
                subname = index;
            } else {
                subname = '*'
            }
            return `${this.field}.${subname}`;
        },
        /**
         * Метод для открытия редактора поля изображения.  
         * @param {Object} imageData изображение
         * @method editorShow
         */
        editorShow(imageData) {
            const field = this._imagesFields.find(f => f.value === imageData);
            console.log('[ProfileImagesCollection]editorShow(): field=', field)
            if (field) {
                return field.$refs.layout.showEditor();
            }
        },
        /**
         * Метод генерации меню для изображения
         * @param {Object} imageData изображение
         * @param {Array} imagesCollection коллекция всех изображений
         * @method _getMenu
         */
        _getMenu(parameters) {
            const labels = this.getImageMenuActionsLabels(parameters),
                handlers = this.getImageMenuActionsHandlers(parameters);

            // patch image actions for users profile
            const baseReplace = handlers[ImagesService.IMAGE_ACTIONS.REPLACE];
            handlers[ImagesService.IMAGE_ACTIONS.REPLACE] = () => {
                parameters.previousImageData = Object.assign({}, parameters.imageData);
                this.galleryClose();
                this.editorShow(parameters.imageData).then(() => {
                    baseReplace(parameters);
                });
            };

            const baseDelete = handlers[ImagesService.IMAGE_ACTIONS.DELETE];
            handlers[ImagesService.IMAGE_ACTIONS.DELETE] = () => {
                this.galleryClose();
                baseDelete();
            };
            return this.getImageMenu(parameters.imageData, labels, handlers);
        },
        /**
         * Метод переопределяющий стандартный метод в галерее 
         * {@link ProfileImagesCollectionGalleryMixin} для получения меню изображения.
         * 
         * @param {Object} options опции
         * @method galleryGetImageMenu
         */
        galleryGetImageMenu(parameters) {
            // override mixin method
            return this._getMenu(parameters);
        },
        onImgFieldAdd(imageData) {
            this.editorShow(imageData);
        },
        onUploadImage({ value, previousValue }) {
            ImagesService.upload({ imageData: value, imagesCollection: this.value, previousImageData: previousValue });
        },
        onUploadAvatarImage(properties) {
            properties.value.isAvatar = true;
            this.onUploadImage(properties)
        },
    },
    computed: {
        _imagesFields() {
            return Object.entries(this.$refs)
                .filter(([key, val]) => key.indexOf(this.imageFieldRefPrefix) !== -1)
                .map(([key, val]) => val);
        },
        avatarMenu() {
            return this._getMenu({ imageData: this.valueAvatarImage, imagesCollection: this.value });
        },
        valueAvatarImage() {
            const avatar = Array.isArray(this.value) && this.value.find(img => img.isAvatar);
            if (avatar)
                return avatar;
            if (!this._avatar) { // кэшируем 
                this._avatar
                /* eslint-disable vue/no-side-effects-in-computed-properties */
                this._avatar = this.createBlankImage({
                    id: this.createBlankImageNewId(),
                    isAvatar: true
                });
            }
            return this._avatar;
        },
        valueImagesPreviews() {
            let previews = Array.isArray(this.value) ?
                this.value.filter(img => !img.isAvatar) : (this.previews || []);
            if (previews.length > this.previewsCount) {                previews = previews.slice(0, this.previewsCount);
            } else if (previews.length < this.previewsCount) {
                // кэшируем
                /* eslint-disable vue/no-side-effects-in-computed-properties */
                previews = previews.concat(
                    Array.from({ length: this.previewsCount - previews.length },
                        (v, i) => this.createBlankImage({ id: this.createBlankImageNewId() }))
                )
            }
            this.previews = previews; // кэшируем
            return previews;
        },
        avatarGalleryIndex() {
            return this.getImageGalleryIndex(this.valueAvatarImage, this.value)
        }
    },

    /**
     * FIXME:   - hiding opened menu if no hover
     **/
};
</script>
