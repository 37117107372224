<template>
    <!-- // TODO: make adaptive and responsive avatar when it isn't set -->
    <profile-field-layout 
        ref="layout"
        v-bind="layout"
        :editable="editable"
        @editor-show="onEditorShow"
        :validation-errors="validationErrors"
        :dropdown-list-class="dropdownListClass">

        <template #value>
            <img-wrap :wrap-class="wrapClass" :use="!(!forceWrap && (isSet || disableWrap))">
                <span v-if="editable && !isSet" :class="hoverClass" @click="$emit('add', value)">+</span>
                <img ref="img" :class="imgClassDisplay" @click="$emit('img-click', value)" v-bind="imgAttrs" :alt="alt" :src="imageSrcDisplay" />
                <template v-for="sticker in stickers" :key="sticker.src">
                    <img :class="sticker.class" :src="$static(sticker.src)" @click="$emit('sticker-click', sticker)">
                </template>
                <like-button 
                    v-if="showLike && isSet"    
                    :value="value"
                    :can-like="canLike"
                    @like="$emit('like', value)"
                    class="strangersProfileMain-likeWrapper">
                </like-button> 
                <slot></slot>
            </img-wrap>
        </template>

        <template #editor>
            <span class="myProfileMain-dropdownTitle line-height-1">
                <template v-if="hasImageToPost">
                    Фото выбрано.
                </template>
                <template v-else>
                    Сменить фото?
                </template>
            </span>
            <input  style="display: none"  
                    type="file"
                    :accept="accept"
                    @change="onChangeFileInput($event, index)" 
                    ref="fileInput"> <!--невидимое поле для выбора файла -->

            <li v-if="hasImageToPost" class="dropdownEl flex flex-d-column a-i-center">
                <button
                        @keydown.enter="onUploadImageClick"
                        @click="onUploadImageClick"
                        class="myProfileMain-photoMenuButton goldButton">
                    {{uploadFileLabel}}
                </button>         
            </li>
            <li v-if="hasImageToPost" class="dropdownEl flex flex-d-column a-i-center">
                <span class="findSettings-small line-height-1 block"> или </span>
            </li>
            <li v-if="hasImageToPost" class="dropdownEl flex flex-d-column a-i-center">
                <button @keydown.enter="openFileDialog()"
                        @click="openFileDialog()"
                        class="myProfileMain-photoMenuButton goldButton">
                    {{selectAnotherFileLabel}}
                </button>           
            </li>
            <li v-else class="dropdownEl flex flex-d-column a-i-center">
                <button @keydown.enter="openFileDialog()"
                        @click="openFileDialog()"
                        class="myProfileMain-photoMenuButton goldButton">
                    {{selectFileLabel}}
                </button>           
            </li>
            <li v-if="!hasImageToPost" class="dropdownEl flex flex-d-column a-i-center">
                <span class="findSettings-small line-height-1 block">{{ noFileSelectedLabel }}</span>
            </li>
        </template>
    </profile-field-layout>
</template>

<script>
import ProfilePlainField from '@/components/fields/ProfilePlainField.vue'
import { INPUT_TYPE } from '@/constants'
import ImageFieldMixin from '@/components/fields/ImageFieldMixin'
import LikeButton from '@/components/buttons/LikeButton.vue'

/**
 * Класс представляющий стикер
 * @class ImageSticker
 */
export class ImageSticker {
    constructor(
        src = '/icons/corona-on-avatar.png',
        classes = 'myProfileMain-coronaOnAvatar hide-small hide-medium') {
        this.src = src;
        this.class = classes
    }
}

/**
 * Компонент поля изображения
 * @vue-data {*} lastUploadedValue последнее загружённое изображение
 * @prop {Object} imgAttrs объект с аттрибутами для тэга изобраения (img)
 * @class ProfileImageField
 */
export default {
    mixins: [ProfilePlainField, ImageFieldMixin],
    components: {
        LikeButton,
        /**
         * Обёртка для поля изображения
         * @class 'img-wrap' 
         */
        'img-wrap': {
            props: ['use', 'wrapClass'],
            template: `
            <button v-if="use" :class="wrapClass">
                <slot></slot>
            </button>
            <template v-else>
                <slot></slot>
            </template>
        `
        }
    },
    emits: ['img-click', 'sticker-click', 'add', 'upload', 'like'],
    props: {
        alt: { type: String, default: 'Изображение' },
        accept: { type: String, default: 'image/png, image/jpeg' },
        initial: { type: Object, default: ()=> {} },
        imgAttrs: {
            type: Object,
            default: () => ({
                width: "297"
            })
        },
        inputType: { type: String, default: INPUT_TYPE.OBJECT },
        notSetSrc: { type: String, default: '/icons/camera.png' },

        noFileSelectedLabel: { type: String, default: 'фото не выбрано' },
        uploadFileLabel: { type: String, default: 'загрузить фото' },
        selectFileLabel: { type: String, default: 'выбрать фото' },
        selectAnotherFileLabel: { type: String, default: 'выбрать другое' },

        stickers: { type: Array, default () { return [new ImageSticker()] } },

        imgClass: { type: String, default: 'myProfileMain-avatar' },
        noImgClass: { type: String, default: 'myProfileMain-noPhotoIcon' },
        disableWrap: { type: Boolean, default: false },
        forceWrap: { type: Boolean, default: false },
        canLike: { type: Boolean, default: true },
        showLike: { type: Boolean, default: false },
        wrapClass: { type: String, default: 'myProfileMain-avatarWrapper' },
        hoverClass: { type: String, default: 'myProfileMain-avatarHover' },
    },
    data() {
        return {
            dropdownListClass: 'myProfileMain-list-photo-menu',
            lastUploadedValue: Object.assign({}, this.value),
        }
    },
    methods: {
        openFileDialog() {
            this.$refs.fileInput.click();
        },
        /**
         * Метод для загрузки изображения
         * @method upload
         */
        upload() {
            this.$emit('upload', { value: this.value, previousValue: this.lastUploadedValue });
            this.lastUploadedValue = Object.assign({}, this.value);
        },
        onChangeFileInput(e, index) {
            const file = e.target.files[0];
            if (!file) return;
            const imgToPost = this.createBlankImage({
                isAvatar: !!this.value && this.value.isAvatar,
                toPost: true,
                srcFull: file
            });
            if (this.value) {
                // сохраняем изначальный объект данных изображения this.value
                // для сохранения ссылок на него. Заполняем аттрибуты новыми значениями. 
                Object.assign(this.value, imgToPost);
            } else {
                // изначальный объект данных изображения отсутствует, просто присваиваем
                this.value = imgToPost;
            }
        },
        onEditorShow() {
            if (!this.hasImageToPost)
                this.openFileDialog();
        },
        onUploadImageClick() {
            this.upload()
                /**
                 * не понятно почему, this.$refs.layout.hideEditor() должно вызываться асинхронно, т.к.
                 * иначе редактор не скрывается не смотря на метод hideEditor() устанавливающий 
                 * значение editModeOn в false.
                 * @todo изучить, исправить.
                 */
            setTimeout(() => this.$refs.layout.hideEditor(), 0);
        },
    },
    computed: {
        layout(){
            return this.$refs.layout
        },
        stickersDisplay() {
            return this.stickers.map(s => Array.isArray(s) ? new ImageSticker(...s) : s);
        },
        imageSrcToPost() {
            if (this.hasImageToPost)
                return URL.createObjectURL(this.value.srcFull);
            return undefined;
        },
        valueForDisplay() {
            const imgToPost = this.imageSrcToPost;
            if (imgToPost)
                return imgToPost
            if (this.value)
                return this.value.src;
            return undefined;
        },
        hasImageToPost() {
            return this.value && this.value.toPost;
        },
        isSet() {
            return !!this.valueForDisplay
        },
        imageSrc() {
            const imageSetSrc = this.valueForDisplay;
            return imageSetSrc || this.notSetSrc;
        },
        imageSrcDisplay() {
            if (this.hasImageToPost) {
                return URL.createObjectURL(this.value.srcFull);
            }
            if (this.isSet) {
                return this.$media(this.imageSrc);
            }
            return this.$static(this.notSetSrc);
        },
        imgClassDisplay() {
            return this.isSet ? this.imgClass : this.noImgClass
        },
    }
};
</script>