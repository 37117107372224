<template>
<div class="owl-carousel myProfileMain-carousel">
    <slider-small-items 
        :top-list="topsDisplay.country" 
        :show-loading="isLoadingDisplay"
    >
    </slider-small-items>
</div>
</template>
<script>
import SliderSmallItems from './SliderSmallItems.vue'
import TopSliderMixin from "./TopSliderMixin"
import { OC_SMALL_SLIDER_PARAMS } from "./constants"

const OC_SELECTOR = ".myProfileMain-carousel"


export default {
    props: {
        sliderParams: { type: Object, default(){ return OC_SMALL_SLIDER_PARAMS } },
    },
    components: {SliderSmallItems},
    mixins: [TopSliderMixin],
    created(){ this.OC_SELECTOR = OC_SELECTOR},
    watch:{
        topsDisplay(topsDisplay, topsDisplayOld){
            console.log("[SmallSlider] topsDisplay watch:", {
                OS_SELECTOR: this.OC_SELECTOR,
                topsDisplay, 
                topsDisplayOld,
                isLoadingDisplay: this.isLoadingDisplay,
            }); 
        }
    },
    mounted(){
        console.log("[SmallSlider] mounted():", {
            OS_SELECTOR: this.OC_SELECTOR,
            // topsDisplay, 
            // topsDisplayOld,
            isLoadingDisplay: this.isLoadingDisplay,
        });
    }
};
</script>