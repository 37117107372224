import * as service from "@/services/MessengerService";
import {
    equalId
} from "@/utils/messenger";

import { bootstrap, modals, $ } from "@/globals";


export default {
    // props: ["profiles"],
    data() {
        return {
            // noAvatarSrc: "/icons/camera.png", // /icons/camera.png
            // // noAvatarSrc: "/img/avatarIcon.png",
            // userNoName: "[аноним]",
            bsTarget: "#messenger-dialog-modal",
            modalInstance: undefined,
        };
    },
    methods: {
        isStartVisible(){
            const visibleEls = this.getVisibleMessagesEls();
            if (!visibleEls || !visibleEls.length) {
                console.warn(
                    `[ProfileMessengerComponent] updateDialogHistory(): no visible elements found, but messages in dialog exists`
                );
            }
    
            return visibleEls.filter((i) => i[0] == 0).length != 0;
            // const isVisibleAll =
            // visibleEls.length == dialog.messages.length;
            // if (isStartVisible && isVisibleAll) {
        },
        getVisibleMessages() {
            if (!service.currentDialog.value || !service.currentDialog.value.messages)
                return []
            const visibleItems = this.getVisibleMessagesEls();

            const visibleMessages = visibleItems.map(
                (i) => service.currentDialog.value.messages[i[0]]
            );
            return visibleMessages;
        },
        getVisibleMessagesEls(
            { intersectionMargin = 30, modalRequired = true } = {
                intersectionMargin: 30,
                modalRequired: true,
            }
        ) {
            const modal = document.querySelector(
                "#messenger-dialog-modal"
            );
            const isModalVisible =
                ("" + modal.style.display).toLowerCase() == "block";
            if (modalRequired && !isModalVisible) {
                return [];
            }
            const messagesWrapperEl = document.querySelector(
                ".dialog-messagesWrapper"
            );
            const messagesMainEl =
                document.querySelector(".dialog-main");

            const scroll = messagesWrapperEl.scrollTop;
            // const fullHeight = messagesWrapperEl.scrollHeight;
            const visibleHeight = messagesWrapperEl.clientHeight;
            // console.log(
            //     "[ProfileMessengerComponent] getVisibleMessagesEls(): visibleHeight=",
            //     visibleHeight
            // );
            // console.log('[ProfileMessengerComponent] mounted(): scroll / visibleHeight=', scroll, visibleHeight)

            const messagesEls = [
                ...document.querySelectorAll(
                    ".dialog-messagesWrapper>*"
                ),
            ];
            // console.log(
            //     "[ProfileMessengerComponent] getVisibleMessagesEls(): messagesEls=",
            //     messagesEls
            // );
            let visibleEls = [];

            let visibleFrom = scroll;
            let visibleTo = scroll + visibleHeight;
            // console.log(
            //     "[ProfileMessengerComponent] getVisibleMessagesEls(): visibleFrom=",
            //     visibleFrom
            // );
            // console.log(
            //     "[ProfileMessengerComponent] getVisibleMessagesEls(): visibleTo=",
            //     visibleTo
            // );
            for (const elIdx in messagesEls) {
                const el = messagesEls[elIdx];
                const messageStart = messagesEls
                    .slice(0, elIdx)
                    .reduce((base, el) => base + el.clientHeight, 0);
                // console.log(
                //     "[ProfileMessengerComponent] getVisibleMessagesEls(): messageStart=",
                //     messageStart
                // );
                const intersectionStart =
                    Math.max(messageStart, visibleFrom) +
                    intersectionMargin;
                // console.log(
                //     "[ProfileMessengerComponent] getVisibleMessagesEls(): intersectionStart=",
                //     intersectionStart
                // );
                const messageEnd = messageStart + el.clientHeight;
                // console.log(
                //     "[ProfileMessengerComponent] getVisibleMessagesEls(): messageEnd=",
                //     messageEnd
                // );
                const intersectionEnd =
                    Math.min(messageEnd, visibleTo) -
                    intersectionMargin;
                // console.log(
                //     "[ProfileMessengerComponent] getVisibleMessagesEls(): intersectionEnd=",
                //     intersectionEnd
                // );
                const intersects =
                    intersectionStart <= intersectionEnd ||
                    intersectionStart + intersectionEnd == 0; // if one message
                // console.log(
                //     "[ProfileMessengerComponent] getVisibleMessagesEls(): intersects=",
                //     intersects
                // );
                // if (intersects){
                //     el.style.color = "#00AAFF";
                // } else {
                //     el.style.color = "#FFAA00";
                // }
                if (intersects) {
                    visibleEls.push([elIdx, messagesEls[elIdx]]);
                }
                // visibleEls.push(intersects ? 'V' : '_')
            }

            // hotfix
            // if (messagesEls.length == 1 && visibleEls.length == 0){
            //     visibleEls.push([0, messagesEls[0]]);
            // }

            return visibleEls;

            // TODO: использовать полученные индексы видимости для проставления отметок о видимости для сообщений

            // const scale = 0.05;
            // let visibleDisplay = (
            //     Array(Math.floor(visibleFrom*scale)).join('_')
            //     + Array(Math.floor(visibleHeight*scale)).join('V')
            //     + Array(Math.floor((fullHeight-messagesWrapperEl.clientHeight-visibleFrom)*scale)).join('_')
            // )

            // console.log('[ProfileMessengerComponent] mounted(): visibleDisplay =', visibleDisplay)
            // console.log('[ProfileMessengerComponent] mounted(): messages visibleEls=', visibleEls.join(''))
            // console.log('[ProfileMessengerComponent] mounted(): messages visibleEls=', visibleEls.join(''))
        },
        isModalVisible() {
            return $(this.bsTarget).hasClass("in");
        },
        showModal({ bsOptions } = {}) {
            const modalEl = document.querySelector(this.bsTarget);
            if (!this.modalInstance)
                this.modalInstance = new bootstrap.Modal(
                    modalEl,
                    bsOptions
                );

            // if (this.onHidden._listener)
            //     this.onHidden._listener = this.onHidden.bind(this);

            // modalEl.addEventListener('hidden.bs.modal', this.onHidden._listener);

            this.modalInstance.show();
            // this.markReadCurrentDialogDebounced()
            // if (!this.isModalVisible())
            //     modals.messengerDialog.show();
            // else{
            //     modals.messengerDialog.show();
            // }
            // bus.emit(EVENTS.TRIGGER_PROFILE_OPEN_DIALOG, {user_id})
        },
        getMessageTextInputEl(){
            return document.querySelector('.dialog-bottomBar .dialog-messagesSender')
        },
        insertTextAtCursor(inputEl, text) {
            let cursorPos = this._selectionStart || inputEl.selectionStart || 0;
            let v = this.textInput || '';
            let textBefore = v.substring(0,  cursorPos);
            let textAfter  = v.substring( cursorPos, v.length );
            this.textInput = ''+ textBefore + text + textAfter;
            cursorPos += text.length;
            this._selectionStart = cursorPos;
            inputEl.focus();
            inputEl.setSelectionRange(cursorPos, cursorPos);
        },
        insertTextInInput(text){
            const inputEl = this.getMessageTextInputEl();
            this.insertTextAtCursor(inputEl, text);
        },
        scrollToLast(smooth = true) {
            // const messagesEls = document.querySelectorAll(
            //     ".dialog-messagesWrapper>*"
            // );
            // const el = messagesEls[messagesEls.length - 1];
            // if (el) {
            //     el.scrollIntoView({ behavior: "smooth" });
            // }
            const messagesWrEl = document.querySelector(
                ".dialog-messagesWrapper"
            );
            const lastMsgEl = document.querySelector(
                ".dialog-messagesWrapper>*:last-child"
            );
            // console.log(
            //     "[ProfileMessengerComponent] scrollToLast(): {messagesWrEl, lastMsgEl}=",
            //     { messagesWrEl, lastMsgEl }
            // );
            if (smooth) {
                if (lastMsgEl) {
                    lastMsgEl.scrollIntoView({ smooth: true });
                    // console.log(
                    //     "[ProfileMessengerComponent] scrollToLast(): method: scrollIntoView(), lastMsgEl=",
                    //     lastMsgEl
                    // );
                } else {
                    if (!!messagesWrEl) messagesWrEl.scrollTo(0, messagesWrEl.scrollTopMax);
                    // console.log(
                    //     "[ProfileMessengerComponent] scrollToLast(): method: scrollTo(), messagesWrEl=",
                    //     messagesWrEl
                    // );
                }
            } else {
                if (!!messagesWrEl) messagesWrEl.scrollTop = Number.MAX_VALUE;
                // console.log(
                //     "[ProfileMessengerComponent] scrollToLast(): method: el.scrollTop = Number.MAX_VALUE, messagesWrEl=",
                //     messagesWrEl
                // );
            }
        },
        onCaretMove(e){
            this._selectionStart = e.target.selectionStart
            this._selectionEnd = e.target.selectionEnd
        },
    },
    // computed: {
    //     dialogs(){
    //         return this._getDialogs()
    //     }
    // },
};