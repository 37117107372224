import { Exception } from "sass";

/**
 * Функция получения значения аттрибута расположенного в объекте по определённому пути 
 * @param {Object} obj контейнер
 * @param {Array} path путь к значению в контейнере в виде массива строк
 * @param {*} def значение по умолчанию (если путь не существует или значение не определено)
 * @returns аттрибут по пути path или def при отсутствии аттрибута по этому пути
 * @function pathGet
 */
export function pathGet(obj, path, def) {
    if ((!obj) || (obj.constructor !== Object))
        return def;
    let current = obj;
    const lastInPath = path[path.length - 1];
    for (const p of path) {
        if (current 
                && (current.constructor === Object 
                    || current.constructor === Array
                ) 
                && (p in current))
            {
            current = current[p];
            continue;
        }   
        
        return def;
    }
    return current;
}

/**
 * Функция установки значения аттрибута расположенного в объекте по определённому пути 
 * @param {Object} obj контейнер
 * @param {Array} path путь к значению в контейнере в виде массива строк 
 * @param {*} val устанавливаемое значение аттрибута
 * @param {*} isArrayItem является аттрибут (по указанному пути) элементом массива
 * @param {*} pathDetailed определён ли путь? 
 * @returns индекс элемента в массиве, если он присваивался автоматически, иначе undefined
 * @function pathGet
 */
export function pathSet(obj, path, val, isArrayItem = false, arrayIndexOfDetailedPath = undefined) {
    if (!obj || !(obj instanceof Object)) throw new TypeError("Can't set value in non-object container");
    const pathLength = path.length;
    let current = obj;
    const lastInPath = path[pathLength - 1];
    const arrayPath = path[pathLength - 2];
    for (const p of path) {
        if (p === lastInPath) {
            if (isArrayItem) {
                if (arrayIndexOfDetailedPath === undefined) {
                    current.push(val);
                    return current.length - 1;
                } else {
                    current[arrayIndexOfDetailedPath] = val;
                    return undefined;
                }
            } else {
                current[p] = val;
                return p;
            }
        } else {
            if (!(p in current && current[p] instanceof Object)) {
                if (isArrayItem && p === arrayPath) {
                    current[p] = [];
                } else {
                    current[p] = {};
                }
            }
            current = current[p];
        }
    }
}

/**
 * @param {Object} obj обект откуда брать соответствие
 * @param {Any} value значение по которому ищется ключ
 * @param {Any} defaultKey ключ по-умолчанию, если не найдётся в объекте
 * @returns string ключ равный значению в объекте (не строго ==)
 */
export function getKey(obj, value, defaultKey=undefined){
    for(const [key, objValue] of Object.entries(obj)){
        if (objValue == value){
            return key
        }
    }
    return defaultKey
}