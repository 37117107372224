import BaseCompositeComponent from "./BaseCompositeComponent";
import SchemaStorageCompositeMixin from "./SchemaStorageCompositeMixin";
// import {
//     sendMessage,
//     stopTyping,
//     startTyping,
//     queueHistory,
//     connector,
//     WsDataTypeEnum,
//     NotificationUserIdTypeEnum,
// } from "@/services/MessengerService";
// import { bootstrap, modals, $ } from "@/globals";
import {WsDataTypeEnum} from "@/api/constants"
import { EVENTS } from "@/constants";
import {
    matchAllMessageTags,
    tag2HtmlConverter
} from "@/content/text_extra/utils";
import bus from "@/utils/eventBus";

import * as service from "@/services/MessengerService";
import MessengerMixin from "../components/mixins/MessengerMixin";
import { msgOpponent } from "@/services/messenger/utils"

/**
 * Компонент (композит из компонетов) представляющий профиль пользователя
 */
export default class extends BaseCompositeComponent {
    constructor(config) {
        super({ ...config, schema: "messenger" });
        this.vueComponent = {
            mixins: [SchemaStorageCompositeMixin, MessengerMixin],
            data() {
                return {
                    dialogsDisplay:service.dialogsDisplay,
                    // listDisplay: [],
                    noAvatarSrc: "/img/avatarIcon.png",
                    // testParam: 'testParam',
                    // renderComponent: true,
                };
            },
            components: {},
            mounted() {
                // console.log(
                //     "[ProfileMessengerDialogsComponent] mounted(): echo!"
                // );
                // console.log(
                //     "[ProfileMessengerDialogsComponent] mounted(): this.dialogsDisplay=", this.dialogsDisplay
                // );
                // console.log(
                //     "[ProfileMessengerDialogsComponent] mounted(): this.getValuesStorage()=", this.getValuesStorage()
                // );
                // console.log(
                //     "[ProfileMessengerDialogsComponent] mounted(): this.getValuesStorage()['dialogsDisplay']=", this.getValuesStorage()['dialogsDisplay']
                // );
                // window.__kupMessengerStorage = this.getValuesStorage();
                // window.__kupidonDialogs = {
                //     update: () => this.$forceUpdate(),
                //     component: this
                // }
                // const storage = this.getValuesStorage();
                // let dialogs;
                // setTimeout(()=>{
                //     dialogs=this.getValuesStorage().dialogsDisplay || [];
                //     this.listDisplay.length = 0;
                //     this.listDisplay.push(...dialogs)
                //     // this.listDisplay=[...dialogs];
                //     this.$forceUpdate();
                //     this.$nextTick(()=>{
                //         dialogs=this.getValuesStorage().dialogsDisplay || [];
                //         // this.listDisplay=[...dialogs];
                //         this.listDisplay.length = 0;
                //         this.listDisplay.push(...dialogs)
                //         this.$forceUpdate();
                //     })
                    
                //     // console.log(
                //     //     "[ProfileMessengerDialogsComponent] mounted() setTimeout: this.getValuesStorage()=", this.getValuesStorage()
                //     // );
                //     // console.log(
                //     //     "[ProfileMessengerDialogsComponent] mounted() setTimeout: dialogs=", dialogs
                //     // );
                //     // console.log(
                //     //     "[ProfileMessengerDialogsComponent] mounted() setTimeout: this.listDisplay=", this.listDisplay
                //     // );
                // }, 2000)
                // // this.$nextTick();
                // // this.listDisplay = dialogs;
                // // this.$forceUpdate();
                // // console.log(
                // //     "[ProfileMessengerDialogsComponent] mounted(): storage=", storage
                // // );
                // // console.log(
                // //     "[ProfileMessengerDialogsComponent] mounted(): dialogs=", dialogs
                // // );
                // // console.log(
                // //     "[ProfileMessengerDialogsComponent] mounted(): this.listDisplay=", this.listDisplay
                // // );
            },
            created() {
                this.msgOpponent = msgOpponent
                // bus.on(EVENTS.MESSAGE_RECEIVE, (m) => console.log('[ProfileInbox] message received! message =', m));
                // bus.on(EVENTS.MESSAGE_RECEIVE, this.onMessageReceive, this);
                // bus.on(
                //     EVENTS.DIALOGS_DISPLAY_UPDATED,
                //     this.onDialogsDisplayUpdated,
                //     this
                // );
            },
            unmounted() {
                // bus.off(EVENTS.MESSAGE_RECEIVE, this.onMessageReceive);
                // bus.off(
                //     EVENTS.DIALOGS_DISPLAY_UPDATED,
                //     this.onDialogsDisplayUpdated
                // );
            },
            methods: {
                onDialogsDisplayUpdated({ dialogsDisplay } = {}) {
                    // const storage = this.$parent.getValuesStorage('messenger');
                    // this.listDisplay = dialogsDisplay;
                    this.listDisplay.length = 0;
                    dialogsDisplay = dialogsDisplay || this.getValuesStorage().dialogsDisplay
                    this.listDisplay.push(...dialogsDisplay)
                    this.$forceUpdate();
                },
                showDialog({ user_id, id } = {}) {
                    console.log("[ProfileInbox] showDialog(): {user_id, id}=", {
                        user_id,
                        id,
                    });

                    bus.emit(EVENTS.TRIGGER_PROFILE_OPEN_DIALOG, {
                        user_id,
                        id,
                    });
                    window.closeModal("#messenger-dialogs-list-modal");
                },
                // getMessageText(txt) {
                //     txt = txt
                //         .replaceAll("&quot;", '"')
                //         // .replaceAll("&amp;", '\\')
                //         .replaceAll("&amp;quot;", '"');
                //     let txtNew = "";
                //     let lastMatchIndex = 0;
                //     let hasTags = false;
                //     const tags = matchAllMessageTags(txt);
                //     for (const matchTag of tags) {
                //         // console.log(
                //         //     `[ProfileMessengerDialogComponent] getMessageText(): foun tag=`,
                //         //     matchTag
                //         // );
                //         matchTag.attrs.src = this.$static(matchTag.attrs.src);
                //         const htmlTag = tag2HtmlConverter(
                //             matchTag, 
                //             window.kup.initial_content.text_extra_collections,
                //             undefined,
                //             true,
                //         )
                //         // const htmlTag = converter.toHtml(matchTag);
                //         // console.log(
                //         //     `[ProfileMessengerDialogComponent] getMessageText(): htmlTag=`,
                //         //     htmlTag
                //         // );
                //         // lastMatchIndex + matchTag.rawTag.length
                //         txtNew += txt.substring(lastMatchIndex, matchTag.index);
                //         txtNew += htmlTag;
                //         lastMatchIndex =
                //             matchTag.index + matchTag.rawTag.length;
                //         hasTags = true;
                //         // console.log(
                //         //     `[ProfileMessengerDialogComponent] getMessageText(): txtNew=`,
                //         //     txtNew
                //         // );
                //     }

                //     if (hasTags) {
                //         txtNew += txt.substring(lastMatchIndex, txt.length);
                //         return txtNew;
                //     } else {
                //         // console.log(
                //         //     `[ProfileMessengerDialogComponent] getMessageText(): no tags found! tags=`,
                //         //     [...tags]
                //         // );
                //         // console.log(
                //         //     `[ProfileMessengerDialogComponent] getMessageText(): txt=`,
                //         //     txt
                //         // );
                //         // [[emj "src":"/img/text-extra/10/default_emoji/U0001F600.png"]] [[emj "src":"/img/text-extra/10/default_emoji/U0001F600.png", "slug":"U0001F600"]]
                //     }
                //     return txt
                // },
            },
            computed: {
                // dialogsListDisplay() {
                //     console.log("[ProfileMessengerDialogComponent] computed dialogsDisplay: service.dialogsDisplay=:", service.dialogsDisplay)
                //     return service.dialogsDisplay.value
                //     // console.log("[ProfileMessengerDialogComponent] computed dialogsDisplay: this.getValuesStorage().dialogsDisplay:", this.getValuesStorage().dialogsDisplay)
                //     // if (!this.getValuesStorage().dialogsDisplay) {
                //     //     return [];
                //     // }
                //     // return this.getValuesStorage().dialogsDisplay;
                // },
                // dialogs() {
                //     console.log("[ProfileMessengerDialogComponent] computed dialogsDisplay: this.getValuesStorage():", this.getValuesStorage())
                //     console.log("[ProfileMessengerDialogComponent] computed dialogs: this.getValuesStorage().dialogs:", this.getValuesStorage().dialogs)
                //     if (!this.getValuesStorage().dialogs) {
                //         return {};
                //     }
                //     return this.getValuesStorage().dialogs;
                // },
                // currentClientId() {
                //     console.log("[ProfileMessengerDialogComponent] computed dialogsDisplay: this.getValuesStorage().currentClientId:", this.getValuesStorage().currentClientId)
                //     return this.getValuesStorage().current_client_id;
                // },
            },
        };
    }
}
