export const EVENTS = {
    TRIGGER_INSERT_AT_CURSOR: 'trigger.insert_at_cursor'
}


export const TextExtraItemTypeEnum = {
    EMOJI: 10,
    STICKER: 20,
}

export const MessengerSelectorStyleEnum = {
    EMOJI: 10,
    IMAGE: 20,
}
