/**
 * Simple debounce function
 * @example
 * function saveInput(){
 *      console.log('Saving data');
 * }
 * const processChange = debounce(() => saveInput());
 */
export function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

let globalTempIdCount = 0;
let tempPrefix = '*temp-id=';
const id = {
    tempGenerator: (function*() {
        /**
         * Генератор временных id для новых объектов
         */
        while (true)
            yield tempPrefix + (globalTempIdCount++);
    })()
};

export { id }

/**
 * Чистит объект и вложенные объекты от пустыъ значений
 * @param {object} obj объект для очистки 
 * @returns 
 */
export function removeEmptys(obj) {
    var isArray = obj instanceof Array;
    for (var k in obj) {
        if (obj[k] === null) isArray ? obj.splice(k, 1) : delete obj[k];
        else if (typeof obj[k] == "object" || Array.isArray(obj[k])) removeEmptys(obj[k]);
        if (isArray && obj.length == k) removeEmptys(obj);
    }
    return obj;
}

/**
 * Возвращает  возрат по дню рождения
 * @param {Date} birthday день рождения
 * @param {Date} now момент времени, на который надо получить возраст, по умолчанию - берётся сейчас (текущий момент) 
 * @returns {Number} к-во лет возраста
 */
export function getAge(birthday, now=undefined){
    if (!now){ now = Date.now() }
    if (!(birthday instanceof Date)) {
        birthday = new Date(birthday)
    }
    const ageDifMs = now - birthday;
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

/**
 * Возвращает правильное окончание для чисел (последеней цифры числа)
 * @param {Number} number
 * @param {String} one
 * @param {String} two 
 * @param {String} five 
 * @method getNoun 
 */
export function getNoun(number, one, two, five) {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
        return five;
    }
    n %= 10;
    if (n === 1) {
        return one;
    }
    if (n >= 2 && n <= 4) {
        return two;
    }
    return five;
}

/**
 * Убирает пробелы в начале и конце текста + дублирующие пробелы в тексте
 * @param {*} str 
 * @returns 
 */
export function trimSpaces (str) {
    str = str.replace(/^\s+/, '');
    for (var i = str.length - 1; i >= 0; i--) {
        if (/\S/.test(str.charAt(i))) {
            str = str.substring(0, i + 1);
            break;
        }
    }
    return str;
}
