/**
 * Сервис, объединяющий в себе работу с бизнес-логикой на клиенте.
 * @module
 */
import * as messengerService from './MessengerService'
import * as historyService from './HistoryService'
import * as contentServices from '../content/index'

contentServices.mount()
historyService.mount()
messengerService.mount()


export const messenger = messengerService
export const history = historyService
export const content = contentServices

