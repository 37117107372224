export const EVENTS = {
    // Values storage
    TRIGGER_SCHEMA_STORAGE_SAVE: 'trigger.schemaStorage_save',
    TRIGGER_SCHEMA_STORAGE_PULL: 'trigger.schemaStorage_pull',
    TRIGGER_PROFILE_OPEN_DIALOG: 'trigger.profile_open_dialog',
    TRIGGER_MESSAGE_SEND: 'trigger.message_send',
    TRIGGER_INDICATE: 'trigger.indicate',
    SCHEMA_STORAGE_SAVED: 'schemaStorage.saved',
    SCHEMA_STORAGE_PULLED: 'schemaStorage.pulled',
    MESSAGE_RECEIVE: 'message.receive',
    DIALOGS_RECEIVE: 'dialogs.receive',
    DIALOGS_DISPLAY_UPDATED: 'dialogs_display.updated',
};

/**
 * Типы инпутов используемых в виджетах
 */
 export const INPUT_TYPE = {
    TEXT: 'text',
    NUMBER: 'number',
    SELECT: 'select',
    RANGE: 'range',
    OBJECT: 'object',
    FILE: 'file',
};

export const NEW_DIALOG_ID = "new";
export const MESSENGER_NO_AVATAR_SRC = "/icons/camera.png";  // "/img/avatarIcon.png",
export const MESSENGER_NO_NAME_PLACEHOLDER = "[аноним]";
export const MESSENGER_NO_AGE_PLACEHOLDER = "*";
export const MESSENGER_DIALOGS_DISPLAY_UPDATE_DEBOUNCE = 300;

export const PROFILE_NO_AVATAR_SRC = "/img/noPhotoFind.png";  // "/img/avatarIcon.png",
export const PROFILE_NO_NAME_PLACEHOLDER = "[аноним]";
export const PROFILE_NO_AGE_PLACEHOLDER = "*";
export const PROFILE_NO_LOCALITY_PLACEHOLDER = "*";

export const CURRENT_CLIENT_UPDATE_INTERVAL = 1 * 60 * 1000;

export const OnlineStatusEnum = {
    ONLINE: 10,
    JUST_NOW: 20,
    RECENTLY: 30,
    LONG_AGO: 40,
    // LONG_LONG_AGO = ..., "был(а) в сети дд.мм.гггг" 
};

export default { 
    EVENTS, 
    INPUT_TYPE,
}