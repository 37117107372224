import BaseCompositeComponent from "./BaseCompositeComponent";
import SchemaStorageCompositeMixin from "./SchemaStorageCompositeMixin";
import {
    WsDataTypeEnum,
    NotificationUserIdTypeEnum,
    MessageStatus,
} from "@/api/constants";
// import {api} from "@/services/MessengerService";
// import service from "@/services/MessengerService";
import * as service from "@/services/MessengerService";
import {MessengerFoundContactsError, MessengerError} from "@/exceptions";
import { bootstrap, modals, $ } from "@/globals";
import bus from "@/utils/eventBus";
import { trimSpaces, debounce } from "@/utils/index";
import { EVENTS, NEW_DIALOG_ID, MESSENGER_NO_NAME_PLACEHOLDER } from "@/constants";
import { OnlineStatusEnum } from '@/constants'

import {
    includesId,
    indexId,
    equalId,
    checkvisible,
    makeUserClientId,
    makeDialogClientId,
} from "@/utils/messenger";
import {
    reactive,
    watchEffect,
    ref,
    computed,
    watch,
    nextTick,
} from "vue/dist/vue.esm-bundler";
import { TextExtraItemTypeEnum } from "@/content/text_extra/constants";
import { content_params } from "@/content/gifts/bridge";

import {
    matchAllMessageTags,
    tag2HtmlConverter,
} from "@/content/text_extra/utils";

import { EVENTS as TEXT_EXTRA_EVENTS } from "@/content/text_extra/constants";

import MessengerTextExtraButton from "@/content/text_extra/components/MessengerTextExtraButton.vue";
import BaseTextExtra from "@/content/text_extra/components/BaseTextExtra.vue";

import MessengerMixin from "@/components/mixins/MessengerMixin";
import MessengerDialogMixin from "@/components/mixins/MessengerDialogMixin";
import { msgOpponent } from "@/services/messenger/utils"
// import { tag2HtmlConverter } from "../content/text_extra/utils";

/**
 * Компонент (композит из компонетов) представляющий профиль пользователя
 */

// const NEW_DIALOG_ID = "new";


export default class extends BaseCompositeComponent {
    constructor(config) {
        super({ ...config, schema: "messenger" });
        this.vueComponent = {
            mixins: [
                SchemaStorageCompositeMixin,
                MessengerMixin,
                MessengerDialogMixin,
            ],
            components: {
                MessengerTextExtraButton,
                BaseTextExtra,
                // 'txtextrabtn': MessengerTextExtraButton,
            },
            data() {
                return {
                    MessageStatus,
                    TextExtraItemTypeEnum,
                    meInfo: {},
                    ws: undefined,
                    reconnect: true,
                    reconnectInterval: undefined,
                    timeout: undefined,
                    timeoutMs: 1000,
                    textInput: "",
                    noAvatarSrc: "/icons/camera.png",
                    userNoName: MESSENGER_NO_NAME_PLACEHOLDER,
                    _currentClientId: undefined,
                    currentDialogId: undefined,
                    _onIncomingMessageBind: undefined,
                    dialogsFetched: false,
                    _markReadDebounce: 5,
                    _updateCurrentDialogDebounce: 350,
                    _historyQueueScrollOffset: 50,
                    _historyQueueScrollOffsetStepback: 60,
                    _selectionStart: undefined,
                    _selectionEnd: undefined,
                    lastUpdatedMessageId: undefined,
                    _lastUpdatedMessageDebounce: 300,
                    currentDialogMessagesDisplay: service.currentDialogMessagesDisplay,
                    te_collections:
                        window.kup.initial_content.text_extra_collections,
                    // te_collections_slugs: window.kup.text_extra_collections.map(c=>c.slug),
                };
            },
            created() {
                this.msgOpponent = msgOpponent
                this._onIncomingMessageBind = this.onIncomingMessage.bind(this);
                this.markReadCurrentDialogDebounced = debounce(
                    this.markReadCurrentDialogDebounced.bind(this),
                    this._markReadDebounce
                );
                this._updateCurrentDialogDebounced = debounce(
                    this._updateCurrentDialog.bind(this),
                    this._updateCurrentDialogDebounce
                );
                this.updateDialogsDisplayDebounced = debounce(
                    this.updateDialogsDisplay.bind(this),
                    this._lastUpdatedMessageDebounce
                );
                const watchcurrentDialog = watch(service.currentDialog, () => {
                    if (!service.currentDialog.value){
                        window.closeModal("#messenger-dialog-modal");
                    }
                });
                
            },
            unmounted() {
                bus.off(
                    EVENTS.TRIGGER_PROFILE_OPEN_DIALOG,
                    this.onTriggerProfileOpenDialog
                );
                bus.off(
                    TEXT_EXTRA_EVENTS.TRIGGER_INSERT_AT_CURSOR,
                    this.onTriggerInsertAtCursor
                );
                bus.off(EVENTS.TRIGGER_MESSAGE_SEND, this.onTriggerMessageSend);
                this._disconnectListeners();
            },
            onCaretMove(e) {
                this._selectionStart = e.target.selectionStart;
                this._selectionEnd = e.target.selectionEnd;
                console.log("Caret at: ", this._selectionStart);
            },

            mounted() {
                this._connectListeners();
                // $(this.compositeConfig.mountPoint).on('show.bs.modal', async function (e) {
                //     this.updateDialogsDisplay(true);
                // });
                // window._kupOpenMessengerDialog =
                //     this.onTriggerProfileOpenDialog;
                bus.on(
                    EVENTS.TRIGGER_PROFILE_OPEN_DIALOG,
                    this.onTriggerProfileOpenDialog,
                    this
                );
                bus.on(
                    EVENTS.TRIGGER_MESSAGE_SEND,
                    this.onTriggerMessageSend,
                    this
                );
                bus.on(
                    TEXT_EXTRA_EVENTS.TRIGGER_INSERT_AT_CURSOR,
                    this.onTriggerInsertAtCursor,
                    this
                );
                console.log(
                    "[ProfileMessengerComponent] mounted(): echo!, bus=",
                    bus
                );
                service.setMonitor(this);
                const watchCurrentDialogLastMessageDisplay = watch(service.currentDialogLastMessageDisplay, () => {
                    this.$nextTick(()=>{ 
                        // scroll after drawing added last element
                        this.scrollToLast()
                        this.markReadCurrentDialogDebounced();
                    })
                })
                // this.openWs();

                // this.reconnectInterval = setInterval(() => {
                //     const isReload = window.performance.getEntriesByType("navigation")[0].type == 'reload';
                //     if (!this.isWsOpen() && !isReload){
                //         this.openWs();
                //     }
                // }, 1000)
                
            },
            methods: {
                _getMessagesWrapperEl() {
                    return document.querySelector(".dialog-messagesWrapper");
                },
                _connectListeners() {
                    this._getMessagesWrapperEl().addEventListener(
                        "scroll",
                        this._onScrollMessagesWrapperEl
                    );
                    this.getMessageTextInputEl().addEventListener(
                        "keyup",
                        (e) => this.onCaretMove(e)
                    );
                    this.getMessageTextInputEl().addEventListener(
                        "click",
                        (e) => this.onCaretMove(e)
                    );
                },
                _disconnectListeners() {
                    this._getMessagesWrapperEl().removeEventListener(
                        "scroll",
                        this._onScrollMessagesWrapperEl
                    );
                },
                _updateCurrentDialog() {
                    const el = this._getMessagesWrapperEl();
                    // console.log(
                    //     "[ProfileMessengerComponent] _updateCurrentDialog(): el=",
                    //     el
                    // );
                    // console.log(
                    //     "[ProfileMessengerComponent] _updateCurrentDialog(): el.scrollTop=",
                    //     el.scrollTop
                    // );
                    // console.log(
                    //     "[ProfileMessengerComponent] _updateCurrentDialog(): this._historyQueueScrollOffset=",
                    //     this._historyQueueScrollOffset
                    // );
                    // console.log(
                    //     "[ProfileMessengerComponent] _updateCurrentDialog(): this._getMessagesWrapperEl().scrollTop < this._historyQueueScrollOffset=",
                    //     this._getMessagesWrapperEl().scrollTop <
                    //         this._historyQueueScrollOffset
                    // );

                    if (
                        el.clientHeight != 0 &&
                        el.scrollTop < this._historyQueueScrollOffset
                    ) {
                        console.log(
                            "[ProfileMessengerComponent] _updateCurrentDialog(): el.scrollTop=",
                            el.scrollTop
                        );
                        el.scrollTop = this._historyQueueScrollOffsetStepback 
                        service.updateDialogHistory(service.currentDialogId.value);
                    }
                },
                _onScrollMessagesWrapperEl() {
                    // const messagesWrapperEl
                    this.markReadCurrentDialogDebounced();
                    this._updateCurrentDialogDebounced();
                },
                isWsOpen() {
                    return (
                        this.ws &&
                        (this.ws.readyState == WebSocket.OPEN ||
                            this.ws.readyState == WebSocket.CONNECTING)
                    );
                },
                openWs() {
                    // const connector = getConnector();
                    // // console.log("[ProfileMessengerComponent] openWs(): connector=", connector)
                    // connector.addEventListener("message", this._onIncomingMessageBind)
                    // const connectionPromise = connector.getConnection();
                    // // console.log("[ProfileMessengerComponent] openWs(): connectionPromise=", connectionPromise)
                    // return connectionPromise.then((ws) => {
                    //     // ws.addEventListener(
                    //     //     "message",
                    //     //     this._onIncomingMessageBind
                    //     // );
                    //     this.ws = ws;
                    //     // console.debug(
                    //     //     "[ProfileMessengerComponent] openWs(): connecting started. Websocket: ",
                    //     //     this.ws
                    //     // );
                    //     return ws;
                    // });
                },
                setWsOpened() {
                    // if (!this.isWsOpen()) {
                    //     this.openWs();
                    // }
                },
                
                setText(text) {
                    this.textInput = text;
                },
                // getMember(clientId) {
                //     for (const m of this.members) {
                //         if (equalId({ id1: m.client_id, id2: clientId })) {
                //             return m;
                //         }
                //     }
                //     return undefined;
                // },
                // setMember(clientId, member) {
                //     this.members = this.members.filter(
                //         (m) => !equalId({ id1: m.client_id, id2: clientId })
                //     );
                //     this.members.push(member);
                // },
                // getMemberIds() {
                //     const ids = [];
                //     for (const m of this.members) {
                //         ids.push(m.client_id);
                //     }
                //     return ids;
                // },
                _processMessageBeforeAppend(message) {
                    let txt = message.data.message
                        .replaceAll("&quot;", '"')
                        // .replaceAll("&amp;", '\\')
                        .replaceAll("&amp;quot;", '"');
                    let txtNew = "";
                    let lastMatchIndex = 0;
                    let hasTags = false;
                    const tags = matchAllMessageTags(txt);
                    for (const matchTag of tags) {
                        // console.log(
                        //     `[ProfileMessengerComponent] _processMessageBeforeAppend(): foun tag=`,
                        //     matchTag
                        // );
                        // const converter = tag2HtmlConverters[matchTag.name];
                        // if (!converter) {
                        //     console.warn(
                        //         `[ProfileMessengerComponent] _processMessageBeforeAppend(): converter with name=${matchTag.name} not exists`,
                        //         message.to_id
                        //     );
                        //     continue;
                        // }
                        // console.log(
                        //     `[ProfileMessengerComponent] _processMessageBeforeAppend(): converter=`,
                        //     converter
                        // );
                        matchTag.attrs.src = this.$static(matchTag.attrs.src);
                        const htmlTag = tag2HtmlConverter(
                            matchTag,
                            window.kup.initial_content.text_extra_collections,
                            (src) => this.$static("/" + src)
                        );
                        // const htmlTag = converter.toHtml(matchTag);
                        // console.log(
                        //     `[ProfileMessengerComponent] _processMessageBeforeAppend(): htmlTag=`,
                        //     htmlTag
                        // );
                        // lastMatchIndex + matchTag.rawTag.length
                        txtNew += txt.substring(lastMatchIndex, matchTag.index);
                        txtNew += htmlTag;
                        lastMatchIndex =
                            matchTag.index + matchTag.rawTag.length;
                        hasTags = true;
                        // console.log(
                        //     `[ProfileMessengerComponent] _processMessageBeforeAppend(): txtNew=`,
                        //     txtNew
                        // );
                    }

                    if (hasTags) {
                        txtNew += txt.substring(lastMatchIndex, txt.length);
                        message.data.messageHtml = txtNew;
                    } else {
                        // console.log(
                        //     `[ProfileMessengerComponent] _processMessageBeforeAppend(): no tags found! tags=`,
                        //     [...tags]
                        // );
                        // console.log(
                        //     `[ProfileMessengerComponent] _processMessageBeforeAppend(): txt=`,
                        //     txt
                        // );
                        // [[emj "src":"/img/text-extra/10/default_emoji/U0001F600.png"]] [[emj "src":"/img/text-extra/10/default_emoji/U0001F600.png", "slug":"U0001F600"]]
                    }
                },
                appendMessage(
                    message,
                    emitMsgReceiveEvent = true,
                    dialogId = undefined,
                    history = false
                ) {
                    if ([
                        WsDataTypeEnum.USER_CONNECTED, 
                        WsDataTypeEnum.USER_DISCONNECTED
                    ].includes(message.type)
                    ) {
                        return;
                    }
                    dialogId = dialogId || message.to_id[1];
                    if (!message.to_id && !!dialogId) {
                        message.to_id = makeDialogClientId(dialogId);
                    }
                    let dialog = this.dialogs[dialogId];
                    if (!dialog) {
                        console.debug(
                            "[ProfileMessengerComponent] appendMessage(): dialogId=",
                            dialogId
                        );
                        this._presetDialog({ id: dialogId });
                        dialog = this.dialogs[dialogId];
                        if (message.data.clients_info) {
                            for (const info of message.data.clients_info) {
                                this.setMember(info.client_id, info);
                                dialog.members_ids = dialog.members_ids.filter(
                                    (id) =>
                                        !equalId({
                                            id1: id,
                                            id2: info.client_id,
                                        })
                                );
                                dialog.members_ids.push(info.client_id);
                            }
                        }
                        if (this.currentDialogId == NEW_DIALOG_ID) {
                            this.currentDialogId = dialogId;
                        }
                    }
                    if (
                        ![
                            WsDataTypeEnum.MESSAGE_SENT,
                            WsDataTypeEnum.HISTORY,
                        ].includes(message.type)
                    ) {
                        return;
                    }

                    if (
                        !message.to_id ||
                        message.to_id[0] != NotificationUserIdTypeEnum.DIALOG_ID
                    ) {
                        console.error(
                            "[ProfileMessengerComponent] appendMessage(): unknown message.to_id type. to_id=",
                            message.to_id
                        );
                    }
                    message.isMe = equalId({
                        id1: message.from_id,
                        id2: this.getCurrentClientId(),
                    });

                    this._processMessageBeforeAppend(message);

                    if (!dialog.messages) dialog.messages = [];
                    if (history) {
                        dialog.messages.unshift(message);
                    } else {
                        dialog.messages.push(message);
                    }

                    this.lastUpdatedMessageId = message.data.message_id

                    if (this.currentDialogId == NEW_DIALOG_ID) {
                        const dialog_ids = this._dialogIdMatchUsersIds(
                            dialog.members_ids
                        );
                        if (Object.keys(this.dialogs).length > 1) {
                            if (dialog_ids.length > 0) {
                                this.currentDialogId = dialog_ids[0];
                                delete this.dialogs[NEW_DIALOG_ID];
                            }
                        }
                    }

                    return message

                    if (emitMsgReceiveEvent) {
                        // bus.emit(EVENTS.MESSAGE_RECEIVE, { message });
                        this._onMsgReceiveEvent({ dialogId, message });
                    }

                    // console.log(
                    //     "[ProfileMessengerComponent] appendMessage(): added message to dialog with id=",
                    //     message.to_id[1]
                    // );
                    // console.debug(
                    //     "[ProfileMessengerComponent] appendMessage(): dialogs=",
                    //     this.dialogs
                    // );
                    // this.messages.push(message)
                    // TODO: optimize messages count (messagesCount)

                    // this.messages = messages;
                    // console.log("this.messages=", this.messages);
                },
                // _onMsgReceiveEvent({ dialogId, message } = {}) {
                //     console.log(
                //         "[ProfileMessengerComponent] _onMsgReceiveEvent(): ",
                //         { dialogId, message }
                //     );
                //     if (dialogId == this.currentDialogId) {
                //         this.$nextTick(() => {
                //             console.log(
                //                 "[ProfileMessengerComponent] _onMsgReceiveEvent(): dialogId == this.currentDialogId =",
                //                 dialogId == this.currentDialogId
                //             );
                //             this.scrollToLast();
                //             this.markReadCurrentDialog();
                //             // this.markReadCurrentDialogDebounced();
                //         });
                //     }
                // },

                onNewMessagesAppended({ dialogId, messages } = {}) {
                    // console.log(
                    //     "[ProfileMessengerComponent] _onMsgReceiveEvent(): ",
                    //     { dialogId, message }
                    // );
                    if (dialogId == this.currentDialogId) {
                        this.$nextTick(() => {
                            console.log(
                                "[ProfileMessengerComponent] onNewMessagesAppended(): dialogId == this.currentDialogId =",
                                dialogId == this.currentDialogId
                            );
                            this.scrollToLast();
                            this.markReadCurrentDialog();
                            // this.markReadCurrentDialogDebounced();
                        });
                    }
                },
                appendHistory(response){
                    const messages = response.data.messages || [];
                    if (response.data.members) {
                        // preprocess dialogs data
                        for (const m of response.data.members || []) {
                            this.setMember(m.client_id, m);
                        }

                        // this.members = response.data.members;
                        // window._kup_members = this.members; // for debug
                        // bus.emit(EVENTS.DIALOGS_RECEIVE, {dialogs: this.dialogs});
                    }

                    if (response.data.dialogs) {
                        // preprocess dialogs data
                        for (const d_id in response.data.dialogs) {
                            // find index of current user id in members_ids
                            const idx = indexId({
                                id: this.getCurrentClientId(),
                                arr: response.data.dialogs[d_id]
                                    .members_ids,
                                typed: false,
                            });
                            if (idx !== -1) {
                                response.data.dialogs[
                                    d_id
                                ].members_ids.splice(idx, 1);
                            }
                        }
                        this.dialogs = response.data.dialogs;
                        bus.emit(EVENTS.DIALOGS_RECEIVE, {
                            dialogs: this.dialogs,
                        });
                        // this.updateDialogsDisplay();
                    }

                    const messages2append = Array.from(messages);
                    const lastMessage2append = messages2append.pop();

                    if (response.data.messages) {
                        // is messages send in history
                        const dialog = response.data.messages_dialog_id
                            ? this.dialogs[
                                    response.data.messages_dialog_id
                                ]
                            : undefined;
                        if (dialog) {
                            dialog.historyPending = false;
                            dialog.unread_messages_count = response.data.unread_messages_count;
                        }
                    }



                    for (const message of messages2append) {
                        // console.log(
                        //     "[ProfileMessengerComponent] onIncomingMessage(): history message =",
                        //     message
                        // );
                        this.appendMessage(
                            message,
                            false,
                            response.data.messages_dialog_id,
                            true
                        );
                        // this.scrollToLast();
                    }
                    if (lastMessage2append) {
                        this.appendMessage(
                            lastMessage2append,
                            true,
                            response.data.messages_dialog_id,
                            true
                        );
                    }
                    if (messages.length > 0) {
                        // bus.emit(EVENTS.MESSAGE_RECEIVE, { messages });
                    }
                },
                setTimeoutFunc(timeout) {
                    this.timeout = timeout;
                },
                getDialog(id) {
                    this.dialogs[id];
                },
                onIncomingMessage(wsEvent) {
                    // listen to data sent from the websocket server
                    const response = JSON.parse(wsEvent.data);
                    if (!response.data) response.data = {};
                    let dialog;
                    console.log(
                        "[ProfileMessengerComponent] onIncomingMessage(): responce=",
                        response
                    );
                    switch (response.type) {
                        case WsDataTypeEnum.USER_CONNECTED:
                        case WsDataTypeEnum.USER_DISCONNECTED:
                            console.debug("[ProfileMessengerComponent] onIncomingMessage(): response.type=", response.type)
                            break
                        case WsDataTypeEnum.MESSAGE_SENT:
                            let appendedMessage = this.appendMessage(response);
                            this.onNewMessagesAppended({ dialogId:appendedMessage.to_id[1] })
                            this.updateDialogsDisplay();
                            // if (response.to_id[1] == this.currentDialogId) {
                            //     if (
                            //         !(
                            //             response.type ==
                            //                 WsDataTypeEnum.HISTORY &&
                            //             !!response.data.messages &&
                            //             !!response.data.messages.length
                            //         )
                            //     ) {
                            //         this.scrollToLast();
                            //     }
                            //     this.markReadCurrentDialogDebounced();
                            // }
                            break;
                        case WsDataTypeEnum.READ:
                            response.data.messages_ids =
                                response.data.messages_ids || [];
                            const readMessages = this.messages.filter(
                                (m) =>
                                    response.data.messages_ids.includes(
                                        m.data.message_id
                                    ) && m.to_id[1] == this.currentDialogId
                            );
                            for (let m of readMessages) {
                                m.data.status = MessageStatus.READ;
                                this.lastUpdatedMessageId = m.data.message_id
                            }
                            console.log(
                                "[ProfileMessengerComponent] onIncomingMessage(): response.data.messages_ids=",
                                response.data.messages_ids
                            );
                            console.log(
                                "[ProfileMessengerComponent] onIncomingMessage(): readMessages=",
                                readMessages
                            );
                            if (readMessages.length > 0) {
                                this.updateDialogsDisplay(true);
                            }
                            // this.updateDialogsDisplay(true);
                            break;
                        case WsDataTypeEnum.HISTORY:
                            console.log(
                                "[ProfileMessengerComponent] onIncomingMessage(): processing HISTORY"
                            );
                            this.appendHistory(response)
                            if (
                                response.data.dialogs ||
                                response.data.messages
                            ) {
                                this.updateDialogsDisplay();
                                // this.scrollToLast();
                            }
                            // dialog;
                            break;
                        case WsDataTypeEnum.USER_TYPING:
                            if (
                                response.to_id[0] !=
                                NotificationUserIdTypeEnum.DIALOG_ID
                            ) {
                                console.warn(
                                    "[ProfileMessengerComponent] onIncomingMessage(): response.to_id type is not match dialog id"
                                );
                            }
                            this._presetDialog({ id: response.to_id[1] });
                            dialog = this.dialogs[response.to_id[1]];
                            if (
                                !dialog.typing.includes(response.from_id) &&
                                response.from_id[1] !== this.currentClientId
                            ) {
                                dialog.typing = [
                                    ...dialog.typing,
                                    response.from_id,
                                ];
                            }
                            break;
                        case WsDataTypeEnum.USER_STOPPED_TYPING:
                            if (
                                response.to_id[0] !=
                                NotificationUserIdTypeEnum.DIALOG_ID
                            ) {
                                console.warn(
                                    "[ProfileMessengerComponent] onIncomingMessage(): response.to_id type is not match dialog id"
                                );
                            }
                            this._presetDialog({ id: response.to_id[1] });
                            dialog = this.dialogs[response.to_id[1]];
                            if (dialog.typing.includes(response.from_id)) {
                                dialog.typing = dialog.typing.filter(
                                    (from_id) => from_id !== response.from_id
                                );
                            }
                            break;
                        default:
                            break;
                    }
                    // scrollToBottom();
                    console.log(response);
                },
                onChange(e) {
                    clearTimeout(this.timeout);
                    const _this = this;
                    if (this.ws) {
                        // this.waitForSocketConnection(this.ws, startTyping.bind(this, {
                        //     ws: this.ws,
                        //     to_id:this._getToIdSend(),
                        //     from_id: this.currentClientId,
                        // }))
                        // // disabled "Typing" function
                        // startTyping({
                        //     ws: this.ws,
                        //     to_id:this._getToIdSend(),
                        //     from_id: this.currentClientId,
                        // });
                        // this.setTimeoutFunc(
                        //     setTimeout(() => {
                        //         // _this.setWsOpened()
                        //         // this.waitForSocketConnection(this.ws, stopTyping.bind(this, {
                        //         //     ws: this.ws,
                        //         //     to_id:this._getToIdSend(),
                        //         //     from_id: this.currentClientId,
                        //         //     message:this.textInput,
                        //         // }))
                        //         // // disabled "Typing" function
                        //         // stopTyping({
                        //         //     ws: this.ws,
                        //         //     to_id:this._getToIdSend(),
                        //         //     from_id: this.currentClientId,
                        //         //     message:this.textInput,
                        //         // });
                        //     }, this.timeoutMs)
                        // );
                    }

                    this.setText(e.currentTarget.value);
                },
                sendMessage(txt) {
                    try {
                        this.setWsOpened();
                        if (this.ws && txt != "") {
                            // this.waitForSocketConnection(this.ws, sendMessage.bind(this, {
                            //     ws: this.ws,
                            //     to_id: this._getToIdSend(),
                            //     from_id: this.currentClientId,
                            //     message:this.textInput,
                            // }))
                            sendMessage({
                                ws: this.ws,
                                to_id: this._getToIdSend(),
                                from_id: this.getCurrentClientId(),
                                message: txt,
                            });
                            this.setWsOpened();
                            // this.waitForSocketConnection(this.ws, stopTyping.bind(this, {
                            //     ws: this.ws,
                            //     to_id:this._getToIdSend(),
                            //     from_id: this.currentClientId,
                            // }))

                            // // disabled "Typing" function
                            // stopTyping({
                            //     ws: this.ws,
                            //     to_id: this._getToIdSend(),
                            //     from_id: this.currentClientId,
                            // });
                        }
                    } catch (error) {
                        console.error(
                            "[ProfileMessengerComponent] onSubmit(): error=",
                            error
                        ); // catch error
                    }
                },
                onSubmit() {
                    const txt = this._getMessageTextClear();
                    let cleanText = true;
                    try{
                        service.sendMessageText(txt);
                    } catch (error) {
                        console.error("[MessengerService] onSubmit(): can't send message, error=", error); // catch error
                        if (error instanceof MessengerFoundContactsError){
                            cleanText = false;
                            modals.dialog.showHint("Сообщение не отправлено. " + error.message)
                        } else {
                            modals.dialog.showHint("Ошибка при отправке. " + error.message)
                        }
                    }
                    if(cleanText) this.setText("");
                },
                handleKeyPress(event) {
                    if (event.key === "Enter") {
                        this.onSubmit();
                    }
                },
                _getMessageTextClear() {
                    let text = trimSpaces(this.textInput);
                    return text;
                },
                
                onTriggerMessageSend({ text } = {}) {
                    this.sendMessage(text);
                },
                onTriggerInsertAtCursor({ text } = {}) {
                    this.insertTextInInput(text);
                },
                onTriggerProfileOpenDialog({ user_id, id } = {}) {
                    service.setCurrentDialog({user_id, id})

                    // const dlg = this.dialogs[id]
                    // if (!dlg.historyPending){
                    //     const time_from = (dlg.messages && dlg.messages.length > 0) ? dlg.messages.created : undefined
                    //     api.queueHistory({
                    //         ws: this.ws,
                    //         to_id: makeDialogClientId(id),
                    //         time_from,
                    //     })
                    //     dlg.historyPending = true
                    // }

                    if (id != NEW_DIALOG_ID) {
                        // if (!this.messages || !this.messages.length) {
                        //     this._updateCurrentDialog();
                        // }
                        setTimeout(() => {
                            this.$nextTick(() => {
                                //     // console.log(
                                //     //     "[ProfileMessengerComponent] _onMsgReceiveEvent(): dialogId == this.currentDialogId =",
                                //     //     dialogId == this.currentDialogId
                                //     // );
                                this.scrollToLast();
                                this.markReadCurrentDialogDebounced();
                            });
                            // this.scrollToLast();
                            // this.markReadCurrentDialogDebounced();
                        }, 300);
                    }

                    this.showModal();
                    // }
                    // if (
                    //     !this.currentDialog.historyPending &&
                    //     this.currentDialogId != NEW_DIALOG_ID
                    // ) {
                    //     this.updateDialogHistory(this.currentDialogId)
                    //     // api.queueHistory({
                    //     //     ws: this.ws,
                    //     //     to_id: [
                    //     //         NotificationUserIdTypeEnum.DIALOG_ID,
                    //     //         this.currentDialogId,
                    //     //     ],
                    //     // });
                    // setTimeout(()=>{
                    //     // this.$nextTick(() => {
                    //     //     // console.log(
                    //     //     //     "[ProfileMessengerComponent] _onMsgReceiveEvent(): dialogId == this.currentDialogId =",
                    //     //     //     dialogId == this.currentDialogId
                    //     //     // );
                    //     //     this.scrollToLast();
                    //     //     this.markReadCurrentDialogDebounced();
                    //     // });
                    //     // this.markReadCurrentDialogDebounced();
                    // }, 100)
                    // }
                },
                _createNewDialog({ id }) {
                    this.dialogs[id] = {
                        members_ids: [],
                        typing: [],
                        messages: [],
                        historyPending: false,
                    };
                },
                _dialogIdMatchUsersIds(members_ids) {
                    const matched = [];
                    for (const d in this.dialogs) {
                        let match = true;
                        for (const clId of this.dialogs[d].members_ids) {
                            if (members_ids.includes(clId[1])) {
                                // continue   // TODO: remove this!!!
                                break;
                            }
                            match = false;
                            break;
                        }
                        if (match) {
                            matched.push(d);
                        }
                    }
                    return matched;
                },
                _isDialogExists(id) {
                    return id in this.dialogs;
                },
                _presetDialog({ id }) {
                    if (!this._isDialogExists(id)) {
                        this._createNewDialog({ id });
                    }
                },
                _presetCurrentDialogId() {
                    const dialog_ids = Object.keys(this.dialogs);
                    this.currentDialogId = !!dialog_ids.length
                        ? dialog_ids[0]
                        : NEW_DIALOG_ID;
                },
                _presetCurrentDialog() {
                    this._presetDialog({ id: this.currentDialogId });
                },
                getOrCreateDialogIdWithUser(user_id) {
                    const clientId = makeUserClientId(user_id);
                    let dialog_id;
                    for (const d_id in this.dialogs) {
                        if (
                            includesId({
                                arr: this.dialogs[d_id].members_ids,
                                id: clientId,
                            })
                        ) {
                            dialog_id = d_id;
                            break;
                        }
                    }
                    if (dialog_id) return dialog_id;

                    this._presetDialog({ id: NEW_DIALOG_ID });
                    this.dialogs[NEW_DIALOG_ID].members_ids = [clientId];
                    return NEW_DIALOG_ID;
                },
                _getToIdSend() {
                    // console.log('[ProfileMessengerComponent] _getToIdSend(): this=', this)
                    // console.log('[ProfileMessengerComponent] _getToIdSend(): this.currentDialog=', this.currentDialog)
                    // console.log('[ProfileMessengerComponent] _getToIdSend(): this.currentDialogId=', this.currentDialogId)
                    // console.log('[ProfileMessengerComponent] _getToIdSend(): this.dialogs[this.currentDialogId]=', this.dialogs[this.currentDialogId])
                    // console.log('[ProfileMessengerComponent] _getToIdSend(): this.dialogs[this.currentDialogId].users=', this.dialogs[this.currentDialogId].users)
                    if (this.currentDialogId == NEW_DIALOG_ID) {
                        // TODO: improve this!
                        return this.currentDialog.members_ids.filter(
                            (mId) =>
                                !equalId({
                                    id1: mId,
                                    id2: this.getCurrentClientId(),
                                })
                        )[0];
                    } else {
                        return makeDialogClientId(this.currentDialogId);
                    }
                },
                updateDialogsDisplay(force=false) {
                    console.log(
                        "[ProfileMessengerComponent] updateDialogsDisplay(): messenger dialogs=",
                        this.dialogs
                    );
                    console.log(
                        "[ProfileMessengerComponent] updateDialogsDisplay(): messenger members=",
                        this.members
                    );
                    if (
                        this.dialogsDisplay &&
                        this.dialogsDisplay.length ==
                            Object.keys(this.dialogs).length
                    ) {
                        for (const dd of this.dialogsDisplay) {
                            const d = this.dialogs[dd.dialog_id];
                            const messagesCount = d.messages
                                ? d.messages.length
                                : 0;
                            const displayMessagesCount = dd.messagesCount;
                            if ((messagesCount === displayMessagesCount) && !force) {
                                continue;
                            }
                            const hasMessages = messagesCount > 0;
                            const lastMessage = hasMessages
                                ? d.messages[messagesCount - 1]
                                : d.last_message;
                            
                            let unreadMessagesCount = this.getUnreadDialogMessagesCount(
                                dd.dialog_id
                            );
                            if (!hasMessages){
                                // counting unread accepted messages if history not loaded yet (no preloaded
                                unreadMessagesCount += d.unread_messages_count
                            }
                            Object.assign(dd, {
                                ...lastMessage,
                                messagesCount,
                                unreadMessagesCount,
                            });
                        }
                    } else {
                        const dialogsDisplay = [];
                        for (const dialog_id in this.dialogs) {
                            const d = this.dialogs[dialog_id];
                            const messagesCount = d.messages
                                ? d.messages.length
                                : 0;
                            const hasMessages = messagesCount > 0;
                            const lastMessage = hasMessages
                                ? d.messages[messagesCount - 1]
                                : d.last_message;

                            let unreadMessagesCount = d.unread_messages_count;
                            if (hasMessages) {
                                unreadMessagesCount =
                                    this.getUnreadDialogMessagesCount(
                                        dialog_id
                                    );
                            } 
                            if (!!lastMessage)
                                dialogsDisplay.push({
                                    ...lastMessage,
                                    dialog_id,
                                    messagesCount: hasMessages ? messagesCount : undefined,
                                    unreadMessagesCount,
                                });
                        }
                        this.dialogsDisplay.length = 0
                        this.dialogsDisplay.push(...dialogsDisplay);
                    }

                    console.log(
                        "[ProfileMessengerComponent] updateDialogsDisplay() this.dialogsDisplay=",
                        this.dialogsDisplay
                    );
                    // bus.emit(EVENTS.TRIGGER_INDICATE, {
                    //     name: "unreadMessagesCount",
                    //     value: this.getAllUnreadMessagesCount(),
                    // });
                    bus.emit(EVENTS.DIALOGS_DISPLAY_UPDATED, {
                        dialogsDisplay: this.dialogsDisplay,
                    });
                },
                markReadCurrentDialog() {
                    const visibleMessages = this.getVisibleMessages();
                    service.markRead(visibleMessages)
                    // const markedAsRead = [];
                    // for (const m of visibleMessages) {
                    //     if (
                    //         m.data.status != MessageStatus.READ &&
                    //         !equalId({
                    //             id1: m.from_id,
                    //             id2: this.getCurrentClientId(),
                    //         })
                    //     ) {
                    //         m.data.status = MessageStatus.READ;
                    //         markedAsRead.push(m.data.message_id);
                    //     }
                    // }
                    // const messagesEls = [
                    //     ...document.querySelectorAll(
                    //         ".dialog-messagesWrapper>*"
                    //     ),
                    // ];
                    // console.log(
                    //     "[ProfileMessengerComponent] markReadCurrentDialog(): messagesEls=",
                    //     messagesEls
                    // );
                    // console.log(
                    //     "[ProfileMessengerComponent] markReadCurrentDialog(): this.messages[]=",
                    //     this.messages
                    // );
                    // console.log(
                    //     "[ProfileMessengerComponent] markReadCurrentDialog(): markedAsRead=",
                    //     markedAsRead
                    // );
                    // console.log(
                    //     "[ProfileMessengerComponent] markReadCurrentDialog(): visibleMessages=",
                    //     visibleMessages
                    // );
                    // if (markedAsRead.length > 0) {
                    //     markRead({
                    //         ws: this.ws,
                    //         messages_ids: markedAsRead,
                    //         to_id: this._getToIdSend(),
                    //     }).then(()=> {
                    //         for(const msg of this.dialogs[this.currentDialogId].messages){
                    //             if (markedAsRead.includes(msg.data.message_id)){
                    //                 msg.data.status=MessageStatus.READ
                    //             }
                    //         }
                    //         this.updateDialogsDisplay(true);
                    //     });
                    //     this.updateDialogsDisplay(true) // todo: fix this
                    //     bus.emit(EVENTS.TRIGGER_INDICATE, {
                    //         name: "unreadMessagesCount",
                    //         value: this.getAllUnreadMessagesCount(),
                    //     });
                    // }else {
                    //     this.updateDialogsDisplay();
                    // }
                    
                },
                markReadCurrentDialogDebounced() {
                    return this.markReadCurrentDialog();
                },
                updateDialogHistory(dialogId) {
                    const visibleEls = this.getVisibleMessagesEls()

                    const dialog = this.dialogs[dialogId];
                    if (!dialog) {
                        throw ReferenceError(
                            `No dialog with id ${dialogId} on client`
                        );
                    }
                    if (dialog.historyPending !== undefined) {
                        return false;
                    }
                    if (!dialog.messages || !dialog.messages.length) {
                        // no messages at all
                        // newDialog.historyPending
                        console.warn(
                            `[ProfileMessengerComponent] updateDialogHistory(): requesting history of dialogId=${dialogId} without "time_from" param`
                        );

                        // api.queueHistory({
                        //     ws: this.ws,
                        //     to_id: [
                        //         NotificationUserIdTypeEnum.DIALOG_ID,
                        //         dialogId,
                        //     ],
                        //     dialog_single: true,
                        // });
                        dialog.historyPending = true;
                        return true;
                    }

                    let time_from = undefined;
                    if (
                        this.currentDialogId == dialogId &&
                        this.currentDialogId
                    ) {
                        const visibleEls = this.getVisibleMessagesEls();
                        if (!visibleEls || !visibleEls.length) {
                            console.warn(
                                `[ProfileMessengerComponent] updateDialogHistory(): no visible elements found, but messages in dialog exists`
                            );
                        }

                        const isStartVisible =
                            visibleEls.filter((i) => i[0] == 0).length != 0;
                        // const isVisibleAll =
                        // visibleEls.length == dialog.messages.length;
                        // if (isStartVisible && isVisibleAll) {
                        if (isStartVisible) {
                            time_from = dialog.messages[0].data.created;
                        }
                    } else {
                        // time_from = dialog.messages[0].data.created
                    }

                    if (
                        !!time_from
                        // && !dialog.messages
                        // && dialog.messages.length == 0
                    ) {
                        console.warn(
                            `[ProfileMessengerComponent] updateDialogHistory(): requesting history of dialogId=${dialogId} with "time_from"=${time_from}`
                        );
                        // api.queueHistory({
                        //     ws: this.ws,
                        //     to_id: [
                        //         NotificationUserIdTypeEnum.DIALOG_ID,
                        //         dialogId,
                        //     ],
                        //     dialog_single: true,
                        //     time_from,
                        // });
                        dialog.historyPending = true;
                        return true;
                    } else {
                        if (dialog.historyPending === undefined){
                            // api.queueHistory({
                            //     ws: this.ws,
                            //     to_id: [
                            //         NotificationUserIdTypeEnum.DIALOG_ID,
                            //         dialogId,
                            //     ],
                            //     dialog_single: true,
                            //     time_from: dialog.last_message.data.created,
                            // });
                            dialog.historyPending = true;
                            return true;
                        }
                        console.warn(
                            `[ProfileMessengerComponent] updateDialogHistory(): skipping request history of dialogId=${dialogId} with "time_from"=${time_from}`
                        );
                        console.debug(
                            `[ProfileMessengerComponent] updateDialogHistory(): dialog=`,
                            dialog
                        );
                    }
                    return false;
                },
                getCurrentClientId() {
                    return this.getValuesStorage().current_client_id;
                },
                getMessagesDisplay() {
                    if (
                        !!this.currentDialogId &&
                        this.currentDialogId != NEW_DIALOG_ID
                    )
                        return this.dialogs[this.currentDialogId].messages;
                    return [];
                },
                onClickMakeGift() {
                    content_params.to_user_id = this.toUserId
                },
            },
            computed: {
                currentOpponent(){
                    if (service.currentDialogId.value){
                        return service.getOpponent(
                            service.currentDialogId.value, 
                            service.currentClientId.value,
                        )
                    }
                    return {}
                },
                toUserId(){
                    const op = this.currentOpponent
                    
                    if (op && op.client_id) {
                        if (op.client_id[1] != 20){
                            console.warn("[ProfileMessengerComponent] toUserId computed: bad client type this.currentOpponent=", op)
                        }
                        return op.client_id[1]
                    }
                    return undefined
                },
                currentOpponentOnline(){
                    if (!service.membersChangesCounter.value){
                        // console.log()
                    }
                    let op = undefined;
                    if (service.currentDialogId.value){
                        op = service.getOpponent(
                            service.currentDialogId.value, 
                            service.currentClientId.value,
                        )
                    }
                    if (op) { return op.online }
                    return undefined
                },
                curOpponentIsOnline(){ return (
                    !!service.currentOpponentOnline.value 
                    && service.currentOpponentOnline.value[0] == OnlineStatusEnum.ONLINE
                )},
                curOpponentOnlineStr(){ 
                    if (!service.currentOpponentOnline.value)
                        return "оффлайн"
                    return service.currentOpponentOnline.value[1] || "оффлайн"
                },
                dialogsDisplay: {
                    get() {
                        const storage = this.getValuesStorage();
                        if (!storage.dialogsDisplay) {
                            storage.dialogsDisplay = [];
                            // this.updateDialogsDisplay();
                        }
                        return storage.dialogsDisplay;
                    },
                    set(val) {
                        const storage = this.getValuesStorage();
                        if (storage.dialogsDisplay) {
                            storage.dialogsDisplay.length = 0;
                            storage.dialogsDisplay.push(...val);
                        } else {
                            storage.dialogsDisplay = val;
                        }
                    },
                },
                dialogs: {
                    get() {
                        const storage = this.getValuesStorage();
                        if (!storage.dialogs) {
                            storage.dialogs = {};
                        }
                        return storage.dialogs;
                        // return this._getDialogs()
                    },
                    set(val) {
                        const storage = this.getValuesStorage();
                        const dialogs = storage.dialogs;
                        Object.assign(dialogs || {}, val);
                        // this.getValuesStorage().dialogs = val;
                    },
                },
                currentDialog: {
                    get() {
                        this._presetCurrentDialogId();
                        this._presetCurrentDialog();
                        return this.dialogs[this.currentDialogId];
                    },
                    set(val) {
                        this._presetCurrentDialogId();
                        this._presetCurrentDialog();
                        this.dialogs[this.currentDialogId] = val;
                    },
                },
                messages: {
                    get() {
                        return this.dialogs[this.currentDialogId].messages;
                    },
                    set(val) {
                        this.dialogs[this.currentDialogId].messages = val;
                    },
                },
                users: {
                    get() {
                        return this.dialogs[this.currentDialogId].members_ids;
                    },
                    set(val) {
                        this.dialogs[this.currentDialogId].members_ids = val;
                    },
                },
                members: {
                    get() {
                        const storage = this.getValuesStorage();
                        if (!storage.members) {
                            storage.members = [];
                        }
                        return storage.members;
                    },
                    set(val) {
                        return (this.getValuesStorage().members = val);
                    },
                },
                typing: {
                    get() {
                        return this.dialogs[this.currentDialogId].typing;
                    },
                    set(val) {
                        return (this.dialogs[this.currentDialogId].typing =
                            val);
                    },
                },
                messagesDisplay() {
                    if (
                        !!this.currentDialogId &&
                        this.currentDialogId != NEW_DIALOG_ID
                    )
                        return this.messages;
                    return [];
                },
                currentClientId: {
                    get() {
                        const storage = this.getValuesStorage();
                        // return this._get
                        if (!!storage && !storage.current_client_id) {
                            storage.current_client_id = undefined;
                            return storage.current_client_id;
                        }
                        return undefined;
                    },
                    set(val) {
                        return (this.getValuesStorage().current_client_id =
                            val);
                    },
                    // get(){
                    // console.log('[ProfileMessengerComponent] computed currentClientId= ', this.meInfo.client_id)
                    // return this.meInfo.client_id

                    // },
                    // cache: false
                    // const storage = this._getRootValuesStorage();
                    // console.log("[ProfileMessengerComponent] currentClientId: storage= ", storage)
                    // return this._currentClientId
                },
            },
            watch: {
                lastUpdatedMessageId(newId, oldId){
                    console.log(
                        "[ProfileMessengerComponent] watch lastUpdatedMessageId = ",
                        { newId, oldId }
                    );
                    this.updateDialogsDisplayDebounced()
                },
                dialogs(newdialog, olddialog) {
                    console.log(
                        "[ProfileMessengerComponent] watch dialogs = ",
                        { newdialog, olddialog }
                    );
                },
                currentDialogId(newId, oldId) {
                    const newDialog = this.dialogs[newId];
                    // const isWsOpen = this.isWsOpen();
                    // const time_from = (
                    //     (newDialog.messages
                    //         && newDialog.messages.length)
                    //     ? newDialog.messages[newDialog.messages.length - 1].data.created
                    //     : undefined
                    // )

                    if (
                        // !newDialog.historyPending &&
                        // isWsOpen &&
                        (newId != NEW_DIALOG_ID || newDialog.messages.length < 5)  // TODO: fix this! 
                    ) {
                        console.log(
                            "[ProfileMessengerComponent] watch currentDialogId: updating dialog history",
                            { newId, oldId }
                        );
                        this.updateDialogHistory(newId);
                        this.markReadCurrentDialogDebounced();
                        this.updateDialogsDisplay();
                        // api.queueHistory({
                        //     ws: this.ws,
                        //     to_id: [
                        //         NotificationUserIdTypeEnum.DIALOG_ID,
                        //         newId,
                        //     ],
                        //     time_from,
                        // });
                        // // newDialog.historyPending = true;
                    } else {
                        if (!isWsOpen)
                            console.log(
                                "[ProfileMessengerComponent] watch currentDialogId: can't update dialogs history because websocket is not open. ws=",
                                this.ws,
                                { newId, oldId }
                            );
                        // else
                        //     console.log(
                        //         "[ProfileMessengerComponent] watch currentDialogId: can't update dialogs history because websocket is not open. ws=",
                        //         this.ws,
                        //         { newId, oldId }
                        //     )
                    }
                },
            },
        };
    }
}
