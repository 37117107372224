import { requestApi } from '@/utils/synchronizer'
import { oapiGetRefObject } from './utils'


const encodeGetParams = p => 
  Object.entries(p).map(kv => kv.map(encodeURIComponent).join("=")).join("&");


function oapiGetJson(){ return window.kup.openapi_schema; }


function getOpenApiComponentSchema(schemaRef){
    const schemaRefPath = schemaRef.split('/')
    const schemaName = schemaRefPath[schemaRefPath.length - 1]
    const oapi = window.kup.openapi_schema;
    const schemas = oapi.components && (oapi.components.schemas || {})
    return schemas[schemaName]
}


function oapiCall({ operationId, data={} }) {
    const oapi = oapiGetJson();
    let url, path, methods, method, operation, schema;
    for(url in oapi.paths){ 
        path = oapi.paths[url]
        methods = Object.keys(path)
        for(method of methods){
            if (path[method].operationId == operationId){
                operation = path[method];
                break;
            }
        }
        if (!!operation) break;
    }
    if (!operation) 
        throw new Error(`[api] oapiCall(): No operation with id= ${operationId}`)
    
    const hasFiles = !!(
        operation.requestBody 
        && operation.requestBody.content 
        && operation.requestBody.content['multipart/form-data']
    )
    let requestData = {} 
    let query = {}
    let pathParams = {}
    for (const parameter of (operation.parameters || [])) {
        if (parameter.name in data){
            if(parameter.in == "query"){
                query[parameter.name] = data[parameter.name];
            } else if(parameter.in == "path"){
                pathParams[parameter.name] = data[parameter.name];
            } else {
                requestData[parameter.name] = data[parameter.name];
            }
        } 
    }
    // if (
    //     operation.requestBody 
    //     && operation.requestBody.content 
    //     && operation.requestBody.content['application/json']
    //     && operation.requestBody.content['application/json'].schema
    //     && operation.requestBody.content['application/json'].schema.$ref
    // ){
    //     const $ref = operation.requestBody.content['application/json'].schema.$ref
    //     schema = oapiGetRefObject(oapiGetJson(), $ref)
    //     console.log("[api] oapiCall(): schema=", schema)
    // }

    for (const name in pathParams){
        url = url.replace("{"+name+"}", pathParams[name])
    }
    if (query && Object.keys(query).length){
        url += '?'+ encodeGetParams(query)
    }
    
    // if (operation.parameters && method.toLocaleLowerCase()=="post"){
    //     // const encodeGetParams = p => 
    //     //     Object.entries(p).map(kv => kv.map(encodeURIComponent).join("=")).join("&");
    //     url += '?'+ encodeGetParams(query)
    //     // requestData = {}
    // } else {
    //     url += '?'+ encodeGetParams(query)
    // }
    
    const operationContent = (
        (
            operation.requestBody 
            && operation.requestBody.content
        ) 
        || {}
    )
    for(const contentName in operationContent){ 
        const sref = operationContent[contentName].schema.$ref;
        const schema = getOpenApiComponentSchema(sref);
        for (const propertyName in (schema.properties || {})) {
            if (propertyName in data) 
                requestData[propertyName] = data[propertyName];
        }
    }
    
    const ajaxParams = { 
        hostUrl: url, 
        method, 
        hasFiles,
        beforeSend: function(req, params){
            /**
             * удаляем знак "?" в конце урла добавляемый JQuery
             */
            if (params 
                && params.url 
                && params.url.slice(-1) == '?'
            ){
                params.url = params.url.slice(0, -1); 
            }
        }
    }
    if (requestData && Object.keys(requestData).length){
        ajaxParams.data = requestData
    }
    if (hasFiles){
        ajaxParams.hasFiles = hasFiles
    }
    return requestApi(ajaxParams)
}

/**
 * Сервис описывающий взпимодействие с api.
 * @module
 */
export default {
    apiCall({ name, data={} }) {
        return oapiCall({ operationId: name, data })
        // return oapiCall({ name, data })
    },
};
