<template>
    <div
        class="myProfileMain-inbox flex flex-d-column a-i-center hide-medium hide-small"
    >
        <div class="myProfileMain-viewOther flex">
            <button
                class="myProfileMain-buttonLoupe purpleButton"
                data-bs-toggle="modal"
                data-bs-target="#find-modal"
            >
                <img
                    class="myProfileMain-topLoupe"
                    :src="$static('/icons/loupe.png')"
                />
            </button>
            <span
                class="myProfileMain-viewTop line-height-1 text-a-center text-purple"
                >смотреть ТОП - анкеты других пользователей</span
            >
        </div>
        <span
            class="myProfileMain-messageText hide-small hide-medium text-orange"
            v-if="inboxDialogsDisplay && inboxDialogsDisplay.length"
        >
            Сообщения:
        </span>
        <span
            class="myProfileMain-messageText hide-small hide-medium text-orange"
            v-else
        >
            Нет новых сообщений
        </span>
        <!-- <button 
            class="myProfileMain-inboxMsgItem a-i-center flex" 
            data-bs-toggle="modal"
            data-bs-target="#messenger-dialog-modal"
        > -->
        <button
            v-for="message in inboxDialogsDisplay"
            :key="message.data.message_id"
            class="myProfileMain-inboxMsgItem a-i-center flex"
            @click="showDialog({ id: message.dialog_id })"
        >
            <img
                class="myProfileMain-senderAvatar"
                :src="msgOpponent(message).userImageSrc"
            />
            <div class="myProfileMain-senderInfo flex flex-d-column">
                <span
                    v-for="nItem in msgOpponent(message).nameItems"
                    class="myProfileMain-senderName text-purple line-height-1 text-a-start"
                >
                    {{ nItem }}
                </span>
                <!--
                <span class="myProfileMain-senderSecondName text-purple line-height-1 text-a-start">Мяликкулиевич</span>
                <span class="myProfileMain-senderSurname text-purple line-height-1 text-a-start">Бердымухаммедов</span>
                -->
                <div class="flex">
                    <div class="myProfileMain-msgWrapper flex a-i-center">
                        <img
                            class="myProfileMain-msgIconUnread"
                            :src="$static('/img/message-icon.png')"
                        />
                        <span class="myProfileMain-msgCount line-height-1">{{
                            message.unreadMessagesCount
                        }}</span>
                    </div>
                    <div class="flex flex-d-column">
                        <span
                            class="myProfileMain-senderAge text-purple text-bold-600 line-height-1"
                        >
                            {{ msgOpponent(message).age }}
                            <span
                                class="myProfileMain-senderAgeText line-height-1"
                                >лет</span
                            >
                        </span>
                        <span
                            class="myProfileMain-senderCity text-purple line-height-1"
                        >
                            {{ msgOpponent(message).locality_label }}
                        </span>
                    </div>
                </div>
            </div>
        </button>
        
        <div class="myProfileMain-dialogs-list-button-wrapper">
            <button
                class="myProfileMain-menuButton goldButton"
                data-bs-toggle="modal"
                data-bs-target="#messenger-dialogs-list-modal"
            >
                все диалоги
            </button>
        </div>
    </div>
</template>

<script>
import { msgOpponent } from "@/services/messenger/utils"
import bus from "@/utils/eventBus";
import {
    EVENTS,
    MESSENGER_NO_AVATAR_SRC,
    MESSENGER_NO_NAME_PLACEHOLDER,
} from "@/constants";
import {
    WsDataTypeEnum,
    MessageStatus,
    NotificationUserIdTypeEnum,
    dialogsDisplay,
    getOpponentId,
} from "@/services/MessengerService";
import {
    includesId,
    indexId,
    equalId,
    makeUserClientId,
    makeDialogClientId,
} from "@/utils/messenger";
import { trimSpaces } from "@/utils/index";
import MessengerMixin from "@/components/mixins/MessengerMixin";

// import { getNoun, getAge } from '@/utils/index'
// import { modalsFn } from '@/globals'
// import { EVENTS as PROFILE_COMPONENT_EVENTS } from '@/composites/ProfileComponent'

export default {
    // props: ["profiles"],
    mixins: [MessengerMixin],
    created(){
        this.msgOpponent = msgOpponent
    },
    computed: {
        inboxDialogsDisplay() {
            let inbox = dialogsDisplay.value.filter(
                (dd) => (dd.unreadMessagesCount || 0) > 0
            );
            inbox = inbox.map((dd) => ({
                ...dd,
                opponent: this.getMemberDisplay(getOpponentId(dd.dialog_id)),
            }));
            return inbox;
        },
    },
    methods: {
        showDialog({ user_id, id } = {}) {
            console.log("[ProfileInbox] showDialog(): {user_id, id}=", {
                user_id,
                id,
            });
            bus.emit(EVENTS.TRIGGER_PROFILE_OPEN_DIALOG, { user_id, id });
        },
    },
};
</script>
