import {
    // WsDataTypeEnum,
    NotificationUserIdTypeEnum,
} from "@/api/constants";

export function equalId({ id1, id2, typed = true }) {
    if (id1 === id2){
        return true
    }
    if (id1 == undefined || id2 == undefined || id1 == null || id2 == null){
        return false
    }
    if (typed) {
        if (id1[0] == id2[0] && id1[1] == id2[1]) {
            return true;
        }
    } else {
        if (id1[1] == id2[1]) {
            return true;
        }
    }
    return false;
}

export function indexId({ id, arr, typed = true }) {
    if (typed) {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i][0] == id[0] && arr[i][1] == id[1]) {
                return i;
            }
        }
    } else {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i][1] == id) {
                return i;
            }
        }
    }
    return -1;
}

export function includesId({ id, arr, typed = true }) {
    return indexId({ id, arr, typed }) !== -1;
}

export function makeClientId(typeId, userId) {
    return [typeId, userId];
}

export function makeUserClientId(userId) {
    return makeClientId(NotificationUserIdTypeEnum.USER_ID, userId);
}

export function makeDialogClientId(dialogId) {
    return makeClientId(NotificationUserIdTypeEnum.DIALOG_ID, dialogId);
}


function posY(elm) {
    var test = elm, top = 0;

    while(!!test && test.tagName.toLowerCase() !== "body") {
        top += test.offsetTop;
        test = test.offsetParent;
    }

    return top;
}

function viewPortHeight() {
    var de = document.documentElement;
    // var de = document.querySelector('.dialog-main');

    if(!!window.innerWidth)
    { return window.innerHeight; }
    else if( de && !isNaN(de.clientHeight) )
    { return de.clientHeight; }
    
    return 0;
}

function scrollY() {
    if( window.pageYOffset ) { return window.pageYOffset; }
    return Math.max(document.documentElement.scrollTop, document.body.scrollTop);
}

export function checkvisible( elm ) {
    var vpH = viewPortHeight(), // Viewport Height
        st = scrollY(), // Scroll Top
        y = posY(elm);
    
    return (y > (vpH + st));
}