import { modals } from '@/globals'
import { id } from '@/utils/index'
import api from '@/api/index'


/**
 * Сервис описывающий бизнес-логику связанную с изображениями(фото) пользователей.
 * @module
 */
export default {
    /**
     * Функция лайк изображения
     * 
     * @param {object} imageData объект с аттрибутами описывающими изображение
     * @example // объект imageData должен иметь следующий вид:
     * const imageData = {
     *      likesCount: 0,  // количество лайков изображения всего
     *      isILikeIt: false,  // установлен ли лайк мой
     *      // ...anotherImageAttributes 
     * }
     * @function like
     */
    like({ imageData }) {
        const previousImageData = {...imageData}
        imageData.isILikeIt = !imageData.isILikeIt;
        if (imageData.isILikeIt)
            imageData.likesCount++;
        else
            imageData.likesCount--;

        return api
            .apiCall({
                name: "user_profile_photos_togglelike_api_v1_user_photos_toggle_like_post",
                data: {
                    picture_id: imageData.id,
                },
            })
            .then(function (response) {
                console.log(
                    `[services/ImagesService] like() then: imageData=`, imageData,`, response=`,
                    response
                );
                imageData.likesCount = response.data.likes_count
                imageData.isILikeIt = response.data.is_i_like_it
            })
            .catch(function (cause) {
                console.log(
                    `[services/ImagesService] like() catch: imageData=`, imageData,`, cause=`,
                    cause
                );
                imageData.likesCount = previousImageData.likesCount
                imageData.isILikeIt = previousImageData.isILikeIt
                // bus.emit(EVENTS.ORDERING_FAILED, { cause });
                throw cause;
                // Object.assign(imageData, previousImageData)
            });
    },
    /**
     * Функция установки изображения аватаром
     * 
     * @param {object} imageData изображение, которое должно стать аватаром
     * @param {object} imagesCollection коллекция всех изображений пользователя, в т.ч. предыдущий аватар
     * @function setAvatar
     */
    setAvatar({ imageData, imagesCollection }) {
        const avatar = imagesCollection.find(i => i.isAvatar == true);
        if (avatar) avatar.isAvatar = false;
        imageData.isAvatar = true;
    },
    /**
     * Функция создания(загрузки) изображения
     * 
     * @param {object} imageData изображение, которое должно быть создано(загружено)
     * @param {object} imagesCollection коллекция всех изображений пользователя
     * @param {object} previousImageData предыдущее изображение, которое было на месте создаваемого (загружаемого)
     * @function upload
     */
    upload({ imageData, imagesCollection, previousImageData }) {
        api.apiCall({
            name: 'user_profile_photos_upload_api_v1_user_photos__post',
            data: { file_content: imageData.srcFull, is_avatar: imageData.isAvatar }
        }).then(function(resp) {
            console.log('[ImagesService] upload(): success response=', resp);
            delete imageData.toPost;
            Object.assign(imageData, resp.data)

            if (this.isImageSet(previousImageData)) {
                if (imageData.isAvatar && imagesCollection.some(img => img.isAvatar == true))
                    previousImageData.isAvatar = false;
                imagesCollection.push(previousImageData); // сохраняем предыдущее изображение
            }
        }).catch(function(cause) {
            Object.assign(imageData, previousImageData)
            console.warn('[ImagesService] upload(): fail cause=', cause);
        });
    },
    /**
     * Функция удаления изображения
     * 
     * @param {object} imageData изображение, которое должно быть удалено
     * @param {object} imagesCollection коллекция изображений, откуда изображение д.б. удалено
     * @function delete
     */
    delete({ imageData, imagesCollection }) {
        const _this = this;
        modals.dialog.getConfirmation({ title: 'удаление изображения' }).then(confirmed => {
            api.apiCall({
                name: 'user_profile_photos_delete_api_v1_user_photos__delete',
                data: { photo_id: imageData.id }
            }).then(function(resp) {
                const index = imagesCollection.indexOf(imageData);
                if (index === -1) {
                    console.warn('[ImagesService] delete(): изображение для удаления на клиенте не найдено, но на сервере удалено. Обновите пожалуйста страницу');
                    return   
                }
                const requireNewAvatar = imagesCollection[index].isAvatar;
                imagesCollection.splice(index, 1);
                if (requireNewAvatar && imagesCollection.length > 0) {
                    _this.setAvatar({ imageData: imagesCollection[0], imagesCollection });
                }
            });
        });
    },
    /**
     * Функция замены изображений в полях
     * 
     * @param {object} imageData новое изображение, которое должно быть заменено
     * @param {object} imagesCollection коллекция изображений, где изображение должно быть заменено
     * @param {object} oldImageData изображение, которое должно быть заменено
     * @function replace
     */
    replace({ imageData, imagesCollection, previousImageData }) {
        if (this.isImageSet(previousImageData)) {
            if (imageData.isAvatar && imagesCollection.some(img => img.isAvatar == true))
                previousImageData.isAvatar = false;
            imagesCollection.push(previousImageData); // сохраняем предыдущее изображение
        }
    },

    /**
     * Перечень возможных действий
     * 
     * @param {object} imageData изображение, которое должно быть заменено
     * @var IMAGE_ACTIONS
     */
    IMAGE_ACTIONS: {
        UPLOAD: 'upload',
        DELETE: 'delete',
        REPLACE: 'replace',
        SET_AVATAR: 'setAvatar',
    },
    /**
     * Функция получения возможных действий с изображением
     * 
     * @param {object} imageData изображение, которое должно быть заменено
     * @function getImageAvailableActions
     */
    getImageAvailableActions({ imageData }) {
        let available = [];
        if (!imageData || !imageData.isAvatar) {
            available.push(this.IMAGE_ACTIONS.SET_AVATAR)
        }
        available.push(this.IMAGE_ACTIONS.REPLACE);
        available.push(this.IMAGE_ACTIONS.DELETE);
        return available;
    },
    /**
     * Присвоено ли изображению какое-либо значение (не пустое)
     * @param {Object} imageData изображение
     * @returns {Boolean} true - не пустое, false - иначе
     * @function isImageSet
     */
    isImageSet(imageData) {
        return imageData && (!!imageData.src || !!imageData.srcFull);
    },
    getBlankImageNewId() {
        return id.tempGenerator.next().value;
    },
    /**
     * Создаёт объект описывающий "пустое" изображение (информация изображение) 
     * @param {object} fill заполнение объекта описывающего изображение "по умолчанию"
     * @returns объект описывающий пустое изображение
     * @method createBlankImage
     */
    createBlankImage(fill = {}) {
        let img = {
            isAvatar: false,
            isILikeIt: false,
            likesCount: 0,
            src: undefined,
            srcFull: undefined,
        };

        img = {...img, ...fill };
        return img;
    },
};