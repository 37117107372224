<template>
    <profile-field-layout 
        ref="layout" v-bind="layout"
        :editable="editable"
        :value-class="valueClass"
        :value-display-str="valueDisplayStr" 
        :placeholder="placeholder"
        :validation-errors="validationErrors"
        :hint="hint"
        :hint-description="hintDescription"
        >
        
        <template #editor>
        <li class="dropdownEl flex flex-d-column a-i-center">
            {{ label }}
            <input  :type="inputType" 
                    v-model="value"
                    :minlength="minLength"
                    :maxlength="maxLength" 
                    class="myProfileMain-dropdownNameField dropdownInput">
        </li>
        </template>
    </profile-field-layout>
</template>

<script>
import BaseField from "./BaseField";
import ProfileFieldLayout from '@/components/layouts/profile/ProfileFieldLayout.vue'


/**
 * ProfilePlainField - базовое простое поле профиля, от которого наследуются все остальные поля профиля
 * @class
 */
export default {
    mixins: [BaseField],
    props: {
        iconSrc: { type: String, default: undefined },
        valueClass: { type: String, default: 'myProfileMain-purpleItem' },
        label: { type: String, default: '' },
        hint: { type: String, default: undefined },
        placeholder: { type: String, default: 'не заполнено' },
        hintDescription: { type: String, default: undefined },
        initEditModeOn: { type: Boolean, default: false },
        layout: { type: Object, default: () => {} },
    },
    components: {
        ProfileFieldLayout
    },
    data() {
        return {
            dropdownListClass: 'myProfileMain-list',
            showClass: 'show',
            defaultIconSrc: '/icons/bird.png',
        }
    },
    computed: {
        iconSrcDisplay() {
            if (this.iconSrc)
                return this.$static(this.iconSrc);
            return undefined
        },
    },
    methods: {
        _capitalizeFirst(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
    }
};
</script>