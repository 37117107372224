import { removeEmptys } from "@/utils/index";
import BaseCompositeComponent from "./BaseCompositeComponent";
import SchemaStorageCompositeMixin from "./SchemaStorageCompositeMixin";


import ProfilesList from "@/components/lists/ProfilesList.vue";


export const OPER_ID_PULL = "user_profiles_list_api_v1_user_profiles_list_get"
export const OPER_ID_PUSH = OPER_ID_PULL
export const PULL_SCHEMA = "findSettings"
// export { PROFILE_OWNER_RELATION }

/**
 * Компонент (композит из компонетов) представляющий профиль пользователя
 */
export default class extends BaseCompositeComponent {
    constructor(config) {
        super({ ...config, schema: "find" });
        this.vueComponent = {
            // data(){ return {_handleStorageSavePull: false,}},
            mixins: [SchemaStorageCompositeMixin],
            components: {
                // Buttons
                // SchemaStorageSaveButton,
                // SchemaStoragePullButton,

                // // Fields
                // ProfileRawTextField,
                // ProfilePlainField,
                // ProfileSelectField,
                // ProfileAutocompleteSelectField,
                // ProfileImagesCollectionField,
                // ProfileAgeField,
                // ProfileNumberField,
                // ProfileTextAreaField,
                // ProfileRangeField,

                // Lists
                ProfilesList,

                // // Modals
                // DialogModal,
            },
            beforeMount() {
                this.pullSchemaValues(this.compositeSchema);
            },
            methods: {
                _getPullOId(){ return OPER_ID_PULL },
                _getPushOID(){ return OPER_ID_PUSH },
                _getPullArgs(){
                    let settings = this.getValuesStorage(PULL_SCHEMA)
                    settings = removeEmptys(settings);
                    if (!settings || !Object.keys(settings).length)
                        return {params: null} 
                    return {params: JSON.stringify(settings)} 
                },
                
                // _addEventListeners(){},
                // _removeEventListeners(){},
                getValuesStorageForPull(schema) {
                    const findSettingsStorageName = "findSettings";
                    let storage = this.getValuesStorage(
                        findSettingsStorageName
                    );
                    if (!storage) {
                        console.log(
                            "[ProfileFindComponent] getValuesStorageForPull(): no storage"
                        );
                        return {};
                    }
                    console.log(
                        "[ProfileFindComponent] getValuesStorageForPull(): storage =",
                        storage
                    );
                    
                    return {
                        [findSettingsStorageName]: JSON.stringify(storage),
                    };
                },
            },
        };
    }
}
