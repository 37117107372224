<template>
    <div :class="dropdownOrientation">
        <div    ref="toggle"
                :class="toggleClass"
                data-bs-toggle="dropdown"
                data-bs-auto-close="true" 
                aria-expanded="false">
            <slot   name="toggle" 
                    :iconSrc="iconSrc" 
                    :src="$static(iconSrc)" >
                <img v-if="iconSrc" 
                    :class="iconClass" 
                    :src="$static(iconSrc)" />
                <button v-if="label"
                        type="button" 
                        :class="buttonClass" 
                        data-bs-toggle="dropdown" 
                        aria-expanded="false" 
                        data-bs-offset="10,20">
                    {{ label }}
                </button>
            </slot>
        </div>
        <ul     ref="list" 
                :class="dropdownMenuClass">
            <slot name="item" :menu="menu" >
            <li     v-for="(item, index) in menu" 
                    :key="index"
                    @click="item.handler({item, index, $event})">    
                <a :class="dropdownItemClass" :href="item.href">{{ item.label }}</a>
            </li>
            </slot>
        </ul>
    </div>
</template>
<script>
/**
 * Компонент меню (кнопка с выпадающим меню), обёртывает элемент dropdown от bootstrap
 * 
 * @param {Array} menu список из объектов (object) описывающих свойства элементов меню
 * @param {String} label текст для кнопки меню
 * @example // элемент массива описывающего меню должен иметь следующий вид:
 * const singleMenuItem = {
 *      handler(item, index, $event){
 *          // функция обработки выбора элемента меню, где:
 *          // @arg item {object} выбранный элемент
 *          // @arg index {Number} выбранный элемент
 *          // @arg $event объект события выбора элемента
 *      },
 *      label: 'Menu item label',
 *      href: 'http://example.ex/example', // optional menu property
 * }
 * <menu-button :menu="[singleMenuItem, ...anotherMenuItems]"></menu-button>
 * @class
 */
export default {
    props: {
        iconSrc: { type: String, default: '/icons/three-dots.svg' },
        iconClass: { type: String, default: 'myProfileMain-photoMenu' },
        buttonClass: { type: String, default: undefined },
        toggleClass: { type: String, default: undefined },
        dropdownOrientation: { type: String, default: 'dropstart' },
        dropdownItemClass: {type: String, default: 'dropdown-item'},
        dropdownMenuClass: {type: String, default: 'dropdown-menu'},
        label: { type: String, default: undefined },
        menu: { type: Array, required: true },
    }
};
</script>