import { initial_state } from "@/globals";
import { getStaticUrl, getMediaUrl } from "@/utils/files";
import { modalVisibillity } from "@/utils/ui";
// import {api} from "@/api/Messenger";
import {
    reactive,
    watchEffect,
    ref,
    computed,
    watch,
    nextTick,
} from "vue";
// } from "vue/dist/vue.esm-bundler";
import {
    includesId,
    indexId,
    equalId,
    checkvisible,
    makeUserClientId,
    makeDialogClientId,
} from "@/utils/messenger";
import {
    WsDataTypeEnum,
    NotificationUserIdTypeEnum,
    MessageStatus,

} from "@/api/constants";
import {
    matchAllMessageTags,
    tag2HtmlConverter,
} from "@/content/text_extra/utils";
import bus from "@/utils/eventBus";
import { trimSpaces, debounce } from "@/utils/index";
import {
    NEW_DIALOG_ID,
    MESSENGER_NO_AVATAR_SRC,
    MESSENGER_NO_NAME_PLACEHOLDER,
    MESSENGER_DIALOGS_DISPLAY_UPDATE_DEBOUNCE,
    CURRENT_CLIENT_UPDATE_INTERVAL,
} from "@/constants";
import * as api from "../api/Messenger";
import { connector } from "@/api/websocket";
import {
    MessengerFoundContactsError
} from "@/exceptions"
import { OnlineStatusEnum } from '@/constants'
import { userContent } from "@/content/state";


const NEW_DIALOG_SEND_TIMEOUT = 5000
/**
 * Сервис описывающий бизнес-логику связанную с обменом сообщениями пользователей.
 * @module
 */


export let messengerMonitor = undefined;
export function getMonitor() {
    return messengerMonitor;
}
export function setMonitor(newMessengerMonitor) {
    messengerMonitor = newMessengerMonitor;
}

const messenger = initial_state.messenger || {};

// const connector = getConnector();

export const dialogs = reactive(messenger.dialogs || {});
export const dialogsUpdatesCounter = ref(0);
export const members = reactive(messenger.members || []);
export const currentDialogId = ref(undefined);
export const allUnreadMessagesCount = ref(0);
export const currentClientId = ref(messenger.current_client_id);
export let isMessengerLaunched = ref(true);
const display = reactive({
    dialogs: [],
});
export const dialogsDisplay = computed(() => display.dialogs);
export const membersChangesCounter = ref(1);
watch(members, (m) => {
    membersChangesCounter.value++;
    console.log("[MessengerService] members waich: members=", members)
});

// export const currentDialogMessagesDisplay = ref([]);
const updateDialogsDisplayDebounced = debounce(
    () => updateDialogsDisplay(),
    MESSENGER_DIALOGS_DISPLAY_UPDATE_DEBOUNCE
);

const watchModalVisibillity = watch(modalVisibillity, (v, ov) => {
    if (v["messenger-dialog-modal"] == false){
        currentDialogId.value = undefined
    }
});


const watchDialogs = watch(dialogs, () => {
    dialogsUpdatesCounter.value++;
    // console.log(
    //     `[MessengerService] watchDialogs watch: dialogsUpdatesCounter.value=`,
    //     dialogsUpdatesCounter.value
    // );
    // console.log(`[MessengerService] watchDialogs watch: dialogs=`, dialogs);
    updateDialogsDisplay()
    // updateDialogsDisplayDebounced()
});

export const currentDialog = computed(() => {
    return dialogs[currentDialogId.value];
});

export const currentOpponent = computed(() => {
    if (currentDialogId.value){
        return getOpponent(
            currentDialogId.value, 
            currentClientId.value,
        )
    }
    return {}
})

export const currentOpponentOnline = ref(undefined)
watch(membersChangesCounter, () => {
    let op = undefined;
    if (currentDialogId.value){
        op = getOpponent(
            currentDialogId.value, 
            currentClientId.value,
        )
        console.log("[MessengerService] currentOpponentOnline: op=", op)
    }
    if (op && op.online) currentOpponentOnline.value = op.online
    else currentOpponentOnline.value = undefined
})

// export const curOpponentIsOnline = computed(()=>{ 
//     return !!currentOpponentOnline.value && currentOpponentOnline.value[0] == OnlineStatusEnum.ONLINE
// })
// export const curOpponentOnlineStr = computed(()=>{
//     if (!service.currentOpponentOnline)
//         return "оффлайн"
//     return service.currentOpponentOnline[1] || "оффлайн"
// })

export let currentDialogMessagesDisplay = computed(() => {
    // console.log(`[MessengerService] currentDialogMessagesDisplay computed: `, {
    //     isMessengerLaunched: isMessengerLaunched.value,
    //     currentDialogId: currentDialogId.value,
    // });
    if (
        isMessengerLaunched.value &&
        !!currentDialog.value 
        // && currentDialogId.value != NEW_DIALOG_ID
    ) {

        // console.log(
        //     `[MessengerService] currentDialogMessagesDisplay computed: dialogs[currentDialogId.value].messages=`,
        //     currentDialog.value.messages
        // );
        return currentDialog.value.messages;
    } else {
        // console.log(
        //     `[MessengerService] currentDialogMessagesDisplay computed: return []`
        // );
        return [];
    }
});

export let currentDialogLastMessageDisplay = computed(() => {
    const messages = currentDialogMessagesDisplay.value;
    // console.log(`[MessengerService] currentDialogLastMessageDisplay: `, {
    //     messages,
    // });
    if (!messages || !messages.length) {
        return undefined;
    }
    return messages[messages.length - 1];
});

export function* allMessages() {
    for (const did of Object.keys(dialogs)) {
        for (const msg of dialogs[did].messages || []) {
            yield msg;
        }
    }
}

/**
 * Таймер обновления статуса собеседника в текущем диалоге
 */ 
let _currentDialogUpdateTimer = undefined;
/**
 * Триггер обновления статуса собеседника в текущем диалоге
 */ 
watch(currentDialogId, (dialogId, oldDialogId) => {
    if (_currentDialogUpdateTimer){
        unsetCurrentClientUpdater(dialogId, _currentDialogUpdateTimer)
        _currentDialogUpdateTimer = undefined;
    }
    if (!dialogId) return;  // если текущий не выбран - не обновляем
    // обновляем только текущий
    _currentDialogUpdateTimer = setCurrentClientUpdater(
        dialogId, CURRENT_CLIENT_UPDATE_INTERVAL
    )
});


const watchCurrentDialogId = watch(currentDialogId, (dialogId, oldDialogId) => {
    console.log("[MessengerService] watch currentDialogId:", {
        dialogId,
        oldDialogId,
    });
    if (dialogId == NEW_DIALOG_ID || !dialogId) return;

    const dialog = dialogs[dialogId];

    console.log("[MessengerService] watch currentDialogId: dialog=", dialog);
    if (dialog.historyPending === undefined) updateDialogHistory(dialogId);
    else if (dialog.historyPending === false) {
        if (!messengerMonitor) {
            console.error(
                "[MessengerService] watch currentDialogId: no messages monitor (messengerMonitor=)",
                messengerMonitor
            );
            console.error(
                "[MessengerService] watch currentDialogId: history update ignored."
            );
            return;
        }
        const visibleEls = messengerMonitor.getVisibleMessagesEls();
        updateDialogHistory(dialogId, { visibleEls });
    }
});


export function wsConnect() {
    console.log("[MessengerService] wsConnect(): connector=", connector);
    connector.addEventListener("message", onIncomingMessage);
}

export function mount() {
    if (window.location.pathname == '/') {
        console.log("[MessengerService] mount(): CANCELED!");
        return undefined;    
    }

    api.getClientState().then((data)=>{
        console.log("[MessengerService] mount() got client state.", {data});
        currentClientId.value = data.current_client_id
        members.length = 0
        members.push(...data.members)
        
        Object.assign(dialogs, data.dialogs)
        console.log("[MessengerService] mount(): dialogs result:", dialogs);
        console.log("[MessengerService] mount(): members result:", members)

        updateDialogsDisplay();
        console.log("[MessengerService] mount() connecting to websocket...");
        return wsConnect();
    }).catch((cause) => {
        console.error("[MessengerService] mount() catch: load state failed! Cause=", cause);
    });
}

export function setCurrentDialog({ user_id, id } = {}) {
    console.log("[MessengerService] setCurrentDialog(): {user_id, id} = ", {
        user_id,
        id,
    });

    if (!!id && (id in dialogs)) {
        nextTick(() => {
            currentDialogId.value = id;
        });
    } else {
        id = getOrCreateDialogIdWithUser(user_id);
        nextTick(() => {
            currentDialogId.value = id;
        });
    }
}

function setCurrentClientUpdater(dialog_id, intervalms){
    const clientId = getOpponentId(dialog_id, currentClientId.value)
    console.info(
        "[MessengerService] setCurrentClientUpdater(): set update for!", 
        {dialog_id, intervalms, clientId}
    )
    return setInterval(()=>{
        if (!clientId) {
            console.warn(
                "[MessengerService] setCurrentClientUpdater(): client_Id is falsy!", 
                clientId
            )
            return
        }
        api.clientStatus({client_id: clientId})
    }, intervalms)
}

function unsetCurrentClientUpdater(dialog_id, instance){
    console.info(
        "[MessengerService] unsetCurrentClientUpdater(): unsetset update for", 
        {dialog_id, instance}
    )
    return clearInterval(instance)
}

export function _processMessageBeforeAppend(message) {
    if (!message || !message.data || !message.data.message){
        return message
    }
    let txt = message.data.message
        .replaceAll("&quot;", '"')
        // .replaceAll("&amp;", '\\')
        .replaceAll("&amp;quot;", '"');
    let txtNew = "";
    let lastMatchIndex = 0;
    let hasTags = false;
    const tags = matchAllMessageTags(txt);
    for (const matchTag of tags) {
        matchTag.attrs.src = getStaticUrl(matchTag.attrs.src);
        const htmlTag = tag2HtmlConverter(
            matchTag,
            userContent.text_extra_collections,
            (src) => getStaticUrl("/" + src)
        );
        // const htmlTag = converter.toHtml(matchTag);
        // console.log(
        //     `[MessengerService] _processMessageBeforeAppend(): htmlTag=`,
        //     htmlTag
        // );
        // lastMatchIndex + matchTag.rawTag.length
        txtNew += txt.substring(lastMatchIndex, matchTag.index);
        txtNew += htmlTag;
        lastMatchIndex = matchTag.index + matchTag.rawTag.length;
        hasTags = true;
        // console.log(
        //     `[MessengerService] _processMessageBeforeAppend(): txtNew=`,
        //     txtNew
        // );
    }

    if (hasTags) {
        txtNew += txt.substring(lastMatchIndex, txt.length);
        message.data.messageHtml = txtNew;
    } else {
        // console.log(
        //     `[MessengerService] _processMessageBeforeAppend(): no tags found! tags=`,
        //     [...tags]
        // );
        // console.log(
        //     `[MessengerService] _processMessageBeforeAppend(): txt=`,
        //     txt
        // );
        // [[emj "src":"/img/text-extra/10/default_emoji/U0001F600.png"]] [[emj "src":"/img/text-extra/10/default_emoji/U0001F600.png", "slug":"U0001F600"]]
    }
    return message;
}

export const beforeAppendMessagePipeline = [
    (message) => {
        if (!message || !message.from_id)
            return message;
        message.isMe = equalId({
            id1: message.from_id,
            id2: currentClientId.value,
        });
        return message;
    },
    (message) => {
        if (!message || !message.data || !message.data.created)
            return message;
        message.data.createdDate = new Date(
            message.data.created + "Z"
        ).toLocaleString();
        return message;
    },
    (message) => {
        if (!message || !message.data || !message.data.message)
            return message;
        return _processMessageBeforeAppend(message)
    },
];

function excecBeforeAppendMessagePipeline(message) {
    let res = message;
    for (const p of beforeAppendMessagePipeline) {
        res = p(res);
    }
    return res;
}

function deleteDialog(dialog_id){
    if (dialogs[NEW_DIALOG_ID]) {
        delete dialogs[NEW_DIALOG_ID];
    }
}

function deleteNewDialog(){
    deleteDialog(NEW_DIALOG_ID)
}

export function appendMessage(
    message,
    emitMsgReceiveEvent = true,
    dialogId = undefined,
    history = false
) {
    if (
        [
            WsDataTypeEnum.USER_CONNECTED,
            WsDataTypeEnum.USER_DISCONNECTED,
        ].includes(message.type)
    ) {
        return;
    }
    if (!!message.to_id && message.to_id[0] == NotificationUserIdTypeEnum.DIALOG_ID){
        dialogId = dialogId || message.to_id[1];
    } else {
        dialogId = currentDialogId.value
    }
    
    if (!message.to_id && !!dialogId) {
        message.to_id = makeDialogClientId(dialogId);
    }
    let dialog = dialogs[dialogId];
    if (!dialog) {
        console.debug(
            "[MessengerService] appendMessage(): dialogId=",
            dialogId
        );
        _presetDialog({ id: dialogId });
        dialog = dialogs[dialogId];
        if (message.data && message.data.clients_info) {
            for (const info of message.data.clients_info) {
                setMember(info.client_id, info);
                dialog.members_ids = dialog.members_ids.filter(
                    (id) =>
                        !equalId({
                            id1: id,
                            id2: info.client_id,
                        })
                );
                dialog.members_ids.push(info.client_id);
            }
        }
        if (currentDialogId.value == NEW_DIALOG_ID) {
            currentDialogId.value = dialogId;
        }
    }
    if (
        ![WsDataTypeEnum.MESSAGE_SENT, WsDataTypeEnum.HISTORY].includes(
            message.type
        )
    ) {
        return;
    }

    if (
        !message.to_id ||
        message.to_id[0] != NotificationUserIdTypeEnum.DIALOG_ID
    ) {
        if (message.to_id[0] == NotificationUserIdTypeEnum.USER_ID){
            if (message.to_id[1]){}
        } else {
            console.error(
                "[MessengerService] appendMessage(): unknown message.to_id type. to_id=",
                message.to_id
            );
        }

        
    }
    message = excecBeforeAppendMessagePipeline(message);
    // message.isMe = equalId({
    //     id1: message.from_id,    
    //     id2: currentClientId,
    // });
    // message.data.createdDate = (new Date(message.data.created+'Z')).toLocaleString()

    // _processMessageBeforeAppend(message);

    if (!dialog.messages) dialog.messages = [];
    if (history) {
        dialog.messages.unshift(message);
    } else {
        dialog.messages.push(message);
    }

    if (currentDialogId.value == NEW_DIALOG_ID) {
        const dialog_ids = _dialogIdMatchUsersIds(dialog.members_ids);
        if (Object.keys(dialogs).length > 1) {
            if (dialog_ids.length > 0) {
                currentDialogId.value = dialog_ids[0];
                deleteDialog(NEW_DIALOG_ID);
            }
        }
    }

    return message;
}
export function _createNewDialog({ id }) {
    dialogs[id] = {
        members_ids: [],
        typing: [],
        messages: [],
        historyPending: false,
    };
}
export function _dialogIdMatchUsersIds(members_ids) {
    const matched = [];
    for (const d in dialogs) {
        let match = true;
        for (const clId of dialogs[d].members_ids) {
            if (members_ids.includes(clId[1])) {
                // continue   // TODO: remove this!!!
                break;
            }
            match = false;
            break;
        }
        if (match) {
            matched.push(d);
        }
    }
    return matched;
}
export function _isDialogExists(id) {
    return id in dialogs;
}
export function _presetDialog({ id }) {
    if (!_isDialogExists(id)) {
        _createNewDialog({ id });
    }
}
export function _presetCurrentDialogId() {
    const dialog_ids = Object.keys(dialogs);
    currentDialogId.value = !!dialog_ids.length ? dialog_ids[0] : NEW_DIALOG_ID;
}
export function _presetCurrentDialog() {
    _presetDialog({ id: currentDialogId.value });
}

function _getNewDialog(timeout=5000){
    _presetDialog({id: NEW_DIALOG_ID});
    // if (dialogId == NEW_DIALOG_ID || !dialogId) return;
    // setTimeout(() => {
    //     deleteDialog(NEW_DIALOG_ID);
    //     currentDialogId.value = undefined
    // }, timeout)
    return NEW_DIALOG_ID
}

export function getOrCreateDialogIdWithUser(user_id) {
    const clientId = makeUserClientId(user_id);
    let dialog_id;
    for (const d_id in dialogs) {
        if (
            includesId({
                arr: dialogs[d_id].members_ids,
                id: clientId,
            })
        ) {
            dialog_id = d_id;
            break;
        }
    }
    if (dialog_id) return dialog_id;

    dialog_id = _getNewDialog() 
    dialogs[dialog_id].members_ids = [clientId];
    return dialog_id;
}
export function _getToIdSend() {
    // console.log('[MessengerService] _getToIdSend(): this=', this)
    // console.log('[MessengerService] _getToIdSend(): currentDialog=', currentDialog)
    // console.log('[MessengerService] _getToIdSend(): currentDialogId.value=', currentDialogId.value)
    // console.log('[MessengerService] _getToIdSend(): dialogs[currentDialogId.value]=', dialogs[currentDialogId.value])
    // console.log('[MessengerService] _getToIdSend(): dialogs[currentDialogId.value].users=', dialogs[currentDialogId.value].users)
    if (currentDialogId.value == NEW_DIALOG_ID && currentDialog.value) {
        // TODO: improve this!
        return currentDialog.value.members_ids.filter(
            (mId) =>
                !equalId({
                    id1: mId,
                    id2: currentClientId.value,
                })
        )[0];
    } else {
        return makeDialogClientId(currentDialogId.value);
    }
}
export function appendHistory(response) {
    const messages = response.data.messages || [];
    if (response.data.members) {
        // preprocess dialogs data
        for (const m of response.data.members || []) {
            setMember(m.client_id, m);
        }

        // members = response.data.members;
        // window._kup_members = members; // for debug
        // bus.emit(EVENTS.DIALOGS_RECEIVE, {dialogs: dialogs});
    }

    if (response.data.dialogs) {
        // preprocess dialogs data
        for (const d_id in response.data.dialogs) {
            // find index of current user id in members_ids
            const idx = indexId({
                id: currentClientId.value,
                arr: response.data.dialogs[d_id].members_ids,
                typed: false,
            });
            if (idx !== -1) {
                response.data.dialogs[d_id].members_ids.splice(idx, 1);
            }
        }
        dialogs = response.data.dialogs;
        bus.emit(EVENTS.DIALOGS_RECEIVE, {
            dialogs: dialogs,
        });
        // this.updateDialogsDisplay();
    }

    const messages2append = Array.from(messages);
    const lastMessage2append = messages2append.pop();

    if (response.data.messages) {
        // is messages send in history
        const dialog = response.data.messages_dialog_id
            ? dialogs[response.data.messages_dialog_id]
            : undefined;
        if (dialog) {
            dialog.historyPending = false;
            dialog.historyQueue = undefined;
            dialog.unread_messages_count = response.data.unread_messages_count;
        }
    }

    for (const message of messages2append) {
        // console.log(
        //     "[MessengerService] onIncomingMessage(): history message =",
        //     message
        // );
        appendMessage(message, false, response.data.messages_dialog_id, true);
        // this.scrollToLast();
    }
    if (lastMessage2append) {
        appendMessage(
            lastMessage2append,
            true,
            response.data.messages_dialog_id,
            true
        );
    }
    if (messages.length > 0) {
        // bus.emit(EVENTS.MESSAGE_RECEIVE, { messages });
    }
    dialogsUpdatesCounter.value++;
}

export function onIncomingMessage(wsEvent) {
    // listen to data sent from the websocket server
    const response = JSON.parse(wsEvent.data);
    if (!response.data) response.data = {};
    let dialog;

    // console.debug(
    //     "[MessengerService] onIncomingMessage(): response.type=",
    //     response.type
    // );
    // console.log("[MessengerService] onIncomingMessage(): responce=", response);

    switch (response.type) {
        case WsDataTypeEnum.USER_CONNECTED:
        case WsDataTypeEnum.USER_DISCONNECTED:
            break;
        case WsDataTypeEnum.MESSAGE_SENT:
            let appendedMessage = appendMessage(response);
            // onNewMessagesAppended({ dialogId: appendedMessage.to_id[1] });
            // updateDialogsDisplay();
            // if (response.to_id[1] == currentDialogId.value) {
            //     if (
            //         !(
            //             response.type ==
            //                 WsDataTypeEnum.HISTORY &&
            //             !!response.data.messages &&
            //             !!response.data.messages.length
            //         )
            //     ) {
            //         this.scrollToLast();
            //     }
            //     this.markReadCurrentDialogDebounced();
            // }
            break;
        case WsDataTypeEnum.READ:
            response.data.messages_ids = response.data.messages_ids || [];

            const readMessages = [];
            for (const m of allMessages()) {
                if (
                    response.data.messages_ids.includes(m.data.message_id) &&
                    m.to_id[1] == currentDialogId.value
                ) {
                    m.data.status = MessageStatus.READ;
                    readMessages.push(m);
                    // this.lastUpdatedMessageId = m.data.message_id;
                }
            }
            // allMessages.value.filter(
            //     (m) =>
            //         response.data.messages_ids.includes(m.data.message_id) &&
            //         m.to_id[1] == currentDialogId.value
            // );

            // for (let m of readMessages) {
            //     m.data.status = MessageStatus.READ;
            //     this.lastUpdatedMessageId = m.data.message_id;
            // }
            // console.log(
            //     "[MessengerService] onIncomingMessage(): response.data.messages_ids=",
            //     response.data.messages_ids
            // );
            console.log(
                "[MessengerService] onIncomingMessage(): readMessages=",
                readMessages
            );
            if (readMessages.length > 0) {
                // this.updateDialogsDisplay(true);
            }
            // this.updateDialogsDisplay(true);
            break;
        case WsDataTypeEnum.HISTORY:
            console.log(
                "[MessengerService] onIncomingMessage(): processing HISTORY"
            );
            appendHistory(response);
            if (response.data.dialogs || response.data.messages) {
                // this.updateDialogsDisplay();
                // this.scrollToLast();
            }
            // dialog;
            break;
        case WsDataTypeEnum.USER_TYPING:
            if (response.to_id[0] != NotificationUserIdTypeEnum.DIALOG_ID) {
                console.warn(
                    "[MessengerService] onIncomingMessage(): response.to_id type is not match dialog id"
                );
            }
            _presetDialog({ id: response.to_id[1] });
            dialog = dialogs[response.to_id[1]];
            if (
                !dialog.typing.includes(response.from_id) &&
                response.from_id[1] !== currentClientId.value
            ) {
                dialog.typing = [...dialog.typing, response.from_id];
            }
            break;
        case WsDataTypeEnum.USER_STOPPED_TYPING:
            if (response.to_id[0] != NotificationUserIdTypeEnum.DIALOG_ID) {
                console.warn(
                    "[MessengerService] onIncomingMessage(): response.to_id type is not match dialog id"
                );
            }
            _presetDialog({ id: response.to_id[1] });
            dialog = dialogs[response.to_id[1]];
            if (dialog.typing.includes(response.from_id)) {
                dialog.typing = dialog.typing.filter(
                    (from_id) => from_id !== response.from_id
                );
            }
            break;
        case WsDataTypeEnum.CLIENT_STATUS:
            const member = response.data
            const clientId = member.client_id
            console.log("[MessengerService] onIncomingMessage():", {member, clientId})
            // delete member.client_id
            setMember(clientId, member)
            break;
        default:
            break;
    }
    // scrollToBottom();
    // console.log(response);
}

export function getMember(clientId) {
    for (const m of members) {
        // console.log('[ProfileInbox] getMember() m=', m)
        // console.log('[ProfileInbox] getMember() m.client_id=', m.client_id)
        if (equalId({ id1: m.client_id, id2: clientId })) {
            return m;
        }
    }
    // console.log(
    //     "[MessengerService] getMember(): members=",
    //     members
    // );
    return undefined;
}
export function getMemberIds() {
    const ids = [];
    for (const m of members) {
        ids.push(m.client_id);
    }
    return ids;
}

export function setMember(clientId, member) {
    if (!clientId) {
        console.error();
    }

    const newMembers = members.filter(
        function(m) {
            return !equalId({ id1: m.client_id, id2: clientId })
        }
    );
    members.length = 0
    members.push(newMembers)
    members.push(member);
}
export function getMemberInfo(clientId, name, def = undefined) {
    const info = getMember(clientId);
    if (!info) {
        console.log(
            `[MessengerService] not fount info ${name} for client ${clientId}, available: ${getMemberIds()}`
        );
        console.debug(`[MessengerService] info name=${name}`);
        return def;
    }
    // console.log('[MessengerService] getMemberInfo(): name=', name, 'info=', info, 'clientId=', clientId)
    return info[name] || def;
}
export function getUserMessageInfo(message, name, def = undefined) {
    if (!message) return def;
    if (
        !message.from_id ||
        message.from_id[0] != NotificationUserIdTypeEnum.USER_ID
    ) {
        return def;
    }
    const clientId = message.from_id;

    const info = getMemberInfo(clientId, name, def);
    // console.log('[MessengerService] getUserMessageInfo(): clientId=', clientId, 'info=', info)
    return info;
}

export function getOpponentId(dialogId, baseOponentId = undefined) {
    baseOponentId = baseOponentId || currentClientId.value;
    for (const mId of dialogs[dialogId].members_ids) {
        if (!equalId({ id1: mId, id2: baseOponentId })) {
            return mId;
        }
    }
    return undefined;
}

export function getOpponent(dialogId, baseOponentId = undefined) {
    const opponentClientId = getOpponentId(dialogId, baseOponentId);
    return getMember(opponentClientId);
}

export function getOpponentInfo(
    dialogId,
    baseOponentId,
    infoName,
    infoDefault
) {
    const opponentClientId = getOpponentId(dialogId, baseOponentId);
    return getMemberInfo(opponentClientId, infoName, infoDefault);
}

export function getDialogMessageInfo(message, name, def = undefined) {
    if (!message) return def;
    if (
        !message.from_id ||
        message.from_id[0] != NotificationUserIdTypeEnum.USER_ID
    ) {
        return def;
    }
    let clientId = undefined;
    // console.log(
    //     "[MessengerService] getDialogMessageInfo(): message.to_id=",
    //     message.to_id
    // );
    // console.log(
    //     "[MessengerService] getDialogMessageInfo(): dialogs=",
    //     dialogs
    // );
    // console.log(
    //     "[MessengerService] getDialogMessageInfo(): dialogs[message.to_id[1]]=",
    //     dialogs[message.to_id[1]]
    // );
    for (const mId of dialogs[message.to_id[1]].members_ids) {
        // console.log('[ProfileInbox] getMember() m=', m)
        // console.log('[ProfileInbox] getMember() m.client_id=', m.client_id)
        if (!equalId({ id1: mId, id2: currentClientId.value })) {
            clientId = mId;
            // console.log(
            //     "[MessengerService] getDialogMessageInfo(): !equalId - { id1: mId, id2: currentClientId.value }=",
            //     { id1: mId, id2: currentClientId.value }
            // );
            break;
        }
    }

    const info = getMemberInfo(clientId, name, def);
    // console.log('[MessengerService] getDialogMessageInfo(): clientId=', clientId, 'info=', info)
    return info;
}

export function getMessageUserNameList(message) {
    const name = getMessageUserName(message);
    return name.split(" ");
}

export function getMessageUserImageSrcFull(message) {
    const avatarSrc = getUserMessageInfo(message, "avatar_src");
    if (avatarSrc) {
        return getMediaUrl(avatarSrc);
    }
    return getStaticUrl(MESSENGER_NO_AVATAR_SRC);
}

export function getMessageUserName(message) {
    return getUserMessageInfo(message, "name", MESSENGER_NO_NAME_PLACEHOLDER);
}

export function updateDialogHistory(
    dialogId,
    { visibleEls = [], time_from = undefined } = {}
) {
    const dialog = dialogs[dialogId];
    if (!dialog) {
        throw ReferenceError(`No dialog with id ${dialogId} on client`);
    }
    if (dialog.historyPending === true) {
        console.error(
            "[MessengerService] updateDialogHistory(): can't make new history queue until previous not finished"
        );
        return false;
    }
    if (
        !!dialog.messages &&
        !!dialog.messages[0] &&
        !!dialog.messages[0].data.message_extra &&
        !!dialog.messages[0].data.message_extra.is_first
    ) {
        console.info(
            "[MessengerService] updateDialogHistory(): furst message in dialog queued, no histoty to queue"
        );
        return false;
    }
    if (!dialog.messages || !dialog.messages.length) {
        // no messages at all
        // newDialog.historyPending
        console.warn(
            `[MessengerService] updateDialogHistory(): requesting history of dialogId=${dialogId} without "time_from" param`
        );

        dialog.historyQueue = api.queueHistory({
            // ws: this.ws,
            to_id: [NotificationUserIdTypeEnum.DIALOG_ID, dialogId],
            dialog_single: true,
        });
        dialog.historyPending = true;
        return dialog.historyQueue;
    }

    // time_from = undefined;
    // if (
    //     currentDialogId.value == dialogId &&
    //     currentDialogId.value
    // ) {
    //     // const visibleEls = this.getVisibleMessagesEls();
    //     // if (!visibleEls || !visibleEls.length) {
    //     //     console.warn(
    //     //         `[MessengerService] updateDialogHistory(): no visible elements found, but messages in dialog exists`
    //     //     );
    //     // }

    //     // const isStartVisible =
    //     //     visibleEls.filter((i) => i[0] == 0).length != 0;
    //     // // const isVisibleAll =
    //     // // visibleEls.length == dialog.messages.length;
    //     // // if (isStartVisible && isVisibleAll) {
    //     if (messengerMonitor.isStartVisible()) {
    //         time_from = dialog.messages[0].data.created;
    //     }
    // } else {
    //     // time_from = dialog.messages[0].data.created
    // }

    time_from =
        time_from || dialog.messages[0]
            ? dialog.messages[0].data.created
            : undefined;
    dialog.historyQueue = api.queueHistory({
        // ws: this.ws,
        to_id: [NotificationUserIdTypeEnum.DIALOG_ID, dialogId],
        dialog_single: true,
        time_from: time_from,
        // time_from: dialog.last_message.data.created,
    });
    dialog.historyPending = true;
    return dialog.historyQueue;

    // if (
    //     !!time_from
    //     // && !dialog.messages
    //     // && dialog.messages.length == 0
    // ) {
    //     console.warn(
    //         `[MessengerService] updateDialogHistory(): requesting history of dialogId=${dialogId} with "time_from"=${time_from}`
    //     );
    //     dialog.historyQueue = api.queueHistory({
    //         // ws: this.ws,
    //         to_id: [NotificationUserIdTypeEnum.DIALOG_ID, dialogId],
    //         dialog_single: true,
    //         time_from: time_from,
    //     });
    //     dialog.historyPending = true;
    //     return dialog.historyQueue;
    // } else {
    //     if ([undefined, false].includes(dialog.historyPending)) {
    //         time_from = dialog.messages[0].data.created;
    //         dialog.historyQueue = api.queueHistory({
    //             // ws: this.ws,
    //             to_id: [NotificationUserIdTypeEnum.DIALOG_ID, dialogId],
    //             dialog_single: true,
    //             time_from: time_from,
    //             // time_from: dialog.last_message.data.created,
    //         });
    //         dialog.historyPending = true;
    //         return dialog.historyQueue;
    //     }
    //     console.warn(
    //         `[MessengerService] updateDialogHistory(): skipping request history of dialogId=${dialogId} with "time_from"=${time_from}`
    //     );
    //     console.debug(
    //         `[MessengerService] updateDialogHistory(): dialog=`,
    //         dialog
    //     );
    // }
    // return false;
}

export function sendMessageText(txt) {
    if (txt == "" || !txt) {
        console.warn("[MessengerService] sendMessageText(): no text to send: ", txt)
        return
    }
    if (hasContactsInTxt(txt)){
        throw new MessengerFoundContactsError()
    }
    // this.waitForSocketConnection(this.ws, sendMessage.bind(this, {
    //     ws: this.ws,
    //     to_id: this._getToIdSend(),
    //     from_id: this.currentClientId,
    //     message:this.textInput,
    // }))
    if (currentDialogId.value == NEW_DIALOG_ID){
        setTimeout(() => {
            deleteDialog(NEW_DIALOG_ID);
            currentDialogId.value = undefined
        }, NEW_DIALOG_SEND_TIMEOUT)
    }
    return api.sendMessage({
        // ws: this.ws,
        to_id: _getToIdSend(),
        from_id: currentClientId.value,
        message: txt,
    });
    // this.setWsOpened();
    // this.waitForSocketConnection(this.ws, stopTyping.bind(this, {
    //     ws: this.ws,
    //     to_id:this._getToIdSend(),
    //     from_id: this.currentClientId,
    // }))

    // // disabled "Typing" function
    // stopTyping({
    //     ws: this.ws,
    //     to_id: this._getToIdSend(),
    //     from_id: this.currentClientId,
    // });
    
}

export function updateDialogsDisplay(force = false) {
    // console.log(
    //     "[MessengerService] updateDialogsDisplay(): messenger dialogs=",
    //     dialogs
    // );
    // console.log(
    //     "[MessengerService] updateDialogsDisplay(): messenger members=",
    //     this.members
    // );
    const newDialogsDisplay = [];
    let allUnread = 0;
    for (const dialog_id in dialogs) {
        const d = dialogs[dialog_id];
        const messagesCount = d.messages ? d.messages.length : 0;
        const hasMessages = messagesCount > 0;
        const lastMessage = hasMessages
            ? d.messages[messagesCount - 1]
            : d.last_message;
        // const messagesCountDisplay = hasMessages ? messagesCount : undefined
        const unreadMessagesCount = hasMessages
            ? getUnreadDialogMessagesCount(dialog_id)
            : d.unread_messages_count;
        newDialogsDisplay.push({
            ...excecBeforeAppendMessagePipeline({...lastMessage}),
            dialog_id,
            unreadMessagesCount,
        });
        allUnread += unreadMessagesCount;
    }
    // console.log(
    //     "[MessengerService] updateDialogsDisplay() newDialogsDisplay=",
    //     newDialogsDisplay
    // );
    display.dialogs = newDialogsDisplay;
    allUnreadMessagesCount.value = allUnread;
    // console.log(
    //     "[MessengerService] updateDialogsDisplay() dialogsDisplay=",
    //     dialogsDisplay.value
    // );
    // console.log(
    //     "[MessengerService] updateDialogsDisplay() display.dialogs=",
    //     display.dialogs
    // );
    // bus.emit(EVENTS.TRIGGER_INDICATE, {
    //     name: "unreadMessagesCount",
    //     value: this.getAllUnreadMessagesCount(),
    // });
    // bus.emit(EVENTS.DIALOGS_DISPLAY_UPDATED, {
    //     dialogsDisplay: dialogsDisplay,
    // });
}

export function getUnreadDialogMessagesCount(dialogId) {
    const dialog = dialogs[dialogId];
    const messages = dialog.messages;
    if (!messages || !messages.length) {
        return dialog.unread_messages_count;
    }
    const messagesCount = getUnreadMessagesCount(messages);
    // if (!messagesCount || dialog.historyPending === undefined) {
    //     return dialog.unread_messages_count;
    // }
    return messagesCount;
}
export function getUnreadMessagesCount(messages) {
    if (!messages || !messages.length) {
        return 0;
    }

    // console.log("[MessengerService] getUnreadMessagesCount(): myClientId=",myClientId)
    const unread = messages.filter(
        (m) =>
            m.data.status != MessageStatus.READ &&
            !equalId({ id1: m.from_id, id2: currentClientId.value })
    );
    return unread.length;
}
export function getAllUnreadMessagesCount() {
    const dialog_ids = Object.keys(dialogs);
    return dialog_ids.reduce(
        (unread_count, d_id) =>
            (unread_count += getUnreadDialogMessagesCount(d_id)),
        0
    );
}
export function markRead(visibleMessages = []) {
    const markedAsRead = [];
    for (const m of visibleMessages) {
        if (
            m.data.status != MessageStatus.READ &&
            !equalId({
                id1: m.from_id,
                id2: currentClientId.value,
            })
        ) {
            m.data.status = MessageStatus.READ;
            markedAsRead.push(m.data.message_id);
        }
    }
    // const messagesEls = [
    //     ...document.querySelectorAll(
    //         ".dialog-messagesWrapper>*"
    //     ),
    // ];
    // console.log(
    //     "[ProfileMessengerComponent] markReadCurrentDialog(): messagesEls=",
    //     messagesEls
    // );
    // console.log(
    //     "[ProfileMessengerComponent] markReadCurrentDialog(): this.messages[]=",
    //     this.messages
    // );
    // console.log(
    //     "[ProfileMessengerComponent] markReadCurrentDialog(): markedAsRead=",
    //     markedAsRead
    // );
    // console.log(
    //     "[ProfileMessengerComponent] markReadCurrentDialog(): visibleMessages=",
    //     visibleMessages
    // );
    if (markedAsRead.length > 0) {
        return api.markRead({
            messages_ids: markedAsRead,
            to_id: _getToIdSend(),
        });
        // updateDialogsDisplay(true) // todo: fix this
        // bus.emit(EVENTS.TRIGGER_INDICATE, {
        //     name: "unreadMessagesCount",
        //     value: this.getAllUnreadMessagesCount(),
        // });
    } else {
        // this.updateDialogsDisplay();
    }
}

const _rxs = []

export function getContactsRegExps(){
    // TODO: improve regexps
    if (!_rxs.length){
        _rxs.push(
            // phone 1
            // ["phone", new RegExp("\\+?\\(?\\d*\\)? ?\\(?\\d+\\)?\\d*([\\s./-]?\\d{2,})+", "ig")],
            // ["phone", new RegExp("(\\+?\\(?\\d*\\)?[*,/]?\\(?\\d+\\)?\\d*([*\\s./-]?\\d{2,})+.*){3,50}", "g")],
            // ["phone", new RegExp("\\+?\\(?\\d*\\)?[ ,*]?\\(?\\d+\\)?\\d*([\\s./-]?\\d{2,})+", "g")],
            ["phone", new RegExp("\\+?\\(?[\\d,\\.]*\\)?.*\\(?\\d+\\)?\\d*([\\s+\\./-]*\\d{2,15})+", "g")],
            // phone 2
            // new RegExp("^[\.-)( ]*([0-9]{3})[\.-)( ]*([0-9]{3})[\.-)( ]*([0-9]{4})$", "ig"),
            // email
            ["email", new RegExp("[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*", "ig")],
            
            // url (+ non latin unicode urls)
            // ["url_unicode", new RegExp("(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?", "ig")],
            // url
            ["url", new RegExp("(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z0-9]+-?)*[a-z0-9]+)(?:\\.(?:[a-z0-9]+-?)*[a-z0-9]+)*(?:\\.(?:[a-z]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?", "ig")],
        )
    }
    return _rxs
}
try{
    getContactsRegExps()
}catch(e){
    console.error("[MessengerService] bad regexp:", e)
}

export function hasContactsInTxt(txt) {
    const rxs = getContactsRegExps()
    for(const rx of _rxs){
        rx[1].lastIndex = 0;  // reqiored for /g key (https://stackoverflow.com/a/8911576)
        if (rx[1].test(txt)){
            return rx[0]
        }
    }
    return undefined
}

export default {
    api: { ...api },
    // queueHistory: api
    sendMessageText,
    // markRead,
    // connector: _connector,
    // getConnector,
    MessageStatus,
    NotificationUserIdTypeEnum,
};

// export function *connector(props = {}) {
//     yield* connect(props);
// };

// export default {
//     WsDataTypeEnum,

// };
