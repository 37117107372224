import { $ } from "@/globals";
import { reactive } from "vue";


export const modalVisibillity = reactive({})


$(".modal").on("hidden.bs.modal", (e) => {
    modalVisibillity[e.target.id] = false
});
$(".modal").on("show.bs.modal", (e) => {
    modalVisibillity[e.target.id] = true
});
