import BaseCompositeComponent from "@/composites/BaseCompositeComponent";
import SchemaStorageCompositeMixin from "@/composites/SchemaStorageCompositeMixin";
import { update as topsUpdate } from "@/content/tops/services";

import { UP_TOP_REGION_CONTENT_SLUG, UP_TOP_CITY_CONTENT_SLUG } from "../constants";
import { order } from "@/content/services";
import { Exception } from "sass";

// export { PROFILE_OWNER_RELATION }

/**
 * Компонент (композит из компонетов) представляющий профиль пользователя
 */
export default class extends BaseCompositeComponent {
    constructor(config) {
        super({ ...config, schema: "user_content" });
        this.vueComponent = {
            mixins: [SchemaStorageCompositeMixin],
            components: {
                // Buttons
                // SchemaStorageSaveButton,
                // SchemaStoragePullButton,
                // // Fields
                // ProfileRawTextField,
                // ProfilePlainField,
                // ProfileSelectField,
                // ProfileAutocompleteSelectField,
                // ProfileImagesCollectionField,
                // ProfileAgeField,
                // ProfileNumberField,
                // ProfileTextAreaField,
                // ProfileRangeField,
                // Lists
                // ProfilesList,
                // // Modals
                // DialogModal,
            },
            data() {
                return {
                    // topupAmount: DEFAULT_TOPUP_AMOUNT,
                };
            },
            beforeMount() {
                // this.pullSchemaValues(this.compositeSchema)
            },
            methods: {
                order(contentSlug){
                    order({ 
                        content_slug: contentSlug 
                    }).then((result)=>{
                        console.log('[UpInRegionCityModal] order() then: result=', result)
                        // this.getValuesStorage().search_position=1;
                        topsUpdate()
                        window.closeModal(this.compositeConfig.mountPoint);
                    }).catch((cause)=>{
                        window.closeModal(this.compositeConfig.mountPoint);
                    })
                },
                onClickOrderRegion() {
                    this.order(UP_TOP_REGION_CONTENT_SLUG)
                },
                onClickOrderCity() {
                    this.order(UP_TOP_CITY_CONTENT_SLUG)
                },
                
                getShowcaseContent(slug){
                    const showcase = this.getValuesStorage().content_showcase;
                    console.log(`[UpInSearchModal] getShowcaseContent(): showcase=`, showcase)
                    return showcase.filter(value => value.slug==slug)[0]
                },
                getRegionContentParams(){
                    const content = this.getShowcaseContent(UP_TOP_REGION_CONTENT_SLUG);
                    // console.log(`[UpInSearchModal] getContentParams(): content=`, content)
                    return content;
                },
                getCityContentParams(){
                    const content = this.getShowcaseContent(UP_TOP_CITY_CONTENT_SLUG);
                    // console.log(`[UpInSearchModal] getContentParams(): content=`, content)
                    return content;
                },
            },
            computed: {
                // contentParams() {
                //     return this.getContentParams()
                // },
                // amount(){
                //     return contentParams.price
                // },
                amountTypeStr() {
                    return 'дней'
                    // TODO: добавить получение из openapi информации о типе
                    // return this.contentParams.amount_type  
                }
            },
        };
    }
}
