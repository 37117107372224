import bus from "@/utils/eventBus";
import api from "@/api/index";
import EVENTS from "./events";

import { loadMissingContent as loadTopsMissingContent} from "./tops/services";
import { DEFAULT_CONTENT_KEY } from "./constants";
import { userContent, profile, loggedInUserId, setReady, setFailed, setLoading } from "./state";

/**
 * Сервис описывающий бизнес-логику связанную с контентом.
 * @module
 */


export function loadMissingContent(){
    console.log(`[content/coint/services] loadMissingContent(): `);
    setLoading(DEFAULT_CONTENT_KEY)
    const callTopsUserContent = loadTopsMissingContent()
    const callUserContent = api.apiCall({
        name: "content_user_me_api_v1_content_user_content_get",
    }).then(function (response) {
        // console.log(
        //     `[content/services] loadMissingContent() then: response=`,
        //     response
        // );
        for(const key in response.data){
            if (response.data[key] === undefined 
                || response.data[key] === null
            ){
                continue
            }
            userContent[key] = response.data[key]
            setReady(key)
        }
        return response
    })
    .catch(function (cause) {
        setFailed(DEFAULT_CONTENT_KEY, cause)
        // console.log(
        //     `[content/services] loadMissingContent() catch: cause=`,
        //     cause
        // );
        // throw cause;
    });
    
    return {callUserContent, callTopsUserContent}
}

export function order(data) {
    console.log(`[content/coint/services] order(): data=`, data);
    return api
        .apiCall({
            name: "billing_content_order_api_v1_billing_ordering_order_post",
            data: data,
        })
        .then(function (response) {
            console.log(
                `[content/coint/services] order() then: data=${data}, response=`,
                response
            );
            bus.emit(EVENTS.ORDERED_SUCCESSFULLY, { response });
            // delete imageData.toPost;
            return response
        })
        .catch(function (cause) {
            console.log(
                `[content/coint/services] order() catch: data=${data}, cause=`,
                cause
            );
            bus.emit(EVENTS.ORDERING_FAILED, { cause });
            throw cause;
            // Object.assign(imageData, previousImageData)
        });
}
